import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import $ from "jquery"
import SidebarWrap from "./style"
import SearchIcon from "@mui/icons-material/Search"
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft"
import { OverlayTrigger } from "react-bootstrap"
import { Popover } from "react-bootstrap"
import SideMenuArrowIcon from "../../../../assets/images/SideMenuArrowIcon.svg"
import {
  setAddNewTemplate,
  setAddPriorityView,
  setAllTaskView,
  setMyTaskView,
  setNewDocumentModel,
  setNewProjectModel,
  setProjectView
} from "../../../../redux/Tracking/actionCreator"
import UIModal from "../../../Reuseable/Modal"
import Workflow from "../../Portal/Workflow"
import { AddDocumentModel } from "../../../Reuseable/AddDocumentModel"
import { BsPlusLg } from "react-icons/bs"
import { NavLink } from "react-router-dom/dist"

const togleMenu2 = () => {
  $(".backtoMainSideBar").toggleClass("backtoMainSideBarActive")
}

const togleMenu3 = () => {
  $(".backtoMainSideBar").removeClass("backtoMainSideBarActive")
}

const popperstyle = {
  color: "black",
  fontSize: "13px",
  padding: "2px",
  background: "white"
}

const CreateItem = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showDetails, setShowDetails] = useState(false)
  const [selected, setSelected] = useState(null)
  const [showAddNewWorkflow, setShowAddNewWorkflow] = useState(false)
  const [showDoc, setShowDoc] = useState(false)

  let getAllWorkFlow = props.getAllWorkFlow

  const showAddWorkFlow = () => {
    setSelected(null)
    setShowAddNewWorkflow(true)
    setShowDetails(false)
  }
  const handleNavigateClick = (name) => {
    switch (true) {
      case name === "All Task":
        navigate("/tracking")
        dispatch(setAllTaskView("addnew"))
        break
      case name === "My Task":
        navigate("/myTasks")
        dispatch(setMyTaskView("addnew"))
        break
      case name === "Projects":
        dispatch(setProjectView("add project"))
        navigate("/projects")
        break
      case name === "Project model":
        navigate("/projectmodel")
        dispatch(setNewProjectModel("New ProjectModal"))
        break
      case name === "Priority":
        dispatch(setAddPriorityView("New Priority"))
        navigate("/priority")
        break
      case name === "Template":
        navigate("/template")
        dispatch(setAddNewTemplate("New Template"))
        break
      case name === "WorkFlow":
        navigate("/workflow")
        showAddWorkFlow()
        break
      case name === "Documents":
        navigate("/documents")
        dispatch(setNewDocumentModel("new document"))
        setShowDoc(true)
        break

      default:
        break
    }
  }

  let itemJsx

  let myTrackingList = useSelector((state) => state.Tracking.myTrackings)
  let allTrackingsList = useSelector((state) => state.Tracking.allTrackings)

  let items = []
  myTrackingList?.forEach((element) => {
    items.push(element)
  })

  let item1 = []
  allTrackingsList?.forEach((element) => {
    item1.push(element)
  })

  if (props.item.type == null) {
    itemJsx = (
      <>
        <NavLink
          to={props.item?.path}
          className={"nav-link" + (props.darkmode === true ? " darkmode " : "")}
        >
          <div>
            {props.item.name === "My Task" ? <span style={{ marginLeft: "-2%" }}></span> : ""}
            <OverlayTrigger
              rootClose='false'
              placement='right'
              overlay={<Popover style={popperstyle}>{props.item.name}</Popover>}
            >
              <img alt='img' className='me-2' src={props.item.leftIcon} />
            </OverlayTrigger>
            <span className='dsktopView'>{props.item.name}</span>
            {/* } */}
          </div>
          {(props.item.name === "All Task" && props?.item?.permission?.create) ||
          (props.item.name === "My Task" && props?.item?.permission?.create) ||
          (props.item.name === "Projects" && props?.item?.permission?.create) ||
          (props.item.name === "Project model" && props?.item?.permission?.create) ||
          (props.item.name === "Priority" && props?.item?.permission?.create) ||
          (props.item.name === "Template" && props?.item?.permission?.create) ||
          (props.item.name === "Documents" && props?.item?.permission?.create) ||
          (props.item.name === "WorkFlow" && props?.item?.permission?.create) ? (
            <span
              className='sidebar_plus_icon'
              onClick={() => {
                handleNavigateClick(props.item.name)
              }}
            >
              <BsPlusLg className='plus_bg_icon' />
            </span>
          ) : (
            <>
              <li></li>
            </>
          )}
        </NavLink>
      </>
    )
  } else if (props.item.type === "collapse") {
    itemJsx = (
      <>
        <a
          className={"nav-link expandNav" + (props.darkmode === true ? " darkmode " : "")}
          data-bs-toggle='collapse'
          href={"#" + props.item.name}
          aria-expanded='false'
        >
          <img className='me-2' width='20' height='20' src={props.item.leftIcon} alt='left-icons' />
          <span className='dsktopView'>{props.item.name}</span>
          <span className='navItemArrow'>
            <img src={SideMenuArrowIcon} alt='SideMenuArrowIcon' />
          </span>
        </a>
        <div className='collapse dropmenuitems' id={props.item.name}>
          <ul>
            {props.item?.child?.map((item, i) => {
              return (
                <li className='nav-item' key={i}>
                  {item.type === null ? (
                    <NavLink
                      className={
                        "nav-link linkNavItem" + (props.darkmode === true ? " darkmode " : "")
                      }
                    >
                      <span>{item.name}</span>
                    </NavLink>
                  ) : (
                    <>
                      <NavLink
                        className={"nav-link" + (props.darkmode === true ? " darkmode " : "")}
                        to={item.path}
                      >
                        <img
                          className='me-2'
                          width='20'
                          height='20'
                          src={item.leftIcon}
                          alt='left-icons'
                        />
                        <span className='dsktopView'>{item.name}</span>
                      </NavLink>
                      <div className='collapse dropmenuitems' id={item.name}>
                        <ul>
                          {item.child?.map((item, i) => {
                            return (
                              <li className='nav-item' key={i}>
                                <NavLink
                                  className={
                                    "nav-link linkNavItem" +
                                    (props.darkmode === true ? " darkmode " : "")
                                  }
                                  to={item.path}
                                >
                                  <span>{item.name}</span>
                                </NavLink>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      </>
    )
  } else {
    itemJsx = (
      <>
        <a
          className={"nav-link" + (props.darkmode === true ? " darkmode " : "")}
          href='#'
          onClick={togleMenu2}
        >
          <img className='me-2' width='20' height='20' src={props.item.leftIcon} alt='left-icons' />
          <span className='dsktopView'>{props.item.name}</span>
          <span className='listArowIcon'>
            <ArrowCircleLeftIcon
              style={{ float: "right", marginTop: "2px", marginRight: "10px" }}
            />
          </span>
        </a>
        <div className={"backtoMainSideBar" + (props.darkmode === true ? " darkmode " : "")}>
          <div className='backLinkMain'>
            <a
              className={"backlink" + (props.darkmode === true ? " darkmode " : "")}
              href='#'
              onClick={togleMenu3}
            >
              <ArrowCircleLeftIcon />
              <span className='backTxt'>Back</span>
            </a>
          </div>
          <div
            className={"sidebar sidebarInerLinks" + (props.darkmode === true ? " darkmode " : "")}
          >
            <div className='d-flex flex-column'>
              <div className='nav-menu'>
                <ul className='nav flex-column'>
                  {props.item?.child?.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        <CreateItem item={item} key={i} darkmode={props.darkmode} />
                      </React.Fragment>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <li className='nav-item'>{itemJsx}</li>
      <UIModal
        title={"Add New Workflow"}
        showModal={showAddNewWorkflow}
        setShowModal={setShowAddNewWorkflow}
      >
        <Workflow
          showAddNewWorkflow={showAddNewWorkflow}
          setShowAddNewWorkflow={setShowAddNewWorkflow}
          getAllWorkFlow={getAllWorkFlow}
        />
      </UIModal>
      <AddDocumentModel show={showDoc} setShow={setShowDoc} />
    </>
  )
}

const SideMenu = ({ sideBar, getAllWorkFlow, getAllDocuments }) => {
  const darkmode = useSelector((state) => state.Layout.darkmode)

  return (
    <div className='sidebar sidebarMainLinks'>
      <div className='d-flex flex-column'>
        <div className='nav-menu'>
          <ul className='nav flex-column'>
            {sideBar.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <CreateItem
                    item={item}
                    key={i}
                    darkmode={darkmode}
                    getAllWorkFlow={getAllWorkFlow}
                    getAllDocuments={getAllDocuments}
                  />
                </React.Fragment>
              )
            })}
            <li style={{ height: "50px" }}></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const SideMenuBar = () => {
  const [filteredData, setFilteredData] = useState([])
  const darkmode = useSelector((state) => state.Layout.darkmode)
  const sidebarData = useSelector((state) => state.Auth.menuList)

  const Menus = []

  sidebarData?.forEach((one) => {
    if (one.access === true) {
      Menus.push(one)
    }
    return 0
  })

  useEffect(() => {
    setFilteredData(<SideMenu sideBar={Menus || ["/login"]} darkmode={darkmode} />)
  }, [])

  const searchfilter = (query, sideBar, dataArray = []) => {
    sideBar?.forEach((item) => {
      if (item.name.toLowerCase().includes(query.toLowerCase())) {
        dataArray.push(item)
      } else if (item.child) {
        searchfilter(query, item.child, dataArray)
      }
    })
    setFilteredData(<SideMenu sideBar={dataArray} darkmode={darkmode} />)
  }

  const handleSearch = (e) => {
    searchfilter(e.target.value, Menus)
  }
  return (
    <>
      <SidebarWrap className='sideManubarMain'>
        <div className='sidebar sidebarMain'>
          <div className='searchFieldPart'>
            <div className='serchFieldMain position-relative'>
              <SearchIcon />
              <input
                className='form-control me-2 searchField'
                name='searchField'
                type='text'
                onChange={handleSearch}
                placeholder='Search'
              />
            </div>
          </div>
          {filteredData}
        </div>
      </SidebarWrap>
    </>
  )
}

export default SideMenuBar
