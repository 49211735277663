import Styled from "styled-components";

const WorkflowWrap = Styled.div`

  
  .link {
    cursor: pointer;
    color: #4eb6ee;
    position: relative;
    z-index: 3;
    /* float: right; */
    bottom: 0;
  }
.table-view-card
{
    background-color: ${({ theme }) => theme["tableViewCard"]};
    margin-top: 10px;
}
.mobHead{
    display: none;
}
.css-zpcwqm-MuiStepConnector-root {
   top: 38px;
   position: absolute;
}
.workflow_item_box {
    display: flex;
    align-items: center;
    justify-content: center;
    translate: -6px 0px;
}
.divide{
    margin-top: 5px;
    color: ${({ theme }) => theme["themeColor"]};
}
.layout-button{
    color: ${({ theme }) => theme["themeColor"]};
}
.layout-button.active{
    color:  #4eb6ee;
}

 .workflow-dataV::before {
    content:"⇣";
    position: absolute;
    z-index: 0;
    top: -50px;
    bottom: -50px;
    left: 47%;
    font-size: 40px;
    color:  #4eb6ee;
    height: 45px;
    }
    .workflow-data0::before {
     display: none;
    }
    .workflow-data::before {
        content:"⇢";
        position: absolute;
        z-index: 0;
        top: -10px;
        font-size: 40px;
        bottom: -50px;
        left: -35px;
        color: #5FCCF2;
        }
        .workflow-data0::before {
         display: none;
          content:"";
        }
        .flow-chart{
            display: flex;
            overflow-y: hidden;
        }
        .flow-chart::-webkit-scrollbar {
            width: 3px;
            height: 8px;
            opacity: 0.6;
            cursor: pointer;
          }
          .flow-chart::-webkit-scrollbar-track {
            background:  grey;
            border-radius: 10px;
            height: 3px;
          }
          
          .flow-chart::-webkit-scrollbar-thumb {
            background-color: #bfbfbf;
            border-radius: 10px;
            height: 3px;
            cursor: pointer;
          }
          .flow-modal-view{
            color: ${({ theme }) => theme["themeColor"]};
            display: block;
            align-items: center;
            justify-content: center;
            padding-left: 40px;
            padding-top: 20px;
          }

.form-label{
    float: left;
   }
   .form-label{
    color: ${({ theme }) => theme["themeColor"]};
  }
  .form-control:focus {
          border: 1px solid  ${({ theme }) => theme["focusColor"]};        
        }
   .form-control{
    background-color: ${({ theme }) => theme["tableViewCard"]};
    border: 1px solid  ${({ theme }) => theme["normalColor"]};
    // opacity: 0.50 ! important;
    border-radius: 5px;
    color: ${({ theme }) => theme["inputColor"]};
    }  

.form-control input::-moz-placeholder {
    /* Firefox 19+ */
    color: ${({ theme }) => theme["gray-color"]};
}

.form-control input:-ms-input-placeholder {
    /* IE 10+ */
    color: ${({ theme }) => theme["gray-color"]};
}
.workflowPage h3{
    font: normal normal 600 15px/26px Montserrat;
    color: #4EB6EE;
    margin-top: 10px;
}
.workflow-btn{
    background: #4EB6EE;
    border: #4EB6EE;
    font: normal normal 600 15px/20px Montserrat;
    padding: 6px 16px 6px 16px;
    float: right;
    margin-bottom: 5px;
}
.defaultbtn{
    margin-left: 27px;
}
.workflow-btn:active{
    background: #4EB6EE;
    border: #4EB6EE;
}
.label{
    color: ${({ theme }) => theme["themeColor"]};
    display: flex;
      justify-content: center;
    align-items: center;
    width: 180px;

}
.edit-block{
    color: ${({ theme }) => theme["gray-color"]};
}
.icon-color{
    color: #4EB6EE;
    width: 16px;
}
.nav-tabs .nav-link {
    border: none;
}
.tasks-tabs .nav-link.active {
    color:  #4EB6EE;
    border: none;
}
.tasks-tabs  .nav-link:hover {
    border: none;
}
.workflowPage table thead th {
    white-space: nowrap;
    width: 22%;
}
.workflow-data{
    border-radius: 5px;
    display: flex;
    align-items: center;
   width: 200px;
    height: 40px;
    display: flex;
    margin-right: 35px;
}
.workflow-dataV{
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: 215px;
    height: 40px;
    display: flex;
    margin-bottom: 40px;
}
.flow-chart{
    display: flex;
    overflow-y: hidden;
}
.flow-chart::-webkit-scrollbar {
    width: 3px;
    height: 8px;
    opacity: 0.6;
    cursor: pointer;
    // background-color:  ${({ theme }) => theme["focusColor"]}
  }
  .flow-chart::-webkit-scrollbar-track {
    background:  grey;
    border-radius: 10px;
    height: 3px;
  }
  
  .flow-chart::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 10px;
    height: 3px;
    cursor: pointer;
  }

.card-content{
    display: flex;
}
.workflow-name{
    display: flex;
    // word-break: break-all;
}
.modal .modal-content{
 padding: 5px;
}
.theme-color{
    color: ${({ theme }) => theme["themeColor"]};
}
.card-content{
    display: flex;
}
.card-contentV{
   display: inline-flex;
   height: 100%;
   width: 248px;
}
.vertical-container{
    height: 310px; 
    width: 100%;
    overflow: hidden;
}
.input-control {
    background-color: #3e3f3e00;
    border: 1px solid rgb(255 255 255 / 0%);
    border-radius: 5px;
    height: 100%;
    width: 100%;
}
.input-control-hori{
    background-color: #3e3f3e00;
    border: 1px solid rgb(255 255 255 / 0%);
    border-radius: 5px;
    height: 100%;
    width: 135px;
}
.blur-block:after {
    content  : "";
    position : absolute;
    z-index  : 1;
    bottom   : 0;
    left     : 0;
    pointer-events   : none;
    border-radius: 10px;
    background-image : ${({ theme }) => theme["blurview"]};
    width    : 100%;
    height   : 2em;
  }
  .workflowname{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  
  }
.edit-block{
    width: 30px;
    height: 42px;
    background-color: ${({ theme }) => theme["searchBar"]};
    margin-left: 10px;
    border-color: ${({ theme }) => theme["searchBar"]};
    display: flex;
    align-items: center;
    justify-content: center;
}
.dropdown-toggle {
    background-color: transparent;
    outline: none;
    border: none;
    &::after {
      display: none !important;
    }
}
    .dropdown-toggle:focus {
        background-color: transparent;
        border: none;
        outline: none;
      }
    
      .dropdown-menu.show {
        height: auto;
        min-height: 40px !important;
        min-width: 120px !important;
        overflow-x: hidden;
      }
  .dropdown-item {
  padding: 4px 0px 2px 0px;
  &:hover {
    background-color: hsla(201, 82%, 62%, 0.2) !important;
  }
 }
 .content-wrapper {
    overflow-x: scroll;
 }
 .wf-container{
    min-height: 70vh;
 }
 .modal-view-data{
    color: ${({ theme }) => theme["themeColor"]};
    font-size: 13px;
    padding-top: 15px;
    padding-left: 15px;
 }
 .horizontalplus{
    display: flex;
    align-items: center;
    justify-content: center;
    right: 20px;
    position: relative;
 }
 .verticalplus{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; 
    bottom: 20px;
 }
 .verticalviewplus{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; 
    bottom: 20px;
    right: 16px;
 }
@media (max-width: 1023px) {
    .mobHead{
        display: unset;
    }
    .mobSubhead{
        margin-left: 10px;
    }
    .workflowPage table thead th {
        display: none;
    }
    .actionBtns{
        text-align: center;
    }
    .defaultbtn{
        margin-left: unset;
    }
    .workflowPage table tbody tr td {
        white-space: break-spaces;
        padding: 10px 15px !important;
        width: auto !important;
        display: block;
    }
}
.workflow-col{
    
}
@media (max-width: 767px) {
     .workflow-col {
        display:flex;
        align-items: center;
        justify-content: center;
        left: 50%;
     }
}
        
`;

export default WorkflowWrap;
