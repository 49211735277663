import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import Searchbox from "../../../../Reuseable/Searchbox";
import { setFilters } from "../../../../../redux/Tracking/actionCreator";
import plus from "../../../../../assets/images/icons/plus.svg";
import download from "../../../../../assets/images/icons/download1.svg";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DatePicker } from "antd";

export const KpiTableHeader = ({
    setProjectView,
    searchValue,
    handleDueSearch,
    userRole,
    userRoleType,
    allMembers,
    cardView,
    refreshAllTracking,
    loading,
    allProjects,
    allKpiPointsData,
    setTrackingFilters,
    createPermission,
    selectedRows,
    selectAll,
    setSearchFromDate,
    searchfromDate,
    searchDueDate,
    handleFromDate
}) => {
  const item = allKpiPointsData.map(ele => ele)

  const dispatch = useDispatch();
  let allStatus = useSelector((state) => state.Tracking.allFlowStatus);
  let filters = useSelector((state) => state.Tracking.filters);
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid);

  if (allStatus.length > 0) {
    let result = [];
    allStatus?.forEach((element) => {
      result.push({ label: element.label, value: element.id });
    });
    allStatus = result;
  }

  if (allKpiPointsData.length > 0) {
    let result = [];
    allKpiPointsData?.forEach((element) => {
      result.push({ label: element.label, value: element.id });
    });
    allKpiPointsData = result;
  }

  if (allMembers.length > 0) {
    let result = [];
    allMembers?.forEach((element) => {
      result.push({ label: element.personname, value: element.trackinguserid });
    });
    allMembers = result;
  }

  function removeHTML(str) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  }
  const downloadList = () => {
    var A = [
      [
        "Project ID",
        "Project Name",
        "Estimated Start Date",
        "Estimated Due Date",
        "Created By",
        "Priority"
      ],
    ];
    var csvRows = [];

    item.forEach((element) => {
      A.push([element.trackingprojectid,
        element.label.replace(/,/g, ""),
        element.startdate,
        element.duedate,
        element.createdby.replace(/,/g, ""),
        element.priority]);
    });

    for (var i = 0, l = A.length; i < l; ++i) {
      csvRows.push(A[i].join(','));
    }

    var csvString = csvRows.join("\n");
    var a = document.createElement("a");
    a.href = "data:attachment/csv," + encodeURIComponent(csvString);
    a.target = "_blank";
    a.download = `${trackingClientId}_project_list.csv`;
    document.body.appendChild(a);
    a.click();
  };


  return (
    <>
      <Row>
        {/* <p>Employee Performance Board</p> */}
      </Row>
    </>
  );
};