import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import addUserIdea from "../../../../assets/images/AdminImages/User/Product iteration-pana.svg";

const NoUser = ({ CloseAddUser, OpenAddUser }) => {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <Card className="card-bd">
              <Card.Body>
                <div
                  className="fw-bold fs-5 m-4 mt-5"
                  style={{ color: "#4EB6EE" }}
                >
                  You have not added any user to your account.
                </div>
                <div className="ms-4 pb-1">
                  Add user to your account by sending them invitation and keep
                  track of all your projects.
                </div>
                <div className="ms-4">Get started now.</div>

                <Button
                  onClick={OpenAddUser}
                  className="border-0 px-5 py-1 m-4"
                  style={{ background: "#4EB6EE" }}
                >
                  Add User
                </Button>

                <div>
                  <img
                    className="img-fluid float-end"
                    src={addUserIdea}
                    alt="add User idea"
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NoUser;
