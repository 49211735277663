import React, { useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import SideMenuBar from "../sidebar"
import Header from "../header"
import LayoutWrap from "./style"
import $ from "jquery"
import { api } from "../../../../Services/api"
import { useMedia } from "react-use"
import { Alert } from "@mui/material"
import { Box } from "@mui/system"
import UIModal from "../../../Reuseable/Modal"
import Subscribe from "../subscription/Subscribe"
import TrackingModal from "../../Portal/Tracking/TrackingModal"
import { Outlet } from "react-router"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys.js"
import { client } from "../../../.."
import {
  setDocumentData,
  setDocumentList,
  setDocumentNameList
} from "../../../../redux/Tracking/actionCreator"
// import { useWorkflow } from "../../../../Hooks/useWorkflow"

const Main = () => {
  const dispatch = useDispatch()
  const panelview = useSelector((state) => state.Layout.panelview)
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)
  const [showSubscribe, setShowSubscribe] = useState(false)
  const sidebarData = useSelector((state) => state.Auth.menuList)

  const user = useSelector((state) => state.Auth.user)
  const userRole = user?.userRole

  const documentAccess = sidebarData?.find((data) => data?.path === "/documents")?.access

  let filedata = []

  const mobileResponsive = useMedia("(max-width: 767px)")

  useFetch(
    key.get_mydashboardHeaders_data,
    `/masterdata/17`,
    (res) => {
      const { formStructureData } = res
      const sortedFormHeaders = formStructureData.sort((a, b) => {
        return a.column1.localeCompare(b.column1)
      })
      client.setQueryData([key.get_mydashboardHeaders_data], (oldData) => {
        return {
          ...oldData,
          formStructureData: sortedFormHeaders
        }
      })
    },
    () => {},
    false,
    true
  )

  useFetch(
    key.get_table_headers,
    `/masterdata/3`,
    (res) => {
      const { formStructureData } = res
      const sortedFormHeaders = formStructureData.sort((a, b) => {
        return a.column1.localeCompare(b.column1)
      })
      client.setQueryData([key.get_table_headers], (oldData) => {
        return {
          ...oldData,
          formStructureData: sortedFormHeaders
        }
      })
    },
    () => {},
    false,
    true
  )

  useFetch(
    key.get_project_headers,
    "/masterdata/4",
    (res) => {
      const { formStructureData } = res
      const sortedFormHeaders = formStructureData.sort((a, b) => {
        return a.column1.localeCompare(b.column1)
      })
      client.setQueryData([key.get_project_headers], (oldData) => {
        return {
          ...oldData,
          formStructureData: sortedFormHeaders
        }
      })
    },
    () => {},
    false,
    true
  )

  useFetch(
    key.get_workflows_headers,
    "/masterdata/6",
    (res) => {
      const { formStructureData } = res
      const sortedFormHeaders = formStructureData.sort((a, b) => {
        return a.column1.localeCompare(b.column1)
      })
      client.setQueryData([key.get_workflows_headers], (oldData) => {
        return {
          ...oldData,
          formStructureData: sortedFormHeaders
        }
      })
    },
    () => {},
    false,
    true
    // (data) => data?.formStructureData
  )

  useFetch(
    key.get_document_headers,
    "/masterdata/5",
    (res) => {
      const { formStructureData } = res
      const sortedFormHeaders = formStructureData.sort((a, b) => {
        return a.column1.localeCompare(b.column1)
      })
      client.setQueryData([key.get_document_headers], (oldData) => {
        return {
          ...oldData,
          formStructureData: sortedFormHeaders
        }
      })
    },
    () => {},
    false,
    true
  )

  useFetch(
    key.get_backlogs_headers,
    "/masterdata/11",
    (res) => {
      const { formStructureData } = res
      const sortedFormHeaders = formStructureData.sort((a, b) => {
        return a.column1.localeCompare(b.column1)
      })
      client.setQueryData([key.get_backlogs_headers], (oldData) => {
        return {
          ...oldData,
          formStructureData: sortedFormHeaders
        }
      })
    },
    () => {},
    false,
    true
  )

  useFetch(
    key.get_archive_headers,
    "/masterdata/12",
    (res) => {
      const { formStructureData } = res
      const sortedFormHeaders = formStructureData.sort((a, b) => {
        return a.column1.localeCompare(b.column1)
      })
      client.setQueryData([key.get_archive_headers], (oldData) => {
        return {
          ...oldData,
          formStructureData: sortedFormHeaders
        }
      })
    },
    () => {},
    false,
    true
  )

  useFetch(
    key.get_approval_headers,
    "/masterdata/10",
    (res) => {
      const { formStructureData } = res
      const sortedFormHeaders = formStructureData.sort((a, b) => {
        return a.column1.localeCompare(b.column1)
      })
      client.setQueryData([key.get_approval_headers], (oldData) => {
        return {
          ...oldData,
          formStructureData: [...sortedFormHeaders]
        }
      })
    },
    () => {},
    false,
    true
  )

  useFetch(
    key.get_report_tasks_headers,
    "/masterdata/13",
    (res) => {
      const { formStructureData } = res
      const sortedFormHeaders = formStructureData.sort((a, b) => {
        return a.column1.localeCompare(b.column1)
      })
      client.setQueryData([key.get_report_tasks_headers], (oldData) => {
        return {
          ...oldData,
          formStructureData: sortedFormHeaders
        }
      })
    },
    () => {},
    false,
    true
  )

  useFetch(
    key.get_report_weekly_headers,
    "/masterdata/15",
    (res) => {
      const { formStructureData } = res
      const sortedFormHeaders = formStructureData.sort((a, b) => {
        return a.column1.localeCompare(b.column1)
      })
      client.setQueryData([key.get_report_weekly_headers], (oldData) => {
        return {
          ...oldData,
          formStructureData: [...sortedFormHeaders]
        }
      })
    },
    () => {},
    false,
    true
  )

  /************************************Get All Project Data*************************************/
  const OnGetAllProjectSucess = async (res) => {
    if (res.status) {
      let projects = res?.data?.Items?.filter((project) => project !== null)
      projects = projects?.sort((item1, item2) => {
        if (item1.label < item2.label) {
          return -1
        } else {
          return 1
        }
      })
      // Update the cache with the new data
      await client.setQueryData([key.get_all_projects], (oldData) => {
        return {
          ...oldData,
          data: {
            Items: projects,
            ScannedCount: oldData?.data.ScannedCount,
            count: oldData?.data.count
          }
        }
      })
      // getAllProjectDashboardData(res.data?.Items)
    }
  }
  const { data: allProjectsRes } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    OnGetAllProjectSucess,
    () => {},
    false,
    true
  )
  let allProjectsData = allProjectsRes?.data?.Items ?? []

  /**********************************All Workflows*************************************/

  // const { handleWorkflowData } = useWorkflow()

  useFetch(
    key.get_all_workflow,
    `/trekrworkflow`,
    () => {},
    () => {},
    false,
    true
  )

  /**********************************All Members*************************************/
  const ongetAllMemberSucsess = (res) => {
    if (res.status) {
      let members = res?.data
      members = members.sort((item1, item2) => {
        if (item1.personname < item2.personname) return -1
        else return 1
      })
      client.setQueryData([key.get_all_members], (oldData) => {
        return {
          ...oldData,
          data: members
        }
      })
    }
  }

  useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    ongetAllMemberSucsess,
    () => {},
    false,
    true
  )

  /*********************************************for all Tasks Types*******************************************/

  const { data: allTasksTypesRes } = useFetch(
    key.get_all_tasktypes,
    "/trackingtasktype",
    () => {},
    () => {},
    false,
    true
  )
  let allTasksTypes = allTasksTypesRes?.data ?? []

  /*************************************Get All Add New Task Inputs**********************************************/
  const { data: allProjectModalsRes } = useFetch(
    key.get_project_modal_value,
    `/projectmodellist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    true
  )
  let allProjectModals = allProjectModalsRes?.data ?? []

  // TODO: Fetch Template for Templates Menu
  // useFetch(
  //   "get-template",
  //   `/7_templatelist/${trackingClientId}`,
  //   () => {},
  //   () => {},
  //   false,
  //   true
  // );

  if (allProjectModals.length > 0) {
    let ProjectModoptions = []
    allProjectModals.forEach((item) =>
      ProjectModoptions.push({
        label: item.ModelTitle,
        id: item.ProjectModelId
      })
    )
  }

  const formArrOnSuccess = async (res) => {
    let AllProjects = allProjectsData?.map((element) => {
      return {
        label: element?.label,
        id: element?.trackingprojectid
      }
    })

    const formStructureData = res?.formStructureData
    if (Array.isArray(formStructureData) && formStructureData.length > 0) {
      const sortedFormInputs = formStructureData
        .map((item) => ({
          ...item,
          sno: parseInt(item.sno),
          options:
            item.name === "project_name"
              ? AllProjects
              : item.name === "tasktype"
              ? allTasksTypes
              : item.options
        }))
        .sort((a, b) => a.sno - b.sno)
      // Update the cache with the new data
      await client.setQueryData([key.get_all_inputforms], (oldData) => {
        return {
          ...oldData,
          formStructureData: sortedFormInputs
        }
      })
    }
  }

  useFetch(
    key.get_all_inputforms,
    "/masterdata/2",
    formArrOnSuccess,
    () => {},
    false,
    !!allProjectsRes
  )

  /**********************************get All Notification***************************************/

  // useFetch(
  //   "get-AllNotification",
  //   `/tracking/${trackingClientId}/mynotification`,
  //   () => {},
  //   () => {},
  //   false,
  //   true
  // )

  /**********************************get All Document***************************************/
  const OnGetAllDocumentsSucess = (res) => {
    if (res.status) {
      let File = res?.data.documentList
      let FileList = res?.documents

      let FileArr = Object.keys(File).map((key) => File[key][0])

      dispatch(setDocumentList(File))
      for (let i = 0; i < FileArr.length; i++) {
        let data = FileArr[i].fileurls
        let documentfilename = FileArr[i].documentfilename
        getDocumentFileData(
          data,
          FileArr[i].documentfiletype,
          documentfilename,
          FileArr[i].fileurls
        )
      }
      dispatch(setDocumentNameList(FileList))
    }
  }

  useFetch(
    key.get_all_documents,
    `/trackingdocument/${trackingClientId}`,
    OnGetAllDocumentsSucess,
    () => {},
    documentAccess,
    true
  )

  /***********getDocumentFileData****************** */

  const getDocumentFileData = async (data, fileType, documentfilename, filename) => {
    if (data) {
      try {
        const response = await api.get(`/trackingdocument/${trackingClientId}/${data}`, {
          headers: {
            authorizationToken: localStorage.getItem("token")
          }
        })
        let temp = [response.data.url, documentfilename, fileType, filename]
        filedata = [...filedata, temp]
        dispatch(setDocumentData(filedata))
      } catch (error) {
        console.error("getDocumentFileData error")
      }
    }
  }

  /**********************************************************************************/

  const { isLoading: trialPeriodLoading, data: planInformation } = useFetch(
    key.get_trial_period,
    `/trackingadmin/clients/${trackingClientId}`,
    (data) => {
      client.setQueryData([key.get_trial_period], (oldData) => {
        return {
          ...oldData,
          data: {
            ...oldData?.data,
            ...data
          }
        }
      })
    },
    () => {},
    false,
    true,
    (data) => data?.client
  )

  function togleMenu1() {
    $(".page-body-wrapper").removeClass("mobSideBar")
  }

  const [step, setStep] = useState(1)

  const currentStep = (value) => {
    return setStep(value)
  }

  const mainPanelRef = useRef("100%")

  const allTask = sidebarData && sidebarData?.find((data) => data.name === "All Task")

  const [showTaskViewModal, setShowTaskViewModal] = useState(false)

  const [clickAdd, setClickAdd] = useState(false)

  return (
    <LayoutWrap className='adminDshbordBody'>
      <div className='maincontainer-scroller'>
        <Header
          panelview={panelview}
          setShowTaskViewModal={setShowTaskViewModal}
          clickAdd={clickAdd}
          setClickAdd={setClickAdd}
        />
        <div className='container-fluid page-body-wrapper'>
          {panelview === "sidebar" ? <SideMenuBar /> : <></>}
          <div
            style={{ overflow: "auto" }}
            ref={mainPanelRef}
            className={"main-panel " + (panelview === "topmenu" ? "topmenu" : "")}
            onClick={mobileResponsive ? togleMenu1 : null}
          >
            {planInformation?.subscriptionPlan !== "Free" &&
            planInformation?.subscriptionStatus !== "active" &&
            userRole?.toLowerCase() === "admin" &&
            !trialPeriodLoading ? (
              <Box sx={{ width: "100%", marginBottom: "10px" }}>
                <Alert sx={{ padding: "0px 8px" }} variant='filled' severity='error'>
                  Your Free Trial Period Ends on{" "}
                  <Box
                    sx={{
                      color: "gold",
                      display: "inline",
                      fontWeight: "bold"
                    }}
                  >
                    {planInformation?.trailPeriodEndOn}
                  </Box>{" "}
                  Click here to{" "}
                  <span
                    onClick={() => setShowSubscribe(true)}
                    style={{
                      padding: "0px 2px",
                      color: "black",
                      cursor: "pointer"
                    }}
                  >
                    Upgrade
                  </span>
                </Alert>
              </Box>
            ) : (
              <></>
            )}

            <UIModal
              title={step === 1 ? "Choose Your Plan" : "Set up your Credit or Debit Card"}
              showModal={showSubscribe}
              setShowModal={setShowSubscribe}
              size='xl'
            >
              <Subscribe
                subscriptionPlan={planInformation?.subscriptionPlan}
                setShowSubscribe={setShowSubscribe}
                currentStep={currentStep}
              />
            </UIModal>

            <div className='content-wrapper'>
              <TrackingModal
                showTaskViewModal={showTaskViewModal}
                setShowTaskViewModal={setShowTaskViewModal}
                permission={allTask?.permission}
              />

              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </LayoutWrap>
  )
}

export default Main
