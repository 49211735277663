import Styled from "styled-components";

const OTPWrap = Styled.div`
    .user-input{
        margin-top: 20px;
        justify-content: center;
        margin-left: 10px;
    }
    .user-input input{
        border: 1px solid #4EB6EE;
        border-radius: 5px;
        text-align: center;
        width: 32px;
    }
    .alert-message{
        margin-top: 15px;
    }
    .got-otp{
        margin-top: 20px;
        font-size: 13px;
        font-weight: 600;
    }
    .resend-otp{
        color: #4EB6EE;
    }
    .modalbody .form-control{
        color: #000000;
    }
    .loginPageRow{
        display:flex;
    }
    .card-body{
        padding: 0rem 0rem;
    }
    .loginformbody{
        background: #FFFFFF;
    }
    .loginPageContent {
        overflow: auto;
        overflow-x: hidden;
        width: 100%;
        height: 100vh;
        background: #292A29 0% 0% no-repeat padding-box;
        opacity: 1;
        background-blend-mode: multiply;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .loginaccountft{
        margin-bottom: 0px!important;
        margin-top: 30px;
        font-size: 17px;
        font-weight: 600;
        color: black; 
        text-align: center;         
    }
    .mandatorySign{
        display: none;
    }
    .bar-loader{
        margin-right: 6px;
    }
    .btn-verify{
        width: 100px;
        height: 30px;
        padding: 0px;
        font-size: 15px;
        background: #4EB6EE;
        border-width: 2px;
        margin-bottom: 30px;
    }
    .back-btn{
        width: 100px;
        height: 30px;
        padding: 0px;
        font-size: 15px;
        margin-bottom: 30px;
        margin-right: 10px;
    }
    .loginPageRightside {
        margin-top: 220px;
        margin-left: 140px;
    }
    .loginPageLeftside{
        height: 600px;
        float: right;
    }
    .loginFormScreens {
        overflow: hidden;
        overflow-y: auto;
        padding:0px 30px 0px 30px;
        background: #FFFFFF;
        border-radius: 5px;
        opacity: 1;
    }
    .text-center{
        text-align: center
    }
    .btn-name{
        margin-left: 8px;
    }
    .login{
        display: unset;
        line-height: 2rem;
    }
    .loginFormScreens {
        .form-control {
            padding: 10px 20px;
            font-size: 15px;
        }
    }
    .d-block{
        text-align: start;
    }
    .text-center .btn-padding{
        padding: 0px;
    }
    @media (max-width: 1440px){
        .loginPageRightside {
            margin-top: 195px;
            margin-left: 100px;
        }
    }
    @media (max-width: 1024px){
        .loginSubmitBtn{
            width: 50%;
        }
        .loginPageRightside {
            margin-top: 190px;
            margin-left: 70px;
        }
    }
    @media (max-width: 991px){
        .loginPageRightside {
            margin-top: 190px;
            margin-left: 70px;
        }
    }
    @media (max-width: 768px){
        .loginPageRightside {
            margin-left: 55px;
        }
    }
    @media (max-width: 767px){
        .loginFormScreens{
            width: 90%;
            margin-left: 5%;
        }
        .loginPageRightside {
            margin-left: 0px;
        }
    }
    @media (max-width: 500px){
        .loginFormScreens{
            width: 90%;
            margin-left: 5%;
        }
        .loginPageRightside {
            margin-left: 0px;
        }
        .loginaccountft{
            text-align: unset;
        }
    }
    @media (max-width: 325px){
        .loginFormScreens {
            width: 94%;
            margin-left: 3%;
        }
    }
        
`;

export default OTPWrap;
