import React from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import styled from "styled-components";
import OTPInput from "otp-input-react";
import Plan from "./Plan";
import { pricingPlans } from "./Pricings";

const OTPFieldWrap = styled.div`

.price {
    padding-top: 10px;
    color: #5FCCF2;
    font-size: 3rem;
    font-weight: 600;
    .dollar {
        color: white;
        font-size: 1rem;
        font-weight: 100;
    }
}

.verify {
    font-weight: 600;
    font-size: 2rem;
    color: #5FCCF2;
    padding-bottom: 10px;
}

.otp-field {
  display: grid;
  place-content: center;
  place-items: center;
}

.otp-field.col {
  margin: 0px 12px;
}

  input {
    border: 1px solid #4EB6EE;
    border-radius: 0.25em;
    margin: 0 10px;
    color: black;
    &:focus-within,
    &:focus {
      outline: 1px solid #4EB6EE;
    }
  }

  @media screen and (max-width: 767px) {
    .otp-field.col {
        margin: 10px 12px;
      }
  }

  @media screen and (max-width: 420px) {
    input {
      margin: 0 2.5px !important;
    }
  }
`;

const OTPField = ({ otp, updateFields, emailId, plan, resendOTP, otpLoader }) => {

  const selectedPlan = pricingPlans.filter((pricingPlan) => {
    return pricingPlan.tier.includes(plan)
  })

  return (
    <OTPFieldWrap>

      <Row className="text-start pb-4">
        <div className="verify">
          Verify
        </div>

        <div style={{color:"white"}}>
          Enter the 6 digit code for the Selected plan
        </div>
      </Row>

      <Row>
        <Col className="text-start">
          <Plan
            otpPage={true}
            tier={selectedPlan[0].tier}
            price={selectedPlan[0].price}
            title={selectedPlan[0].title}
            duration={selectedPlan[0].duration}
            benefits={selectedPlan[0].benefits}
          />

        </Col>

        <Col style={{ background: "rgba(95, 203, 242, 0.09)" }} className="otp-field">
          <div className="fw-semibold">
            <div className="text-center font-bold fs-5 pt-2 " style={{color:"white"}}>
              Verify your email address to continue
            </div>
            <div className="text-center pt-3" style={{color:"white"}}>
              Enter the 6 digit code sent to {emailId}
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center pt-4">

              <OTPFieldWrap>
                <OTPInput
                  value={otp}
                  onChange={(o) => updateFields({ otp: o })}
                  placeholder="******"
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                // secure
                />
              </OTPFieldWrap>


              <div className="">
                <div className="pt-2" style={{color:"white"}}>
                  Didn't receive a code? <span style={{ color: "#4EB6EE", cursor: "pointer" }} onClick={() => resendOTP()}>Resend</span>
                </div>
              </div>
            </div>

            <div className="text-center p-4">
              <Button
                className="multi-form-btn"
                type="submit"
              >
                {otpLoader ? <Spinner animation="border" size="sm" /> : "Verify"}
              </Button>
            </div>
          </div>
        </Col>
      </Row>

    </OTPFieldWrap >
  );
};

export default OTPField;
