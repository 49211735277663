import Styled from "styled-components";

const EditorWrap = Styled.div`
   padding : 10px 20px 0px 20px;
   border: 1px solid  ${({ theme }) => theme["normalColor"]};
   border-radius: 5px;
   margin-bottom : 15px;
 
   .rdw-fontfamily-dropdown {
      width: 150px;
   }
   .rdw-fontfamily-placeholder{
      color: black;
   }
   .rdw-editor-main{
      overflow : inherit;
      color: ${({ theme }) => theme["themeColor"]};
   }
   a:active, a:hover, a:focus {
      color: #ff9516;
   }
   .rdw-editor-toolbar{
      background-color: ${({ theme }) => theme["tableViewCard"]};
      border: none;
      border-radius: 5px;
      max-width: 800px;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
   }

   .rdw-editor-toolbar .rdw-option-wrapper{
      border: 1px solid #F1F1F1;
      padding: 5px;
      min-width: 30px;
      height: 30px;
      border-radius: 2px;
      margin: 0 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: white;
      text-transform: capitalize;
   }

   @media (max-width:991px){
      .rdw-editor-toolbar{
         padding-bottom: 10px;
   }
} 
}
`;

export default EditorWrap;