import styled from "styled-components";

const CartContainer = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
  }
  .cards {
    width: 100%;
    /* max-height: 120px; */
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-color: ${({ theme }) => theme["border"]};
    border-radius: 10px;
    background-color: ${({ theme }) => theme["tableViewCard"]};
    position: relative;
  }
  .plusIcon {
    font-size: 12px;
  }

  .cardBody {
    display: flex;
    flex-direction: column;
  }
  .cardheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }) => theme["body-text"]};
    margin-top: .125rem;
  }
  .cardheader h1 {
    font-size: 15px;
    text-decoration: underline;

    cursor: pointer;
    text-transform: uppercase;
    /* margin-bottom: 8px; */
    color: ${({ theme }) => theme["body-text"]};
  }
  .created-on {
    color: ${({ theme }) => theme["body-text"]};
    font-size: .8rem;
    font-weight: 100;
    flex-grow: 1;
    white-space: nowrap;
    text-align: end;
  }
  .cardIcon {
    color: #4eb6ee;
    cursor: pointer;
  }
  .cardcontent p {
    color: ${({ theme }) => theme["body-text"]};
    white-space: wrap;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    font-size: 15px;
    padding-right: 1.2rem;
    margin-bottom: .4rem;
    opacity: 0.8;
  }
  .cardfooter {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    gap: 0.5rem;
  }
  .footerBody {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 0.3rem;
    & p {
      margin: 0;
    }
  }
  .Icon {
    font-size: 1rem;
    text-align: center;
    /* padding: .125rem; */
    margin: 0;
    color: #2685E2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: #292A29;
  }
  .footerContent p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme["body-text"]};
    font-size: 15px;
    opacity: 0.8;
  }
  .task-level {
    /* color: hsla(201, 82%, 62%, 1); */
    color: ${({ theme }) => theme["body-text"]};
    font-size: 15px;
    display: flex;
    justify-content: space-between;
  }
  .dropdown-toggle {
    background-color: transparent;
    outline: none;
    border: none;
    &::after {
      display: none !important;
    }
  }
  .dropdown-toggle:focus {
    background-color: transparent;
    border: none;
    outline: none;
  }

  .dropdown-menu.show {
    height: auto;
    width: auto;
    min-width: 80px;
    /* padding: 4px 8px; */
    min-height: 40px !important;
    overflow-x: hidden;
  }
  .assignedTo {
    font-weight: 100;
    font-size: .85rem;
    color: #E0E0E0;
  }

 .dropdown-item {
  padding: 4px 4px 2px 4px;
  &:hover {
    background-color: hsla(201, 82%, 62%, 0.2) !important;
  }
 }
  @media (min-width: 280px) and (max-width: 320px) {
    .card {
      padding: 8px;
    }
    .cardheader h1 {
      font-size: 10px;
      /* margin-bottom: 0px; */
    }
    .moreIcon {
      font-size: 1rem;
    }
    .cardIcon {
      font-size: 12px;
    }
    .cardcontent p {
      font-size: 10px;
      margin-bottom: 0.8rem;
    }
    .footerIcon {
      & h3 {
        font-size: 6px;
        color: #ffffff;
        margin: 0;
      }
      width: 10px;
      height: 10px;
    }
    .footerContent p {
      font-size: 10px;
    }
    .cardTask p {
      font-size: 10px;
    }
    .dropdown-toggle {
      padding: 2px;
    }
    .dropenu {
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0px;

      button {
        padding: 1px 2px;
        border: none;
        outline: none;
        border-radius: 8px;
        font-size: 10px;
      }
    }
  }
  @media screen and (min-width: 320px) and (max-width: 480px) {
    .cardheader h1 {
      font-size: 12px;
    }
    .moreIcon {
      font-size: 1rem;
    }
    .cardcontent p {
      font-size: 12px;
    }
    .footerContent p {
      font-size: 12px;
    }
    .cardTask p {
      font-size: 12px;
    }
    .dropenu {
      padding: 4px 0px 4px 0px;
      gap: 1px;

      button {
        padding: 1px 2px;
        margin-bottom: 3px;
        border: none;
        outline: none;
        border-radius: 8px;
        font-size: 12px;
      }
    }
  }

  @media screen and (min-width: 481px) and (max-width: 720px) {
    .cardheader h1 {
      font-size: 12px;
    }
    .moreIcon {
      font-size: 1rem;
      color: #4eb6ee;
      cursor: pointer;
    }
    .cardcontent p {
      font-size: 12px;
    }
    .footerContent p {
      font-size: 12px;
    }
    .cardTask p {
      font-size: 12px;
    }
    .plusIcon {
      font-size: 12px;
    }
  }
  @media screen and (min-width: 720px) and (max-width: 900px) {
    .card {
      width: 100%;
      max-height: auto;
    }
    .cardheader h1 {
      font-size: 12px;
    }
    .moreIcon {
      font-size: 1rem;
      cursor: pointer;
    }
    .cardcontent p {
      font-size: 12px;
    }
    .footerContent p {
      font-size: 12px;
    }
    .cardTask p {
      font-size: 12px;
    }
  }
`;
export default CartContainer;
