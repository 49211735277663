import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Row } from "react-bootstrap"
import Tables from "../../../Reuseable/Tables"
import "react-confirm-alert/src/react-confirm-alert.css"
import { setMyChartData } from "../../../../redux/Tracking/actionCreator"
import { MyDashboardListColumns } from "./MyDashboardListColumns"
import { MyDashboardLishHeader } from "./MyDashboardLishHeader"
import MydashboardModalView from "./style"
import LineGraph from "./MyDashboardChart"
import filterDateIcon from "../../../../assets/images/filterDateIcon.svg"
import { DatePicker } from "antd"
import moment from "moment"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"
import { api } from "../../../../Services/api"

export default function MyDashboard({ permission }) {
  const editPermission = permission?.edit
  const createPermission = permission?.create
  const deletePermission = permission?.delete
  const viewPermission = permission?.view

  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)
  const user = useSelector((state) => state.Auth.user)
  const authToken = useSelector((state) => state.Auth.authToken)
  let myDashBoardChartData = useSelector((state) => state.Tracking.allMyChartsData)

  const dispatch = useDispatch()
  const { data: allProjectMembers } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMemberres = allProjectMembers?.data ?? []

  const { data: allProjectWorkflowRes } = useFetch(
    key.get_all_workflow,
    `/trekrworkflow`,
    () => {},
    () => {},
    false,
    false
  )
  let allWorkFlow = allProjectWorkflowRes?.data?.workflowList ?? []

  const [searchValue, setSearchValue] = useState("")

  const [chartDueDate, setChartDueDate] = useState(null)
  const [chartfromDate, setChartFromDate] = useState(null)

  const { data: allTrackingListRes, isLoading: loading } = useFetch(
    `get-tracking-${trackingClientId}`,
    `/tracking/${trackingClientId}`,
    () => {},
    () => {},
    false,
    true
  )
  let allTrackingsList = allTrackingListRes?.data ?? []

  useEffect(() => {
    const fetchMyDashBoardList = () => {
      let FromDate = ChartformatDate(chartfromDate)
      let EndDate = ChartformatDate(chartDueDate)
      let url = `/12_mydashboard/${FromDate}/${EndDate}`
      api
        .get(url, {
          headers: {
            authorizationtoken: `${authToken}`
          }
        })
        .then(function (response) {
          dispatch(setMyChartData(response?.data?.data))
        })
        .catch((error) => {
          console.error("fetchMyDashBoardList error", error)
        })
    }

    if (chartfromDate && chartDueDate) {
      fetchMyDashBoardList()
    }
  }, [chartDueDate, chartfromDate, authToken, dispatch])

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const day = date.getDate()
    const month = getMonthName(date.getMonth())
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }

  const getMonthName = (monthIndex) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ]
    return months[monthIndex]
  }

  const getTableData = allTrackingsList
    .sort((a, b) => new Date(b.reporteddate) - new Date(a.reporteddate))
    .slice(0, 10)
    .filter((item) => {
      if (user.userRole === "Admin") {
        return item.trackingprojectstatusid === "0"
      } else {
        return item.trackingprojectstatusid === "0" || item.trackingprojectstatusid === "1"
      }
    })
    .map((item, i) => {
      if (item.trackingclientid === trackingClientId) {
        const Work_Flow = Object.values(allWorkFlow).flatMap((obj) => Object.values(obj))
        let workflow = Work_Flow.find((e) => e.workFlowId == item.workflowid)
        if (workflow) item.workflow = workflow?.workFlow
        const statusName =
          item?.workflow && item?.workflow[item.trackingprojectstatusid]
            ? item?.workflow[item.trackingprojectstatusid]
            : ""
        item.status_name = statusName ? Object.values(statusName) : ""
        return (
          <tr key={i} val={item} className='text-center'>
            <td className='text-center' style={{ cursor: "pointer" }}>
              <strong className='mobHead'>Task number:</strong>
              <span className='mydashboard_tasknumber'>
                {trackingClientId}-{item.trackingid}
              </span>
            </td>
            <td>
              <strong className='mobHead'>Task name :</strong>
              <span>{item?.title}</span>
            </td>
            <td>
              <strong className='mobHead'>Status :</strong>
              {item.status_name === "" ? "-" : item.status_name}
            </td>
            <td>
              <strong className='mobHead'>Created on :</strong>
              <span>{item.reporteddate === "" ? "-" : formatDate(item.reporteddate)}</span>
            </td>
            <td>
              <strong className='mobHead'>Created by :</strong>
              <span>
                {item.reportedByName ||
                  allMemberres.map((member) => {
                    if (member.userId === item.reportedby) {
                      return member.personname
                    }
                    return ""
                  })}
              </span>
            </td>
            <td>
              <strong className='mobHead'>Priority : </strong>
              <span className='text-capitalize'>
                {item.priority === "blocker"
                  ? "Blocker Level 1"
                  : item.priority === "blocker2"
                  ? "Blocker Level 2"
                  : item.priority === ""
                  ? "-"
                  : item.priority}
              </span>
            </td>
          </tr>
        )
      }
    })

  const [trackingSortings, setTrackingSorting] = useState({
    selectedSortingItem: "trackingid",
    trackingNumberSorting: "down",
    statusSorting: "",
    projectNameSorting: "",
    reportedBySorting: "",
    prioritySorting: "",
    assignedToSorting: ""
  })

  const handleSearch = (value) => {
    setSearchValue(value)
  }

  const ChartformatDate = (date) => {
    return moment(date).format("YYYY-MM-DD")
  }

  const handleChartFromDate = (value) => {
    setChartFromDate(moment(value, formatedDate))
    if (!value) {
      setChartFromDate([])
    }
  }

  const handleChartDueSearch = (value) => {
    setChartDueDate(moment(value, formatedDate))
    if (!value) {
      setChartDueDate([])
    }
  }

  const workflowIds = myDashBoardChartData.map((item) => item.workflowId)

  function getNextFiveDates(date) {
    var dates = []
    var currentDate = new Date(date)
    dates.push(currentDate.getDate().toString())
    for (var i = 0; i < 4; i++) {
      currentDate.setDate(currentDate.getDate() + 1)
      dates.push(currentDate.getDate().toString())
    }
    return dates
  }

  var convertedDate = moment(chartfromDate, "YYYY-MM-DD").format("YYYY-MM-DD")
  var inputDate = new Date(convertedDate)
  var nextFiveDates = getNextFiveDates(inputDate)

  const getLastWeekMondayAndFriday = () => {
    const today = new Date()
    const previousWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)

    // Calculate the Monday date of last week
    const mondayOfLastWeek = new Date(
      previousWeek.getFullYear(),
      previousWeek.getMonth(),
      previousWeek.getDate() - previousWeek.getDay() + 1
    )
    setChartFromDate(mondayOfLastWeek)

    // Calculate the Friday date of last week
    const fridayOfLastWeek = new Date(mondayOfLastWeek.getTime() + 4 * 24 * 60 * 60 * 1000)
    setChartDueDate(fridayOfLastWeek)
  }

  const formatedDate = (date) => {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const day = date.getDate().toString().padStart(2, "0")

    return `${year}-${month}-${day}`
  }

  useEffect(() => {
    getLastWeekMondayAndFriday()
  }, [])

  return (
    <>
      <MydashboardModalView>
        <div className='trackingPage'>
          <Tables
            setProjectTable={true}
            myDashBoard='myDashBoard'
            columns={
              <MyDashboardListColumns
                handleSearch={handleSearch}
                trackingSortings={trackingSortings}
                setTrackingSorting={setTrackingSorting}
              />
            }
            data={getTableData}
            showInfiniteScroll={false}
            header={
              <MyDashboardLishHeader
                searchValue={searchValue}
                handleSearch={handleSearch}
                userRole={user.rolename}
                userRoleType={user.roletype}
                allMembers={allMemberres}
                allTrackingsList={allTrackingsList}
                refreshAllTracking={() => {}}
                createPermission={createPermission}
              />
            }
            loading={loading}
            notfound={"No My Dashboard data Found"}
            refreshLoading={false}
          />
        </div>
        <br></br>

        <div className='my_Chart_container_title'>
          <p>Timeline for last week</p>
        </div>
        <div className='my_Chart_datae_section'>
          <p>Name: Diksha</p>
          <p>No. of tasks: {myDashBoardChartData.length}</p>
          <DatePicker
            suffixIcon={<img src={filterDateIcon} alt='Calendar' />}
            value={moment(chartfromDate)}
            onChange={handleChartFromDate}
            // selected={chartfromDate}
            name={chartfromDate}
            placeholder='From date'
            className='form-control search-control border-start-0 chart_date_filter'
          />
          <DatePicker
            suffixIcon={<img src={filterDateIcon} alt='Calendar' />}
            value={moment(chartDueDate)}
            onChange={handleChartDueSearch}
            // selected={chartDueDate}
            name={chartDueDate}
            placeholder='to date'
            className='form-control search-control border-start-0 chart_date_filter'
          />
        </div>
        <Row className='align-items-center gap-10'>
          {myDashBoardChartData.map((item, index) => {
            let Work_Flow = Object.values(allWorkFlow).flatMap((obj) => Object.values(obj))
            let workflow_status = Work_Flow?.find((e) => e.workFlowId === item?.workflowId)
            let statusName = workflow_status?.workFlow
            item.workflow_status = statusName ? Object.values(statusName) : ""
            let noTask = item.length
            let yAxixValues = item?.data
            let xAxisNames = item?.workflow_status
            let TaskNames = item?.trackingId
            return (
              <Col className='mb-3' key={index}>
                <LineGraph
                  allTrackingsList={allTrackingsList}
                  allWorkFlow={allWorkFlow}
                  TaskNames={TaskNames}
                  fromDate={chartfromDate}
                  toDate={chartDueDate}
                  MyChart={yAxixValues}
                  xAxisNames={xAxisNames}
                  nextFiveDates={nextFiveDates}
                  noTask={noTask}
                />
              </Col>
            )
          })}
        </Row>
      </MydashboardModalView>
    </>
  )
}
