import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { dark } from "@mui/material/styles/createPalette";

const WorkflowBox = ({
  index,
  editNode,
  updateData,
  id,
  allWorkFlow,
  temp,
  viewData,
  value,
  EditNodeData,
  setEditNode,
  handleCardChange,
  setEditData,
  handleCardUpdate,
  openView,
  setViewData,
  verticalview,
  editPermission,
}) => {
  const darkmode = useSelector((state) => state.Layout.darkmode);

  const [Color, setColor] = useState("");
  let key = id;
  const tickCloseIconCSS = {
    fontSize: "17px",
    marginTop: "-2px",
    marginLeft: "1%",
    cursor: "pointer",
    color: "#4eb6ee",
  };

  useEffect(() => {
    if (darkmode === true) {
      var lum = -0.25;
      var hex = "#ffffff";
      if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      var rgb = "#",
        c,
        i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
      }
      setColor("#ffffff");
      // return rgb;
    } else {
      let R = Math.floor(Math.random() * 127 + 127);
      let G = Math.floor(Math.random() * 127 + 127);
      let B = Math.floor(Math.random() * 127 + 127);

      let rgb = (R << 16) + (G << 8) + B;
      const color = "#3b3b3b";
      setColor(color);
      // return color;
    }
  }, [darkmode]);

  const removeWorkflowBox = (index) => {
    if (openView) {
      let removetempdata = [...value.value.workFlow];
      removetempdata.splice(index, 1);
      return (value.value.workFlow = removetempdata);
    } else {
      let removetempdata = [...value.workFlow];
      removetempdata.splice(index, 1);
      return (value.workFlow = removetempdata);
    }
  };

  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {viewData}
      </Tooltip>
    );
  };

  return (
    <>
      <Box
        className={
          verticalview
            ? index === 0
              ? "workflow-dataV workflow-data0 position-relative ps-3 me-3 mt-3"
              : "workflow-dataV position-relative ps-3 me-3 mt-3"
            : index === 0
            ? "workflow-data workflow-data0 position-relative ps-3 mb-4 mt-3"
            : "workflow-data position-relative ps-3 mb-4 mt-3"
        }
        style={{ background: Color }}
      >
        {editNode &&
        editNode.editable &&
        editNode.index === index &&
        editNode.key === key ? (
          <>
            <div className="workflow_item_box">
              <input
                type="text"
                name="status"
                value={updateData[index].status}
                onChange={(event) => handleCardChange(index, event, key)}
                style={{ fontSize: 15 }}
                maxLength="80"
                className="input-control-hori p-0 "
                required
              />
              <div
                className="ms-2 text-center edit-cross"
                onClick={(e) =>
                  updateData[index].status === ""
                    ? (setEditNode({
                        editable: false,
                        index: "",
                        key: "",
                        value: "",
                      }),
                      removeWorkflowBox(index))
                    : (handleCardUpdate(),
                      setEditNode({
                        editable: false,
                        index: "",
                        key: "",
                        value: "",
                      }),
                      setViewData({
                        value: allWorkFlow[key][0],
                        key: key,
                      }))
                }
              >
                <CheckIcon style={tickCloseIconCSS} />
              </div>
              <div
                className="ms-2 text-center edit-cross"
                onClick={(e) =>
                  editNode.editable && editNode.newNode
                    ? (removeWorkflowBox(index),
                      setEditNode({
                        editable: false,
                        index: "",
                        key: "",
                        value: "",
                      }))
                    : setEditNode({
                        editable: false,
                        index: "",
                        key: "",
                        value: "",
                      })
                }
              >
                <CloseIcon style={tickCloseIconCSS} />
              </div>
            </div>
          </>
        ) : (
          <>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250 }}
              overlay={renderTooltip}
            >
              <span
                style={{
                  // wordBreak: 'break-all',
                  // overflowWrap: "break-word",
                  color: darkmode ? "#151d25" : "#ffffff",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "150px",
                  overflow: "hidden",
                  cursor: "default",
                }}
              >
                {viewData}
              </span>
            </OverlayTrigger>

            {editPermission && (
              <EditIcon
                style={{
                  fontSize: "15px",
                  cursor: "pointer",
                  marginLeft: "20px",
                  color: "#4eb6ee",
                }}
                className="theme-color me-2"
                onClick={() => {
                  setEditNode({
                    editable: true,
                    index: index,
                    key: key,
                    value: value,
                  });
                  EditNodeData(
                    openView ? value.value.workFlow : value.workFlow
                  );
                  setEditData(openView ? value.value : value);
                }}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default WorkflowBox;
