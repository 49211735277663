import React from "react"
import { Modal, Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import { Activity } from "../Tracking/Activity"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import TrackingModalView from "./style"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"
import moment from "moment"

export const ApprovalTrackingView = ({ show, item, setView, activity, type }) => {
  const trackingClientId = useSelector(state => state.Auth.user.trackingclientid)
  const { data: allProjectMembers } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMemberres = allProjectMembers?.data ?? []
  return (
    <div className='approval_model_container'>
      <Modal show={show} onHide={e => setView(false)} size='lg' keyboard={false} centered>
        <TrackingModalView>
          <Modal.Header className='border-0'>
            <Modal.Title>Title : {item.title} </Modal.Title>
            <CloseRoundedIcon
              className='close-icon float_close_icon'
              onClick={() => setView(false)}
              sx={{ fontSize: 15 }}
            />
          </Modal.Header>
        </TrackingModalView>
        <Modal.Body className='approval_model_bodytable'>
          <TrackingModalView>
            <Table>
              <tbody>
                <tr>
                  <td className='border-0 text-color'>
                    <strong>Task No:</strong>
                  </td>
                  <td className='border-0 text-capitalize text-color'>
                    <strong>
                      {trackingClientId}-{item.trackingid}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className='border-0 text-color'>Task type:</td>
                  <td className='border-0 text-capitalize text-color'>{item.taskName}</td>
                </tr>
                <tr>
                  <td className='border-0 text-color'>Reported On:</td>
                  <td className='border-0 text-color'>
                    {moment(item.reporteddate).format("MMM D, YYYY hh:mm A")}
                  </td>
                </tr>
                <tr>
                  <td className='border-0 text-color'>Reported by:</td>
                  <td className='border-0 text-color'>
                    {item.reportedByName
                      ? item.reportedByName
                      : allMemberres.map(member => {
                          if (member.userId === item.reportedby) {
                            return member.personname
                          }
                          return ""
                        })}
                  </td>
                </tr>
                {type == "tracking" ? (
                  <tr>
                    <td className='border-0'>Timeline: </td>
                    <td className='border-0'>{item.timeline ? item.timeline + " hours" : "-"}</td>
                  </tr>
                ) : (
                  <></>
                )}
                {type == "tracking" ? (
                  <tr>
                    <td className='border-0'>Due date: </td>
                    <td className='border-0'>{item.duedate ? item.duedate : "-"}</td>
                  </tr>
                ) : (
                  <></>
                )}
                {type == "tracking" ? (
                  <tr>
                    <td className='border-0'>Assigned to:</td>
                    <td className='border-0'>
                      {item.assignedto != "" ? item.assignedtoName : "Not Assigned"}
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {type == "tracking" ? (
                  <tr>
                    <td className='border-0'>Priority: </td>
                    <td className='border-0 text-capitalize'>
                      {item.priority === "blocker"
                        ? "Blocker Level 1"
                        : item.priority === "blocker2"
                        ? "Blocker Level 2"
                        : item.priority === ""
                        ? "None"
                        : item.priority}
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {type == "tracking" ? (
                  <tr>
                    <td className='border-0'>Status: </td>
                    <td className='border-0'>{item.status_name}</td>
                  </tr>
                ) : (
                  <></>
                )}
                {type == "tracking" ? (
                  <tr>
                    <td className='border-0'>Attachments: </td>
                    <td className='border-0'>No files attached</td>
                  </tr>
                ) : (
                  <></>
                )}
                <tr>
                  <td className='border-0 text-color'>Description: </td>
                  <td
                    className='border-0 text-color'
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </tr>
              </tbody>
            </Table>
          </TrackingModalView>
        </Modal.Body>
        {activity ? <Activity item={item} /> : <></>}
      </Modal>
    </div>
  )
}
