import React, { useEffect, useState } from "react"
import { BsThreeDotsVertical, BsFillEyeFill, BsFillTrashFill } from "react-icons/bs"
import Dropdown from "react-bootstrap/Dropdown"
import CardContainer from "./Projectcard_style"
import moment from "moment"
import { useDispatch } from "react-redux"
import { setSelectedProject } from "../../../../../redux/Tracking/actionCreator"

const Card = (props) => {
  const {
    setShowView,
    setProjectView,
    setTrackingItemData,
    setDeleteConfirmationShow,
    item,
    deletePermission
  } = props

  const dispatch = useDispatch()

  const [currentTime, setCurrentTime] = useState(new moment())

  const startDate = moment(props.startdate, "DD/MM/YYYY")

  const dueDate = moment(props.duedate, "DD/MM/YYYY")

  const duration = moment.duration(moment(dueDate).diff(moment(startDate))).asMilliseconds()

  const actualDuration = Math.abs(
    moment.duration(currentTime.diff(moment(startDate))).asMilliseconds()
  )

  const projectDurationPercentage = (actualDuration / duration) * 100

  const settingProjectLabel = () => {
    if (projectDurationPercentage >= 0) {
      if (projectDurationPercentage >= 100) {
        return { color: "#A80809", label: "Off track" }
      }
      if (projectDurationPercentage >= 80) {
        return { color: "#FCC404", label: "At risk" }
      }
      if (projectDurationPercentage < 80) {
        return { color: "#246324", label: "Good" }
      }
      return { color: "grey", label: "-" }
    }
  }

  const [projectLabel, setProjectLabel] = useState(settingProjectLabel())

  useEffect(() => {
    let interval = setInterval(
      () => {
        setProjectLabel(settingProjectLabel())
        setCurrentTime(new moment())
      },
      60000 //? For Every Minute the CurrentTime Will update and conditional check the project Label status
    )
    return () => clearInterval(interval)
  }, [currentTime])

  return (
    <CardContainer>
      <div className='cards'>
        {/* header section */}
        <div className='cardBody'>
          <div className='cardheader'>
            <h1
              onClick={(e) => {
                try {
                  setProjectView("project view")
                  setShowView(true)
                  setTrackingItemData(item)
                  dispatch(setSelectedProject(props.trackingprojectid))
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              {props.trackingprojectid}
            </h1>

            {/* <div style={{ rotate: "90deg", color: "#4eb6ee" }} ><DragIndicatorIcon /></div> */}

            <div
              className={`projectLabel ms-auto`}
              style={{ backgroundColor: projectLabel?.color }}
            >
              {projectLabel?.label}
            </div>

            <Dropdown className='d-inline-block dropView'>
              <Dropdown.Toggle
                id='project-dropdown-card'
                className='imgfirstLetrDropdwnTogle'
                onSelect={() => null}
              >
                <BsThreeDotsVertical variant='primary' className='cardIcon' />
              </Dropdown.Toggle>
              <Dropdown.Menu className='text-center w-100'>
                <Dropdown.Item
                  eventKey={1}
                  style={{
                    backgroundColor: "transparent",
                    color: "#4eb6ee"
                  }}
                  onClick={(e) => {
                    try {
                      setProjectView("project view")
                      setShowView(true)
                      setTrackingItemData(item)
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                >
                  <BsFillEyeFill className='plusIcon' /> View
                </Dropdown.Item>

                {deletePermission && (
                  <Dropdown.Item
                    eventKey={2}
                    className='colsebtn'
                    style={{
                      backgroundColor: "transparent",
                      color: "red"
                    }}
                    onClick={(e) => {
                      setDeleteConfirmationShow(true)
                      setTrackingItemData(item)
                    }}
                  >
                    <BsFillTrashFill className='plusIcon' /> Delete
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className='cardcontent d-flex justify-content-start align-items-center mb-2'>
            <div className='footerIcon'>
              <h3>{props.projectName.trim()[0]}</h3>
            </div>

            <div className='ps-1 projectName'>{props.projectName}</div>
          </div>
        </div>

        {/* card footer section */}
        <div className='cardfooter'>
          <div className='footerBody'>
            <div className='footerContent'>
              <p>
                Start Date:{" "}
                {moment(props.startdate, "DD/MM/YYYY").format("MMM DD, YYYY h:mm a") !==
                "Invalid date"
                  ? moment(props.startdate, "DD/MM/YYYY").format("MMM DD, YYYY ")
                  : "-"}{" "}
              </p>
            </div>

            <div className='footerContent'>
              <p>
                Due Date:{" "}
                {moment(props.duedate, "DD/MM/YYYY").format("MMM DD, YYYY h:mm a") !==
                "Invalid date"
                  ? moment(props.duedate, "DD/MM/YYYY").format("MMM DD, YYYY")
                  : "-"}{" "}
              </p>
            </div>

            <div className=''>
              <p className='workflow'>
                Workflow ID: <span className='cardTask'>{props.workflow}</span>
              </p>
            </div>
          </div>
          {/* Task info */}
        </div>
      </div>
    </CardContainer>
  )
}

export default Card
