import React, { useState } from "react";
import backlogs_priority from "../../../../../../../assets/images/backlogs-priority.svg"
import backlogs_search from "../../../../../../../assets/images/backlogs-search.svg"
import { useSelector } from "react-redux";

export const ProjectTaskListColumn = () => {
    return (
        <> 
            <th className="text-nowrap" >
                    <span  className="ps-1"> 
                    Task No
                    </span>
                </th>
                <th  className="text-nowrap">
                    Title
                </th>
                <th className="text-nowrap">
                    Task Type
                </th>               
                <th className="text-nowrap">
                   Status
                </th>               
                <th className="text-nowrap">
                    Timeline
                </th>
                <th className="text-nowrap">
                    Reported On
                </th>
                <th className="text-nowrap">
                    Reported By
                </th>
                <th className="text-nowrap">
                    Assigned To
                </th>
                <th className="text-nowrap">
                    Priority
                </th>         
        </>
    );
};