import React from "react"
import { Card } from "react-bootstrap"
import DownloadIcon from "@mui/icons-material/Download"
import { BsThreeDotsVertical, BsFillEyeFill, BsFillTrashFill } from "react-icons/bs"
import Dropdown from "react-bootstrap/Dropdown"
import { saveAs } from "file-saver"

const DocumentCard = ({
  FileList,
  setViewDocument,
  setDeleteValue,
  setDeleteModal,
  setFileBuffer,
  setTaskFileType,
  setDeleteIndex,
  allfiledata,
  deletePermission,
  deleteDocument
}) => {
  const fileData = Object.entries(FileList).map((value) => value[1][0])

  const downloadFile = (url, filename, type) => {
    let FileName =
      type === "application/pdf"
        ? filename + ".pdf"
        : type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ? filename + ".docx"
        : type === "text/csv" ||
          type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ? filename + ".xlsx"
        : filename + ".png"

    saveAs(url, FileName)
  }

  const openWordDocument = (url) => {
    window.open(url, "_blank")
  }

  return (
    <>
      <div className='file-conatiner'>
        {allfiledata?.map((value, index) => {
          return (
            <>
              <div className='file mx-2'>
                <Card
                  className='card-bd image-card m-2'
                  style={{ width: "150px", height: "210px" }}
                >
                  <Card.Body className='file-card'>
                    <>
                      <div className='file_block'>
                        {value[2] === "application/pdf" ? (
                          <>
                            <img
                              alt=''
                              onClick={() => {
                                setViewDocument(true)
                                setTaskFileType(value[2])
                                setFileBuffer(value[0])
                              }}
                              src='https://img.icons8.com/color/96/000000/pdf-2--v1.png'
                              width='150px'
                              height='150px'
                              style={{ cursor: "pointer" }}
                            />
                          </>
                        ) : value[2] ===
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                          value[2] === "doc" ||
                          value[2] === "DOCX" ||
                          value[2] === "DOC" ? (
                          <>
                            <img
                              alt=''
                              onClick={() => {
                                openWordDocument(value[0])
                              }}
                              src='https://img.icons8.com/color/144/000000/google-docs--v1.png'
                              width='150px'
                              height='150px'
                              style={{ cursor: "pointer" }}
                            />
                          </>
                        ) : value[2] === "text/csv" ||
                          value[2] ===
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                          value[2] === "xls" ||
                          value[2] === "xlsx" ||
                          value[2] === "XLS" ||
                          value[2] === "XLSX" ? (
                          <>
                            <img
                              alt=''
                              src='https://img.icons8.com/color/144/000000/microsoft-excel-2019--v1.png'
                              width='150px'
                              height='150px'
                              onClick={() => {
                                openWordDocument(value[0])
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              alt=''
                              onClick={() => {
                                setViewDocument(true)
                                setTaskFileType(value[2])
                                setFileBuffer(value[0])
                              }}
                              src={value[0]}
                              width='150px'
                              height='150px'
                              style={{ cursor: "pointer" }}
                            />
                          </>
                        )}
                      </div>
                      <div className='file_details p-2'>
                        <p className='filename p-0 mt-2' style={{ wordBreak: "break-all" }}>
                          {value[1]}
                        </p>
                        <div className='icon ms-1'>
                          <Dropdown className='d-inline-block dropView'>
                            <Dropdown.Toggle
                              onSelect={() => null}
                              id='dropdown-autoclose-outside'
                              className='imgfirstLetrDropdwnTogle float-end'
                            >
                              <BsThreeDotsVertical className='cardIcon' />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='w-100 customMenu px-2'>
                              {value[2] === "text/csv" ||
                              value[2] ===
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                              value[2] ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? null : (
                                <Dropdown.Item
                                  eventKey={1}
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "#4eb6ee"
                                  }}
                                  onClick={() => {
                                    setViewDocument(true)
                                    setTaskFileType(value[2])
                                    setFileBuffer(value[0])
                                  }}
                                >
                                  <BsFillEyeFill className='plusIcon mx-1' /> View
                                </Dropdown.Item>
                              )}
                              <Dropdown.Item
                                eventKey={2}
                                className='colsebtn'
                                style={{
                                  backgroundColor: "transparent",
                                  color: "#4eb6ee"
                                }}
                                onClick={(e) => {
                                  const document = fileData?.find(
                                    (item) => item?.fileurls === value[3]
                                  )

                                  downloadFile(
                                    value[0],
                                    document?.documentfilename ?? "document",
                                    value[2]
                                  )
                                }}
                              >
                                <DownloadIcon className='plusIcon' /> Download
                              </Dropdown.Item>
                              {deletePermission && (
                                <Dropdown.Item
                                  eventKey={3}
                                  className='colsebtn'
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "red"
                                  }}
                                  onClick={(e) => {
                                    const document = fileData?.find(
                                      (item) => item?.fileurls === value[3]
                                    )
                                 
                                    setDeleteValue(document?.documentId)
                                    setDeleteModal(true)
                                    setDeleteIndex(index)
                                  }}
                                >
                                  <BsFillTrashFill className='plusIcon mx-1' /> Delete
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </>
                  </Card.Body>
                </Card>
              </div>
            </>
          )
        })}
      </div>
    </>
  )
}
export default DocumentCard
