import React from "react";
import Styled from 'styled-components';

export const KpiTableColumns = ({
  setTrackingSorting,
}) => {
  const applyTrackingNumberSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "trackingid",
        projectNameSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, trackingNumberSorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, trackingNumberSorting: "down" };
      });
  };

  const applyProjectNumberSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "project",
        trackingNumberSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, projectNameSorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, projectNameSorting: "down" };
      });
  };

  const applyReportedBySorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "reportedby",
        trackingNumberSorting: "",
        projectNameSorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, reportedBySorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, reportedBySorting: "down" };
      });
  };

  const applyPrioritySorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "priority",
        trackingNumberSorting: "",
        projectNameSorting: "",
        reportedBySorting: "",
        assignedToSorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, prioritySorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, prioritySorting: "down" };
      });
  };

  const applyAssignToSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "assignedto",
        trackingNumberSorting: "",
        projectNameSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, assignedToSorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, assignedToSorting: "down" };
      });
  };

  const applyStatusSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "status",
        trackingNumberSorting: "",
        projectNameSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, statusSorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, statusSorting: "down" };
      });
  };

  return (
    <>
        <th className=""></th>
        <th style={{ width: "15%" }} className="kpi_header">Employee</th>
        <th className="text-center kpi_header">No. Of task</th>
        <th className="text-center kpi_header">Total Points</th>
        <th className="text-center kpi_header">Gained Points</th>
    </>
  );
};
