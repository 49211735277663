import styled from "styled-components"

const UserlistWrapper = styled.div`
  .user_data_container {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
  .user_id_lists_card {
    width: 35%;
  }
  .user_details_lists_card {
    width: 65%;
  }
  .user_menu_table_container {
    height: 40vh;
    overflow: auto !important;
    .table-responsive {
      overflow: unset;
    }
    .user_menu_table {
      margin-bottom: 0rem;
    }
  }
  .user_menu_table_container::-webkit-scrollbar {
    height: 6px;
    width: 8px;
    background-color: transparent;
  }
  .user_menu_table_container::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 10px;
    width: 5px;
  }
  .team-name-circle {
    width: 39px;
    height: 39px;
    border-radius: 50%;
    font-weight: 100;
    padding: 8px 12px;
  }
  .active-card {
    background: rgb(178 185 185 / 40%);
    color: ${({ theme }) => theme["themeColor"]};
  }
  .card-bd {
    overflow: hidden;
  }
  .inactive-card {
    background-color: ${({ theme }) => theme["tableViewCard"]};
  }
  .scrollbar_custom {
    max-height: 800px;
    overflow: auto !important;
  }
  .list-card:focus {
    background: #5c5c5c;
  }
  .list-card:target {
    background: #5c5c5c;
  }
  .Userlist-Search {
    width: 100%;
    display: inline-flex;
  }
  .userlist-header {
    display: inline-flex;
    padding: 15px 10px 20px 10px;
  }
  .menu-list-row {
    display: inline-flex;
  }
  .menu-checkbox {
    .MuiFormControlLabel-label {
      font-size: 14px;
    }
  }

  .userlist-dividerblock {
    width: 6px;
    height: 68vh;
    background-color: ${({ theme }) => theme["tableViewCard"]};
    border-radius: 3px;
    opacity: 0.5;
  }
  .User-details-container {
    display: inline-flex;
  }

  .Name-team-row {
    display: inline-flex;
    align-items: end;
    gap: 10px;
    padding: 0px;
    h4 {
      margin-bottom: 0rem;
    }
  }
  .user_permission_title {
    margin-bottom: 0rem;
  }
  .MuiDivider-root {
    background-color: ${({ theme }) => theme["divider"]} !important;
    opacity: 1;
    height: 3px;
  }
  .delete-bg {
    background-color: ${({ theme }) => theme["divider"]} !important;
  }
  .permission-labels {
    display: flex;
    padding: 2px;
  }
  .labels {
    width: 270px;
  }
  .faded-text {
    font-weight: 200;
    opacity: 0.85;
    margin-bottom: 0rem;
  }
  .menu-list {
    display: inline-flex;
    width: 160px;
  }
  .userid-box {
    display: flex;
    letter-spacing: 0px;
    color: ${({ theme }) => theme["themeColor"]};
    opacity: 0.75;
    font-size: 12px;
  }
  .divider0 {
    display: none;
  }
  .User-details-container {
    font-size: 14px;
  }
  th {
    font-weight: 100 !important;
  }
  .table {
    border-color: #5c5c5c;
  }
  tbody {
    th {
      background-color: ${({ theme }) => theme["tableViewBody"]};
      color: ${({ theme }) => theme["themeColor"]};
    }
  }
  thead {
    th {
      background-color: ${({ theme }) => theme["tableHeadBg"]};
      color: ${({ theme }) => theme["themeColor"]};
    }
  }
  .details-card-body {
    border-radius: 25px;
    position: absolute;
    z-index: 10;
    backdrop-filter: blur(20px);
  }
  .deletebtn {
    width: 400px !important;
  }
  .box {
    letter-spacing: 0.3px;
    display: block;
    align-items: center;
    justify-content: center;
  }
  .Mui-checked + .MuiSwitch-track {
    background-color: #4eb6ee !important;
    opacity: 1 !important;
  }
  .MuiSwitch-thumb {
    background-color: ${({ theme }) => theme["themeColor"]} !important;
  }
  .MuiSwitch-thumb {
    margin-top: 1px;
    width: 13px !important;
    height: 13px !important;
  }
  .row > * {
    padding: 0px !important;
  }
  .card {
    border: none !important;
  }
  .send_remainder {
    color: #4eb6ee !important;
    cursor: pointer;
  }
  @media (max-width: 992px) {
    .user_data_container {
      display: flex;
      flex-wrap: wrap;
    }
    .user_id_lists_card {
      width: 100%;
    }
    .user_details_lists_card {
      width: 100%;
    }
    .card-background {
      height: 30vh;
    }
  }
  @media (max-width: 767px) {
    .card-background {
      //    max-height: 30vh;
      margin-bottom: 20px;
    }
    .card-border {
      border: none;
    }
  }
  @media (max-width: 350px) {
    .search-input {
      width: 170px;
    }
  }
`
export default UserlistWrapper
