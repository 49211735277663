import { useState } from "react"

const UseMultipleForms = (steps) => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0)

    const next = () => {
        if (currentStepIndex >= steps.length - 1) return
        setCurrentStepIndex((prevIndex) => {
            return prevIndex + 1
        })
    }

    const back = () => {
        if (currentStepIndex <= 0) return
        setCurrentStepIndex((prevIndex) => {
            return prevIndex - 1
        })
    }

    const goTo = (stepId) => {
        setCurrentStepIndex(stepId)
    }

    return {
        step: steps[currentStepIndex],
        currentStep: currentStepIndex + 1,
        totalSteps: steps.length,
        next,
        back,
        goTo,
        firstStep: currentStepIndex === 0,
        lastStep: currentStepIndex === steps.length - 1
    }
}

export default UseMultipleForms
