import Styled from 'styled-components';

const TrackingModalView = Styled.div`
    .modal-header {
        position: relative;
    }

    .text-color {
        color: ${({ theme }) => theme["themeColor"]};
    }
    .modal .modal-content{
        .approval_model_bodytable{
        .table>:not(caption)>*>* {
            background-color: transparent !important
        }
    }}

    .modal-header:after {
        background: linear-gradient(to right, #4285f4 25%, #34a853 25%, #fabb05 50%, #e94235 75%);
        position: absolute;
        content: '';
        height: 4px;
        right: 0;
        left: 0;
        bottom: 0;
    }
`;

export default TrackingModalView;

