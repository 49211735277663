import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row, Alert } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import { Forms } from "../../../components/Reuseable/Forms";
import LoginWrap from "./style";
import { userLogin } from "../../../redux/Auth/actionCreator";
import tractaskLogo from "../../../assets/images/logo/tracTasklogo.svg";
import personimage from "../../../assets/images/login/personimage12.png";
import { api } from "../../../Services/api";
import { Validators } from "../../../Utilities/validator";
import { Link } from "react-router-dom";

const Login = () => {
  const [invalidUser, setInvalidUser] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState("Invalid User");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [changeInForm, setChangeInForm] = useState(false);

  const inValidMsgJsx = () => {
    return (
      <div>
        Sorry to hear that, but we cannot find an account with this email
        address. create a <Link to="/register"> new account </Link>
      </div>
    );
  };

  const SubmitLogin = (form, valid) => {
    if (valid) {
      setLoading(true);
      form.password = form.password.trim();
      const obj = {
        userid: form.email,
        password: form.password,
      };
      api
        .post("/trackinguserauth", obj)
        .then(function (response) {
          let user = jwt_decode(response.data.authorizationToken);
          if (response.status === 200) {
            let result = {
              authToken: response.data.authorizationToken,
              user: user,
              menuList: response.data.menuList,
            };
            setSubmitted(true);
            dispatch(userLogin(result));
            setLoading(false);
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 400) {
              setInvalidUser(true);
              const invalidMsg = error.response.data.message;

              if (
                invalidMsg ===
                "Sorry to hear that, but we cannot find an account with this email address. create a new account."
              ) {
                setInvalidMessage(inValidMsgJsx);
              } else {
                setInvalidMessage(invalidMsg);
              }
            }
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
          setLoading(false);
        });
    }
  };

  return (
    <LoginWrap>
      <section className="loginPageContent">
        <Row>
          <div className="loginPageRow">
            <Col
              className="loginPageRightside ps-0 pe-0"
              lg={5}
              md={12}
              sm={12}
              xs={12}
            >
              <div className="loginScreenLogoMain text-center pb-3 ">
                <a href="https://app.tractask.com/">
                  <img
                    className="img-fluid mx-auto loginScreenLogoimg"
                    src={tractaskLogo}
                    alt="trekr icon"
                  />
                </a>
              </div>
              <div className="loginFormScreens text-center flex-fill col-md-12">
                <p className="loginaccountft">Log in to your account</p>
                {invalidUser ? (
                  <Alert className="m-3 p-1 text-center" variant="danger">
                    {invalidMessage}
                  </Alert>
                ) : (
                  <></>
                )}
                <Forms
                  title={""}
                  formArr={[
                    {
                      name: "email",
                      type: "email",
                      placeholder: "Email",
                      validators: [
                        {
                          check: Validators.required,
                          message: "Email is required",
                        },
                        {
                          check: Validators.email,
                          message: "Email is not Valid",
                        },
                      ],
                      error: false,
                      errormsg: "",
                      value: "",
                      show: true,
                    },
                    {
                      name: "password",
                      type: "password",
                      placeholder: "Password",
                      validators: [
                        {
                          check: Validators.required,
                          message: "Password is required",
                        },
                      ],
                      error: false,
                      errormsg: "",
                      value: "",
                      show: true,
                    },
                  ]}
                  submitBtn={"Login"}
                  formlinks={
                    <div className="loginBottom">
                      <Link to="/forgot-password" className="forgotLink">
                        Forgot password?
                      </Link>

                      <div className="registerLink">
                        <Link id="createHere" to="/register" className="link">
                          Don't have an account?{` `}
                          Create here.
                        </Link>
                      </div>
                    </div>
                  }
                  onSubmit={SubmitLogin}
                  loading={loading}
                  submitted={submitted}
                  changeInForm={changeInForm}
                  setChangeInForm={setChangeInForm}
                />
              </div>
            </Col>
            <Col>
              <img
                className="loginPageLeftside"
                src={personimage}
                alt="PersonImage"
              />
            </Col>
          </div>
        </Row>
      </section>
    </LoginWrap>
  );
};

export default Login;
