import React, { useState } from "react"
import { useSelector } from "react-redux"
import Alert from "@mui/material/Alert"
import Modal from "react-bootstrap/Modal"
import CloseIcon from "@mui/icons-material/Close"
import { Forms } from "../../../../Reuseable/Forms"
import { Validators } from "../../../../../Utilities/validator"
import AdminDashBoardWrapper from "../Style"
import { api } from "../../../../../Services/api"
import newsletter from "../../../../../assets/images/AdminImages/User/Newsletter-bro.svg"
import { client } from "../../../../.."
import { key } from "../../../../../data/queryKeys"

const AddNewuser = ({ CloseAddUser, showAddUser, setShowNoUser }) => {
  const authToken = useSelector((state) => state.Auth.authToken)

  const [changeInForm, setChangeInForm] = useState(false)
  const [confirmMessage, setConfirmMessage] = useState("")
  const [subUserEmail, setsubUserEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [exsistUserAlert, setExsistuserAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const CloseConfirmMessage = () => setConfirmMessage(false)
  const OpenConfirmMessage = () => setConfirmMessage(true)

  let formArr = [
    {
      label: "Name",
      name: "name",
      type: "text",
      placeholder: "Name",
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      value: "",
      maxLength: 100,
      show: true
    },
    {
      label: "E-mail",
      name: "email",
      type: "email",
      placeholder: "Email",
      validators: [
        { check: Validators.required, message: "This Field is required" },
        { check: Validators.email, message: "Email is not Valid" }
      ],
      error: false,
      errormsg: "",
      value: "",
      show: true
    }
  ]

  const SubmitSubUser = (form, valid) => {
    if (valid) {
      setExsistuserAlert(false)
      setLoading(true)
      setsubUserEmail(form.email)
      let Obj = {
        emailId: form.email,
        personname: form.name
      }

      api
        .post("/trackingadmin/subuser", Obj, {
          headers: {
            authorizationtoken: ` ${authToken}`
          }
        })
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.status === true) {
              setShowNoUser(false)
              setLoading(false)
              CloseAddUser()
              OpenConfirmMessage()
              client.invalidateQueries({
                queryKey: [key.get_all_members]
              })
            } else {
              setLoading(false)
              setExsistuserAlert(true)
              setErrorMessage(response.data.message)
            }
          }
        })
        .catch(function (error) {
          console.log("[SubmitSubUser] Error-->", error)
          setLoading(false)
          setExsistuserAlert(true)
          setErrorMessage("Internal server error")
        })
    }
  }

  return (
    <>
      <Modal
        show={confirmMessage}
        onHide={() => {
          CloseConfirmMessage()
          setsubUserEmail("")
          client.invalidateQueries({
            queryKey: [key.get_all_members]
          })
        }}
      >
        <AdminDashBoardWrapper>
          <div className='p-2'>
            <CloseIcon
              className='float-end mt-1'
              onClick={CloseConfirmMessage}
              style={{ color: "#4EB6EE", cursor: "pointer" }}
            />
            <div className='success-message p-5'>
              <div className='email-image mb-3'>
                <img src={newsletter} alt='news-letter' />
              </div>
              <div className='email-text my-2'>
                <strong>your invite has been sent to the following user</strong>
              </div>
              <div>
                <span className='message-email'>{subUserEmail}</span>
              </div>
            </div>
          </div>
        </AdminDashBoardWrapper>
      </Modal>

      <Modal
        show={showAddUser}
        onHide={() => {
          CloseAddUser()
          setExsistuserAlert(false)
        }}
      >
        <AdminDashBoardWrapper>
          <div className='p-2'>
            <div className='px-3 pt-2' style={{ color: "#4EB6EE", fontSize: "20px" }}>
              <span>Add new user</span>
              <CloseIcon
                className='float-end mt-1'
                onClick={() => {
                  CloseAddUser()
                  setExsistuserAlert(false)
                }}
                style={{ cursor: "pointer" }}
              />
              {exsistUserAlert ? (
                <div className='px-4 pt-3'>
                  <Alert variant='filled' severity='warning'>
                    {errorMessage}
                  </Alert>
                </div>
              ) : null}
            </div>
            <Forms
              title={""}
              formArr={formArr}
              submitBtn={"Send Request"}
              submitted={true}
              loading={loading}
              onSubmit={SubmitSubUser}
              changeInForm={changeInForm}
              setChangeInForm={setChangeInForm}
            />
          </div>
        </AdminDashBoardWrapper>
      </Modal>
    </>
  )
}

export default AddNewuser
