import { useDispatch, useSelector } from 'react-redux'
import { setTrackingItemData } from '../redux/Tracking/actionCreator'

const useTrackingItemData = () => {

    const value = useSelector(state => state.Tracking.trackingItemData)

    const dispatch = useDispatch()

    const setValue = (value) => {
        dispatch(setTrackingItemData(value))
    }
    return [value, setValue]

}

export default useTrackingItemData