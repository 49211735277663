import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import Modal from "react-bootstrap/Modal"
import CloseIcon from "@mui/icons-material/Close"
import NoUser from "./NoUser"
import AdminDashBoardWrapper from "./Style"
import AddNewuser from "./AddNewUser"
import UserList from "./UserList"
import ListGroup from "react-bootstrap/ListGroup"
import ControlPointIcon from "@mui/icons-material/ControlPoint"
import { Spinner } from "react-bootstrap"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"

const AdminDashBoard = () => {
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)

  const { data: membersRes, isLoading: loading } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  const Members = membersRes?.data ?? []

  let allMembers = Members.sort((item1, item2) => {
    if (item1.status < item2.status) return -1
    else return 1
  })

  const [showAddUser, setShowAddUser] = useState(false)
  const [showNoUser, setShowNoUser] = useState(false)
  const [memberDetails, setMemberDetails] = useState([])
  const [subUserPermission, setsubUserPermission] = useState("")
  const [showAlertBox, setShowAlertBox] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [active, setactive] = useState("")
  const [userView, setUserView] = useState("Unverified")
  const [openPendinguser, setOpenPendingUser] = useState(false)

  const UnverifiedCount = memberDetails.filter((obj) => obj.status === "Unverified").length
  const VerifiedCount = memberDetails.filter((obj) => obj.status === "verified").length

  const pendinginvite =
    userView === "Unverified"
      ? memberDetails.filter((obj) => obj.status === "Unverified")
      : memberDetails.filter((obj) => obj.status === "verified")

  useEffect(() => {
    setMemberDetails([])

    if (allMembers.length === 1) {
      setShowNoUser(true)
    }
    if (allMembers.length > 1) {
      if (allMembers[0].userRole === "User") {
        setsubUserPermission(allMembers[0])
        setactive(allMembers[0].userId)
      } else {
        setsubUserPermission(allMembers[1])
        setactive(allMembers[1].userId)
      }
    }
    for (let i = 0; i < allMembers.length; i++) {
      if (allMembers[i].userRole === "User") {
        setMemberDetails((prevfiles) => [...prevfiles, allMembers[i]])
      }
    }
  }, [allMembers])

  const CloseAddUser = () => setShowAddUser(false)
  const OpenAddUser = () => setShowAddUser(true)

  return (
    <AdminDashBoardWrapper>
      <>
        <div className='text-white d-flex align-items-center mb-2'>
          <div className='add-user ms-3 me-auto fw-bold fs-5'>
            Users
            <ControlPointIcon onClick={OpenAddUser} className='plus-button'></ControlPointIcon>
          </div>
          <div className='d-flex me-2'>
            <div
              onClick={() => {
                memberDetails.length > 0 && setOpenPendingUser(true)
                setUserView("verified")
              }}
            >
              <CurrentInfo text='Active Users' data={VerifiedCount} />
            </div>
            <div
              onClick={() => {
                pendinginvite.length > 0 && setOpenPendingUser(true)
                setUserView("Unverified")
              }}
            >
              <CurrentInfo text='Pending Invites' data={UnverifiedCount} bgColor='#4EB6EE' />
            </div>
          </div>
        </div>

        <AddNewuser
          showAddUser={showAddUser}
          OpenAddUser={OpenAddUser}
          CloseAddUser={CloseAddUser}
          setShowNoUser={setShowNoUser}
        />

        {showNoUser || !allMembers ? (
          !showNoUser ? (
            <div className='text-center mt-5 mb-5'>
              <Spinner animation='border' variant='info' />
            </div>
          ) : (
            <NoUser OpenAddUser={OpenAddUser} CloseAddUser={CloseAddUser} />
          )
        ) : (
          <>
            {loading ? (
              <div className='text-center mt-5 mb-5'>
                <Spinner animation='border' variant='info' />
              </div>
            ) : (
              <UserList
                setactive={setactive}
                active={active}
                setShowNoUser={setShowNoUser}
                showAlertBox={showAlertBox}
                alertMessage={alertMessage}
                memberDetails={memberDetails}
                setShowAlertBox={setShowAlertBox}
                setAlertMessage={setAlertMessage}
                setMemberDetails={setMemberDetails}
                setsubUserPermission={setsubUserPermission}
                subUserPermission={subUserPermission}
              />
            )}
          </>
        )}
        <PendingList
          openPendinguser={openPendinguser}
          setOpenPendingUser={setOpenPendingUser}
          pendinginvite={pendinginvite}
          title={userView === "Unverified" ? "Pending Invites" : "Member list"}
        />
      </>
    </AdminDashBoardWrapper>
  )
}
export default AdminDashBoard

const CurrentInfo = ({ text, data, bgColor }) => {
  return (
    <div
      className='px-2 px-md-4 py-2 rounded mx-2'
      style={{
        background: bgColor ? bgColor : "#3E3F3E",
        cursor: "pointer"
      }}
    >
      {text}: {data}
    </div>
  )
}

const PendingList = ({ openPendinguser, setOpenPendingUser, pendinginvite, title }) => {
  return (
    <Modal
      show={openPendinguser}
      onHide={() => {
        setOpenPendingUser(false)
      }}
      centered
      size='sm'
    >
      <AdminDashBoardWrapper>
        <div className='p-2'>
          <CloseIcon
            className='float-end mt-1'
            onClick={() => {
              setOpenPendingUser(false)
            }}
            style={{ color: "#4EB6EE", cursor: "pointer" }}
          />
          <h6 className='mt-2' style={{ color: "#4EB6EE" }}>
            {title}
          </h6>
          <ListGroup>
            {pendinginvite.map((data, index) => {
              let hex = Math.floor(Math.random() * 0xffffff)
              let color = "#" + hex.toString(16)

              return (
                <ListGroup.Item className='p-2 mb-3 me-4' key={index}>
                  <div
                    style={{
                      display: "inline-flex"
                    }}
                  >
                    <div
                      className='team-name-circle '
                      style={{
                        background: color
                      }}
                    >
                      <center>{data.personname[0]}</center>
                    </div>
                    <div className='ms-2 me-auto'>
                      <div className='fw-bold'>{data.personname}</div>
                      <div
                        style={{
                          opacity: "0.7",
                          width: "220px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis"
                        }}
                      >
                        {data.userId}
                      </div>
                    </div>
                  </div>
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        </div>
      </AdminDashBoardWrapper>
    </Modal>
  )
}
