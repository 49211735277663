import Styled from "styled-components";

const LoginWrap = Styled.div`
    .loginPageRow .form-control{
        color: #000000;
    }
    .loginformbody .form-control{
        background: #F1E8E6;
        border: 1px solid #bfbdbd;
        border-radius: 5px;
        color: #000000;
        opacity: 1 !important;
    }  
    .modalbody .form-control{
        color: #000000;
    }
    .loginPageRow{
        display:flex;
    }
    .card-body{
        padding: 0rem 0rem;
    }
    .loginformbody{
        background: #F1E8E6;
    }
    .loginScreenLogoMain{
        width: 100%;
    }
    .loginScreenLogoimg {
        max-height: 56px;
        cursor: pointer;
    }
    .tier{
        color:#ffffff !important;
    }
    .loginPageContent {
        overflow: auto;
        overflow-x: hidden;
        width: 100%;
        height: 100vh;
        background: #292A29 0% 0% no-repeat padding-box;
        opacity: 1;
        background-blend-mode: multiply;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .loginPageRightside{
        margin-top: 150px;
        /* align-items: center; */
        margin-left: 150px;
    }
    .loginPageLeftside{
        height: 600px;
        float: right;
    }
    .loginaccountft{
        margin-bottom: 0px!important;
        margin-top: 30px;
        font-size: 17px;
        font-weight: 600;
        color: black; 
        text-align: center;         
    }
    .mandatorySign{
        display: none;
    }
    .loginformbody .text-center .btn {
        width: 178px;
        height: 30px;
        padding: 0px;
    }
    .loginFormScreens {
        overflow: hidden;
        overflow-y: auto;
        padding:0px 30px 0px 30px;
        background: #F1E8E6;
        border-radius: 5px;
        opacity: 1;
    }
    .text-center{
        text-align: center;
    }
    li {
        color: white;
    }
    .loginBottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .forgotLink {
        color:  #4EB6EE;
        font-size: 15px;
        font-weight: 500;
        padding-top: 5px;
        text-align: center;
        &:hover {
            color: #4EB6EE;
        }
        &:active {
            color: #4EB6EE;
        }
        &:focus {
            color:#4EB6EE;
        }
    }

    .registerLink {
        color:  #4EB6EE;
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        padding-top: 5px;
        &:hover {
            color: #4EB6EE;
        }
        &:active {
            color: #4EB6EE;
        }
        &:focus {
            color:#4EB6EE;
        }
    }

    .link {
        color:  #4EB6EE;
        font-size: 15px;
        font-weight: 500;
        padding-top: 5px;
        &:hover {
            color: #4EB6EE;
        }
        &:active {
            color: #4EB6EE;
        }
        &:focus {
            color:#4EB6EE;
        }
    }

    .login{
        display: unset;
        line-height: 2rem;
    }
    .btn{
        &.btn-orange {
            font-size: 15px;
            margin-bottom: 0px;
        }
    }
    .loginFormScreens {
        .form-control {
            padding: 10px 20px;
            font-size: 15px;
        }
    }
    .d-block{
        text-align: start;
    }
    .text-center .btn-padding{
        padding: 0px;
    }
    @media (max-width: 1440px){
        .loginPageRightside {
            margin-top: 160px;
            margin-left: 150px;
        }
    }
    @media (max-width: 1300px){
        .loginPageLeftside {
            height: 560px;
        }
        .loginPageRightside {
            margin-top: 100px;
            margin-left: 110px;
        }
    }
    @media (max-width: 1200px){
        .loginPageLeftside {
            height: 520px;
        }
        .loginPageRightside {
            margin-top: 100px;
            margin-left: 100px;
        }
    }
    @media (max-width: 1100px){
        .loginPageRightside {
            margin-left: 80px;
        }
    }
    @media (max-width: 1024px){
        .loginPageRightside {
            margin-left: 70px;
            margin-top: 110px;
        }
    }
    @media (max-width: 991px){
        .loginPageRightside {
            margin-top: 100px;
            margin-left: 50px;
        }
        .loginFormScreens{
            width: 87%;
        }
        .loginScreenLogoMain {
            width: 80%;
        }
    }
    @media (max-width: 768px){
        .loginPageRightside {
            margin-left: 77px;
        }
    }
    @media (max-width: 767px){
        .loginPageRightside {
            margin-left: 10px;
        }
        .loginFormScreens{
            width: 97%;
        }
        .loginScreenLogoMain {
            width: 97%;
        }
    }
    @media (max-width: 500px){
        .loginFormScreens{
            width: 95%;
        }
        .loginScreenLogoMain {
            width: 95%;
        }
        .loginBottom {
            justify-content: start;
            flex-direction: column;
        }
    }
    @media (max-width: 325px){
        .loginFormScreens{
            width: 94%;
        }
        .loginScreenLogoMain {
            width: 94%;
        }
    }
        
`;

export default LoginWrap;
