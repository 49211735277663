import Styled from "styled-components";

const TrackingViewWrap = Styled.div`

.titlecancel, .titleupdate{
        padding: 4px 4px;
        color:  #9d9d9d;
        background: #f1f1f1;
        border: 2px solid #d3d3d3; 
        border-color: #d3d3d3;
        &:hover {
          background: #a6a6a6;
          color: white;
        }
    }

    .kpy_points_container{
      padding-top: 1rem;
    }
    .kpy_points_header{
      color: white;
      font-size: 16px;
    }
    .kpy_points_status{
      display: flex;
      gap: 2rem;
      color: white;
      font-size: 16px;
    }
    .kpy_points_status_title{
      width: 140px;
      color: ${({ theme }) => theme["themeColor"]};
    }
    .kpi_points_radio{
      color: ${({ theme }) => theme["themeColor"]};
    }
    .clone-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .select_backlogs_options{
      width: 200px;
    }
    
    .video_inprogress{
      width:100% !important;
      height:15px !important;      
    }

`;

export default TrackingViewWrap;
