import React from "react";

const NotFound = () => {
  return (
    <>
      <h1 className="text-center text-white">Page Not Found</h1>
    </>
  );
};

export default NotFound;
