import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Modal, Form, Alert } from "react-bootstrap"
import BarLoader from "react-spinners/ClipLoader"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import BlockIcon from "@mui/icons-material/Block"
import Tables from "../../../Reuseable/Tables"
import { api } from "../../../../Services/api"
import { setApprovalTaskView, setApprovalTrackings } from "../../../../redux/Tracking/actionCreator"
import approvedIcon from "../../../../assets/images/approved-icon.svg"
import CloseIcon from "@mui/icons-material/Close"
import viewIcon from "../../../../assets/images/view.svg"
import ApprovalWrap from "./style"
import { ConfirmationBox } from "../../../Reuseable/ConfimationBox"
import { TrackingView } from "../Tracking/TrackingView"
import { ApprovalTrackingView } from "../ApprovalTrackingView"
import { useSessionStorage } from "react-use"
import axios from "axios"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"
import moment from "moment"

export const Approval = () => {
  const dispatch = useDispatch()
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)
  const { data: allProjectMembers } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMemberres = allProjectMembers?.data ?? []

  const { data: allProjectWorkflowRes } = useFetch(
    key.get_all_workflow,
    `/trekrworkflow`,
    () => {},
    () => {},
    false,
    false
  )
  let allWorkFlow = allProjectWorkflowRes?.data?.workflowList ?? []

  const { data: allProjectsRes } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allProjectsData = allProjectsRes?.data?.Items ?? []

  const { data: allTasksTypesRes } = useFetch(
    key.get_all_tasktypes,
    "/trackingtasktype",
    () => {},
    () => {},
    false,
    true
  )
  let allTasksTypes = allTasksTypesRes?.data ?? []

  const trackingUserId = useSelector((state) => state.Auth.user.trackinguserid)

  let allTrackingsList = useSelector((state) => state.Tracking.allTrackings)
  const allFlowStatus = useSelector((state) => state.Tracking.allFlowStatus)
  const authToken = useSelector((state) => state.Auth.authToken)
  let taskview = useSelector((state) => state.Tracking.approvalView)

  const [taskHistory, setHistory] = useState("")
  const [declineLoading, setDeclineLoading] = useState(false)
  const [approvedLoading, setApprovedLoading] = useState(false)
  const [showApprovedPopup, setShowApprovedPopup] = useState(false)
  const [showDeclinedPopup, setShowDeclinedPopup] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [showView, setShowView] = useState(false)
  const [fileLoading, setFileLoading] = useState(false)
  const [approveConfirmationShow, setApproveConfirmationShow] = useState(false)
  const [trackingItemData, setTrackingItemData] = useSessionStorage("approvalTrackingItemData", {})

  const [updatedData, setUpdatedData] = useSessionStorage("trackingUpdatedData", {})
  const [selectedPriority, setSelectedPriority] = useState("")
  const [declineReason, setDeclineReason] = useState("")
  const [viewTaskData, setViewTaskData] = useState({})
  const [taskfile, setTaskFile] = useState("")
  const [taskfileList, setTaskFileList] = useState("")
  const [taskfileType, setTaskFileType] = useState("")
  const [showEditTask, setShowEditTask] = useState("")
  const [showAlertBox, setShowAlertBox] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false)

  useEffect(() => {
    setViewTaskData((prevState) => {
      return {
        ...prevState,
        title: trackingItemData.title,
        projectid: trackingItemData.trackingprojectsid,
        taskType: trackingItemData.type,
        assignedTo: trackingItemData.assignedto,
        priority: trackingItemData.priority,
        status: trackingItemData.trackingprojectstatusid,
        description: trackingItemData.description,
        timeline: trackingItemData.timeline,
        fileurls: trackingItemData.fileurls,
        filesize: trackingItemData.filesize ?? [],
        fileArray: []
      }
    })

    setUpdatedData((prevState) => {
      return {
        ...prevState,
        title: trackingItemData.title,
        description: trackingItemData.description,
        fileurls: trackingItemData.fileurls,
        timeline: trackingItemData.timeline,
        filesize: trackingItemData.filesize
      }
    })
  }, [trackingItemData])

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
    }, 2500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
    }, 2500)
  }, [showAlertBox])

  const {
    data: approvalTrackingsRes,
    refetch: fetchApprovalTrackings,
    isLoading: loadingList
  } = useFetch(
    key.get_approval_data,
    `/tracking/approval/${trackingClientId}`,
    () => {},
    () => {},
    false,
    true
  )
  let approvalTrackings = approvalTrackingsRes?.data ?? []

  const { data: approvalHeadersRes } = useFetch(
    key.get_approval_headers,
    "/masterdata/10",
    () => {},
    () => {},
    false,
    false
  )

  const allApprovalsHeader = approvalHeadersRes?.formStructureData ?? []

  const onPriorityHandler = async (prioritySelected) => {
    api
      .patch(
        "/tracking/update/" + selectedItem.trackingid,
        {
          name: "priority", //* Empty String for Approved resemblance on the backend
          value: prioritySelected,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        {
          headers: {
            authorizationtoken: `${authToken}`
          }
        }
      )
      .then(function (response) {
        if (response.data.status == true) {
          fetchApprovalTrackings()
        }
      })
      .catch((error) => {
        console.log("onPriorityHandler  error", error)
      })
  }

  const onApproveHandler = () => {
    let index = approvalTrackings.findIndex(
      (element) => element.trackingid == selectedItem.trackingid
    )
    setApprovedLoading(true)
    api
      .patch(
        "/tracking/update/" + selectedItem.trackingid,
        {
          name: "approval_status", //* Empty String for Approved resemblance on the backend
          value: "",
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          approved_by: trackingUserId
        },
        {
          headers: {
            authorizationtoken: `${authToken}`
          }
        }
      )
      .then(function (response) {
        if (response.data.status == true) {
          setApproveConfirmationShow(false)
          approvalTrackings.splice(index, 1)
          dispatch(setApprovalTrackings(approvalTrackings))
          setShowApprovedPopup(true)
          setApprovedLoading(false)
        }
      })
      .catch((error) => {
        console.log("onApproveHandler  error", error)
      })
  }

  const onDeclineHandler = (item) => {
    setSelectedItem(item)
    setShowDeclinedPopup(true)
  }

  const onSubmitDeclineHandle = () => {
    let index = approvalTrackings.findIndex(
      (element) => element.trackingid == selectedItem.trackingid
    )
    setDeclineLoading(true)
    api
      .patch(
        "/tracking/update/" + selectedItem.trackingid,
        {
          name: "approval_status",
          value: "2",
          approved_by: trackingUserId,
          reason: declineReason,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        {
          headers: {
            authorizationtoken: ` ${authToken}`
          }
        }
      )
      .then(function (response) {
        if (response.data.status == true) {
          fetchApprovalTrackings()
          setShowDeclinedPopup(false)
          setDeclineLoading(false)
        }
      })
      .catch((error) => {
        console.log("onSubmitDeclineHandle  error", error)
      })
  }

  const ApprovedView = ({ show }) => {
    return (
      <Modal
        show={show}
        centered
        onHide={(e) => setShowApprovedPopup(false)}
        size='sm'
        keyboard={false}
      >
        <Modal.Body>
          <div className='text-center p-3'>
            <img
              className='mb-3'
              alt='approvalIcon'
              src={approvedIcon}
              height={"50"}
              width={"50"}
            />
            <br />
            <h5>
              Tracking ID : <strong>{selectedItem.trackingid}</strong> <br />
              is now approved
            </h5>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  let ApprovalscolumnValues = []

  for (let i = 0; i < allApprovalsHeader.length; i++) {
    ApprovalscolumnValues.push(allApprovalsHeader[i].column1)
    ApprovalscolumnValues.push(allApprovalsHeader[i].column2)
    ApprovalscolumnValues.push(allApprovalsHeader[i].column3)
    ApprovalscolumnValues.push(allApprovalsHeader[i].column4)
    ApprovalscolumnValues.push(allApprovalsHeader[i].column5)
    ApprovalscolumnValues.push(allApprovalsHeader[i].column6)
  }
  const ApprovalColumns = () => {
    return (
      <>
        {ApprovalscolumnValues.map((item, index) => {
          return (
            <th className={item === "Actions" && "text-center"} key={index}>
              {item}
            </th>
          )
        })}
      </>
    )
  }

  let priorityOptions = [
    { label: "Blocker Level 1", id: "blocker" },
    { label: "Blocker Level 2", id: "blocker2" },
    { label: "Major", id: "major" },
    { label: "Medium", id: "medium" },
    { label: "Minor", id: "minor" }
  ]

  const ApprovalData = approvalTrackings
    ? approvalTrackings
        .sort((item1, item2) => {
          if (parseInt(item1.trackingid) < parseInt(item2.trackingid)) return 1
          else return -1
        })
        .map((item, i) => {
          let project_name = allProjectsData.find(
            (e) => e.trackingprojectid == item?.trackingprojectsid
          )
          if (project_name) item.project_name = project_name.label
          let reportedByName = allMemberres.find((e) => e.userId == item.reportedby)
          if (reportedByName) item.reportedByName = reportedByName.personname
          let task = allTasksTypes.find((e) => e.id == item.type)
          if (task) item.taskName = task.label
          return (
            <tr key={i}>
              <td>
                <strong className='mobHead'>Tracking No. : </strong>
                <span
                  style={{ color: "#4EB6EE", cursor: "pointer" }}
                  className='mobSubhead'
                  onClick={(e) => {
                    taskViewRender("taskview")
                    setTrackingItemData(item)
                  }}
                >
                  {trackingClientId}-{item.trackingid}{" "}
                  {item.priority === "blocker" || item.priority === "blocker2" ? (
                    <BlockIcon
                      className='icon-color'
                      style={{
                        cursor: "pointer",
                        color: "red",
                        fontSize: "17px",
                        margin: "0px 0px 3px 0px"
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </span>
              </td>
              <td>
                <strong className='mobHead'>Title : </strong>
                <span className='mobSubhead'>{item.title}</span>
              </td>
              <td>
                <strong className='mobHead'>Project : </strong>
                <span className='mobSubhead'>{item.projectname}</span>
              </td>
              <td>
                <strong className='mobHead'>Reported On : </strong>
                <span className='mobSubhead'>
                  {moment(item.reporteddate).format("MMM D, YYYY hh:mm A")}
                </span>
              </td>
              <td className='text-capitalize'>
                <strong className='mobHead'>Reported By : </strong>
                <span className='mobSubhead'>{item.reportedByName}</span>
              </td>
              <td className='text-center actionBtns'>
                {item.approval_status === "Approved" || item.approval_status === "1" ? ( //? 1. waiting for approval 2. decline
                  <>
                    {/* {isApprover(trackingUserId) &&  */}
                    <Button
                      size='sm'
                      title='Approve'
                      variant='link'
                      className='text-decoration-none pe-1 ps-1'
                      onClick={(e) => {
                        setSelectedItem(item)
                        setApproveConfirmationShow(true)
                        setSelectedPriority(item.priority)
                      }}
                    >
                      <CheckCircleIcon style={{ color: "green" }} />
                    </Button>
                    {/* } */}
                    <Button
                      size='sm'
                      title='View'
                      variant='link'
                      className='text-decoration-none pe-1 ps-1'
                      onClick={(e) => {
                        setShowView(true)
                        setTrackingItemData(item)
                      }}
                    >
                      <img className='' src={viewIcon} alt='viewIcon' />
                    </Button>
                    {/* {isApprover(trackingUserId) &&  */}
                    <Button
                      size='sm'
                      title='Decline'
                      variant='link'
                      className='text-decoration-none pe-1 ps-1'
                      onClick={(e) => onDeclineHandler(item)}
                    >
                      <CancelIcon style={{ color: "red" }} />
                    </Button>
                    {/* } */}
                  </>
                ) : item.approval_status === "2" ? (
                  <>
                    {/* {isApprover(trackingUserId) &&  */}
                    <Button
                      size='sm'
                      title='Approve'
                      variant='link'
                      className='text-decoration-none pe-1 ps-1'
                      onClick={(e) => {
                        setSelectedItem(item)
                        setApproveConfirmationShow(true)
                        setSelectedPriority(item.priority)
                      }}
                    >
                      <CheckCircleIcon style={{ color: "green" }} />
                    </Button>
                    {/* } */}
                    <Button
                      size='sm'
                      title='View'
                      variant='link'
                      className='text-decoration-none pe-1 ps-1'
                      onClick={(e) => {
                        setShowView(true)
                        setTrackingItemData(item)
                      }}
                    >
                      <img className='' src={viewIcon} alt='viewIcon' />
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </td>
            </tr>
          )
        })
    : []

  const fetchFileData = (item) => {
    for (let i = 0; i < item.fileurls.length; i++) {
      setFileLoading(true)
      axios({
        url:
          "https://trackingapitestupload.hashching.org/upload/" +
          trackingClientId +
          "/S3" +
          trackingClientId +
          item.trackingid +
          "/" +
          item.fileurls[i],
        method: "GET",
        responseType: "arraybuffer"
      }).then((response) => {
        let base64ImageString = Buffer.from(response.data, "binary").toString("base64")
        setTaskFile((prevfiles) => [...prevfiles, base64ImageString])
        setTaskFileType((prevfiles) => [...prevfiles, item.fileurls[i].split(".").pop()])
        setTaskFileList((prevfiles) => [...prevfiles, item.fileurls[i]])
        setFileLoading(false)
      })
    }
  }

  const refreshfiles = (item) => {
    fetchFileData(item, "refresh")
  }

  const taskViewRender = (value) => {
    dispatch(setApprovalTaskView(value))
  }

  const deleteConfimrationHandler = (response) => {
    if (response) {
      api
        .delete("/tracking/" + trackingClientId + "/" + trackingItemData.trackingid, {
          headers: {
            authorizationtoken: ` ${authToken}`
          }
        })
        .then(function (response) {
          if (response.status === 200) {
            setShowAlertBox(true)
            taskViewRender("AllTask")
            let index = allTrackingsList.findIndex(
              (element) => element.trackingid === trackingItemData.trackingid
            )
            if (index >= 0) {
              allTrackingsList.splice(index, 1)
            }
            setAlertMessage("Selected item has been deleted successfully")
            // refreshAllTracking()
          }
        })
    }
  }

  if (taskview === "taskview") {
    return (
      <TrackingView
        setCloneTask={() => {}}
        fileLoading={fileLoading}
        setTaskview={taskViewRender}
        show={true}
        setTaskFile={setTaskFile}
        setTaskFileType={setTaskFileType}
        taskfileType={taskfileType}
        setTrackingItemData={setTrackingItemData}
        taskfileList={taskfileList}
        setTaskFileList={setTaskFileList}
        taskfile={taskfile}
        refreshfiles={refreshfiles}
        setShowView={(value) => setShowView(value)}
        allTaskTypes={allTasksTypes}
        allStatus={allFlowStatus}
        item={trackingItemData}
        data={viewTaskData}
        setViewTaskData={setViewTaskData}
        showEditTask={showEditTask}
        setShowEditTask={setShowEditTask}
        updatedData={updatedData}
        setUpdatedData={setUpdatedData}
        history={taskHistory}
        setHistory={setHistory}
        setShowClone={() => {}}
        selected_menu={"all_tasks"}
        setDeleteConfirmationShow={setDeleteConfirmationShow}
        fetchFileData={fetchFileData}
        deletePermission={true}
        editPermission={true}
        type='approve'
      />
    )
  }

  return (
    <>
      {showAlertBox && (
        <Alert
          variant='success'
          className='text-center alert-sucess alert mt-3 mb-1'
          dismissible
          closeVariant='white'
          onClick={(e) => setShowAlertBox(false)}
        >
          {alertMessage}
        </Alert>
      )}

      <ApprovalWrap>
        <div className='approvePage'>
          <h3 className='pagetitle'>Approval</h3>
          <Tables
            columns={ApprovalColumns()}
            data={ApprovalData}
            showInfiniteScroll={false}
            setProjectTable={true}
            header={""}
            notfound={"No Trackings Found"}
            pagination={""}
            loading={loadingList}
          />
        </div>
      </ApprovalWrap>

      <ConfirmationBox
        heading='Are you sure to delete this ?'
        hidePopup={(e) => setDeleteConfirmationShow(false)}
        show={deleteConfirmationShow}
        confirmationResponse={deleteConfimrationHandler}
      />

      <ApprovalTrackingView
        show={showView}
        item={trackingItemData}
        setView={(value) => setShowView(value)}
        type={"approve"}
        activity={false}
      />

      <ApprovedView show={showApprovedPopup} />
      <Modal
        show={showDeclinedPopup}
        centered
        onHide={(e) => setShowDeclinedPopup(false)}
        size='md'
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Are you sure want to Decline? </Modal.Title>
          <CloseIcon
            className='close-icon float_close_icon'
            onClick={() => setShowDeclinedPopup(false)}
            style={{ cursor: "pointer", fontSize: "30px" }}
          />
        </Modal.Header>
        <Modal.Body>
          {selectedItem.approval_status == "decline" ? (
            <span>{selectedItem.declined_remark}</span>
          ) : (
            <Form
              autoComplete='off'
              onSubmit={(e) => {
                onSubmitDeclineHandle()
                e.preventDefault()
              }}
            >
              <Form.Group className='mb-2' controlId={"reason"}>
                <Form.Label>Reason</Form.Label>
                <Form.Control
                  as='textarea'
                  rows='4'
                  placeholder='Enter the Reason here...'
                  name={"reason"}
                  value={declineReason}
                  onChange={(e) => setDeclineReason(e.target.value)}
                />
              </Form.Group>

              <div className='text-center'>
                <Button
                  type='submit'
                  className=' btn btn-orange px-2 mx-2'
                  variant='orange'
                  style={{
                    background: declineLoading ? "#FFFFFF" : "#4EB6EE",
                    color: declineLoading ? "#808080" : "#FFFFFF",
                    border: declineLoading ? "2px solid #FFFFFF" : "2px solid #4EB6EE"
                  }}
                >
                  {declineLoading ? (
                    <BarLoader
                      color='#808080'
                      className='bar-loader'
                      size={14}
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    <></>
                  )}
                  Decline{""}
                </Button>

                <Button
                  type='button'
                  className='btn btn-orange px-2 mx-2'
                  variant='orange'
                  onClick={() => {
                    setShowDeclinedPopup(false)
                  }}
                  style={{
                    background: "#FFFFFF",
                    color: "#808080",
                    height: "33px"
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={approveConfirmationShow}
        centered
        onHide={(e) => setApproveConfirmationShow(false)}
        size='md'
        keyboard={false}
      >
        <Modal.Header className='border-0'>
          <Modal.Title>Approve Tracking </Modal.Title>
          <CloseIcon
            className='close-icon float_close_icon'
            onClick={() => setApproveConfirmationShow(false)}
            style={{ cursor: "pointer", fontSize: "30px" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Form
            autoComplete='off'
            onSubmit={(e) => {
              onApproveHandler()
              e.preventDefault()
            }}
          >
            <Form.Group controlId='priority'>
              <Form.Label>
                Priority<span className='mandatorySign text-danger'>*</span>
              </Form.Label>
              <Form.Control
                as='select'
                value={selectedPriority}
                name='priority'
                onChange={(e) => {
                  setSelectedPriority(e.target.value)
                  onPriorityHandler(e.target.value)
                }}
              >
                <option value=''>Select</option>
                {priorityOptions.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <div className='text-center mt-3'>
              <Button
                type='submit'
                disabled={!selectedPriority || selectedPriority === "" ? true : ""}
                className='loginSubmitBtn btn btn-orange me-2'
                style={{
                  background: approvedLoading ? "#FFFFFF" : "#4EB6EE",
                  color: approvedLoading ? "#808080" : "#FFFFFF",
                  border: approvedLoading ? "2px solid #FFFFFF" : "2px solid #4EB6EE"
                }}
              >
                {approvedLoading ? (
                  <BarLoader
                    color='#808080'
                    className='bar-loader'
                    size={14}
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  <></>
                )}
                Approve{""}
              </Button>
              <Button
                type='button'
                onClick={() => {
                  setApproveConfirmationShow(false)
                }}
                className='loginSubmitBtn btn btn-orange me-2 px-2'
                style={{
                  background: "#FFFFFF",
                  color: "#808080",
                  height: "33px"
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
    // null
  )
}
