import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Alert, Spinner } from "react-bootstrap"
import norecordsvg from "../../../../assets/images/norecord.svg"
import Cards from "../../../Reuseable/Cards"
import Tables from "../../../Reuseable/Tables"
import { api } from "../../../../Services/api"
import "react-confirm-alert/src/react-confirm-alert.css"
import {
  setShowAlert,
  setAllTrackings,
  setFetchedAllTrackingsList,
  setFetchedMyTrackingList,
  setStartAllTrekId,
  setStartMyTrekId,
  setMyTrackings,
  setMyTaskSelectedProject,
  setMyTaskView
} from "../../../../redux/Tracking/actionCreator"
import { ConfirmationBox } from "../../../Reuseable/ConfimationBox"
import TrackingModalView, { CardView } from "../Tracking/style"
import { TrackingView } from "../Tracking/TrackingView"
import { TrackingListHeader } from "../Tracking/TrackingListHeader"
import { TrackingListColumns } from "../Tracking/TrackingListColumns"
import { NewTracking } from "../NewTracking"
import { Clone } from "../Tracking/Clone"
import NoDataFound from "../Tracking/NoDataFound"
import Dynamicdata from "../../../Reuseable/Select"
import { v4 as uuid } from "uuid"
import moment from "moment"
import { useSessionStorage } from "react-use"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"
import { client } from "../../../.."

export default function Tracking({ permission, backlogsPermission }) {
  const editPermission = permission.edit
  const createPermission = permission.create
  const deletePermission = permission.delete
  const fetchedAllTrackings = useSelector((state) => state.Tracking.fetchedAllTrackings)
  const fetchedMyTrackings = useSelector((state) => state.Tracking.fetchedMyTrackings)
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)
  let allTrackingsList = useSelector((state) => state.Tracking.allTrackings)
  let myTrackingList = useSelector((state) => state.Tracking.myTrackings)
  const allFlowStatus = useSelector((state) => state.Tracking.allFlowStatus)
  const user = useSelector((state) => state.Auth.user)
  const authToken = useSelector((state) => state.Auth.authToken)
  const filters = useSelector((state) => state.Tracking.filters)

  const { data: allMembersRes } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMembers = allMembersRes?.data ?? []
  const { data: allTasksTypesRes } = useFetch(
    key.get_all_tasktypes,
    "/trackingtasktype",
    () => {},
    () => {},
    false,
    false
  )
  let allTaskTypes = allTasksTypesRes?.data ?? []

  const { data: allProjectWorkflowRes } = useFetch(
    key.get_all_workflow,
    `/trekrworkflow`,
    () => {},
    () => {},
    false,
    false
  )

  let allWorkFlow = allProjectWorkflowRes?.data?.workflowList ?? []

  let Work_Flow = Object.keys(allWorkFlow).map((key) => allWorkFlow[key])

  let allProjectModals = useSelector((state) => state.Tracking.projectModel)

  const [searchValue, setSearchValue] = useState("")
  const [loading, setLoading] = useState(false)
  const [fileLoading, setFileLoading] = useState(false)
  const [showAlertBox, setShowAlertBox] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [showView, setShowView] = useState(false)
  const [showFormView, setShowFormView] = useState(false)
  const [trackingItemData, setTrackingItemData] = useSessionStorage("myTaskTrackingData", {})
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false)
  const [formType, setFormType] = useState("")
  const [clonetaskData, setCloneTask] = useState({})
  const taskview = useSelector((state) => state.Tracking.myTaskView)

  const [allcheckTable, setAllCheckTable] = useState([])
  const [selectAllTasks, setSelectAllTasks] = useState(false)

  const handleTasksHeaderCheckbox = () => {
    if (selectAllTasks) {
      setAllCheckTable([])
    } else {
      const allRowIndexes = myTrackingList.map((item, index) => ({
        item,
        index
      }))
      setAllCheckTable(allRowIndexes)
    }
    setSelectAllTasks((prevSelectAll) => !prevSelectAll)
  }

  const handleMoveCheckboxTable = (item, index) => {
    if (allcheckTable.some((row) => row.index === index)) {
      setAllCheckTable((prevSelectedRows) => prevSelectedRows.filter((row) => row.index !== index))
    } else {
      setAllCheckTable((prevSelectedRows) => {
        if (!Array.isArray(prevSelectedRows)) {
          prevSelectedRows = []
        }
        return [...prevSelectedRows, { item, index }]
      })
    }
  }

  const setTaskview = (view) => {
    dispatch(setMyTaskView(view))
  }

  const handleSearch = (value) => {
    setSearchValue(value)
  }
  const dispatch = useDispatch()

  const [trackingSortings, setTrackingSorting] = useState({
    selectedSortingItem: "trackingid",
    trackingNumberSorting: "down",
    statusSorting: "",
    typeSorting: "",
    projectNameSorting: "",
    reportedBySorting: "",
    prioritySorting: "",
    assignedToSorting: ""
  })

  const [trackingFilters, setTrackingFilters] = useState({
    projectsFilter: filters?.projects,
    typeFilter: filters?.taskType,
    assignedtoFilter: filters?.assignedTo,
    statusFilter: filters?.status,
    priorityFilter: filters?.priority,
    reportedByFilter: filters?.reportedBy
  })

  const selected_menu = "my_tasks"
  const [cardui, setCardui] = useSessionStorage("List", "List")
  const [changeInForm, setChangeInForm] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [viewTaskData, setViewTaskData] = useState({})
  const [taskfile, setTaskFile] = useState("")
  const [taskfileList, setTaskFileList] = useState("")
  const [taskfileType, setTaskFileType] = useState("")
  const [showEditTask, setShowEditTask] = useState("")
  const [updatedData, setUpdatedData] = useSessionStorage("myTrackingUpdatedData", {})
  const [taskHistory, setHistory] = useState("")
  const [showClone, setShowClone] = useState(false)
  const [startMyTrackingId, setStartMyTrackingId] = useState(
    useSelector((state) => state.Tracking.startMyTrackingId)
  )

  const selectedProject = useSelector((state) => state.Tracking.myTaskSelectedProject)
  const [selectedProjectName, setSelectedProjectName] = useState("")

  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowAlert(false))
      setAlertMessage("")
      setShowAlertBox(false)
    }, 2500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
    }, 2500)
  }, [showAlertBox])

  useEffect(() => {
    setViewTaskData((prevState) => {
      return {
        ...prevState,
        title: trackingItemData.title,
        projectid: trackingItemData.trackingprojectsid,
        taskType: trackingItemData.type,
        assignedTo: trackingItemData.assignedto,
        priority: trackingItemData.priority,
        status: trackingItemData.trackingprojectstatusid, // there is not status with ID 0 and 1
        description: trackingItemData.description,
        timeline: trackingItemData.timeline,
        fileurls: trackingItemData.fileurls
      }
    })
    setCloneTask((prevState) => {
      return {
        ...prevState,
        title: trackingItemData.cloneTitle,
        assignedto: trackingItemData.assignedto
      }
    })
    setUpdatedData((prevState) => {
      return {
        ...prevState,
        title: trackingItemData.title,
        description: trackingItemData.description,
        fileurls: trackingItemData.fileurls,
        timeline: trackingItemData.timeline
      }
    })
  }, [trackingItemData])

  useEffect(() => {
    if (selected_menu === "my_tasks") {
      setShowFilter(false)
      setTrackingFilters({})
    }
  }, [selected_menu])

  const refreshAllTracking = () => {
    fetchTrackingsList(project, "refresh")
  }

  const { data: allProjectsRes, isLoading: allProjectsLoading } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    true
  )

  const allProjects = allProjectsRes?.data?.Items ?? []

  const project = selectedProject || allProjects[0]?.trackingprojectid

  const fetchTrackingsList = (startId, fetchtype) => {
    setLoading(true)
    let url = `/tracking/mytask/${startId === "none" ? null : startId}`
    api
      .get(url, {
        headers: {
          authorizationtoken: ` ${authToken}`,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"
        }
      })
      .then(function (response) {
        if (response.data.status === true) {
          if (selected_menu === "my_tasks") {
            if (response.data.data) {
              if (fetchtype === "refresh") dispatch(setMyTrackings([...response.data.data]))
              else {
                if (myTrackingList?.length > 0) {
                  dispatch(setMyTrackings(response.data.data))
                } else dispatch(setMyTrackings(response.data.data))
              }
            }

            if (response.data.data.LastEvaluatedKey) {
              setStartMyTrackingId(response.data.data.LastEvaluatedKey.trackingid)
              dispatch(setStartMyTrekId(response.data.data.LastEvaluatedKey.trackingid))
            } else {
              setStartMyTrackingId("none")
              dispatch(setStartMyTrekId("none"))
            }
            dispatch(setFetchedMyTrackingList(true))
          } else {
            if (response.data.data) {
              if (fetchtype === "refresh") dispatch(setAllTrackings([...response.data.data]))
              else {
                if (allTrackingsList?.length > 0) {
                  allTrackingsList = [...allTrackingsList, ...response.data.data]
                  dispatch(setAllTrackings(allTrackingsList))
                } else dispatch(setAllTrackings(response.data.data))
              }
            }

            if (response.data.data.LastEvaluatedKey) {
              dispatch(setStartAllTrekId(response.data.data.LastEvaluatedKey.trackingid))
            } else {
              dispatch(setStartAllTrekId("none"))
            }
            dispatch(setFetchedAllTrackingsList(true))
          }

          setLoading(false)
        }
      })
      .catch((error) => {
        console.error("fetchTrackingsList  error", error)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (project) {
      fetchTrackingsList(project)
    }
  }, [project])

  let ProjectModoptions = []
  if (allProjectModals?.length > 0) {
    allProjectModals.forEach((item) =>
      ProjectModoptions.push({
        label: item.ModelTitle,
        id: item.ProjectModelId
      })
    )
  }

  let AllProjects = []
  if (allProjects?.length > 0) {
    allProjects.forEach((element) => {
      AllProjects.push({ label: element.label, id: element.trackingprojectid })
    })
  }

  const { data: allInputformsRed } = useFetch(
    key.get_all_inputforms,
    "/masterdata/2",
    () => {},
    () => {},
    false,
    false
  )
  let allAddTaskInputs = allInputformsRed?.formStructureData ?? []
  let inputFormArr = useSelector((state) => state.Tracking.inputFormArr)

  const menus = useSelector((state) => state.Auth.menuList)
  const editorRef = useRef(null)

  const changesInList = useRef(null)

  const changesMade = () => {
    changesInList.current = uuid()
  }

  const deleteConfimrationHandler = (response) => {
    if (response) {
      api
        .delete("/tracking/" + trackingClientId + "/" + trackingItemData.trackingid, {
          headers: {
            authorizationtoken: ` ${authToken}`
          }
        })
        .then(function (response) {
          if (response.status === 200) {
            changesMade()
            setShowAlertBox(true)
            setTaskview("AllTask")
            let index = myTrackingList.findIndex(
              (element) => element.trackingid === trackingItemData.trackingid
            )
            if (index >= 0) {
              myTrackingList.splice(index, 1)
            }
            changesMade()
            setAlertMessage("Selected item has been deleted successfully")
          }
        })
    }
  }

  const taskViewRender = (value) => {
    setTaskview(value)
  }

  const refreshfiles = (item) => {
    fetchFileData(item, "refresh")
  }

  const fetchFileData = (item) => {
    for (let i = 0; i < item.fileurls?.length; i++) {
      setFileLoading(true)
      api
        .get(`/tracking/fileupload/${trackingClientId}/${item.trackingid}/${item.fileurls[i]}`)
        .then((response) => {
          if (response) {
            let Filename = item.fileurls[i]
            setTaskFile((prevfiles) => [...prevfiles, response.data.url])
            setTaskFileType((prevfiles) => [...prevfiles, Filename.split(".").pop()])
            setTaskFileList((prevfiles) => [...prevfiles, Filename])
            setFileLoading(false)
          } else {
            console.error("Error")
          }
        })
    }
  }

  const getData = (data) => {
    const result = data
      ?.sort((item1, item2) => {
        if (trackingSortings.selectedSortingItem === "priority") {
          if (trackingSortings.prioritySorting) {
            if (trackingSortings.prioritySorting === "down") {
              if (item1.priority < item2.priority) return 1
              else return -1
            } else {
              if (item1.priority < item2.priority) return -1
              else return 1
            }
          }
        } else if (trackingSortings.selectedSortingItem === "project") {
          if (trackingSortings.projectNameSorting) {
            if (trackingSortings.projectNameSorting === "down") {
              if (item1.project_name < item2.project_name) return 1
              else return -1
            } else {
              if (item1.project_name < item2.project_name) return -1
              else return 1
            }
          }
        } else if (trackingSortings.selectedSortingItem === "reportedby") {
          if (trackingSortings.reportedBySorting) {
            if (trackingSortings.reportedBySorting === "down") {
              if (item1.reportedByName < item2.reportedByName) return 1
              else return -1
            } else {
              if (item1.reportedByName < item2.reportedByName) return -1
              else return 1
            }
          }
        } else if (trackingSortings.selectedSortingItem === "status") {
          if (trackingSortings.statusSorting) {
            if (trackingSortings.statusSorting === "down") {
              if (item1.status_name < item2.status_name) return 1
              else return -1
            } else {
              if (item1.status_name < item2.status_name) return -1
              else return 1
            }
          }
        } else if (trackingSortings.selectedSortingItem === "assignedto") {
          if (trackingSortings.assignedToSorting) {
            if (trackingSortings.assignedToSorting === "down") {
              if (item1.assignedtoName < item2.assignedtoName) return 1
              else return -1
            } else {
              if (item1.assignedtoName < item2.assignedtoName) return -1
              else return 1
            }
          }
        } else if (trackingSortings.selectedSortingItem === "type") {
          if (trackingSortings.typeSorting) {
            if (trackingSortings.typeSorting === "down") {
              if (item1.taskName < item2.taskName) return 1
              else return -1
            } else {
              if (item1.taskName < item2.taskName) return -1
              else return 1
            }
          }
        } else {
          if (trackingSortings.trackingNumberSorting) {
            if (trackingSortings.trackingNumberSorting === "down") {
              if (parseInt(item1.trackingid) < parseInt(item2.trackingid)) return 1
              else return -1
            } else {
              if (parseInt(item1.trackingid) < parseInt(item2.trackingid)) return -1
              else return 1
            }
          }
        }
      })
      .filter((item) => {
        if (selected_menu === "my_tasks")
          return item.status_name !== "Deployed" && item.status_name !== "Verified"
        else return item
      })
      .filter((item) => {
        let trackingnumber = trackingClientId + "-" + item.trackingid
        let trackingnumber1 = trackingClientId + item.trackingid
        return (
          trackingnumber.toLowerCase().includes(searchValue.toLowerCase()) ||
          trackingnumber1.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.title.toLowerCase().includes(searchValue.toLowerCase())
        )
      })
      .map((item, i) => {
        let project_name = allProjects.find((e) => e.trackingprojectid === item.trackingprojectsid)
        if (project_name) item.project_name = project_name.label
        else item.project_name = "Not Assigned"

        let workflowId = allProjects.find(
          (e) =>
            e.trackingprojectid == item.trackingprojectsid || e.number == item.trackingprojectsid
        )
        if (workflowId) item.workflowId = workflowId.workflow

        let newArr = []
        Work_Flow.map((e, i) => {
          Object.keys(e).map((n) => {
            newArr.push(e[n])
          })
        })
        let status_name = newArr.find((e) => e.workFlowId == item.workflowId)
        if (status_name) item.workflow = status_name.workFlow

        if (status_name) item.status_name = status_name.workFlow[item.trackingprojectstatusid]
        else item.status_name = "In approval"

        if (item.status_name) {
          let name = []
          Object.keys(item.status_name).map((n) => {
            name.push(item.status_name[n])
          })
          if (status_name) item.status_name = name
          else item.status_name = "In approval"
        }
        let reportedByName = allMembers.find((e) => e.userId === item.reportedby)
        if (reportedByName) item.reportedByName = reportedByName.personname

        let assignedto = allMembers.find((e) => e.userId === item.assignedto)
        if (assignedto) item.assignedtoName = assignedto.personname
        else item.assignedtoName = "Not Assigned"
        let task = allTaskTypes.find((e) => e.id === item.type)
        if (task) item.taskName = task.label
        item.cloneTitle = "Clone - " + item.title

        return item
      })

    return result
  }

  const [myTrackingData, setMyTrackingData] = useState([])

  const showWorkFlow = allProjects.find(
    (allProject) => allProject.trackingprojectid === project
  )?.workflow
  const workflow = Work_Flow.find((flow) => flow[0].workFlowId == showWorkFlow) // ? Don't Use "===" insted use "==" because it does not check same string or number

  let initialWorkflow = useRef({})

  let WorkFlow =
    workflow &&
    workflow[0]?.workFlow &&
    Object.entries(workflow[0]?.workFlow)?.map(([key, value], index) => {
      return {
        [uuid()]: {
          name: value[key],
          items: [],
          trackingprojectstatusid: index.toString(),
          edit: false,
          loader: false
        }
      }
    })

  initialWorkflow.current = {}

  WorkFlow?.forEach((element, index) => {
    const [key] = Object.keys(element)
    const [value] = Object.values(element)
    initialWorkflow.current[key] = value
  })

  const [columns, setColumns] = useState(initialWorkflow.current)
  const [quickTaskLoading, setQuickTaskLoading] = useState(false)

  const includeUuid = (data) => {
    return (
      data &&
      Object.entries(data).map(([k, d]) => {
        return { ...d, id: uuid() }
      })
    )
  }

  const makeCategory = (data) => {
    let uuidData = includeUuid(data)
    let cols = initialWorkflow.current
    uuidData &&
      Object.entries(uuidData).map(([key, value]) => {
        return Object.entries(cols).map(([k, col]) => {
          if (col.name?.toLowerCase() === value.status_name?.toString().toLowerCase()) {
            return col.items.push(value)
          }
          return 0
        })
      })
    setColumns(cols)
  }

  const handleWorkFlowNameUpdate = (updatedColumns) => {
    let workflowarray = Object.entries(updatedColumns).map(([key, value], index) => {
      return { [index]: value.name }
    })

    const currentWorkFlow = workflow[0]

    const WorkflowObject = {
      workFlowId: currentWorkFlow.workFlowId,
      workFlow: {
        createdBy: currentWorkFlow.createdBy,
        workFlowName: currentWorkFlow.workFlowName,
        createdOn: currentWorkFlow.createdOn,
        updatedOn: new moment().format("DD/MM/YYYY"),
        workFlowId: currentWorkFlow.workFlowId,
        workFlow: workflowarray,
        workflowStatus: currentWorkFlow.workflowStatus
      }
    }
    api
      .patch("/trekrworkflow", WorkflowObject, {
        headers: {
          authorizationtoken: `${authToken}`
        }
      })
      .then((response) => {
        console.log("Response-->", response)
        // getAllWorkFlow()
        client.invalidateQueries({
          queryKey: [key.get_all_workflow]
        })
      })
      .catch((error) => {
        return error
      })
  }

  const editSwitch = (name, state, updatedName, updateToApi = false) => {
    const newColumns = Object.entries(columns).map(([key, value]) => {
      if (value.name === name) {
        return {
          [uuid()]: { ...value, edit: state, name: updatedName }
        }
      }
      return { [uuid()]: value }
    })

    let updatedColumns = {}

    newColumns.forEach((element, index) => {
      const [key] = Object.keys(element)
      const [value] = Object.values(element)
      updatedColumns[key] = value
    })

    setColumns(updatedColumns)

    if (updateToApi) {
      handleWorkFlowNameUpdate(updatedColumns)
    }
  }

  const isLoading = (id, ans) => {
    setQuickTaskLoading(ans)
    const newColumns = Object.entries(columns).map(([key, value]) => {
      if (value.trackingprojectstatusid === id) {
        return {
          [uuid()]: { ...value, loader: ans }
        }
      }
      return { [uuid()]: value }
    })

    let updatedColumns = {}

    newColumns.forEach((element, index) => {
      const [key] = Object.keys(element)
      const [value] = Object.values(element)
      updatedColumns[key] = value
    })

    changesMade()
    setColumns(updatedColumns)
  }

  useEffect(() => {
    setMyTrackingData(getData(myTrackingList))
  }, [
    myTrackingList,
    project,
    changesInList.current,
    workflow,
    searchValue,
    trackingFilters,
    trackingSortings
  ])

  useEffect(() => {
    const newData = myTrackingData
    !quickTaskLoading && makeCategory(newData)
  }, [
    myTrackingData,
    project,
    changesInList.current,
    workflow,
    searchValue,
    trackingFilters,
    trackingSortings
  ])

  const getSelectedOption = (data, name) => {
    dispatch(setMyTaskSelectedProject(data))
    setSelectedProjectName(name)
  }

  if (taskview === "addnew") {
    return (
      <NewTracking
        setTaskview={setTaskview}
        setShowAlertBox={(val) => setShowAlertBox(val)}
        setAlertMessage={(val) => setAlertMessage(val)}
        refreshAllTracking={refreshAllTracking}
        defaultProjectid={project}
        inputFormArr={allAddTaskInputs}
      />
    )
  }

  if (loading && !fetchedMyTrackings) {
    return (
      <div className='text-center mt-5 mb-5'>
        <Spinner animation='border' variant='info' />
      </div>
    )
  }

  if (
    allProjects?.length <= 0 &&
    myTrackingList?.length <= 0 &&
    (!fetchedMyTrackings || !loading)
  ) {
    return allProjectsLoading ? (
      cardui !== "List" && (
        <div className='text-center mt-5 mb-5'>
          <Spinner animation='border' variant='info' />
        </div>
      )
    ) : (
      <NoDataFound
        setTaskview={taskViewRender}
        changeInForm={changeInForm}
        setAlertMessage={setAlertMessage}
        setShowAlertBox={setShowAlertBox}
        setChangeInForm={setChangeInForm}
        addtask='add task'
        headcontent='You have not Created any Project.'
        bodycontent='create a task or project for your team to work on.'
        btncontent='task'
        img={norecordsvg}
        refreshAllTracking={refreshAllTracking}
      />
    )
  }

  return (
    <>
      {myTrackingList?.length > 0 || columns ? (
        <>
          {showAlertBox && (
            <Alert
              variant='success'
              className='text-center alert-sucess mt-3 mb-1'
              dismissible
              closeVariant='white'
              onClick={(e) => setShowAlertBox(false)}
            >
              {alertMessage}
            </Alert>
          )}

          <ConfirmationBox
            heading='Are you sure to delete this ?'
            hidePopup={(e) => setDeleteConfirmationShow(false)}
            show={deleteConfirmationShow}
            confirmationResponse={deleteConfimrationHandler}
            setAlertMessage={setAlertMessage}
          />
          {taskview === "AllTask" ? (
            <>
              <CardView>
                <div
                  className='topContent'
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textAlign: "center"
                  }}
                >
                  <div className='view'>
                    <h2
                      onClick={(e) => setCardui("cardui")}
                      className={`Cardbtn ${cardui === "cardui" ? "active" : ""}`}
                    >
                      Card
                    </h2>
                    <span>|</span>
                    <h2
                      onClick={(e) => setCardui("List")}
                      className={`Listbtn ${cardui === "List" ? "active" : ""}`}
                    >
                      List
                    </h2>
                  </div>
                  {cardui === "cardui" ? (
                    createPermission && (
                      <div className='addTask'>
                        <button
                          style={{
                            color: "#FFFFFF",
                            fontSize: "15px",
                            padding: "5px 12px",
                            backgroundColor: "#4EB6EE",
                            border: "none",
                            outline: "none",
                            borderRadius: "5px"
                          }}
                          onClick={(e) => {
                            setTaskview("addnew")
                            setFormType("new")
                            setChangeInForm(false)
                          }}
                        >
                          Add Task
                        </button>
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <Dynamicdata
                  selectedProject={selectedProject}
                  getSelectedOption={getSelectedOption}
                />
              </CardView>
              {cardui === "cardui" ? (
                <div className='mt-3'>
                  <TrackingModalView>
                    <TrackingListHeader
                      allTrackingsList={myTrackingList}
                      searchValue={searchValue}
                      handleSearch={handleSearch}
                      userRole={user.rolename}
                      userRoleType={user.roletype}
                      refreshAllTracking={refreshAllTracking}
                      setLoading={(val) => setLoading(val)}
                      loading={loading}
                      trackingFilters={trackingFilters}
                      setTrackingFilters={setTrackingFilters}
                      trackingData={selected_menu === "my_tasks" ? myTrackingList : []}
                      cardView='Card'
                      setShowFormView={(val) => setShowFormView(val)}
                      setTaskview={setTaskview}
                      setFormType={(val) => setFormType(val)}
                      setChangeInForm={setChangeInForm}
                      showFilter={showFilter}
                      setAllCheckTable={setAllCheckTable}
                      backlogsPermission={backlogsPermission}
                    />
                  </TrackingModalView>{" "}
                </div>
              ) : (
                <></>
              )}

              {allProjectsLoading ? (
                cardui !== "List" && (
                  <div className='text-center mt-5 mb-5'>
                    <Spinner animation='border' variant='info' />
                  </div>
                )
              ) : (
                <></>
              )}

              {cardui === "cardui" ? (
                <>
                  <Cards
                    columns={columns}
                    setColumns={setColumns}
                    editSwitch={editSwitch}
                    isLoading={isLoading}
                    loading={loading}
                    firstData={project}
                    Work_Flow={Work_Flow}
                    showWorkFlow={showWorkFlow}
                    setTaskview={setTaskview}
                    setShowAlertBox={setShowAlertBox}
                    setAlertMessage={setAlertMessage}
                    key='cards'
                    assignedToMe={true}
                    trackingItemData={trackingItemData}
                    setTrackingItemData={setTrackingItemData}
                    fetchFileData={fetchFileData}
                    selected_menu={selected_menu}
                    setDeleteConfirmationShow={setDeleteConfirmationShow}
                    img={norecordsvg}
                    deletePermission={deletePermission}
                    createPermission={createPermission}
                  />

                  <ConfirmationBox
                    heading='Are you sure to delete this ?'
                    hidePopup={(e) => setDeleteConfirmationShow(false)}
                    show={deleteConfirmationShow}
                    confirmationResponse={deleteConfimrationHandler}
                    setAlertMessage={setAlertMessage}
                  />
                </>
              ) : (
                <>
                  <TrackingModalView>
                    <div className='trackingPage mt-1'>
                      <Tables
                        setDeleteConfirmationShow={setDeleteConfirmationShow}
                        selected_menu={selected_menu}
                        setTaskview={setTaskview}
                        setTrackingItemData={setTrackingItemData}
                        setShowEditTask={setShowEditTask}
                        deletePermission={deletePermission}
                        handleMoveCheckboxTable={handleMoveCheckboxTable}
                        allcheckTable={allcheckTable}
                        setAllCheckTable={setAllCheckTable}
                        columns={
                          <TrackingListColumns
                            handleSearch={handleSearch}
                            trackingSortings={trackingSortings}
                            setTrackingSorting={setTrackingSorting}
                            handleTasksHeaderCheckbox={handleTasksHeaderCheckbox}
                            setAllCheckTable={setAllCheckTable}
                            selectAllTasks={selectAllTasks}
                          />
                        }
                        startTrackingId={
                          searchValue
                            ? "none"
                            : selected_menu === "my_tasks"
                            ? startMyTrackingId
                            : "none"
                        }
                        data={selected_menu === "my_tasks" ? myTrackingData : searchValue}
                        showInfiniteScroll={true}
                        setHistory={setHistory}
                        header={
                          <TrackingListHeader
                          
                            setAlertMessage={setAlertMessage}
                            setShowAlertBox={setShowAlertBox}
                            allcheckTable={allcheckTable}
                            myTrackingList={myTrackingList}
                            searchValue={searchValue}
                            handleSearch={handleSearch}
                            userRole={user.rolename}
                            userRoleType={user.roletype}
                            allMembers={allMembers}
                            allProjects={allProjects}
                            createPermission={createPermission}
                            refreshAllTracking={refreshAllTracking}
                            setLoading={(val) => setLoading(val)}
                            loading={loading}
                            trackingFilters={trackingFilters}
                            setTrackingFilters={setTrackingFilters}
                            data={selected_menu === "my_tasks" ? myTrackingData : searchValue}
                            trackingData={
                              selected_menu === "my_tasks" ? myTrackingData : searchValue
                            }
                            menu={"my_tasks"}
                            setShowFormView={(val) => setShowFormView(val)}
                            setTaskview={setTaskview}
                            setFormType={(val) => setFormType(val)}
                            setChangeInForm={setChangeInForm}
                            showFilter={showFilter}
                            backlogsPermission={backlogsPermission}
                          />
                        }
                        notfound={"No Trackings Found"}
                        loading={
                          selected_menu === "all_tasks" ? !fetchedAllTrackings : !fetchedMyTrackings
                        }
                      />
                    </div>
                  </TrackingModalView>

                  <ConfirmationBox
                    heading='Are you sure to delete this ?'
                    hidePopup={(e) => setDeleteConfirmationShow(false)}
                    show={deleteConfirmationShow}
                    confirmationResponse={deleteConfimrationHandler}
                  />
                </>
              )}
            </>
          ) : taskview === "taskview" ? (
            <>
              {trackingItemData.title ? (
                <>
                  <TrackingView
                    fileLoading={fileLoading}
                    setTaskview={setTaskview}
                    show={showView}
                    setTaskFile={setTaskFile}
                    setTaskFileType={setTaskFileType}
                    taskfileType={taskfileType}
                    item={trackingItemData}
                    taskfileList={taskfileList}
                    setTaskFileList={setTaskFileList}
                    taskfile={taskfile}
                    refreshfiles={refreshfiles}
                    setShowView={(value) => setShowView(value)}
                    allTaskTypes={allTaskTypes}
                    allStatus={allFlowStatus}
                    data={viewTaskData}
                    setViewTaskData={setViewTaskData}
                    setTrackingItemData={setTrackingItemData}
                    showEditTask={showEditTask}
                    setShowEditTask={setShowEditTask}
                    updatedData={updatedData}
                    setUpdatedData={setUpdatedData}
                    history={taskHistory}
                    setHistory={setHistory}
                    setShowClone={setShowClone}
                    selected_menu={selected_menu}
                    setDeleteConfirmationShow={setDeleteConfirmationShow}
                    fetchFileData={fetchFileData}
                    deletePermission={deletePermission}
                    editPermission={editPermission}
                    allProjectsData={allProjects}
                    allMemberes={allMembers}
                    backlogsPermission={backlogsPermission}
                    menu='mytask'
                  />
                  {clonetaskData.title !== undefined ? (
                    <Clone
                      setTaskview={setTaskview}
                      showClone={showClone}
                      setShowClone={setShowClone}
                      item={trackingItemData}
                      allTrackingsList={allTrackingsList}
                      setShowAlertBox={setShowAlertBox}
                      setAlertMessage={setAlertMessage}
                      clonetaskData={clonetaskData}
                      setCloneTask={setCloneTask}
                      backTo={"AllTask"}
                      viewTaskData={viewTaskData}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          ) : null}
        </>
      ) : (
        <>
          <CardView>
            <div
              className='topContent'
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textAlign: "center"
              }}
            >
              <div className='view'>
                <h2
                  onClick={(e) => setCardui("cardui")}
                  className={`Cardbtn ${cardui === "cardui" ? "active" : ""}`}
                >
                  Card
                </h2>
                <span>|</span>
                <h2
                  onClick={(e) => setCardui("List")}
                  className={`Listbtn ${cardui === "List" ? "active" : ""}`}
                >
                  List
                </h2>
              </div>
              {cardui === "cardui" ? (
                <div className='addTask'>
                  <button
                    style={{
                      color: "#FFFFFF",
                      fontSize: "15px",
                      padding: "5px 12px",
                      backgroundColor: "#4EB6EE",
                      border: "none",
                      outline: "none",
                      borderRadius: "5px"
                    }}
                    onClick={(e) => {
                      setTaskview("addnew")
                      setFormType("new")
                      setChangeInForm(false)
                    }}
                  >
                    Add Task
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
            <Dynamicdata selectedProject={selectedProject} getSelectedOption={getSelectedOption} />
          </CardView>
        </>
      )}
    </>
  )
}