import React, { useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Searchbox from "../../../../../Reuseable/Searchbox";
import { useSelector } from "react-redux";

export const TimeloggerListColumns = ({
  handleSearch,
  trackingSortings,
  setTrackingSorting,
  handleCheckboxChange,
  handleHeaderCheckboxChange,
  selectedRows,
  selectAll
}) => {
  
  const reportWeeklyHeaderNames = useSelector((state) => state.Tracking.reportWeeklyHeaderNames);
  const applyTrackingNumberSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "trackingid",
        projectNameSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, trackingNumberSorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, trackingNumberSorting: "down" };
      });
  };

  const applyProjectNumberSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "projectname",
        trackingNumberSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, projectNameSorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, projectNameSorting: "down" };
      });
  };

  const applyReportedBySorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "reportedby",
        trackingNumberSorting: "",
        projectNameSorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, reportedBySorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, reportedBySorting: "down" };
      });
  };

  const applyPrioritySorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "reporteddate",
        trackingNumberSorting: "",
        projectNameSorting: "",
        reportedBySorting: "",
        assignedToSorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, prioritySorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, prioritySorting: "down" };
      });
  };

  const applyAssignToSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "priority",
        trackingNumberSorting: "",
        projectNameSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, assignedToSorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, assignedToSorting: "down" };
      });
  };

  let WeeklycolumnValues = [];

  for (let i = 0; i < reportWeeklyHeaderNames.length; i++) {
    WeeklycolumnValues.push(reportWeeklyHeaderNames[i].column1)
    WeeklycolumnValues.push(reportWeeklyHeaderNames[i].column2)
    WeeklycolumnValues.push(reportWeeklyHeaderNames[i].column3)
    WeeklycolumnValues.push(reportWeeklyHeaderNames[i].column4)
    WeeklycolumnValues.push(reportWeeklyHeaderNames[i].column5)
  }

  console.log("reportWeeklyHeaderNames", reportWeeklyHeaderNames)

  return (
    <>
      {/* {WeeklycolumnValues.map((item, index) => {
        return (
          <th key={index} className="text-center">
            {item}
          </th>
        )
      })} */}
        <th className="text-center">
        Date
        </th>
        <th className="text-center">
        Estimated time
        </th>
        <th className="text-center" >
        Logged time
        </th>
        <th className="text-center" >
        Task number
        </th>
        <th className="text-center" style={{ width: "17.5%" }}>
        Status
        </th>
       
    </>
  );
};