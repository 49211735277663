import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Plan from "./Plan";
import { pricingPlans } from "./Pricings";

export const PricingPlansWrap = styled.div`
  color: #ffffff !important;
  padding-top: 10px;
  text-align: start;

  .card-body {
    background-color: ${(props) => (props.darkmode ? "inherit" : "#ffffff")};
  }
  .current_plan_label {
    color: #5fccf2;
    padding: 0px;
    position: absolute;
    top: 0;
    right: 0;
    translate: -5px 5px;
  }
  .price {
    /* padding-top: 10px; */
    color: #5fccf2 !important;
    font-size: 3rem;
    font-weight: 600;
    .dollar {
      color: white;
      font-size: 1rem;
      font-weight: 100;
    }
  }
  .title {
    color: ${(props) => (props.darkmode ? "#ffffff" : "#000000")};
  }
  .tier {
    font-size: 28px;
    padding-left: 6px;
    color: #5fccf2 !important;
  }
  .upgrade {
    color: #5fccf2;
    &:hover {
      color: #ffffff;
    }
  }
  .trekr-plans {
    font-weight: 600;
    font-size: 2rem;
    color: #5fccf2;
    padding-bottom: 10px;
  }
  ul {
    padding-left: 20px;
    color: ${(props) => (props.darkmode ? "#ffffff" : "#000000")};
  }
  ul > li {
    list-style: disc;
  }

  @media screen and (min-width: 767px) {
    /* padding-top: 50px; */
  }
`;
const PricingPlans = ({
  pricingRef,
  updateFields,
  isChecked,
  setIsChecked,
  plans,
  setPlans,
  mobileScreen,
  setOpen,
}) => {
  const handlePlans = (id) => {
    setIsChecked(true);
    setOpen(false);
    const revisedPlans = pricingPlans.map((plan) => {
      if (plan.id === id) {
        if (plan.checked) return plan;
        return { ...plan, checked: true };
      }
      return plan;
    });

    setPlans(revisedPlans);

    revisedPlans.forEach((plan) => {
      if (plan.checked) {
        updateFields({ plan: plan.tier });
      }
    });
  };

  return (
    <PricingPlansWrap ref={pricingRef}>
      {/* <div className='trekr-plans'>
                Trekr pricing plans
            </div> */}

      <Row className="mb-4">
        {plans.map((plan) => {
          return (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={3}
              key={plan.id}
              className={`d-flex justify-content-center pb-2 pb-sm-4`}
            >
              <Plan
                tier={plan.tier}
                price={plan.price}
                title={plan.title}
                duration={plan.duration}
                benefits={plan.benefits}
                checked={plan.checked}
                handlePlans={handlePlans}
                planId={plan.id}
                mobileScreen={mobileScreen}
              />
            </Col>
          );
        })}
      </Row>
    </PricingPlansWrap>
  );
};

export default React.memo(PricingPlans);
