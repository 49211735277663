import { combineReducers } from "redux"
import { LayoutReducer, intialState as initialLayoutState } from "./Layout/reducers"
import { AuthReducer, intialState as initialAuthState } from "./Auth/reducers"
import { TrackingReducer, intialState as initialTrackingState } from "./Tracking/reducers"

const rootReducers = combineReducers({
  Layout: LayoutReducer,
  Auth: AuthReducer,
  Tracking: TrackingReducer
})

const rootStateReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = {
      Layout: initialLayoutState,
      Auth: initialAuthState,
      Tracking: initialTrackingState
    }
  }
  return rootReducers(state, action)
}

export default rootStateReducer
