import viewIcon from "../assets/images/view.svg"
import editIcon from "../assets/images/edit.svg"
import trashIcon from "../assets/images/icons/bluedelete12.svg"

export const Workflowtableheader = [
  "Workflow ID",
  "Workflow Name",
  "Project",
  "Created by",
  "Created on",
  "Actions"
]

export const defaultworkflowbutton = [
  {
    id: 1,
    icon: viewIcon
  }
]

export const workflowbutton = [
  {
    id: 1,
    icon: viewIcon
  },
  {
    id: 2,
    icon: editIcon
  },
  {
    id: 3,
    icon: trashIcon
  }
]

export const Documenttableheader = [
  "Document ID",
  "File name",
  "File type",
  "Created on",
  "Updated on",
  "Created by",
  "Actions"
]

export const Documentbutton = [
  {
    id: 1,
    icon: editIcon
  },
  {
    id: 2,
    icon: trashIcon
  }
]
