import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col, Button, Spinner } from "react-bootstrap"
import Searchbox from "../../../../../Reuseable/Searchbox"
import { setFilters } from "../../../../../../redux/Tracking/actionCreator"
import RefreshIcon from "@mui/icons-material/Refresh"
import file_download_Icon from "../../../../../../assets/images/file_download_Icon.svg"
import print_icon from "../../../../../../assets/images/print_icon.svg"
import filterDateIcon from "../../../../../../assets/images/filterDateIcon.svg"
import { DatePicker } from "antd"
import moment from "moment"

export const WeeklyReportListheaders = ({
  setProjectView,
  searchValue,
  handleSearch,
  handleDueSearch,
  userRole,
  userRoleType,
  allMembers,
  cardView,
  refreshAllTracking,
  loading,
  allProjects,
  allArchiveTableData,
  setTrackingFilters,
  createPermission,
  selectedRows,
  selectAll,
  setSearchFromDate,
  searchfromDate,
  searchDueDate,
  handleFromDate,
}) => {
  const item = allArchiveTableData.map(ele => ele)

  const dispatch = useDispatch()
  let allStatus = useSelector(state => state.Tracking.allFlowStatus)
  let filters = useSelector(state => state.Tracking.filters)
  const trackingClientId = useSelector(state => state.Auth.user.trackingclientid)

  if (allStatus.length > 0) {
    let result = []
    allStatus?.forEach(element => {
      result.push({ label: element.label, value: element.id })
    })
    allStatus = result
  }

  if (allArchiveTableData.length > 0) {
    let result = []
    allArchiveTableData?.forEach(element => {
      result.push({ label: element.label, value: element.id })
    })
    allArchiveTableData = result
  }

  if (allMembers.length > 0) {
    let result = []
    allMembers?.forEach(element => {
      result.push({ label: element.personname, value: element.trackinguserid })
    })
    allMembers = result
  }

  function removeHTML(str) {
    var tmp = document.createElement("DIV")
    tmp.innerHTML = str
    return tmp.textContent || tmp.innerText || ""
  }
  const downloadList = () => {
    var A = [["Name", "Task number", "From date", "To date", "Day"]]
    var csvRows = []

    item.forEach(element => {
      element.title = element.title.replace(/,/g, "")
      A.push([
        element.assignedto,
        element.trackingid,
        moment(element.reporteddate).format("DD/MM/YYYY h:mm:ss a"),
        moment(element.reporteddate).format("DD/MM/YYYY h:mm:ss a"),
        moment(element.reporteddate).format("DD/MM/YYYY h:mm:ss a"),
      ])
    })

    for (var i = 0, l = A.length; i < l; ++i) {
      csvRows.push(A[i].join(","))
    }

    var csvString = csvRows.join("\n")
    var a = document.createElement("a")
    a.href = "data:attachment/csv," + encodeURIComponent(csvString)
    a.target = "_blank"
    a.download = `${trackingClientId}_task_list.csv`

    document.body.appendChild(a)
    a.click()
  }

  const PrintList = () => {
    var tableRows = []
    item.forEach(element => {
      tableRows.push(`
            <tr>
                <td>${element.assignedto || "--"}</td>
                <td>${element.trackingid === "" ? "--" : element.trackingid}</td>
                <td>${moment(element.reporteddate).format("DD/MM/YYYY") || "--"}</td>
                <td>${moment(element.reporteddate).format("DD/MM/YYYY") || "--"}</td>
                <td>${moment(element.reporteddate).format("DD/MM/YYYY") || "--"}</td>             
            </tr>
            `)
    })

    var tableHTML = `
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Task number</th>
                        <th>From date</th>
                        <th>To date</th>                        
                        <th>Day</th>                        
                    </tr>
                </thead>
                <tbody>
                    ${tableRows.join("")}
                </tbody>
            </table>
        `

    var printWindow = window.open("", "_blank")
    printWindow.document.open()
    printWindow.document.write("<html><body>")
    printWindow.document.write(tableHTML)
    printWindow.document.write("</body></html>")
    printWindow.document.close()
    printWindow.print()
  }

  return (
    <>
      {allArchiveTableData.length > 0 && (
        <Row className='pb-2'>
          <div className='report_task_header'>
            <div className='report_task_date_filter'>
              <Col className='report_date_filters'>
                {" "}
                <DatePicker
                  suffixIcon={<img src={filterDateIcon} alt='Calendar' />}
                  onChange={handleFromDate}
                  selected={searchfromDate}
                  name={searchfromDate}
                  placeholder='From date'
                  className='form-control search-control border-start-0 search_date_filter'
                />{" "}
              </Col>
              <Col className='report_date_filters'>
                {" "}
                <DatePicker
                  suffixIcon={<img src={filterDateIcon} alt='Calendar' />}
                  onChange={handleDueSearch}
                  selected={searchDueDate}
                  name={searchDueDate}
                  placeholder='To date'
                  className='form-control search-control border-start-0 search_date_filter'
                />{" "}
              </Col>
              <Col>
                {" "}
                <Searchbox
                  handleSearch={handleSearch}
                  value={searchValue}
                  placeholder={"Resource name"}
                  className='form-control search-control border-start-0 search_name_filter'
                  byName='searchByName'
                  filterNameIcon={filterDateIcon}
                />{" "}
              </Col>
            </div>
            <div className='report_task_action_btns'>
              <Col>
                <Button
                  className={
                    allArchiveTableData.length > 0
                      ? "me-1 download_csv_file_btn"
                      : "me-1 download_csv_file_btn disabled"
                  }
                  onClick={e => {
                    downloadList()
                  }}
                >
                  <img src={file_download_Icon} alt='file_download_Icon' /> Download CSV
                </Button>
              </Col>
              <Col>
                <Button
                  className={
                    allArchiveTableData.length > 0
                      ? "me-1 download_print_btn"
                      : "me-1 download_print_btn disabled"
                  }
                  onClick={e => {
                    PrintList()
                  }}
                >
                  <img src={print_icon} alt='print_icon' /> Print
                </Button>
              </Col>
            </div>
          </div>
        </Row>
      )}
    </>
  )
}
