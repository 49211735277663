import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Button, Card, Form, Table, Modal } from "react-bootstrap"
import BarLoader from "react-spinners/ClipLoader"
import CloseIcon from "@mui/icons-material/Close"
import { resetTracking } from "../../../redux/Tracking/actionCreator"
import { userLogout } from "../../../redux/Auth/actionCreator"
import DeleteAccountWrap from "./style"
import { useDelete } from "../../../Hooks/useDelete"
import { key } from "../../../data/queryKeys"

const CloseAccount = () => {
  const user = useSelector((state) => state.Auth.user)

  let navigate = useNavigate()
  const dispatch = useDispatch()

  const [validated, setValidated] = useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity() === true) {
      handleShow()
    }
    setValidated(true)
  }

  const onCloseAccountSuccess = (res) => {
    if (res.status) {
      let result = {
        isLoggedIn: false,
        authToken: "",
        user: "",
        menuList: []
      }
      dispatch(userLogout(result))
      dispatch(resetTracking())
      navigate("/login")
    }
  }

  const { isLoading: loading, mutate: closeAccount } = useDelete(
    key.close_account,
    `/trackingadmin/clients`,
    onCloseAccountSuccess,
    () => {}
  )

  return (
    <DeleteAccountWrap>
      <Card className='card-bd p-4'>
        <Card.Body>
          <div>
            <h5>Account Settings</h5>
            <Table borderless>
              <tbody className="">
                <tr>
                  <td>Client ID</td>
                  <td>{user.trackingclientid}</td>
                </tr>
                <tr>
                  <td>User ID</td>
                  <td>{user.trackinguserid}</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{user.personname}</td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div>
            <h5>Close Account</h5>
            <div className='p-2'>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Check
                  required
                  label='I understand that by clicking this checkbox, I am closing my TracTask account, The closure of my TracTask account serves as notice to TracTask that I wish to terminate the TracTask customer agreement or any other agreement with TracTask that governs my TracTask account. solety with respect to that account.'
                  feedback='You must agree before submitting.'
                  feedbackType='invalid'
                />
                <p>
                  Monthly usage of certain TracTask sevices is calculated and billed at the
                  beginning of the following month. If I have used these type of services this
                  month. then at the beginning of next month I will receive a bill for usage that
                  occurred prior to termination of my account. In addition , If I have any active
                  subscriptions, then even after my account is closed I may continue to be billed
                  for subscription untill the subscription expires or sold in accordance with
                  theterms governing the subscription
                </p>
                <p>
                  I acknowledge that i may reopen my TracTask account only within 60 days of my
                  account (the "Post-Closure period"). If I reopen my account during the
                  Post-Closure Period, I may be charged for any TracTask services that were not
                  terminated befor I closed my account. If I reopen my TracTask account. I agree
                  that the same terms will govern my access to and use of TracTask services through
                  my reopened TracTask account
                </p>
                <p>
                  If I choose not to reopen my account after the Post-closure period, any content
                  remaining in my TracTask account will be deleted, For more information, Please see
                  then <a>TracTask Website account Closure page</a>{" "}
                </p>
                <Form.Check
                  required
                  label='I understand not to reopen after the Post-Closure period I will not no longer be able to reoprn my closed account.'
                  feedback='You must agree before submitting.'
                  feedbackType='invalid'
                />
                <Form.Check
                  required
                  label='I understand that after the Post-Closure I will no longer be able to access the Billing console to download past bills and tax invoices'
                  feedback='You must agree before submitting.'
                  feedbackType='invalid'
                />
                <Form.Check
                  required
                  label='I understand that after the Post-Closure period I will  not be able to create a new TracTask account with the email address currently associated withthis account.'
                  feedback='You must agree before submitting.'
                  feedbackType='invalid'
                />
                <Button className='close-btn my-4' type='submit'>
                  Close account
                </Button>
              </Form>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <div className='p-2' style={{ color: "#4EB6EE" }}>
          <CloseIcon
            className=' mt-1'
            onClick={handleClose}
            style={{ color: "#4EB6EE", cursor: "pointer", float: "right" }}
          />
          <div className='p-2 py-4'>
            <center>
              <p className='mb-4'>Are you sure you want to close your account?</p>
              <Button
                className='deletebtn mx-3 my-2'
                style={{
                  backgroundColor: "#4EB6EE",
                  border: "none",
                  width: "100px",
                  height: "40px"
                }}
                onClick={handleClose}
              >
                No
              </Button>

              <Button
                className='deletebtn mx-3 my-2'
                onClick={() => closeAccount(user.trackingclientid)}
                style={{
                  background: loading ? "#FFFFFF" : "#4EB6EE",
                  color: loading ? "#808080" : "#FFFFFF",
                  border: loading ? "2px solid #FFFFFF" : "2px solid #4EB6EE",
                  width: "100px",
                  height: "40px"
                }}
              >
                {loading ? (
                  <BarLoader
                    color='#808080'
                    className='bar-loader'
                    size={14}
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  <></>
                )}
                Yes{""}
              </Button>
            </center>
          </div>
        </div>
      </Modal>
    </DeleteAccountWrap>
  )
}

export default CloseAccount
