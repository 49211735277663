import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { api } from "../../../../Services/api";
import { client } from "../../../..";
import { key } from "../../../../data/queryKeys";

const Payment = ({
  clientId,
  loading,
  setLoading,
  setShowSubscribe,
  setStep,
  currentStep,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const subscriptionActivated = useRef(false);

  const stripeResponseToAPI = async (sResponse) => {
    try {
      const url = `/trekrpayment/create`;

      const data = {
        stripeResponse: sResponse,
        clientId: clientId,
      };
      const response = await api.patch(url, data);

      client.invalidateQueries({
        queryKey: [key.get_trial_period],
      });

      if (response) {
        setShowSubscribe(false);
      }
      if (response.data.subscriptionStatus === "active") {
        subscriptionActivated.current = true;
      }
    } catch (error) {
      setLoading(false);
      console.error("StripResponseToAPI error", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    try {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/user`,
        },
        redirect: "if_required",
      });
      stripeResponseToAPI(result);
      setShowSubscribe(false);
    } catch (error) {
      stripeResponseToAPI(error);
    }
  };

  return subscriptionActivated.current ? (
    <h2>Subscription Activated!</h2>
  ) : (
    <form onSubmit={handleSubmit}>
      <PaymentElement />

      <div style={{ textAlign: "center", width: "100%", marginTop: "10px" }}>
        <Button
          variant="info"
          disabled={loading}
          onClick={() => {
            setStep(1);
            currentStep(1);
          }}
          className="px-4 me-2"
        >
          Back
        </Button>
        <Button
          variant="info"
          disable={!stripe || !elements}
          type="submit"
          className="mb-2 mt-2 px-4 ms-2"
        >
          Pay Now
        </Button>
      </div>
    </form>
  );
};

export default Payment;
