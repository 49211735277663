import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Forms } from "../../../../Reuseable/Forms"
import { Validators } from "../../../../../Utilities/validator"
import { api } from "../../../../../Services/api"
import { setMembers, setShowAlert } from "../../../../../redux/Tracking/actionCreator"
import NewProjectFormWrap from "./style.js"
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded"
import { key } from "../../../../../data/queryKeys"
import { useFetch } from "../../../../../Hooks/useFetch"
import { client } from "../../../../.."

export const AddNewProject = ({
  setProjectView,
  setShowAlertBox,
  setAlertMessage,
  onTaskView,
  taskViewPanel
}) => {
  /******************************* react functions   *******************/
  const dispatch = useDispatch()
  /*************************** redux var intiaalize *******************/
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)

  const { refetch: fetchProjects } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )

  /**************************for all members*********************************/
  const { data: allProjectMembers, refetch: fetchMembers } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMembers = allProjectMembers?.data ?? []
  /****************************all workflows*******************************/
  const { data: allProjectWorkflowRes } = useFetch(
    key.get_all_workflow,
    `/trekrworkflow`,
    () => {},
    () => {},
    false,
    true
  )
  let allWorkFlow = allProjectWorkflowRes?.data?.workflowList ?? []
  /***********************************************************************************/
  const user = useSelector((state) => state.Auth.user)
  const authToken = useSelector((state) => state.Auth.authToken)
  const menus = useSelector((state) => state.Auth.menuList)

  /*************************** react state  intiaalize *******************/
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [changeInForm, setChangeInForm] = useState(false)

  const [fileNameList, setFileNameLIst] = useState([])
  const [fileArr, setFileArr] = useState([])
  const [fileSize, setFileSize] = useState("")

  /******* variables declaration *********/

  if (allMembers.length > 0) {
    let result = []
    allMembers.forEach((element) => {
      if (element.userId !== user.trackinguserid) {
        result.push({
          label: element.personname,
          id: element.userId
        })
      }
    })
    allMembers = result
  }

  const userObj = {
    label: user.personname,
    id: user.trackinguserid
  }

  let objectValues = Object.values(allWorkFlow)

  if (objectValues.length > 0) {
    let result = []
    objectValues.forEach((element) => {
      if (element[0].projectId === "Not Assigned" || element[0].project_name === "Not Assigned") {
        result.push({
          label: element[0].workFlowName,
          id: element[0].workFlowId
        })
      }
    })
    allWorkFlow = result
  }

  let priorityOptions = [
    { label: "New", id: "new" },
    { label: "In Progress", id: "in progress" }
    // { label: "Completed", id: "completed" },
    // { label: "Closed", id: "closed" },
  ]

  const addNewMember = [
    {
      label: "+ Add New teammate",
      id: "Add New User",
      value: "+ Add New teammate"
    }
  ]
  const isAbleToAddNewMember =
    menus.find((menu) => menu.name === "Users")?.access ?? false ? true : false

  const addNewWorkflow = [{ label: "+ Add New WorkFlow", id: "Add New WorkFlow" }]

  let allWorkFlows = [
    ...addNewWorkflow,
    ...allWorkFlow,
    { label: "Default Workflow", id: "default" }
  ]

  // let allWorkFlows = [...addNewWorkflow, ...allWorkFlow]

  allMembers = isAbleToAddNewMember ? [...addNewMember, ...allMembers] : allMembers

  let formArr = [
    {
      label: "Project Title",
      name: "title",
      type: "text",
      halfWidth: true,
      placeholder: "Enter project title",
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      value: "",
      maxLength: 100,
      show: true
    },
    {
      label: "Would you like to Set This Project as Priority",
      name: "isPriority",
      type: "priority-radio",
      defaultValue: false,
      placeholder: "",
      halfWidth: true,
      validators: [],
      required: false,
      mandatory: true,
      value: false,
      error: false,
      errormsg: "",
      show: true
    },
    {
      label: "Add teammates",
      name: "teammates",
      type: "multiselect",
      halfWidth: true,
      placeholder: "Enter e-mail",
      mandatory: true,
      // validators: [
      //   { check: Validators.required, message: "This Field is required" },
      // ],
      error: false,
      errormsg: "",
      options: allMembers,
      multiSelectOnchange: (e) => console.log(e, "multiselect"),
      value: [],
      show: true
    },
    {
      label: "Project type",
      name: "priority",
      type: "selectbox",
      placeholder: "Select",
      halfWidth: true,
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      options: priorityOptions,
      value: "",
      show: true
    },
    {
      label: "Estimated Start date",
      name: "start",
      type: "startdate",
      halfWidth: true,
      placeholder: "Select date",
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      value: "",
      show: true
    },
    {
      label: "Estimated Due date",
      name: "Due",
      type: "enddate",
      halfWidth: true,
      placeholder: "Select date",
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      value: "",
      show: true
    },
    {
      label: "Budget",
      name: "Budget",
      type: "budget",
      halfWidth: true,
      placeholder: "Enter Budget",
      validators: [
        // { check: Validators.required, message: "This Field is required" },
      ],
      mandatory: true,
      error: false,
      errormsg: "",
      value: "",
      maxLength: 7,
      show: true
    },
    {
      label: "Set workflow",
      name: "workflow",
      type: "selectbox",
      halfWidth: true,
      error: false,
      errormsg: "",
      validators: [{ check: Validators.required, message: "This Field is required" }],
      options: allWorkFlows,
      value: "",
      // value: { label: 'Default Workflow', id: 'default' },
      defaultValue: { label: 'Default Workflow', id: 'default' },
      show: true
    },
    {
      label: "Percentage",
      name: "percent",
      type: "Percentage",
      placeholder: "Enter percentage",
      maxLength: 3,
      halfWidth: true,
      validators: [
        { check: Validators.percent, message: "Please enter below 100" },
        { check: Validators.required, message: "This Field is required" }
      ],
      error: false,
      errormsg: "",
      value: "",
      show: true
    },
    // {
    //   label: "Upload file",
    //   name: "uploadfile",
    //   type: "uploadfile",
    //   halfWidth: false,
    //   placeholder: "",
    //   mandatory: true,
    //   validators: [],
    //   error: false,
    //   errormsg: "",
    //   value: "",
    //   show: true,
    // },
    {
      label: "Description",
      name: "description",
      type: "textarea",
      halfWidth: false,
      placeholder: "Type here...",
      validators: [
        {
          check: Validators.required,
          message: "Description Field is required"
        }
      ],
      error: false,
      errormsg: "",
      rows: 4,
      value: "",
      show: true
    }
  ]

  /******************************* Functions *******************************/

  const getAllMembers = async () => {
    setLoading(true)
    await api
      .get("/trackingusers/memberlist/" + trackingClientId, {
        headers: {
          authorizationtoken: `${authToken}`
        }
      })
      .then(function (response) {
        if (response.status === 200) {
          let members = response.data.data
          members = members.sort((item1, item2) => {
            if (item1.personname < item2.personname) return -1
            else return 1
          })
          dispatch(setMembers(members))
          setLoading(false)
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            console.log(error.response.data)
          }
        } else if (error.request) {
          console.log(error.request)
        } else {
          console.log("Error", error.message)
        }
      })
  }

  const SubmitForm = (form, valid) => {
    if (valid) {
      setLoading(true)
      let Obj = {
        projectname: form.title,
        isPriority: form.isPriority,
        startdate: form.start,
        duedate: form.Due,
        createdby: user.personname,
        priority: form.priority.id,
        budget: form.Budget,
        percentage: form.percent,
        workflow: form?.workflow?.id ?? "default",
        teammates: [...form.teammates, userObj],
        projectdescription: form.description,
        fileurls: fileNameList,
        filesize: fileSize
      }

      api
        .post("/trackingprojects/" + trackingClientId, Obj, {
          headers: {
            Authorizationtoken: ` ${authToken}`
          }
        })
        .then(function (response) {
          console.log("response", response)
          if (response.status === 200) {
            setShowAlertBox(true)
            setAlertMessage(
              "(" +
                trackingClientId +
                "-" +
                response.data.data.trackingprojectid +
                ") New Project has been created successfully"
            )
            dispatch(setShowAlert(true))
            const trackingprojectid = response.data.data.trackingprojectid
            // uploadImage(trackingprojectid)
            setLoading(false)
            setSubmitted(true)
            fetchProjects()
            fetchMembers()
            client.invalidateQueries({
              queryKey: [key.get_all_workflow]
            })
            onTaskView ? setProjectView() : setProjectView(taskViewPanel)
          }
        })
    }
  }

  return (
    <NewProjectFormWrap onTaskView={onTaskView}>
      {!onTaskView && (
        <h4 className='d-flex justify-content-start align-items-center mt-1 mb-2'>
          <span className='add-project-tittle'>
            <ArrowCircleLeftRoundedIcon
              style={{ color: "#4EB6EE", cursor: "pointer", fontSize: "30px" }}
              onClick={() => {
                setProjectView(taskViewPanel)
              }}
            />
          </span>
          Add new project
        </h4>
      )}

      <Forms
        title={""}
        classes='add-project-container'
        formArr={formArr}
        submitBtn={"Create"}
        allWorkFlows={allWorkFlows}
        allMembers={allMembers}
        dueDtateStyle={{
          float: "right",
          width: "50%"
        }}
        onSubmit={SubmitForm}
        resetBtn={true}
        showcheckbox={true}
        loading={loading}
        submitted={submitted}
        changeInForm={changeInForm}
        setChangeInForm={setChangeInForm}
        FileName={setFileNameLIst}
        FileArr={setFileArr}
        getAllMembers={getAllMembers}
        setFileSize={setFileSize}
      />
    </NewProjectFormWrap>
  )
}
