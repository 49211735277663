import React from 'react'
import { Navigate } from 'react-router-dom'
import Main from '../components/Pages/Layout/main'

const ProtectedRouteAuth = ({ isValidLogin }) => {
    return (
        isValidLogin ? <Main /> : <Navigate to="/login" />
    )
}

export default ProtectedRouteAuth