import Styled from 'styled-components';

const DashboardView = Styled.div`
.member-list{
      height: auto;
}
.project-list {
      height: auto;
}
.progress-default{
      border-radius:10px;
   .progress-bar { 
      background-color: #4EB6EE;
    }
    .progress{
      border-radius:10px;
      margin-top:0.5%;
}}
.progress-off-track{
    border-radius:10px;
    .progress-bar { 
        background-color: #A80809; 
    }
    .progress{
      /* border-radius:10px; */
      margin-top:0.5%;
}}
.progress-at-risk{
      border-radius:10px;
    .progress-bar { 
      background-color: #4EB6EE;
    }
    .progress{
      border-radius:10px;
      margin-top:0.5%;
}}
.progress-good{
    border-radius:10px;
    .progress-bar { 
      background-color: #4EB6EE;
    }
    .progress{
      border-radius:10px;
      margin-top:0.5%;
}}

.circle-progressDiv{
    height: 235px;
   border: 1px solid #3E3F3E;
   margin-bottom: 2%;
}
@media (min-width:1440px){
    .circle-progressDiv{
        width: 30.35%;
        height: 220px;
        margin-left: 1.9%
    }
    .home-head{
        padding-left: 1.95%;
    }
    .home-head1{
        padding-left: 1.3%;
    }
    .pro-gressdiv{
        margin-left: 2%;
    }
    .view-card{
        width:97%;
        margin-left:1%;
    }
    .view-card2{
        width:99%;
        margin-left:0.5%;
    }    
}

@media (min-width:320px) and (max-width:374px){
    .data-row{
        margin-left: 10%;
    }
    .circle-progressDiv{
    height: 246px;
    }
    .percent-column{
        padding-left: 2%;
    }
}
@media (min-width:375px) and (max-width:413px){
    .data-row{
       margin-left:-32px;
    }
    .circle-progressDiv{
    height: 246px;
    }
}
.task-box{
        font-size: 15px;
}
@media (min-width:425px) and (max-width:767px){
    .circle-progressDiv{
    height: 250px;
    }
    .task-box{
        font-size:17px;
    }
}
@media (min-width: 768px){
    .circle-progressDiv{
        display: inline-block;
        height: 250px;
    }
}
@media (min-width: 768px) and (max-width:1199px){
    .home-image{
        padding-left:50%;
    }
}
@media (min-width: 992px){
.col-lg-4 {
    flex: 0 0 auto;
    width: 32.23%;
}
.circle-progressDiv{
        height: 225px;
    }
}
.select-options{
    border:none;
}
.circle-div{
    height:150px;
     width:150px;
     border: 4px solid #3E3F3E;
     border-radius: 50%;
     margin: auto;
     box-shadow: 0px 0px 7px #3E3F3E;
}
.circle-div1{
    height:120px;
     width:120px;
     border-radius: 50%;
     margin: auto;
     margin-top: 7%;
}
.project-select{
    margin-top: 1%;
    margin-bottom: 1%;
}
   .pro-gress{
    display: inline;
   }
   .firstLetr{
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: #fff;
        box-shadow: inset 0px 3px 6px #ffffff30, 0px 3px 6px #00000029;
        font-size: 12px;
        padding: 4px 6px;
        text-align: center;
   }  
   .project-color-block{
       height : 80px;
       width : 80px;
       border-radius: 20%;
       color:white;
       font-size:22px;
       margin: auto;
   }
   .project-label { 
       font-weight : 600;
       font-size: 13px;
       color: ${({ theme }) => theme["themeColor"]};
   }
   .projectlist{
         color: ${({ theme }) => theme["themeColor"]};
   }
   .org-color{
       color:#4EB6EE !important;
   }
   .white-color{
       font-size:16px;
   }
   
   @media (min-width:1200px) and (max-width:1292px){
    .circle-progressDiv{
        height: 240px;
    }
    .task-box{
        font-size: 13px;
    }
}

`;

export default DashboardView;