import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from 'jwt-decode'
import { Col, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Forms } from '../../../components/Reuseable/Forms';
import ResetPasswordWrap from "./style";
import tractaskLogo from "../../../assets/images/logo/tracTasklogo.svg";
import personimage from "../../../assets/images/login/personimage12.png";
import { api } from '../../../Services/api';
import { Validators } from "../../../Utilities/validator";
import { useParams } from "react-router-dom";

const VerifySubUser = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [changeInForm, setChangeInForm] = useState(false);

  let { token } = useParams();
  let user = jwt_decode(token);

  const SubmitPassword = (form, valid) => {
    if (valid) {
      setLoading(true);
      const obj = {
        emailId: user.userId,
        newPassword: form.newpassword,
        clientId: user.clientId,
      }
      console.log("[SubmitPassword] obj-->", obj)

      api.post('/trackinguserauth/email', obj, {
        headers: {
          'authorizationtoken': `${token}`,
        }
      }).then(function (response) {
        if (response.status === 200) {
          if (response.data.status === true) {
            navigate('/login');
            setSubmitted(true);
          }
          else {
            setLoading(false);
          }
        }
      }).catch(function (error) {
        console.log("[SubmitPassword] Error-->", error);
        setLoading(false);
      });
    }
  };

  return (

    <ResetPasswordWrap>
      <section className="loginPageContent">
        <Row>
          <div className="loginPageRow">
            <Col className="loginPageRightside ps-0 pe-0" lg={5} md={12} sm={12} xs={12}>
              <div className="loginScreenLogoMain text-center pb-3 ">
                <a href="https://tracktask.com/">
                  <img className="img-fluid mx-auto loginScreenLogoimg" src={tractaskLogo} alt="trekr icon" />
                </a>
              </div>
              <div className="loginFormScreens flex-fill col-md-12">
                <Forms title={""} formArr={[
                  {
                    label: "New Password",
                    name: "newpassword",
                    type: "password",
                    placeholder: "New Password",
                    validators: [
                      { check: Validators.required, message: "Password is required" },
                      { check: Validators.password, message: "Enter atleast 6 characters" }
                    ],
                    error: false,
                    errormsg: "",
                    value: "",
                    show: true
                  },
                  {
                    label: "Confirm Password",
                    name: "confirmpassword",
                    type: "password",
                    placeholder: "Confirm Password",
                    validators: [
                      { check: Validators.required, message: "Password is required" }
                    ],
                    error: false,
                    errormsg: "",
                    value: "",
                    match: "newpassword",
                    show: true
                  }
                ]} backBtn={false} submitBtn={"Confirm password"} onSubmit={SubmitPassword} loading={loading} submitted={submitted} changeInForm={changeInForm} setChangeInForm={setChangeInForm} />
              </div>
            </Col>
            <Col >
              <img className="loginPageLeftside" src={personimage} alt="PersonImage" />
            </Col>
          </div>
        </Row>
      </section>
    </ResetPasswordWrap>
  );
};

export default VerifySubUser;