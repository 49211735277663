import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, NavLink } from "react-router-dom"
import { Button, Navbar, Nav, Container, Dropdown } from "react-bootstrap"
import $ from "jquery"
import HeaderWrap from "./style"
import SettingsIcon from "@mui/icons-material/Settings"
import Form from "react-bootstrap/Form"
import menunew from "../../../../assets/images/icons/1111.svg"
import tractaskLogo from "../../../../assets/images/logo/tracTasklogo.svg"
import darkThemePreview from "../../../../assets/images/dark-theme-preview.svg"
import lightThemePreview from "../../../../assets/images/light-theme-preview.svg"
import { changetheme } from "../../../../redux/Layout/actionCreator"
import { userLogout } from "../../../../redux/Auth/actionCreator"
import {
  resetTracking,
  setGlobalSearch,
  setGlobalSearchResult,
  setAllTaskView,
  setProjectView
} from "../../../../redux/Tracking/actionCreator"
import { FaRegPlusSquare } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import Search from "../../Admin/Search/Search"
import { useDebounce } from "../../../../Hooks/useDebounce"
import { api } from "../../../../Services/api"
import { BsPlus } from "react-icons/bs"
import UIModal from "../../../Reuseable/Modal"
import Workflow from "../../Portal/Workflow"
import SearchIcon from "@mui/icons-material/Search"
import { IconButton, Menu, MenuItem } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { AddDocumentModel } from "../../../Reuseable/AddDocumentModel"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"
import { useMedia } from "react-use"

const Header = ({ setShowTaskViewModal, permission }) => {
  const darkmode = useSelector((state) => state.Layout.darkmode)
  const panelview = useSelector((state) => state.Layout.panelview)
  const user = useSelector((state) => state.Auth.user)
  const userRole = user.userRole
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)
  const dispatch = useDispatch()
  const settings = useSelector((state) => state.Auth.menuList)
  let navigate = useNavigate()
  const { data: allProjectsRes } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allProjects = allProjectsRes?.data?.Items ?? []
  const authToken = useSelector((state) => state.Auth.authToken)
  const { data: allTasksTypesRes } = useFetch(
    key.get_all_tasktypes,
    "/trackingtasktype",
    () => {},
    () => {},
    false,
    false
  )
  let allTaskTypes = allTasksTypesRes?.data ?? []
  const { data: allProjectMembers } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMembers = allProjectMembers?.data ?? []
  const { data: allProjectWorkflowRes } = useFetch(
    key.get_all_workflow,
    `/trekrworkflow`,
    () => {},
    () => {},
    false,
    false
  )
  let allWorkFlow = allProjectWorkflowRes?.data?.workflowList ?? []
  let Work_Flow = Object.keys(allWorkFlow).map((key) => allWorkFlow[key])

  const [showAddNewWorkflow, setShowAddNewWorkflow] = useState(false)
  const responsiveMenu = useMedia("(max-width: 575px)")

  const selectedProject = useSelector((state) => state.Tracking.allTaskSelectedProject)
  let inputFormData = useSelector((state) => state.Tracking.inputFormArr)
  let allProjectModals = useSelector((state) => state.Tracking.projectModel)

  const createPermission = permission?.create
  const [showDoc, setShowDoc] = useState(false)

  const search = useSelector((state) => state.Tracking.globalSearch)

  useEffect(() => {
    if (darkmode) {
      $("#darkTheme").prop("checked", true)
    } else {
      $("#lightTheme").prop("checked", true)
    }
  })

  const logoutUser = () => {
    let result = {
      isLoggedIn: false,
      authToken: "",
      user: "",
      menuList: []
    }
    dispatch(userLogout(result))
    dispatch(resetTracking())
    navigate("/")
  }

  function settheme(e) {
    let val = true
    if (e === "dark") {
      val = true
    } else {
      val = false
    }

    dispatch(changetheme(val))
  }
  function togleMenu() {
    $(".page-body-wrapper").toggleClass("mobSideBar")
  }

  const setSearch = (value) => {
    dispatch(setGlobalSearch(value))
  }

  const getData = (data) => {
    const result = data.map((item, i) => {
      let project_name = allProjects.find((e) => e.trackingprojectid == item.trackingprojectsid)
      if (project_name) item.project_name = project_name.label
      else item.project_name = "Not Assigned"

      let workflowId = allProjects.find(
        (e) => e.trackingprojectid == item.trackingprojectsid || e.number == item.trackingprojectsid
      )

      // TODO: API CALL FOR for WORKFLOW ID
      if (workflowId) item.workflowId = workflowId.workflow
      let newArr = []
      let status = Work_Flow.map((e, i) => {
        Object.keys(e).map((n) => {
          newArr.push(e[n])
        })
      })
      let status_name = newArr.find((e) => e.workFlowId == item.workflowId)
      if (status_name) item.workflow = status_name.workFlow

      if (status_name) item.status_name = status_name.workFlow[item.trackingprojectstatusid]
      else item.status_name = "In approval"

      if (item.status_name) {
        let name = []
        Object.keys(item.status_name).map((n) => {
          name.push(item.status_name[n])
        })
        if (status_name) item.status_name = name
        else item.status_name = "In approval"
      }

      let reportedByName = allMembers.find((e) => e.userId === item.reportedby)
      if (reportedByName) item.reportedByName = reportedByName.personname

      let assignedto = allMembers.find((e) => e.userId === item.assignedto)
      if (assignedto) item.assignedtoName = assignedto.personname
      else item.assignedtoName = "Not Assigned"
      let task = allTaskTypes.find((e) => e.id === item.type)
      if (task) item.taskName = task.label
      item.cloneTitle = "Clone - " + item.title

      return item
    })

    return result
  }

  const searchQuery = useDebounce(search, 1000)

  const isProjectExist = (project) => {
    const projectIdToFind = Array.isArray(project) ? project[0] : project
    let result = allProjects.find((e) => e.trackingprojectid === projectIdToFind)
    return result !== undefined ? true : false
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  /********************Add new Document modal************************/
  const [searchLoading, setSearchLoading] = useState(true)
  const [showDetails, setShowDetails] = useState(false)
  const [selected, setSelected] = useState(null)

  /********************************************/

  const SearchFromApi = async (query) => {
    const data = {
      query: query
    }
    setSearchLoading(true)
    api
      .post(`/tracking/global`, data, {
        headers: {
          authorizationtoken: `${authToken}`
        }
      })
      .then((res) => {
        setSearchLoading(false)
        let globalSearchValue = res?.data?.data
        let findSearch = globalSearchValue.map((item) => {
          return item?._source?.trackingprojectsid
        })
        if (res.status) {
          if (isProjectExist(findSearch)) {
            const result = res?.data?.data?.map((item) => {
              return item._source
            })
            dispatch(setGlobalSearchResult(getData(result)))
          }
        }
      })
      .catch((err) => {
        setSearchLoading(false)
        console.log("SearchFromApi Error Response", err)
      })
  }

  useEffect(() => {
    if (searchQuery && searchQuery.length >= 0) {
      SearchFromApi(searchQuery)
    } else {
      dispatch(setGlobalSearchResult([]))
      setSearchLoading(true)
    }
  }, [searchQuery])

  const showAddWorkFlow = () => {
    setSelected(null)
    setShowAddNewWorkflow(true)
    setShowDetails(false)
  }

  return (
    <HeaderWrap darkMode={darkmode}>
      <nav className=' default-layout-navbar navbar col-lg-12 col-12 p-0 fixed-top justify-content-start flex-nowrap'>
        <div className='text-center navbar-brand-wrapper d-flex align-items-center'>
          {responsiveMenu && panelview === "sidebar" && (
            <div className='menubarMain' onClick={togleMenu}>
              <img src={menunew} alt='menunew' />
            </div>
          )}

          <Link className='navbar-brand brand-logo' to={"/"}>
            {darkmode ? (
              <img className='img-fluid mx-auto siteLogoImg' src={tractaskLogo} alt='trekr logo' />
            ) : (
              <img className='img-fluid mx-auto siteLogoImg' src={tractaskLogo} alt='trekr logo' />
            )}
          </Link>
        </div>

        <div className='navbar-menu-wrapper d-flex'>
          {panelview === "sidebar" ? (
            <>
              <Container fluid className='my-auto mx-0 w-50 p-0 tp-nav'>
                <Search
                  placeholder='Enter Task No. / Title / Description'
                  setShowTaskViewModal={setShowTaskViewModal}
                  search={search}
                  searchLoading={searchLoading}
                  handleSearch={setSearch}
                  type='globalSearch'
                />
              </Container>
            </>
          ) : (
            <>
              <Navbar
                collapseOnSelect
                bg={darkmode === true ? "black" : "white"}
                variant={darkmode === true ? "dark" : "light"}
              >
                <Nav className='top_menu_bar_nav'>
                  {settings &&
                    settings.map((setting) => {
                      return (
                        setting.access && (
                          <NavLink title={setting.name} key={setting.path} to={setting.path}>
                            {/* <span>{setting.name}</span> */}
                            <img alt='img' className='top_menu_nav_item' src={setting.leftIcon} />
                          </NavLink>
                        )
                      )
                    })}
                </Nav>

                <IconButton
                  className='top_menu_bar_menu_icon'
                  edge='start'
                  color='inherit'
                  aria-label='menu'
                  onClick={handleMenuOpen}
                >
                  <MenuIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                  {settings &&
                    settings.map((setting) => {
                      return (
                        setting.access && (
                          <MenuItem key={setting.path} onClick={handleMenuClose}>
                            <NavLink
                              to={setting.path}
                              className='d-flex justify-content-center align-items-center gap-2'
                            >
                              <img alt='img' className='top_menu_nav_item' src={setting.leftIcon} />
                              <div className='menu_link_item'>{setting.name}</div>
                            </NavLink>
                          </MenuItem>
                        )
                      )
                    })}
                </Menu>
              </Navbar>
            </>
          )}
          <ul className='topNavListing nav justify-content-end ms-auto'>
            {panelview === "sidebar" ? (
              <>
                {/* <li className="me-3 userRole">
                  <small style={{ fontWeight: 600 }}>Client ID <span>:</span> {trackingClientId}</small>
                  <br />
                  <small style={{ fontWeight: 600 }}>User ID <span style={{ marginLeft: "8px" }}>:</span> {user.trackinguserid}</small>
                </li> */}
              </>
            ) : null}

            <li className={`nav-item hoverBg pe-0 ${panelview !== "sidebar" ? "" : "search_icon"}`}>
              <div className='dropdown'>
                <Link
                  className='dropdown-toggle'
                  data-bs-toggle='dropdown'
                  data-bs-auto-close='true'
                  to='#'
                  role='button'
                  aria-expanded='false'
                >
                  <SearchIcon className='search-icon' />
                </Link>
                <div className='dropdown-menu'>
                  <div className='dropdown_search'>
                    <Search
                      placeholder='Enter Task No. / Title / Description'
                      setShowTaskViewModal={setShowTaskViewModal}
                      search={search}
                      searchLoading={searchLoading}
                      handleSearch={setSearch}
                      type='globalSearch'
                    />
                  </div>
                </div>
              </div>
            </li>

            {panelview === "sidebar" && userRole === "Admin" ? (
              <>
                <li className='nav-item all_new_form_container'>
                  <div className='dropdown add_plus_container'>
                    <Link
                      className='dropdown-toggle add_plus_icon'
                      data-bs-toggle='dropdown'
                      data-bs-auto-close='true'
                      to='#'
                      role='button'
                      aria-expanded='false'
                    >
                      <FaRegPlusSquare className='add_puls_img' />
                    </Link>
                    <div className='dropdown-menu dropdown-menu-end'>
                      <div className='custom-dropdown-menu'>
                        <div className='plus_add_tasks'>
                          <p
                            className='add_tasks_titles'
                            onClick={() => {
                              dispatch(setAllTaskView("addnew"))
                              navigate("/tracking")
                            }}
                          >
                            <BsPlus className='task_add_icon' />
                            Add task
                          </p>
                          <p
                            className='add_tasks_titles'
                            onClick={() => {
                              setShowAddNewWorkflow(true)
                              navigate("/workflow")
                              showAddWorkFlow()
                            }}
                          >
                            <BsPlus className='task_add_icon' />
                            Add workflow
                          </p>
                          <p
                            className='add_tasks_titles'
                            onClick={() => {
                              dispatch(setProjectView("add project"))
                              navigate("/projects")
                            }}
                          >
                            <BsPlus className='task_add_icon' />
                            Add project
                          </p>
                          <p
                            className='add_tasks_titles'
                            onClick={(e) => {
                              navigate("/documents")
                              setShowDoc(true)
                            }}
                          >
                            <BsPlus className='task_add_icon' />
                            Add document
                          </p>
                          {/* <p
                            className="add_tasks_titles"
                            onClick={() => {
                              dispatch(setNewProjectModel("New ProjectModal"));
                              navigate("/projectmodel");
                            }}
                          >
                            <BsPlus className="task_add_icon" />
                            Add models
                          </p>
                          <p
                            className="add_tasks_titles"
                            onClick={() => {
                              dispatch(setAddPriorityView("New Priority"));
                              navigate("/priority");
                            }}
                          >
                            <BsPlus className="task_add_icon" />
                            Add priority
                          </p>
                          <p
                            className="add_tasks_titles"
                            onClick={() => {
                              dispatch(setAddNewTemplate("New Template"));
                              navigate("/template");
                            }}
                          >
                            <BsPlus className="task_add_icon" />
                            Add template
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </>
            ) : null}
            <li className='nav-item topnavSetting hoverBg'>
              <div className='dropdown'>
                <Link
                  className='nav-link dropdown-toggle '
                  data-bs-toggle='dropdown'
                  data-bs-auto-close='outside'
                  to='#'
                  role='button'
                  aria-expanded='false'
                >
                  <SettingsIcon />
                </Link>
                <div className='dropdown-menu dropdown-menu-end'>
                  <Form.Group
                    className='selectThemepart p-3'
                    controlId='formradio'
                    style={{ height: "160px" }}
                  >
                    <Form.Label style={{ color: "#a1a1a1", fontSize: "12px" }}>
                      THEME OPTIONS
                    </Form.Label>

                    <Form.Label
                      onClick={() => settheme("dark")}
                      className='mb-3 mt-2 position-relative defaultTheme'
                      style={{ cursor: "pointer" }}
                    >
                      <Form.Check.Input
                        id='darkTheme'
                        name='themeName'
                        type='radio'
                        value='dark'
                        style={{ fontSize: "16px" }}
                      />
                      <Form.Check.Label
                        style={{
                          marginLeft: "26px",
                          color: "#212529",
                          fontSize: "16px"
                        }}
                      >
                        Dark
                      </Form.Check.Label>
                      <Form.Control.Feedback
                        type=''
                        style={{
                          marginLeft: "26px",
                          color: "000000",
                          fontSize: "12px"
                        }}
                      >
                        Default
                      </Form.Control.Feedback>
                      <img
                        className='themePreviewimg darkThemePreviewimg'
                        width='30'
                        height='30'
                        src={darkThemePreview}
                        alt='darkThemePreview'
                      />
                    </Form.Label>
                    <Form.Label
                      onClick={() => settheme("light")}
                      className='mt-2 position-relative'
                      style={{ cursor: "pointer" }}
                    >
                      <Form.Check.Input
                        id='lightTheme'
                        value='light'
                        name='themeName'
                        type='radio'
                        style={{ fontSize: "16px" }}
                      />
                      <Form.Check.Label
                        style={{
                          marginLeft: "10px",
                          color: "#212529",
                          fontSize: "16px"
                        }}
                      >
                        Light
                      </Form.Check.Label>
                      <img
                        className='themePreviewimg lightThemePreviewimg'
                        width='30'
                        height='30'
                        src={lightThemePreview}
                        alt='lightThemePreview'
                      />
                    </Form.Label>
                  </Form.Group>
                  <Link
                    className='text-orange d-block text-center border-top settingLink p-2'
                    to='/settings'
                  >
                    See all Settings
                  </Link>
                </div>
              </div>
            </li>

            <li className={user.userRole === "Admin" ? "nav-item topnavProfile" : "nav-item"}>
              <Dropdown className='d-inline' autoClose='outside'>
                {user.userRole === "Admin" ? (
                  <Dropdown.Toggle
                    id='dropdown-autoclose-outside'
                    className='imgfirstLetrDropdwnTogle'
                  >
                    <div className='userType-container me-3'>
                      <h4 className='imgfirstLetr text-capitalize ms-1'>
                        {" "}
                        {user.personname ? user.personname[0] : ""}
                      </h4>
                      <h6 className='user-role '>{user.userRole}</h6>
                    </div>
                  </Dropdown.Toggle>
                ) : (
                  <Dropdown.Toggle
                    id='dropdown-autoclose-outside'
                    className='imgfirstLetrDropdwnTogle'
                  >
                    <h4 className='imgfirstLetr text-capitalize ms-1'>
                      {" "}
                      {user.personname ? user.personname[0] : ""}
                    </h4>
                  </Dropdown.Toggle>
                )}
                <Dropdown.Menu className='text-center' data-popper-placement='bottom-end'>
                  <div className='dpdwnProfDetail'>
                    <div className='singleProfDetail profInfo pt-4'>
                      <h4 className='imgfirstLetr text-capitalize defultImgFirsLetr'>
                        {user.personname ? user.personname[0] : ""}
                      </h4>
                      <h5 style={{ color: "black" }} className='mb-0'>
                        {user.personname}{" "}
                      </h5>
                      <p className='mb-0'>{user.trackinguserid}</p>
                    </div>
                    <div className='singleProfDetail acountList'>
                      <div className='d-flex align-items-center singleProfListng existingAcount'>
                        <div className='flex-grow-1 ms-3 text-start roleStatus'>
                          <p className='mb-0 roleRow'>
                            <strong>
                              Client ID <span>:</span>
                            </strong>{" "}
                            {trackingClientId}{" "}
                          </p>
                          <p className='mb-0 typeRow'>
                            <strong>
                              User ID <span style={{ marginLeft: "7px" }}>:</span>
                            </strong>{" "}
                            {user.trackinguserid}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='singleProfDetail signOutAcount pt-4 pb-4'>
                      <Button className='pe-5 ps-5' variant='orange' onClick={logoutUser}>
                        Sign Out
                      </Button>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </nav>

      {/***************add new workflow******************/}
      <UIModal
        title={"Add New Workflow"}
        showModal={showAddNewWorkflow}
        setShowModal={setShowAddNewWorkflow}
      >
        <Workflow
          showAddNewWorkflow={showAddNewWorkflow}
          setShowAddNewWorkflow={setShowAddNewWorkflow}
        />
      </UIModal>
      {/***************add new Document******************/}
      <AddDocumentModel show={showDoc} setShow={setShowDoc} />
    </HeaderWrap>
  )
}

export default Header
