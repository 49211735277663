import ForgotPassword from "./components/Pages/ForgotPassword"
import Login from "./components/Pages/Login"
import Disclaimer from "./components/Pages/Login/DisClaimer"
import MultiForm from "./components/Pages/Login/MultiForm"
import OTP from "./components/Pages/OTP"
import ResetPassword from "./components/Pages/ResetPassword"
import VerifySubUser from "./components/Pages/VerifysubUser"

const unProtectedRoutes = [
  {
    type: "collapse",
    name: "Login",
    key: "login-initial",
    route: "/",
    component: <Login />
  },
  {
    type: "collapse",
    name: "Login",
    key: "login",
    route: "/login",
    component: <Login />
  },
  {
    type: "collapse",
    name: "Register",
    key: "register",
    route: "/register",
    component: <MultiForm />
  },
  {
    type: "collapse",
    name: "OTP",
    key: "otp",
    route: "/otp",
    component: <OTP />
  },
  {
    type: "collapse",
    name: "Forgot Password",
    key: "forgotpassword",
    route: "/forgot-password",
    component: <ForgotPassword />
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "resetpassword",
    route: "/reset-password",
    component: <ResetPassword />
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "resetpassword:token",
    route: "/resetpassword/:token",
    component: <VerifySubUser />
  },
  {
    type: "collapse",
    name: "Privacy Policy",
    key: "privacypolicy",
    route: "/privacy-policy",
    component: <Disclaimer terms={false} privacy={true} />
  },
  {
    type: "collapse",
    name: "Terms & Conditions",
    key: "termsandconditions",
    route: "/terms-&-conditions",
    component: <Disclaimer terms={true} privacy={false} />
  }
]

export default unProtectedRoutes
