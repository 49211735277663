import React from "react";
import AddTeams from "./AddTeams";
import CurrentTeams from "./CurrentTeams";
import Search from "../Search/Search";
import { TeamsWrapper } from "./TeamStyle";

const teams = [
  { id: 1, teamName: "Business Team" },
  { id: 2, teamName: "Developement Team" },
  { id: 3, teamName: "Testing Team" },
  { id: 4, teamName: "UX Team" },
  { id: 5, teamName: "Business Team" },
  { id: 6, teamName: "Business Team" },
  { id: 7, teamName: "UX Team" },
  { id: 8, teamName: "Business Team" },
  { id: 9, teamName: "Business Team" },
  { id: 10, teamName: "Business Team" },
];

const Teams = () => {
  return (
    <TeamsWrapper>
      <div className="text-white d-flex align-items-center mb-2">
        <div className="ms-3 me-auto fw-bold fs-5">
          Teams <span className="plus-button">+</span>
        </div>

        <div className="d-flex me-3">
          <Search placeholder="Search Team" />
        </div>
      </div>

      <AddTeams />

      <CurrentTeams teams={teams} />
    </TeamsWrapper>
  );
};

export default Teams;
