import React from "react";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input style={{cursor:'pointer'}} type="checkbox" checked={props.isSelected} onChange={() => null}/>&nbsp;
        <label style={{cursor:'pointer'}}>{props.label}</label>
      </components.Option>
    </div>
  );
};


export const MultiSelect = ({options,optionSelected,setOptionSelected,onSelectOption,type, isMulti}) => {
  let multiOption = isMulti === false ? false : true;
  return (
      <span style={{width:'100%'}}
        className="d-inline-block"
        data-toggle="popover"
        data-trigger="focus"
        data-content="Please selecet account(s)"
      >
        <ReactSelect
          options={options}
          isMulti={multiOption}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{Option}}
          onChange={e => {setOptionSelected(e);onSelectOption(type,e);}}
          allowSelectAll={true}
          value={optionSelected}
        />
      </span>
    );
}