import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Forms } from "../../../Reuseable/Forms"
import { Validators } from "../../../../Utilities/validator"
import { api } from "../../../../Services/api"
import { setShowAlert } from "../../../../redux/Tracking/actionCreator"
import { client } from "../../../.."
import { key } from "../../../../data/queryKeys"

const QuickProject = ({
  setTaskview,
  setShowAlertBox,
  setAlertMessage,
  setShowAddNew,
  column,
  isLoading
}) => {
  const dispatch = useDispatch()

  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)
  const user = useSelector((state) => state.Auth.user)
  const authToken = useSelector((state) => state.Auth.authToken)

  /*************************** react state  intiaalize *******************/
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [changeInForm, setChangeInForm] = useState(false)

  /******* variables declaration *********/

  let formArr = [
    {
      // label: "Project Title",
      mandatory: true,
      name: "title",
      type: "text",
      placeholder: "Enter the Project Title",
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      value: "",
      maxLength: 100,
      show: true,
      autoFocus: true
    },
    {
      // label: "Estimated Start date",
      mandatory: true,
      name: "start",
      type: "startdate",
      halfWidth: true,
      placeholder: "Select date",
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      value: "",
      show: true
    },
    {
      // label: "Estimated Due date",
      mandatory: true,
      name: "Due",
      type: "enddate",
      halfWidth: true,
      placeholder: "Select date",
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      value: "",
      show: true
    }
    // {
    //   label: "Description",
    //   name: "description",
    //   type: "textarea",
    //   placeholder: "Type here...",
    //   validators: [
    //     { check: Validators.required, message: "This Field is required" },
    //   ],
    //   error: false,
    //   errormsg: "",
    //   rows: 4,
    //   value: "",
    //   show: true,
    // }
  ]

  const userObj = {
    label: user.personname,
    id: user.trackinguserid
  }

  /******************************* Functions *******************************/
  const SubmitForm = (form, valid) => {
    if (valid) {
      isLoading(column.name, true)
      setLoading(true)
      let Obj = {
        projectname: form.title,
        startdate: form.start,
        duedate: form.Due,
        createdby: user.personname,
        priority: column.name.toLowerCase(),
        budget: "0",
        percentage: "0",
        workflow: "default",
        teammates: [userObj],
        projectdescription: "<p></p>",
        fileurls: [],
        filesize: ""
      }
      // dispatch(setAllProjectsLoading(true))

      api
        .post("/trackingprojects/" + trackingClientId, Obj, {
          headers: {
            Authorizationtoken: ` ${authToken}`
          }
        })
        .then(function (response) {
          if (response.status === 200) {
            isLoading(column.name, false)
            setShowAlertBox(true)
            setAlertMessage(
              "(" +
                trackingClientId +
                "-" +
                response.data.data.trackingprojectid +
                ") New Project has been created successfully"
            )
            dispatch(setShowAlert(true))
            // dispatch(setAllProjectsLoading(false))
            setLoading(false)
            setSubmitted(true)
            setShowAddNew(false)
            client.setQueryData([key.get_all_projects], (oldData) => {
              return {
                ...oldData,
                data: {
                  Items: [...oldData.data.Items, response.data.data]
                }
              }
            })
            client.invalidateQueries({
              queryKey: [key.get_all_workflow]
            })
            // const trackingprojectid = response.data.data.trackingprojectid;
            // uploadImage(trackingprojectid);
            // onTaskView ? setProjectView() : setProjectView("all projects");
            // newProjects();
          }
        })
        .catch((error) => {
          console.log("Quick Project Creation Error", error)
          isLoading(column.name, false)
        })
    }
  }

  return (
    <>
      {/* <TrackingFormWrap> */}
      {/* <div className="formbody"> */}
      <Forms
        title={""}
        className='p-2'
        formArr={formArr}
        setTaskview={setTaskview}
        FileName={[]}
        FileArr={[]}
        submitBtn={"Create"}
        cancelBtn={true}
        buttonclr={"#3E3F3E"}
        onSubmit={SubmitForm}
        onResetEditor={() => {}}
        onCancel={() => {}}
        showcheckbox={true}
        loading={loading}
        submitted={submitted}
        changeInForm={changeInForm}
        setChangeInForm={setChangeInForm}
        editorRef={null}
        getAllMembers={() => {}}
        getAllProjects={() => {}}
        setFileSize={""}
        quickTask={true}
        setShowAddNew={setShowAddNew}
      />
      {/* </div> */}

      {/* </TrackingFormWrap> */}
    </>
  )
}

export default QuickProject
