import { theme, darktheme } from "./theme"
const layoutClass = localStorage.getItem("Trekr-Layout")

const config = {
  darkMode: true,
  theme,
  darktheme,
  panelView: layoutClass ? layoutClass : "sidebar"
}

export default config
