import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Forms } from "../Forms"
import UIModal from "../Modal"
import { Validators } from "../../../Utilities/validator"
import { api } from "../../../Services/api"
import { toBase64 } from "../../../Utilities/commonFunctions"
import { useFetch } from "../../../Hooks/useFetch"
import { key } from "../../../data/queryKeys"
import { Alert } from "react-bootstrap"

export const AddDocumentModel = ({ show, setShow }) => {
  const [selected, setSelected] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [fileSize, setFileSize] = useState("")
  const [fileArr, setFileArr] = useState([])
  const [loading, setLoading] = useState(false)
  const [changeInForm, setChangeInForm] = useState(false)
  const [docfiletype, setDocfiletype] = useState("")
  const [showAlertBox, setShowAlertBox] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  let allProjects = useSelector((state) => state.Tracking.projects)
  const user = useSelector((state) => state.Auth.user)
  const authToken = useSelector((state) => state.Auth.authToken)
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)
  const [error, setError] = useState("")

  const { refetch: getAllDocuments } = useFetch(
    key.get_all_documents,
    `/trackingdocument/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )

  let AllProjects = []
  if (allProjects.length > 0) {
    allProjects.forEach((element) => {
      AllProjects.push({ label: element.label, id: element.trackingprojectid })
    })
  }
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]
  const today = new Date()
  const yyyy = today.getFullYear()
  let mm = today.getMonth()
  let dd = today.getDate()
  let monthName = monthNames[mm]
  let hours = (today.getHours() < 10 ? "0" : "") + today.getHours()
  let minutes = (today.getMinutes() < 10 ? "0" : "") + today.getMinutes()
  let TimeType = ""
  if (hours <= 11) {
    TimeType = "AM"
  } else {
    TimeType = "PM"
  }
  if (hours > 12) {
    hours = hours - 12
  }
  if (hours == 0) {
    hours = 12
  }
  if (dd < 10) dd = "0" + dd
  if (mm < 10) mm = "0" + mm
  const formattedDate =
    monthName + " " + dd + "," + yyyy + " " + hours + ":" + minutes + " " + TimeType

  const SubmitForm = async (form, valid) => {
    if (valid) {
      setLoading(true)
      let Obj = {
        createddate: formattedDate,
        documentfilename: form.title,
        documentfiletype: fileArr.type,
        docfilesize: fileSize,
        updateddate: formattedDate,
        createdby: user.personname,
        fileurls: fileArr.name,
        filedata: await toBase64(fileArr)
      }
      api
        .post("/trackingdocument", Obj, {
          headers: {
            authorizationtoken: ` ${authToken}`
          }
        })
        .then(function (response) {
          setLoading(false)
          if (response.status === 200) {
            setSubmitted(true)
            handleClose()
            setShowAlertBox(true)
            getAllDocuments()
            setAlertMessage("Document Created Successfully")
          }
        })
        .catch(function (error) {
          setLoading(false)
          console.log(error)
        })
    } else {
      setError("Please fill all the required fields")
      return
    }
  }
  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
    }, 5000)
  }, [showAlertBox])

  let formArr = [
    {
      label: "New File",
      name: "title",
      type: "text",
      halfWidth: false,
      placeholder: "Enter",
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      value: "",
      maxLength: 100,
      show: true
    },
    {
      label: "Upload file",
      name: "uploadfile",
      type: "docuploader",
      halfWidth: false,
      placeholder: "",
      validators: [],
      error: false,
      errormsg: "",
      value: "",
      show: true,
      required: true
    }
  ]

  const handleClose = () => {
    setShow(false)
  }

  return (
    <UIModal
      showModal={show}
      setShowModal={setShow}
      title={selected === null ? "Add Document" : "Update Document"}
    >
      {error && (
        <Alert className='text-center py-1' variant={"danger"}>
          {error}
        </Alert>
      )}

      <Forms
        title={""}
        className='p-2'
        formArr={formArr}
        showFileupload={true}
        setDocfiletype={setDocfiletype}
        FileName={() => {}}
        FileArr={setFileArr}
        submitBtn={"Create"}
        onSubmit={SubmitForm}
        loading={loading}
        submitted={submitted}
        changeInForm={changeInForm}
        setChangeInForm={setChangeInForm}
        setFileSize={setFileSize}
      />
    </UIModal>
  )
}
