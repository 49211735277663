export const key = {
  get_table_headers: "get_table_headers",
  get_project_headers: "get_project_headers",
  get_workflows_headers: "get_workflows_headers",
  get_document_headers: "get_document_headers",
  get_backlogs_headers: "get_backlogs_headers",
  get_archive_headers: "get_archive_headers",
  get_approval_headers: "get_approval_headers",
  get_report_tasks_headers: "get_report_tasks_headers",
  get_report_weekly_headers: "get_report_weekly_headers",
  get_all_workflow: "get_all_workflow",
  get_all_members: "get_all_members",
  get_all_projects: "get_all_projects",
  get_project_tasks: "get_project_tasks",
  get_project_versions: "get_project_versions",
  get_project_modal_value: "get_project_modal_value",
  get_all_tasktypes: "get_all_tasktypes",
  close_account: "close_account",
  get_archive_list: "get_archive_list",
  get_priority_data: "get_priority_data",
  get_backlogs_data: "get_backlogs_data",
  get_approval_data: "get_approval_data",
  get_reportTasks_data: "get_reportTasks_data",
  get_reportTime_data: "get_reportTime_data",
  get_reportWeekly_data: "get_reportWeekly_data",
  get_mydashboardHeaders_data: "get_mydashboardHeaders_data",
  get_project_dashboard_data: "get_project_dashboard_data",
  get_all_documents: "get_all_documents",
  get_all_inputforms: "get_all_inputforms",
  project_model_values: "get_project_modal_value",
  get_trial_period: "get_trial_period"
}
