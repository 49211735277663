import React, { useState } from "react"
import { useSelector } from "react-redux"
import Tables from "../../../Reuseable/Tables"
import "react-confirm-alert/src/react-confirm-alert.css"
import ProjectsModalView from "../Projects/style"
import { ArchiveListColumns } from "./ArchiveListColumns"
import { ArchiveListHeader } from "./ArchiveListHeader"
import moment from "moment"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"
import { client } from "../../../.."

export default function ArchiveTableData({ permission }) {
  const createPermission = permission.create
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)

  const { data: allMembersRes } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  const allMembers = allMembersRes?.data || []

  const user = useSelector((state) => state.Auth.user)
  const [searchDueDate, setSearchDueDate] = useState("")
  const [searchfromDate, setSearchFromDate] = useState("")

  const { data: allProjectWorkflowRes } = useFetch(
    key.get_all_workflow,
    `/trekrworkflow`,
    () => {},
    () => {},
    false,
    false
  )
  let allWorkFlow = allProjectWorkflowRes?.data?.workflowList ?? []

  const [trackingSortings, setTrackingSorting] = useState({
    selectedSortingItem: "trackingid",
    trackingNumberSorting: "down",
    statusSorting: "",
    typeSorting: "",
    projectNameSorting: "",
    reportedBySorting: "",
    prioritySorting: "",
    assignedToSorting: ""
  })

  const {
    data: allArchiveTableDataRes,
    isLoading: loading,
    isFetching,
    refetch: refetchArchiveTableData
  } = useFetch(
    key.get_archive_list,
    `/archivelist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    !client.getQueryData([key.get_archive_list])?.status
  )
  const allArchiveTableData = allArchiveTableDataRes?.data ?? []

  const [searchColumn, setColumnName] = useState("")

  const refreshAllTracking = () => {
    refetchArchiveTableData()
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const day = date.getDate()
    const month = getMonthName(date.getMonth())
    const year = date.getFullYear()
    return `${day} ${month} ${year}`
  }

  const getMonthName = (monthIndex) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ]
    return months[monthIndex]
  }

  const getPriorityClass = (priority) => {
    if (
      priority === "blocker" ||
      priority === "Blocker Level 1" ||
      priority === "Blocker Level 2" ||
      priority === "blocker2"
    ) {
      return "priority-red"
    } else if (priority === "Medium" || priority === "medium") {
      return "priority-Lightgreen"
    } else if (priority === "Major" || priority === "major") {
      return "priority-orange"
    } else if (priority === "Minor" || priority === "minor") {
      return "priority-white"
    } else {
      return ""
    }
  }

  const getTableData = allArchiveTableData
    .sort((item1, item2) => {
      if (trackingSortings.selectedSortingItem === "priority") {
        if (trackingSortings.prioritySorting) {
          if (trackingSortings.prioritySorting === "down") {
            if (item1.priority < item2.priority) return 1
            else return -1
          } else {
            if (item1.priority < item2.priority) return -1
            else return 1
          }
        }
      } else if (trackingSortings.selectedSortingItem === "status") {
        if (trackingSortings.statusSorting) {
          if (trackingSortings.statusSorting === "down") {
            if (item1.status_name < item2.status_name) return 1
            else return -1
          } else {
            if (item1.status_name < item2.status_name) return -1
            else return 1
          }
        }
      } else if (trackingSortings.selectedSortingItem === "type") {
        if (trackingSortings.typeSorting) {
          if (trackingSortings.typeSorting === "down") {
            if (item1.taskName < item2.taskName) return 1
            else return -1
          } else {
            if (item1.taskName < item2.taskName) return -1
            else return 1
          }
        }
      }
      return item2.trackingid - item1.trackingid
    })
    .filter((item) => {
      if (searchColumn) {
        let trackingnumber = trackingClientId + "-" + item.trackingid
        let trackingnumber1 = trackingClientId + item.trackingid
        let byReportedName = allMembers
          .map((member) => {
            if (member.userId === item.reportedby) {
              return member.personname
            }
            return ""
          })
          .find((name) => name !== "")
        return (
          trackingnumber.toLowerCase().includes(searchColumn.toLowerCase()) ||
          trackingnumber1.toLowerCase().includes(searchColumn.toLowerCase()) ||
          (byReportedName && byReportedName.toLowerCase().includes(searchColumn.toLowerCase())) ||
          item.assignedto.toLowerCase().includes(searchColumn.toLowerCase())
        )
      }

      if (searchfromDate && searchfromDate.length && searchDueDate && searchDueDate.length) {
        const reportedDate = moment(formatDate(item.reporteddate))
        if (moment(reportedDate).isBetween(searchfromDate, searchDueDate, "day", "[]")) {
          return item
        }
      } else if (searchfromDate && searchfromDate.length) {
        const reportedDate = moment(formatDate(item.reporteddate))
        if (moment(reportedDate).isSameOrAfter(searchfromDate, "day", "[]")) {
          return item
        }
      } else if (searchDueDate && searchDueDate.length) {
        const reportedDate = moment(formatDate(item.reporteddate))
        if (moment(reportedDate).isSameOrBefore(searchDueDate, "day", "[]")) {
          return item
        }
      } else {
        return item
      }
      return null
    })
    .map((item, i) => {
      const Work_Flow = Object.values(allWorkFlow).flatMap((obj) => Object.values(obj))
      // eslint-disable-next-line eqeqeq
      let workflow = Work_Flow.find((e) => e.workFlowId == item.workflowid)
      if (workflow) item.workflow = workflow?.workFlow
      const statusName =
        item.workflow && item.workflow[item.trackingprojectstatusid]
          ? item.workflow[item.trackingprojectstatusid]
          : ""
      item.status_name = statusName ? Object.values(statusName) : ""
      return (
        <tr key={i} val={item}>
          <td style={{ cursor: "pointer" }} className='text-center'>
            <strong className='mobHead text-center'>Task number:</strong>
            <span className='check_task_number justify-content-center'>
              {trackingClientId}-{item.trackingid}
            </span>
          </td>
          <td className='text-wrap text-center' style={{ width: "20px" }}>
            <strong className='mobHead'>Title :</strong>
            <span>
              {item.title === "" || item.title === null || item.title === undefined ? (
                <span>-</span>
              ) : (
                <span>{item.title}</span>
              )}
            </span>
          </td>
          <td className='text-center'>
            <strong className='mobHead'>Status :</strong>
            <span>
              {item.status_name === "" ? <span>-</span> : <span>{item.status_name}</span>}
            </span>
          </td>
          <td className='text-center'>
            <strong className='mobHead'>Reported Date :</strong>
            <span>
              {item.reporteddate === ""
                ? "-"
                : moment(item.reporteddate).format("MMM D, YYYY hh:mm A")}
            </span>
          </td>
          <td className='text-center'>
            <strong className='mobHead'>Reported by :</strong>
            <span>
              {item.reportedByName ||
                allMembers.map((member) => {
                  if (member.userId === item.reportedby) {
                    return member.personname
                  }
                  return ""
                })}
            </span>
          </td>
          <td className='text-center'>
            <strong className='mobHead'>Assignedto :</strong>
            <span className='assigned_to'>
              {item.assignedto !== ""
                ? allMembers.map((member) => {
                    if (member.userId === item.assignedto) {
                      return member.personname
                    }
                    return ""
                  })
                : "Not Assigned"}
            </span>
          </td>
          <td className='text-center'>
            <strong className='mobHead'>Priority : </strong>
            <span className={`text-capitalize ${getPriorityClass(item.priority)}`}>
              {item.priority === "blocker"
                ? "Blocker Level 1"
                : item.priority === "blocker2"
                ? "Blocker Level 2"
                : item.priority === ""
                ? "-"
                : item.priority}
            </span>
          </td>
        </tr>
      )
    })

  const handleFromDate = (value) => {
    setSearchFromDate(formatDate(value))
    if (!value) {
      setSearchFromDate([])
    }
  }

  const handleDueSearch = (value) => {
    setSearchDueDate(formatDate(value))
    if (!value) {
      setSearchDueDate([])
    }
  }

  const handleColumnSearch = (value) => {
    setColumnName(value)
  }

  return (
    <>
      <ProjectsModalView>
        <div className='trackingPage'>
          <Tables
            setProjectTable={true}
            columns={
              <ArchiveListColumns
                trackingSortings={trackingSortings}
                setTrackingSorting={setTrackingSorting}
                handleColumnSearch={handleColumnSearch}
                searchColumn={searchColumn}
              />
            }
            data={getTableData}
            showInfiniteScroll={false}
            header={
              <ArchiveListHeader
                refreshAllTracking={refreshAllTracking}
                isLoading={loading || isFetching}
                searchValue={""}
                handleFromDate={handleFromDate}
                handleDueSearch={handleDueSearch}
                setSearchFromDate={setSearchFromDate}
                searchfromDate={searchfromDate}
                searchDueDate={searchDueDate}
                userRole={user.rolename}
                userRoleType={user.roletype}
                allMembers={allMembers}
                allArchiveTableData={allArchiveTableData}
                createPermission={createPermission}
              />
            }
            notfound={"No Archive Found"}
            loading={loading}
            refreshLoading={false}
          />
        </div>
      </ProjectsModalView>
    </>
  )
}
