import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Forms } from "../../../Reuseable/Forms";
import { Validators } from "../../../../Utilities/validator";
import { api } from "../../../../Services/api";
import {
  setShowAlert,
  setAllTrackings,
  setMyTrackings,
} from "../../../../redux/Tracking/actionCreator";

const QuickTask = ({
  setTaskview,
  setShowAlertBox,
  setAlertMessage,
  refreshAllTracking,
  setShowAddNew,
  trackingprojectstatusid,
  project,
  isLoading,
  path,
  assignedToMe = false,
}) => {
  const dispatch = useDispatch();

  /*************************** redux var intiaalize *******************/
  let allTrackingsList = useSelector((state) => state.Tracking.allTrackings);
  // console.log('allTrackingsList', allTrackingsList)
  let myTrackingList = useSelector((state) => state.Tracking.myTrackings);
  const trackingClientId = useSelector(
    (state) => state.Auth.user.trackingclientid
  );
  const user = useSelector((state) => state.Auth.user);
  const authToken = useSelector((state) => state.Auth.authToken);

  /*************************** react state  intiaalize *******************/
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [changeInForm, setChangeInForm] = useState(false);
  const [resetEditorValue, setResetEditorValue] = useState(false);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let formArr = [
    {
      mandatory: true,
      name: "title",
      type: "text",
      placeholder: "Enter the Task Title",
      validators: [
        { check: Validators.required, message: "This Field is required" },
      ],
      error: false,
      errormsg: "",
      value: "",
      maxLength: 100,
      show: true,
      autoFocus: true,
    },
  ];

  if (assignedToMe !== true) {
    formArr.splice(1, 0, {
      // label: "Assigned to",
      name: "assignedto",
      type: "selectbox",
      placeholder: "assigned to...",
      // validators: [
      //   { check: Validators.required, message: "This Field is required" },
      // ],
      error: false,
      halfWidth: false,
      errormsg: "",
      mandatory: true,
      options: project.teammates,
      value: "",
      defaultValue: "",
      show: true,
    });
  }

  /******************************* Functions *******************************/
  const SubmitForm = async (form, valid, setEditorState) => {
    if (valid) {
      setLoading(true);
      setShowAddNew(false);
      isLoading(trackingprojectstatusid, true);
      let Obj = {
        title: form.title,
        trackingprojectsid: project.trackingprojectid,
        type: 2,
        priority: "medium",
        fileurls: [],
        filesize: [],
        description: "<p></p>",
        reportedby: user.trackinguserid,
        assignedto: assignedToMe ? user.trackinguserid : form.assignedto.id,
        timezone: timezone,
        referencenumber: form?.referencenumber ?? "",
        startdateandtime: form.startdateandtime,
        enddateandtime: form.enddateandtime,
        label: form?.label ?? "",
        authorizationToken: authToken,
        trackingprojectstatusid: trackingprojectstatusid,
      };
      api
        .post("/tracking/" + trackingClientId, Obj, {
          headers: {
            authorizationtoken: ` ${authToken}`,
          },
        })
        .then(function (response) {
          console.log("response", response);
          if (response.status === 200) {
            dispatch(setShowAlert(true));
            setSubmitted(true);
            // let item = {
            //     trackingid: response.data.data.trackingid,
            //     trackingclientid: response.data.data.trackingclientid,
            //     trackingprojectsid: response.data.data.trackingprojectsid,
            //     comments: response.data.data.comments,
            //     history: response.data.data.history,
            //     description: response.data.data.description,
            //     priority: response.data.data.priority,
            //     fileurls: response.data.data.fileurls,
            //     filesize: response.data.data.filesize,
            //     title: response.data.data.title,
            //     type: response.data.data.type,
            //     trackingprojectstatusid:
            //         response.data.data.trackingprojectstatusid,
            //     assignedto: response.data.data.assignedto,
            //     reportedby: user.trackinguserid,
            //     projectname: response.data.data.projectname,
            //     reporteddate: response.data.data.reporteddate,
            // };
            // console.log('quick Task Respone item', item)

            const approvalStatus = response.data.data.approval_status;

            if (
              response.data.data.assignedto === user.trackinguserid &&
              approvalStatus === ""
            ) {
              myTrackingList.push(response.data.data);
            }
            dispatch(setMyTrackings([...myTrackingList]));

            if (approvalStatus === "") {
              allTrackingsList.push(response.data.data);
            }
            dispatch(setAllTrackings([...allTrackingsList]));

            setLoading(false);
            // if (!form.add_task) {
            //     setTaskview("AllTask");
            // }
            setShowAlertBox(true);
            setAlertMessage(
              "(" +
                trackingClientId +
                "-" +
                response.data.data.trackingid +
                (approvalStatus === ""
                  ? ") New tracking item has been created successfully"
                  : ") New Tracking items has been created and waiting for Approval")
            );
            setResetEditorValue(true);
            isLoading(trackingprojectstatusid, false);
          }
        });
    }
  };

  return (
    <Forms
      title={""}
      className="p-2"
      formArr={formArr}
      setTaskview={setTaskview}
      FileName={[]}
      FileArr={[]}
      submitBtn={"Create"}
      cancelBtn={true}
      buttonclr={"#3E3F3E"}
      onSubmit={SubmitForm}
      onResetEditor={resetEditorValue}
      onCancel={() => {}}
      showcheckbox={true}
      loading={loading}
      submitted={submitted}
      changeInForm={changeInForm}
      setChangeInForm={setChangeInForm}
      editorRef={null}
      getAllMembers={() => {}}
      getAllProjects={() => {}}
      setFileSize={""}
      quickTask={true}
      setShowAddNew={setShowAddNew}
    />
  );
};

export default QuickTask;
