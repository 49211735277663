import React from "react";
import { Link } from "react-router-dom";
import trekr from "../../../assets/images/logo/trekrlogo12.svg"

import Styled from 'styled-components';

const DisclaimerWrap = Styled.div`

background-color: white;
color: white;
overflow: auto;
position: relative;

.logo {
  position: absolute;
  left: 15px;
  top: 5px;
}

.documentView {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

`;


const Disclaimer = ({ terms, privacy }) => {

  return (
    <DisclaimerWrap>

      {/* <div>
        <Link className="logo" to="/">
          <img
            className="img-fluid mx-auto pt-2"
            src={trekr}
            alt="trekr logo"
            height={50}
            width={145}
          />
        </Link>
      </div> */}

      {terms && <iframe
        className="documentView"
        title="terms and conditions"
        src="https://onedrive.live.com/embed?cid=56220588233B96CE&amp;resid=56220588233B96CE%211540&amp;authkey=APws5sRk0CmlaNc&amp;em=2&amp;wdEmbedCode=0&amp;wdStartOn=1">
      </iframe>}

      {privacy && <iframe
        className="documentView"
        title="privacy policy"
        src="https://onedrive.live.com/embed?cid=56220588233B96CE&amp;resid=56220588233B96CE%211538&amp;authkey=AHuvrZBGE8UY56w&amp;em=2&amp;wdEmbedCode=0&amp;wdStartOn=1">
      </iframe>}


      {/* 

      <div className="container mx-auto p-2 md:p-4">
        <h5 className="text-cyan text-center pb-4 text-xl" >
          {title}
        </h5>
        <div className="text-justify leading-[1.45rem] whitespace-normal">
          {contents?.map((content) => {
            return (
              <div key={content.id}>
                <div className="text-center sm:text-start text-xl font-bold pb-2 ">{content?.heading}</div>
                <p className="pb-4" >
                  {content.content}
                </p>
              </div>
            )
          })}
        </div>
      </div> */}

    </DisclaimerWrap >
  );
};

export default Disclaimer;
