import React, { useState } from "react"
import backlogs_priority from "../../../../../../../assets/images/backlogs-priority.svg"
import backlogs_search from "../../../../../../../assets/images/backlogs-search.svg"
import { useSelector } from "react-redux"

export const ProjectVersionsColumns = ({
  trackingSortings,
  setTrackingSorting,
  handleColumnSearch,
  searchColumn
}) => {
  const allArchiveHeaderNames = useSelector((state) => state.Tracking.allArchiveHeaderNames)
  const applyTrackingNumberSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "trackingid",
        projectNameSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, trackingNumberSorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, trackingNumberSorting: "down" }
      })
  }

  const applyProjectNumberSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "projectname",
        trackingNumberSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, projectNameSorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, projectNameSorting: "down" }
      })
  }

  const applyReportedBySorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "reportedby",
        trackingNumberSorting: "",
        projectNameSorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, reportedBySorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, reportedBySorting: "down" }
      })
  }

  const applyPrioritySorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "priority",
        trackingNumberSorting: "",
        typeSorting: "",
        projectNameSorting: "",
        reportedBySorting: "",
        assignedToSorting: "",
        statusSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, prioritySorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, prioritySorting: "down" }
      })
  }

  const applyAssignToSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "priority",
        trackingNumberSorting: "",
        projectNameSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        statusSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, assignedToSorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, assignedToSorting: "down" }
      })
  }

  const applyStatusSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "status",
        trackingNumberSorting: "",
        projectNameSorting: "",
        typeSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, statusSorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, statusSorting: "down" }
      })
  }

  const [clickSearchIcon, setClickSearchIcon] = useState(false)
  let ArchivecolumnValues = []
  if (allArchiveHeaderNames && allArchiveHeaderNames.length) {
    for (let i = 0; i < allArchiveHeaderNames.length; i++) {
      ArchivecolumnValues.push(allArchiveHeaderNames[i].column1)
      ArchivecolumnValues.push(allArchiveHeaderNames[i].column2)
      ArchivecolumnValues.push(allArchiveHeaderNames[i].column3)
      ArchivecolumnValues.push(allArchiveHeaderNames[i].column4)
      ArchivecolumnValues.push(allArchiveHeaderNames[i].column5)
      ArchivecolumnValues.push(allArchiveHeaderNames[i].column6)
      ArchivecolumnValues.push(allArchiveHeaderNames[i].column7)
      ArchivecolumnValues.push(allArchiveHeaderNames[i].column8)
    }
  }
  return (
    <>
      <th className='text-center'>
        <span className='text-center ps-1'>Version</span>
      </th>
      <th>Description</th>
      <th>Aliases</th>
      <th>Created on</th>
      <th style={{ width: "17.5%" }}>Created by</th>
    </>
  )
}
