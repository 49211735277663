import { client } from "../.."
import { ActionTypes } from "./actions"
import jwt_decode from "jwt-decode"

const authToken = localStorage.getItem("token") ?? ""
const menuList = JSON.parse(localStorage.getItem("menuItem")) ?? []
let user = ""

if (authToken) user = jwt_decode(authToken)

export const intialState = {
  authToken: authToken,
  user: user,
  menuList: menuList,
  sessionTimeout: false
}

export const AuthReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.LOGIN:
      return {
        ...state,
        authToken: payload.authToken,
        user: payload.user,
        menuList: payload.menuList
      }
    case ActionTypes.LOGOUT:
      client.clear()
      return {
        ...state,
        authToken: payload.authToken,
        user: payload.user,
        menuList: payload.menuList
      }
    case ActionTypes.SET_SESSION_TIMEOUT:
      return {
        ...state,
        sessionTimeout: payload
      }
    default:
      return state
  }
}
