import React from "react";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

export const ConfirmationBox = ({
  heading,
  show,
  hidePopup,
  confirmationResponse,
}) => {
  const reponseHandler = (response) => {
    confirmationResponse(response);
    hidePopup(false);
  };
  return (
    <Modal
      show={show}
      onHide={(e) => hidePopup(false)}
      size="md"
      keyboard={false}
      centered
    >
      <Modal.Body className="text-center m-2">
        <p style={{ fontSize: 22 }}>{heading}</p>
        <Button
          style={{ width: "50px" }}
          className="me-2 btn btn-orange"
          onClick={(e) => reponseHandler(true)}
        >
          Yes
        </Button>
        <Button
          style={{ width: "50px" }}
          className=" btn btn-orange"
          onClick={(e) => reponseHandler(false)}
        >
          No
        </Button>
      </Modal.Body>
    </Modal>
  );
};
