import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Spinner } from "react-bootstrap"
import ProjectsModalView from "../style"
import { api } from "../../../../../Services/api"
import ProjectDashboard from "../ProjectDashboard"
import {
  setActiveProject,
  setProjectDashboardData,
  setProjectVersionList
} from "../../../../../redux/Tracking/actionCreator"
import { Box, Breadcrumbs, Link, Typography } from "@mui/material"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import usePrevious from "../../../../../Hooks/usePrevious"
import { useFetch } from "../../../../../Hooks/useFetch"
import { key } from "../../../../../data/queryKeys"

export const ProjectView = ({
  setProjectView,
  show,
  item,
  setTaskFileType,
  setTaskFileList,
  taskfileType,
  taskfileList,
  resetState,
  taskfile,
  setTaskFile,
  fileLoading,
  setShowView,
  data,
  setViewTaskData,
  showEditTask,
  setShowEditTask,
  permission,
  fetchFileData,
  deletePermission,
  editPermission,
  allProjects
}) => {
  useEffect(() => {
    if (data?.fileurls?.length >= 0) {
      fetchFileData(item)
    }
    return () => {
      setShowEditTask("")
    }
  }, [])

  let allActiveProject = useSelector((state) => state.Tracking.allActiveProject)
  const selectedProject = useSelector((state) => state.Tracking.selectedProject)
  const allTaskTypes = useSelector((state) => state.Tracking.allTaskTypes)
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)

  const [selectedFile, setSelectedFile] = useState("")
  const [FileUrl, setlFileUrl] = useState([])
  const [fileType, setFileType] = useState([])
  const [fname, setFName] = useState([])
  const [version, setVersion] = useState("Dashboard")
  const [projectTaskList, setProjectTaskList] = useState([])

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const isProjectExist = (project) => {
    const result = allProjects.find((e) => e.trackingprojectid == project || e.number == project)
    return result !== undefined ? true : false
  }
  const previousProject = usePrevious(selectedProject) || allProjects[0]?.trackingprojectid

  const project = isProjectExist(selectedProject)
    ? selectedProject
    : previousProject || allProjects[0]?.trackingprojectid

  const { data: allMembersRes } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMembers = allMembersRes?.data ?? []

  const { data: allProjectWorkflowRes } = useFetch(
    key.get_all_workflow,
    `/trekrworkflow`,
    () => {},
    () => {},
    false,
    false
  )
  let allWorkFlow = allProjectWorkflowRes?.data?.workflowList ?? []

  const getProjectDashBoardData = async () => {
    setLoading(true)
    try {
      const url = `/projectdashboard/${trackingClientId}/${project}`
      const response = await api.get(url)
      if (response.data) {
        dispatch(setProjectDashboardData(response.data.data))
      }
    } catch (error) {
      console.error("error", error)
    }
  }

  useEffect(() => {
    dispatch(setActiveProject(item.trackingprojectid))
    reFetchTrackings(item.trackingprojectid)
    reFetchversions(item.trackingprojectid)
    getProjectDashBoardData()
    setLoading(true)
  }, [])

  const onProjectTaskSuccess = (response) => {
    setProjectTaskList(response.data)
    setLoading(false)
  }
  const onProjectVersionSuccess = (response) => {
    if (response.data) {
      dispatch(setProjectVersionList(response.data))
    }
  }
  const { refetch: reFetchTrackings } = useFetch(
    key.get_project_tasks,
    `/tracking/trackingproject/${trackingClientId}/${item.trackingprojectid}`,
    onProjectTaskSuccess,
    () => {},
    false,
    true
  )

  const { refetch: reFetchversions } = useFetch(
    key.get_project_versions,
    `/projectversion/${item.trackingprojectid}`,
    onProjectVersionSuccess,
    () => {},
    false,
    true
  )

  let taskName = allTaskTypes?.find((e) => e.id === data.taskType)
  if (taskName) taskName = taskName.label
  let assignedToName = allMembers?.find((e) => e.trackinguserid === data.assignedTo)
  if (assignedToName) assignedToName = assignedToName.personname
  let projectName = allProjects?.find((e) => e.id === data.projectid)
  if (projectName) projectName = projectName.label

  let objectValues = allWorkFlow && Object.values(allWorkFlow)

  if (objectValues?.length > 0) {
    let result = []
    objectValues.forEach((element) => {
      result.push({
        label: element[0].workFlowName,
        id: element[0].workFlowId
      })
    })
    allWorkFlow = result
  }

  let workflowOptions = []
  if (objectValues?.length > 0) {
    let result = []
    objectValues?.forEach((element) => {
      if (
        element[0].projectId === "Not Assigned" ||
        element[0].project_name === "Not Assigned" ||
        element[0].workFlowId === "default"
      ) {
        result.push({
          label: element[0].workFlowName,
          id: element[0].workFlowId
        })
      }
    })
    workflowOptions = result
  }

  function converToStr(val) {
    return typeof val === "number" ? val.toString() : val
  }

  let WorkflowName = allWorkFlow?.find((e) => converToStr(e.id) === converToStr(data.workflow))

  if (data.workflow !== "default") workflowOptions.push(WorkflowName)

  if (WorkflowName) WorkflowName = WorkflowName.label

  useEffect(() => {
    if (selectedFile?.length < 1) return
    const newImageURL = []
    selectedFile.forEach((selectedFile) => newImageURL.push(URL.createObjectURL(selectedFile)))
    setlFileUrl(newImageURL)
    setViewTaskData((prevState) => {
      return { ...prevState, fileurls: [...fname, ...data.fileurls] }
    })
  }, [selectedFile])

  var FileArrayUpload = []
  for (var i = 0; i < FileUrl?.length; i++) FileArrayUpload[i] = [fileType[i], FileUrl[i], fname[i]]

  if (taskfile) {
    var FileArray = []
    for (var i = 0; i < taskfile?.length; i++)
      FileArray[i] = [taskfileType[i], taskfile[i], taskfileList[i]]
  }
  const initial = []
  const resetImage = () => {
    setTaskFile(initial)
    setTaskFileType(initial)
    setTaskFileList(initial)
    setSelectedFile(initial)
    setFName(initial)
    setFileType(initial)
    setlFileUrl(initial)
  }

  if (allMembers?.length > 0) {
    let result = []
    allMembers.forEach((element) => {
      result.push({ label: element.personname, id: element.userId })
    })
    allMembers = result
  }

  const breadcrumbs = [
    <Link
      className='project_bread_crumb'
      key='2'
      onClick={() => {
        setProjectView("all projects")
        resetImage()
      }}
    >
      Projects
    </Link>,
    <Typography key='3' className='project_bread_crumb_text'>
      Dashboard
    </Typography>
  ]

  return (
    <ProjectsModalView>
      <Box className='mb-3'>
        <Breadcrumbs
          separator={<NavigateNextIcon className='breadcrumb_right_icon' fontSize='small' />}
          aria-label='breadcrumb'
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <div className='d-flex justify-content-between project_dashboard_header mb-1'>
        <div className='d-flex'>
          <div className='topContent d-flex justify-content-between align-items-center'>
            <div className='view'>
              <h2
                onClick={(e) => setVersion("Dashboard")}
                className={`dashboard_btn m-0 ${version === "Dashboard" ? "active" : ""}`}
              >
                Dashboard
              </h2>
              <span className='text-white mb-1'>|</span>
              <h2
                onClick={(e) => setVersion("List")}
                className={`dashboard_btn m-0 ${version === "List" ? "active" : ""}`}
              >
                List
              </h2>
              <span className='text-white mb-1'>|</span>
              <h2
                onClick={(e) => setVersion("version")}
                className={`version_btn m-0 ${version === "version" ? "active" : ""}`}
              >
                Version
              </h2>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div className='text-center mt-5 mb-5'>
          <Spinner animation='border' variant='info' />
        </div>
      ) : (
        <ProjectDashboard
          datas={allActiveProject}
          version={version}
          permission={permission}
          allProjects={allProjects}
          projectTaskList={projectTaskList}
        />
      )}
    </ProjectsModalView>
  )
}
