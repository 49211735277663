import styled from "styled-components";

const AdminDashBoardWrapper = styled.div`
     .add-user{
        display: flex;  
        justify-content: center;
        gap: 6px;
        align-items: center;
        color: ${({ theme }) => theme["themeColor"]}; 
    }
    
    .plus-button {
        cursor: pointer;
        background-color: #4EB6EE;
        border-radius: 50%;
    }

    .loginSubmitBtn{
        float: right ! important;
        padding: 5px 0px 5px 0px ;
        margin-top: 15px !important;

    }

    .form-control {
        border: 1px solid  ${({ theme }) => theme["bordercolor"]} !important;
        color: #b9b6b6 !important;
    }
    .form-control {
        &:focus {
            border: 1px solid ${({ theme }) => theme["focusColor"]} !important;
            
        }
    }
    .main-panel {
       overflow: hidden !important;
    }
    .loginformbody .form-label{
       float: left;
      } 
    
    .success-message{
        color: ${({ theme }) => theme["themeColor"]}; 
        justify-content: center;
    }



    .message-email{
        opacity: 0.5;
        display: flex;
        justify-content: center;
    }

    .email-image{
        display: flex;
        justify-content: center;
    }
   
    .email-text{
        opacity: 0.8;
        display: flex;
        justify-content: center;
    }
    .list-group {
        --bs-list-group-color:  ${({ theme }) => theme["themeColor"]};
        --bs-list-group-bg: transparent;
        --bs-list-group-border-color: transparent;
    }
    .team-name-circle {
        width: 39px;
        height: 39px;
        border-radius: 50%;
        font-weight: 100;
        padding: 8px 12px;
    }
   
`;
export default AdminDashBoardWrapper;