import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import DownloadIcon from "@mui/icons-material/Download"
import { Button, Alert, Modal, Spinner } from "react-bootstrap"
import { Forms } from "../../../Reuseable/Forms"
import DocumentCard from "./DocumentCard"
import Tables from "../../../Reuseable/Tables"
import { api } from "../../../../Services/api"
import { Validators } from "../../../../Utilities/validator"
import DocumentWrap from "./style"
import UIModal from "../../../Reuseable/Modal"
import CloseIcon from "@mui/icons-material/Close"
import { Documenttableheader, Documentbutton } from "../../../../data/constant"
import ModalImage from "react-modal-image"
import Iframe from "react-iframe"
import BarLoader from "react-spinners/ClipLoader"
import { CardView } from "../Tracking/style"
import NoDocumentFound from "./NoDocumentFound"
import { saveAs } from "file-saver"
import { toBase64 } from "../../../../Utilities/commonFunctions"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"

const Documents = ({ permission }) => {
  const editPermission = permission?.edit
  const createPermission = permission?.create
  const viewPermission = permission?.view
  const deletePermission = permission?.delete
  const authToken = useSelector((state) => state.Auth.authToken)
  const user = useSelector((state) => state.Auth.user)
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)
  let allDocuments = useSelector((state) => state.Tracking.allDocuments)
  let allfiledata = useSelector((state) => state.Tracking.allfiledata)
  const [loading, setLoading] = useState(false)
  const [fileLoading, setfileLoading] = useState(false)

  const [show, setShow] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [selected, setSelected] = useState(null)
  const [documentfilename, setdocumentfilename] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [showAlertBox, setShowAlertBox] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [changeInForm, setChangeInForm] = useState(false)
  const [viewdocument, setViewDocument] = useState(false)
  const [docfiletype, setDocfiletype] = useState("")
  const [formArr, setFormArr] = useState([])
  const [fileNameList, setFileNameLIst] = useState([])
  const [fileArr, setFileArr] = useState([])
  const [getdata, setgetData] = useState([])
  const [cardui, setCardui] = useState("cardui")
  const [FileList, setFileList] = useState([])
  const [file, setfile] = useState([])
  const [filebuffer, setFileBuffer] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [taskfileType, setTaskFileType] = useState("")
  const [deleteValue, setDeleteValue] = useState("")
  const [filebufferCard, setFileBufferCard] = useState([])
  const [fileNameCard, setFileNameCard] = useState([])
  const [taskfileTypeCard, setTaskFileTypeCard] = useState("")
  const [deleteIndex, setDeleteIndex] = useState("")
  const [fileSize, setFileSize] = useState("")
  const [error, setError] = useState("")

  const {
    refetch: getAllDocuments,
    isFetched,
    isLoading
  } = useFetch(
    key.get_all_documents,
    `/trackingdocument/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )

  useEffect(() => {
    if (!show) {
      setError(false);
      setChangeInForm(false)
      setSubmitted(false)
    }
  }, [show])

  const onCloseModal = () => {
    setFileNameLIst([])
    setSelected(null)
  }

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
    }, 6000)
  }, [showAlertBox])

  useEffect(() => {
    let formArr = [
      {
        label: "New File",
        name: "title",
        type: "text",
        halfWidth: false,
        placeholder: "Enter",
        validators: [{ check: Validators.required, message: "This Field is required" }],
        error: false,
        errormsg: "",
        value: "",
        maxLength: 100,
        show: true
      },
      {
        label: "Upload file",
        name: "uploadfile",
        type: "docuploader",
        halfWidth: false,
        placeholder: "",
        validators: [],
        error: false,
        errormsg: "",
        value: "",
        show: true,
        required: true
      }
    ]
    setFormArr(formArr)
  }, [])

  let UpdateArr = [
    {
      label: "Upload file",
      name: "uploadfile",
      type: "docuploader",
      halfWidth: false,
      placeholder: "",
      validators: [],
      error: false,
      errormsg: "",
      value: "",
      show: true,
      required: true
    }
  ]

  useEffect(() => {
    if (allDocuments && Object.keys(allDocuments).length) {
      setfile([])
      Object.keys(allDocuments).map((key) => {
        allDocuments[key].map((value) => {
          setfile((prevfiles) => [...prevfiles, value.fileurls])
        })
      })
      setgetData(allDocuments)
      setFileList(allDocuments)
      setFileBufferCard([])
      setTaskFileTypeCard([])
      setFileNameCard([])
    } else {
      setgetData([])
      setFileList([])
      setfile([])
    }
  }, [allDocuments])

  const getFileData = async (data, fileType) => {
    setfileLoading(true)
    if (data) {
      try {
        const response = await api.get(`/trackingdocument/${trackingClientId}/${data}`, {
          headers: {
            authorizationToken: localStorage.getItem("token")
          }
        })

        if (response) {
          setTaskFileType(fileType)
          setfileLoading(false)
          if (
            fileType ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            fileType === "text/csv" ||
            fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            openWordDocument(response.data.url)
          }
        }
      } catch (error) {
        setfileLoading(false)
        console.error("getDocumentFileData error")
      }
    }
  }

  const openWordDocument = (url) => {
    window.open(url, "_blank")
  }

  const handleClose = () => {
    setShow(false)
    setError("")
  }

  const handleShow = () => {
    setShow(true)
  }

  const showAddDocument = () => {
    handleShow()
    setSelected(null)
    setShowDetails(false)
    setdocumentfilename("")
  }

  const DocumentColumns = () => {
    return Documenttableheader.map((value) => <th className='text-nowrap'>{value}</th>)
  }

  const DocumentgetData = () =>
    Object.keys(getdata).map((key) => {
      return getdata[key].map((value) => (
        <tr>
          <td style={{ cursor: "pointer" }}>
            <strong className='mobHead'>Document ID :</strong>
            <span
              className='mobSubhead text-decoration-underline'
              onClick={(e) => {
                const filebuffer = allfiledata?.find((item) => item[3] === value.fileurls)
                setFileBuffer(filebuffer[0])
                if (
                  value.documentfiletype ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                  value.documentfiletype === "text/csv" ||
                  value.documentfiletype ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                  const filebuffer = allfiledata?.find((item) => item[3] === value.fileurls)
                  openWordDocument(filebuffer[0])

                  getFileData(value.documentfilename, value.documentfiletype)
                } else {
                  DocumentView(value)
                  getFileData(value.documentfilename, value.documentfiletype)
                }
              }}
            >
              {value.documentId}
            </span>
          </td>
          <td>
            <strong className='mobHead'>File name :</strong>
            <span className='mobSubhead'>{value.documentfilename}</span>
          </td>
          <td>
            <strong className='mobHead'>File type :</strong>
            <span className='mobSubhead'>{value.documentfiletype}</span>
          </td>
          <td>
            <strong className='mobHead'>Created on :</strong>
            <span className='mobSubhead'>{value.createddate}</span>
          </td>
          <td>
            <strong className='mobHead'>Updated on :</strong>
            <span className='mobSubhead'>{value.updateddate}</span>
          </td>
          <td>
            <strong className='mobHead'>Created by :</strong>
            <span className='mobSubhead'>{value.createdby}</span>
          </td>
          <td className=' actionBtns'>
            {editPermission && (
              <Button size='sm' variant='link' className='text-decoration-none pe-1 ps-1'>
                <img
                  src={Documentbutton[0].icon}
                  alt='icon'
                  onClick={() => {
                    DocumentEdit(value)
                    getFileData(value.documentfilename)
                  }}
                />
              </Button>
            )}
            {deletePermission && (
              <Button size='sm' variant='link' className='text-decoration-none pe-1 ps-1'>
                <img
                  src={Documentbutton[1].icon}
                  alt='icon'
                  onClick={() => {
                    setDeleteValue(value.documentId)
                    setDeleteModal(true)
                  }}
                />
              </Button>
            )}
            <DownloadIcon
              onClick={() => {
                triggerdownload(value.documentfilename, value.documentfiletype, value)
              }}
              style={{ color: "#4EB6EE", cursor: "pointer" }}
            />
          </td>
        </tr>
      ))
    })

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]
  const today = new Date()
  const yyyy = today.getFullYear()
  let mm = today.getMonth()
  let dd = today.getDate()
  let monthName = monthNames[mm]
  let hours = (today.getHours() < 10 ? "0" : "") + today.getHours()
  let minutes = (today.getMinutes() < 10 ? "0" : "") + today.getMinutes()
  let TimeType = ""

  if (hours <= 11) {
    TimeType = "AM"
  } else {
    TimeType = "PM"
  }
  if (hours > 12) {
    hours = hours - 12
  }
  if (hours === 0) {
    hours = 12
  }

  if (dd < 10) dd = "0" + dd
  if (mm < 10) mm = "0" + mm

  const formattedDate =
    monthName + " " + dd + "," + yyyy + " " + hours + ":" + minutes + " " + TimeType

  const SubmitForm = async (form, valid) => {
    if (fileNameList && fileNameList.length > 0) {
      setError("")
      if (valid) {
        setLoading(true)
        let Obj = {
          createddate: formattedDate,
          documentfilename: form.title,
          documentfiletype: fileArr.type,
          docfilesize: fileSize,
          updateddate: formattedDate,
          createdby: user.personname,
          fileurls: fileArr.name,
          filedata: await toBase64(fileArr)
        }

        api
          .post("/trackingdocument", Obj, {
            headers: {
              authorizationtoken: ` ${authToken}`
            }
          })
          .then(function (response) {
            setLoading(false)
            if (response.status === 200) {
              setSubmitted(true)
              handleClose()
              setShowAlertBox(true)
              getAllDocuments()
              setAlertMessage("Document Created Successfully")
              setFileNameLIst([])
            }
          })
          .catch((error) => {
            setLoading(false)
            return error
          })
      }
    } else {
      setError("Please upload the Document")
    }
  }

  let isUpdating = false

  const handleUpdate = async (valid) => {
    if (fileNameList && fileNameList.length > 0) {
      setError("")
      if (valid && !isUpdating) {
        isUpdating = true
        const DocumentObject = {
          documentId: selected.documentId,
          document: {
            createdby: user.personname,
            documentfilename: documentfilename,
            createddate: selected.createddate,
            updateddate: formattedDate,
            documentId: selected.documentId,
            documentfiletype: fileArr.type,
            docfilesize: fileSize,
            fileurls: fileArr.name,
            filedata: await toBase64(fileArr)
          }
        }
        api
          .patch("/trackingdocument", DocumentObject, {
            headers: {
              authorizationtoken: `${authToken}`
            }
          })
          .then((response) => {
            if (response) {
              handleClose()
              setSelected(null)
              getAllDocuments()
            }
          })
          .catch((error) => {
            return error
          })
      }
    } else {
      setError("Please upload the Document")
    }
  }

  const DocumentEdit = (value) => {
    setSelected(value)
    setShowDetails(false)
    if (value !== null) {
      setdocumentfilename(
        value.documentfilename !== null || value.document !== undefined
          ? value.documentfilename
          : ""
      )
      handleShow()
    }
  }

  const DocumentView = (value) => {
    setViewDocument(true)
    if (value !== null) {
      setdocumentfilename(
        value.documentfilename !== null || value.document !== undefined
          ? value.documentfilename
          : ""
      )
    }
  }

  const deleteDocument = (documentId) => {
    setDeleteLoading(true)

    api
      .delete(`/trackingdocument/document/${documentId}`, {
        headers: {
          authorizationtoken: ` ${authToken}`
        }
      })
      .then((response) => {
        if (response.data.status) {
          setShowAlertBox(true)
          setAlertMessage("Document Deleted Successfully")
          setDeleteLoading(false)
          setDeleteModal(false)
          getAllDocuments()
        }
      })
      .catch((error) => {
        return error
      })
  }

  const triggerdownload = async (fileName, type, value) => {
    const filebuffer = allfiledata?.find((item) => item[3] === value.fileurls)
    if (filebuffer) {
      let FileName =
        type === "application/pdf"
          ? fileName + ".pdf"
          : type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ? fileName + ".docx"
          : type === "text/csv" ||
            type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ? fileName + ".xlsx"
          : fileName + ".png"
      saveAs(filebuffer[0], FileName)
      setTaskFileType("")
      setFileBuffer("")
    }
  }

  return (
    <>
      <DocumentWrap>
        {loading ? (
          <>
            <div className='text-center mt-5 mb-5'>
              <Spinner animation='border' variant='info' />
            </div>
          </>
        ) : (
          <>
            {file.length === 0 && isFetched ? (
              <>
                <NoDocumentFound showAddDocument={showAddDocument} />
              </>
            ) : (
              <>
                <>
                  {showAlertBox ? (
                    <Alert
                      variant='success'
                      closeVariant='white'
                      className='text-center alert-sucess'
                      dismissible
                      onClick={(e) => setShowAlertBox(false)}
                    >
                      {alertMessage}
                    </Alert>
                  ) : (
                    ""
                  )}

                  <CardView>
                    <div
                      className='topContent'
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <div className='view'>
                        <h2
                          onClick={(e) => setCardui("cardui")}
                          className={`Cardbtn ${cardui === "cardui" ? "active" : ""}`}
                        >
                          Card
                        </h2>
                        <span>|</span>
                        <h2
                          onClick={(e) => setCardui("List")}
                          className={`Listbtn ${cardui === "List" ? "active" : ""}`}
                        >
                          List
                        </h2>
                      </div>
                      {createPermission && (
                        <div className='addTask'>
                          <button
                            style={{
                              color: "#FFFFFF",
                              fontSize: "15px",
                              padding: "5px 12px",
                              backgroundColor: "#4EB6EE",
                              border: "none",
                              outline: "none",
                              borderRadius: "5px"
                            }}
                            onClick={(e) => {
                              showAddDocument()
                            }}
                          >
                            + Add new file
                          </button>
                        </div>
                      )}
                    </div>
                  </CardView>

                  {isLoading ? (
                    <div className='text-center mt-5 mb-5'>
                      <Spinner animation='border' variant='info' />
                    </div>
                  ) : (
                    <></>
                  )}

                  {cardui === "cardui" ? (
                    <DocumentCard
                      FileList={FileList}
                      filebuffer={filebuffer}
                      file={file}
                      deleteDocument={deleteDocument}
                      setLoading={setLoading}
                      taskfileTypeCard={taskfileTypeCard}
                      fileNameCard={fileNameCard}
                      filebufferCard={filebufferCard}
                      setDeleteModal={setDeleteModal}
                      setDeleteValue={setDeleteValue}
                      setTaskFileType={setTaskFileType}
                      setFileBuffer={setFileBuffer}
                      setViewDocument={setViewDocument}
                      setDeleteIndex={setDeleteIndex}
                      allfiledata={allfiledata}
                      deletePermission={deletePermission}
                    />
                  ) : (
                    <>
                      <Tables
                        setProjectTable={true}
                        columns={DocumentColumns()}
                        data={Object.keys(getdata).length > 0 ? DocumentgetData() : ""}
                        showInfiniteScroll={false}
                        loading={loading}
                      />
                    </>
                  )}
                </>
                <Modal
                  show={viewdocument}
                  centered
                  onHide={(e) => {
                    setViewDocument(false)
                    setTaskFileType("")
                    setFileBuffer("")
                  }}
                  size='lg'
                  keyboard={false}
                >
                  <Modal.Header>
                    <Modal.Title>View Document</Modal.Title>
                    <CloseIcon
                      className='close-icon'
                      onClick={() => {
                        setViewDocument(false)
                        setTaskFileType("")
                        setFileBuffer("")
                      }}
                      style={{ cursor: "pointer", fontSize: "30px" }}
                    />
                  </Modal.Header>
                  <Modal.Body>
                    {fileLoading ? (
                      <>
                        <div className='text-center mt-5 mb-5'>
                          <Spinner animation='border' variant='info' />
                        </div>
                      </>
                    ) : (
                      <div className='modal-image d-flex'>
                        {taskfileType === "application/pdf" ? (
                          <>
                            <Iframe
                              className='img-fluid'
                              width='100%'
                              styles={{ height: "75vh" }}
                              scrolling='no'
                              frameBorder='0'
                              src={filebuffer}
                            />
                          </>
                        ) : (
                          <div style={{ width: "100%" }}>
                            <ModalImage
                              className='img-fluid'
                              small={filebuffer}
                              large={filebuffer}
                              hideZoom={true}
                              showRotate={true}
                              styles={{ height: "83vh" }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Modal.Body>
                </Modal>
                <Modal
                  show={deleteModal}
                  onHide={() => {
                    setDeleteModal(false)
                    setDeleteValue("")
                  }}
                  backdrop='static'
                  aria-labelledby='contained-modal-title-vcenter'
                  centered
                >
                  <div className='p-2' style={{ color: "#4EB6EE" }}>
                    <CloseIcon
                      className=' mt-1'
                      onClick={() => {
                        setDeleteModal(false)
                        setDeleteValue("")
                      }}
                      style={{
                        color: "#4EB6EE",
                        cursor: "pointer",
                        float: "right"
                      }}
                    />
                    <div className='p-2 py-4'>
                      <center>
                        <p className='mb-4'>Are you sure you want to delete this document?</p>
                        <Button
                          className='deletebtn mx-3 my-2'
                          style={{
                            backgroundColor: "#4EB6EE",
                            border: "none",
                            width: "100px",
                            height: "40px"
                          }}
                          onClick={() => {
                            setDeleteModal(false)
                            setDeleteValue("")
                          }}
                        >
                          No
                        </Button>

                        <Button
                          className='deletebtn mx-3 my-2'
                          onClick={() => {
                            deleteDocument(deleteValue)
                          }}
                          style={{
                            background: deleteLoading ? "#FFFFFF" : "#4EB6EE",
                            color: deleteLoading ? "#808080" : "#FFFFFF",
                            border: deleteLoading ? "2px solid #FFFFFF" : "2px solid #4EB6EE",
                            width: "100px",
                            height: "40px"
                          }}
                        >
                          {deleteLoading ? (
                            <BarLoader
                              color='#808080'
                              className='bar-loader'
                              size={14}
                              role='status'
                              aria-hidden='true'
                            />
                          ) : (
                            <></>
                          )}
                          Yes{""}
                        </Button>
                      </center>
                    </div>
                  </div>
                </Modal>
              </>
            )}
            <UIModal
              showModal={show}
              setShowModal={setShow}
              onCloseModal={onCloseModal}
              document={true}
              title={selected === null ? "Add Document" : "Update Document"}
            >
              {selected === null ? (
                <>
                  {error && (
                    <Alert className='text-center py-1' variant={"danger"}>
                      {error}
                    </Alert>
                  )}
                  <Forms
                    title={""}
                    className='p-2'
                    formArr={formArr}
                    showFileupload={true}
                    setDocfiletype={setDocfiletype}
                    FileName={setFileNameLIst}
                    FileArr={setFileArr}
                    submitBtn={"Create"}
                    onSubmit={SubmitForm}
                    loading={loading}
                    submitted={submitted}
                    changeInForm={changeInForm}
                    setChangeInForm={setChangeInForm}
                    setFileSize={setFileSize}
                  />
                </>
              ) : (
                <>
                  <div className='px-3 py-2'>
                    {error && (
                      <Alert className='text-center py-1' variant={"danger"}>
                        {error}
                      </Alert>
                    )}
                    <h6>File Name:</h6>
                    <input
                      type='text'
                      value={documentfilename}
                      onChange={(e) => setdocumentfilename(e.target.value)}
                      style={{ fontSize: 15 }}
                      maxLength='80'
                      className='form-control ps-2 '
                    />
                  </div>
                  <Forms
                    title={""}
                    className='p-2'
                    formArr={UpdateArr}
                    showFileupload={true}
                    setDocfiletype={setDocfiletype}
                    FileName={setFileNameLIst}
                    FileArr={setFileArr}
                    submitBtn={"Update"}
                    onSubmit={handleUpdate}
                    loading={loading}
                    submitted={submitted}
                    changeInForm={changeInForm}
                    setChangeInForm={setChangeInForm}
                    setFileSize={setFileSize}
                  />
                </>
              )}
            </UIModal>
          </>
        )}
      </DocumentWrap>
    </>
  )
}
export default Documents
