import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Alert, Spinner } from "react-bootstrap"
import Tables from "../../../Reuseable/Tables"
import "react-confirm-alert/src/react-confirm-alert.css"
import {
  setAllProjects,
  setProjectModelData,
  setNewProjectModel
} from "../../../../redux/Tracking/actionCreator"
import { v4 as uuid } from "uuid"
import { useSessionStorage } from "react-use"
import ProjectsModalView from "../Projects/style"
import ProjectModelCard from "./ProjectModelCard"
import Styled from "styled-components"
import AddNewProjectModel from "./AddProjectModal"
import EditProjectModel from "./EditProjectModel"
import useTrackingItemData from "../../../../Hooks/useTrackingItemData"
import ProjectModelView from "./style"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"
import { api } from "../../../../Services/api"

export default function ProjectModel({ permission }) {
  const editPermission = permission?.edit

  const dispatch = useDispatch()

  let allBacklogs = useSelector((state) => state.Tracking.allBacklogs)
  let newProjectModel = useSelector((state) => state.Tracking.newProjectModelView)
  let allProjectModelData = useSelector((state) => state.Tracking.allProjectModelData)
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)

  const user = useSelector((state) => state.Auth.user)
  const authToken = useSelector((state) => state.Auth.authToken)
  const filters = useSelector((state) => state.Tracking.filters)
  const [loading, setLoading] = useState(false)
  const [refreshLoading, setRefreshLoading] = useState(false)
  const [showAlertBox, setShowAlertBox] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")

  const [trackingItemData, setTrackingItemData] = useTrackingItemData()
  const [updatedData, setUpdatedData] = useSessionStorage()
  const [deleteModelId, setDeleteModelId] = useState()
  const view = useSelector((state) => state.Tracking.projectView)

  const selected_menu = "Tablebacklogs"

  const { data: allProjectsRes } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )

  let allProjects = allProjectsRes?.data?.Items ?? []

  const updateAllProject = (newProject) => {
    dispatch(setAllProjects([...allProjects, newProject]))
    return [...allProjects, newProject]
  }

  const deleteConfimrationHandler = (response) => {
    if (response) {
      api
        .delete("/projectmodellist/" + trackingClientId + "/" + deleteModelId)
        .then(function (response) {
          if (response.status === 200) {
            setShowAlertBox(true)
            let index = allProjectModelData.findIndex(
              (element) => element.ProjectModelId === deleteModelId
            )
            if (index >= 0) {
              allProjectModelData.splice(index, 1)
            }
            setAlertMessage("Selected item has been deleted successfully")
          }
        })
    }
  }

  const fetchProjectModelList = () => {
    setLoading(true)
    let url = `/projectmodellist/${trackingClientId}`
    api
      .get(url, {
        headers: {
          authorizationtoken: `${authToken}`,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"
        }
      })
      .then(function (response) {
        if (response.data.status === true) {
          if (selected_menu === "Tablebacklogs") {
            if (response.data.data) {
              dispatch(setProjectModelData([...response.data.data]))
              if (allBacklogs.length > 0) {
                dispatch(setProjectModelData(response.data.data))
              } else dispatch(setProjectModelData(response.data.data))
            }
          }
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error("fetchProjectModelList  error", error)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchProjectModelList()
  }, [])

  const [columns, setColumns] = useState()

  const includeUuid = (data) => {
    return Object.entries(data).map(([k, d]) => {
      return { ...d, id: uuid() }
    })
  }

  const [showEditTask, setShowEditTask] = useState("")
  const [viewTaskData, setViewTaskData] = useState({})
  const [taskHistory, setHistory] = useState("")

  useEffect(() => {
    setViewTaskData((prevState) => {
      return {
        ...prevState,
        ModelTitle: trackingItemData.ModelTitle,
        ModelDescription: trackingItemData.ModelDescription
      }
    })
    setUpdatedData((prevState) => {
      return {
        ...prevState,
        ModelTitle: trackingItemData.ModelTitle,
        ModelDescription: trackingItemData.ModelDescription
      }
    })
  }, [trackingItemData])

  const isLoading = (columnName, ans) => {
    const newColumns = Object.entries(columns).map(([key, value]) => {
      if (value.name === columnName) {
        return {
          [uuid()]: { ...value, loader: ans }
        }
      }
      return { [uuid()]: value }
    })

    let updatedColumns = {}

    newColumns.forEach((element, index) => {
      const [key] = Object.keys(element)
      const [value] = Object.values(element)
      updatedColumns[key] = value
    })
    setColumns(updatedColumns)
  }
  const taskViewRender = (value) => {
    dispatch(setNewProjectModel(value))
  }

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
    }, 2500)
  }, [showAlertBox])

  return (
    <>
      {showAlertBox && (
        <Alert
          variant='success'
          className='text-center alert-sucess alert mt-3 mb-1'
          dismissible
          closeVariant='white'
          onClick={(e) => setShowAlertBox(false)}
        >
          {alertMessage}
        </Alert>
      )}
      <ProjectModelView>
        {newProjectModel === "New ProjectModal" ? (
          <AddNewProjectModel
            setShowAlertBox={setShowAlertBox}
            setAlertMessage={setAlertMessage}
            setTaskview={taskViewRender}
          />
        ) : newProjectModel === "View ProjectModal" ? (
          <EditProjectModel
            showEditTask={showEditTask}
            setTaskview={taskViewRender}
            setViewTaskData={setViewTaskData}
            setShowEditTask={setShowEditTask}
            item={trackingItemData}
            data={viewTaskData}
            setHistory={setHistory}
            editPermission={editPermission}
            setUpdatedData={setUpdatedData}
            updatedData={updatedData}
            setTrackingItemData={setTrackingItemData}
          />
        ) : !loading && newProjectModel === "All ProjectModel" ? (
          <ProjectsModalView>
            {allProjectModelData.length > 0 ? (
              <ProjectModelWrap>
                <div className='table-view-card rounded-3 mt-3'>
                  {allProjectModelData.map((item, index, allProjectModelData) => {
                    let borderLine = index !== allProjectModelData.length - 1 ? true : false
                    return (
                      <ProjectModelCard
                        item={item}
                        key={index}
                        setTaskview={taskViewRender}
                        borderLine={borderLine}
                        deleteConfimrationHandler={deleteConfimrationHandler}
                        setDeleteModelId={setDeleteModelId}
                        setTrackingItemData={setTrackingItemData}
                      />
                    )
                  })}
                </div>
              </ProjectModelWrap>
            ) : (
              <Tables
                data={[]}
                setProjectTable={true}
                notfound={"No ProjectModel Data found!"}
                loading={loading}
                refreshLoading={refreshLoading}
              />
            )}
          </ProjectsModalView>
        ) : (
          <div className='text-center mt-5 mb-5'>
            <Spinner animation='border' variant='info' />
          </div>
        )}
      </ProjectModelView>
    </>
  )
}

const ProjectModelWrap = Styled.div`
    .table-view-card{
        color: white;
        padding:10px; 
        min-height:80vh;     
    }
`
