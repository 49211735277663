import styled from "styled-components";

export const FormsWrapper = styled.div`
    .public-DraftStyleDefault-block {
        border: 0px inset rgba(255, 255, 255, .3);
        border-radius: 5px;
    }
    .backlogs_section_header{
        display: flex;
        align-items: end;
    }
    .backlogs_radio_header{
        display: flex;
        align-items: baseline;
        gap: 2rem;
    }
    .start_date .ant-picker-input > input{
        color: ${({ theme }) => theme["themeColor"]};
    }
    .start_date .ant-picker-dropdown .ant-picker-content td{
        color: black !important;
        background-color: transparent !important;
    }
    .end_date .ant-picker-input > input{
        color: ${({ theme }) => theme["themeColor"]};
    }
    .end_date .ant-picker-dropdown .ant-picker-content td{
        color: black !important;
        background-color: transparent !important;
    }
    .start_date .ant-picker-input input::placeholder{
        color: ${({ theme }) => theme["themeColor"]};
        opacity: 0.5;
    }
    .end_date .ant-picker-input input::placeholder{
        color: ${({ theme }) => theme["themeColor"]};
        opacity: 0.5;
    }
    .template_conatiner{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }
    .template_content_box{
        width: 235px;
        min-height: 250px;
        background-color: #fff;
        padding: 8px;
    }
    /* input placeholder */
    input::placeholder {
        color: hsl(0, 0%, 50%);
    }
    .developer_content{
        font-weight: 650;
        font-size: 10px;
        margin-bottom: 0rem;
    }
    .developer_sub_content{
        font-size: 8px;
        padding-left: 1rem !important;
    }
    .password_view_icon {
        position: absolute;
        right: 0;
        top: 0;
        translate: -5px 3px;
        cursor: pointer;
    }
    .for_template_description{
        padding-top: 1rem;
    }
    .for_template_description .public-DraftEditor-content {
      min-height: 50vh;
    }
    .rdw-editor-wrapper {
        box-sizing: content-box;
    }
    .developer_notes_content{
        font-size: 10px !important; 
        font-weight: 700;
        p{
            color: black;
            background: unset;
            margin-bottom: 0rem !important;
            span{
                font-size: 10px !important; 
                color: black !important;
                background: transparent !important;
            }
        }
        ul {
            color: black;
            background: unset;
            padding-left: 1rem;
        }
        li{
            font-weight: 400 !important;
            font-size: 8px !important; 
            color: black;
            background: unset;
            span{
                font-size: 8px !important; 
                color: black !important;
                background: transparent !important;
            }
        }
    }
    .backlogs_go_btn{
        width: 155px;
        height: 35px;
        background: #4EB6EE 0% 0% no-repeat padding-box !important;
        border-radius: 5px;
        opacity: 1;
    }
    .form_template_view_more{
        width: 200px;
        display: flex;
        align-items: end;
        color: #fff;
        cursor: pointer;
    }
    .view_more_container{
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
    }
    .start_date .ant-picker-panel {
        background-color: red !important;
        width:20% !important;
    }
    .end_date .ant-picker-panel {
        background-color: red !important;
    }
`;
