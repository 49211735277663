import Styled from "styled-components";

const NewProjectFormWrap = Styled.div`
.loginSubmitBtn{
    width: 155px;
}
.form-select{
          border: 1px solid  ${({ theme }) => theme["normalColor"]};
}

.cancelbtn{
    width: 155px;
}
.add-project-tittle{
    margin-right: 20px;
}
input#title, input#Budget{
    ::placeholder{
        color: ${({ theme }) => theme["gray-color"]} !important;
    }
}
.jWLaqw {
    border: 1px solid  ${({ theme }) => theme["normalColor"]} !important;
}
.bsVMGJ {
    border: 1px solid  ${({ theme }) => theme["normalColor"]} !important;
}
.multi-select{  
  .css-1s2u09g-control, .css-1pahdxg-control{
        background-color: ${({ theme }) => theme["tableViewCard"]};       
        border: 1px solid  ${({ theme }) => theme["normalColor"]};
        border-radius: 5px;
        color: ${({ theme }) => theme["inputColor"]}!important;
        box-shadow:unset !important;
  }
}
.css-319lph-ValueContainer {
    .css-1pndypt-Input{
        color: ${({ theme }) => theme["inputColor"]}!important;
    }   
    .css-1rhbuit-multiValue{
        background-color:hsl(0deg 0% 90% / 0%) !important;
    }    
}
.css-6j8wv5-Input{
        color: ${({ theme }) => theme["inputColor"]}!important;
}
#react-select-2-listbox{
        background-color: ${({ theme }) => theme["tableViewCard"]} !important;       
        color: ${({ theme }) => theme["inputColor"]}!important;
        border: 1px solid  ${({ theme }) => theme["normalColor"]};        
}

.resetbtn{
    margin-right: 10px;
    background: #3E3F3E;
}
.btn:first-child:active{
    background: #3E3F3E;
}
.ant-picker{
    background-color: ${({ theme }) => theme["tableViewCard"]} !important;
    color: ${({ theme }) => theme["inputColor"]}!important;
    border: 1px solid  ${({ theme }) => theme["normalColor"]};
    
   
}
.ant-picker-focused{
        border: 1px solid  ${({ theme }) => theme["focusColor"]};
        box-shadow:unset !important;
}
.ant-picker-input > input{
    color: ${({ theme }) => theme["inputColor"]}!important;
    ::placeholder{
        color: ${({ theme }) => theme["gray-color"]} !important;
        font-size:17px;
    }
    
}
span.ant-picker-suffix{
        color: ${({ theme }) => theme["gray-color"]} !important;
}
@media (min-width:411px)
{
    .loginSubmitBtn{width: 155px;
        margin-left: 20px;
    }
    .cancelbtn{
        margin-left: 20px;
    }
}

@media (max-width: 588px){
    .resetbtn{
        margin-left: 10px;
        margin-bottom: 10px;
    }
}

`;

export default NewProjectFormWrap;