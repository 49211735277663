import { ActionTypes } from "./actions"

export const intialState = {
  selectedId: "",
  showAlert: false,
  startAllTrackingId: null,
  startMyTrackingId: null,
  planInformation: {},
  alertMessage: "",
  projects: [],
  allMyChartsData: [],
  allArchiveTableData: [],
  projectModel: [],
  projectsLoading: false,
  allTrackings: [],
  myTrackings: [],
  allApprovalTrackings: [],
  allFlowStatus: [],
  allWorkFlow: [],
  allMembers: [],
  allDocuments: [],
  allfiledata: [],
  allfilename: [],
  allTaskTypes: [],
  notifications: [],
  allReportTasksData: [],
  allTemplates: [],
  newWorkflowModelView: [],
  newDocumentModelView: "doc",
  filters: {
    projects: [],
    status: [],
    reportedBy: [],
    assignedTo: [],
    priority: [],
    type: []
  },
  inputFormArr: [],
  isFiltered: false,
  fetchedAllTrackings: false,
  fetchedMyTrackings: false,
  fetchedAllMembers: false,
  selectedProject: "",
  myTaskSelectedProject: "",
  allTaskSelectedProject: "",
  projectView: "all projects",
  myTaskView: "AllTask",
  allTaskView: "AllTask",
  approvalView: "AllTask",
  addPriorityView: "All Priority",
  newProjectModelView: "All ProjectModel",
  addNewTemplateView: "All Templates",
  allBacklogsView: "All Backlogs",
  workflowView: false,
  globalSearch: "",
  globalSearchResult: [],
  allKpiPointsData: [],
  allMoveTasksData: [],
  allProjectModelData: [],
  allTimeloggerData: [],
  allTemplateTableData: [],
  allPriorityData: [],
  allProjectVersion: [],
  allProjectDashboard: [],
  allActiveProject: [],
  allProjectDashboardData: [],
  trackingItemData: JSON.parse(sessionStorage?.getItem("trackingItemData")) ?? {}
}

export const TrackingReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.RESET_TRACKING:
      sessionStorage.removeItem("trackingItemData")
      return {
        ...state,
        ...intialState
      }
    case ActionTypes.SET_SELECTED_ID:
      return {
        ...state,
        selectedId: payload
      }
    case ActionTypes.SET_ALL_TRACKINGS:
      return {
        ...state,
        allTrackings: payload
      }
    case ActionTypes.SET_SHOW_ALERT:
      return {
        ...state,
        showAlert: payload
      }
    case ActionTypes.SET_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: payload
      }
    case ActionTypes.SET_ALL_MOVE_TASKS:
      return {
        ...state,
        allMoveTasksData: payload
      }
    case ActionTypes.SET_PROJECTS:
      return {
        ...state,
        projects: payload
      }
    case ActionTypes.SET_DOCUMENT_LIST:
      return {
        ...state,
        allDocuments: payload
      }
    case ActionTypes.SET_TEMPLATES_DATA:
      return {
        ...state,
        allTemplates: payload
      }
    case ActionTypes.SET_NEW_TEMPLATES_FORM:
      return {
        ...state,
        addNewTemplateView: payload
      }
    case ActionTypes.SET_ALL_BACKLOGS_TASKS_VIEW:
      return {
        ...state,
        allBacklogsView: payload
      }
    case ActionTypes.SET_ALL_TEMPLATES_DATA:
      return {
        ...state,
        allTemplateTableData: payload
      }
    case ActionTypes.SET_REPORTSTASKS_DATA:
      return {
        ...state,
        allReportTasksData: payload
      }
    case ActionTypes.SET_DOCUMENT_DATA:
      return {
        ...state,
        allfiledata: payload
      }
    case ActionTypes.SET_MY_CHART_DATA:
      return {
        ...state,
        allMyChartsData: payload
      }
    case ActionTypes.SET_ARCHIVE_TABLE_DATA:
      return {
        ...state,
        allArchiveTableData: payload
      }
    case ActionTypes.SET_DOCUMENT_NAME_LIST:
      return {
        ...state,
        allfilename: payload
      }
    case ActionTypes.SET_REPORTSWEEKLY_DATA:
      return {
        ...state,
        reportWeeklyHeaderNames: payload
      }
    case ActionTypes.SET_PROJECTS_LOADING:
      return {
        ...state,
        projectsLoading: payload
      }
    case ActionTypes.SET_FETCHED_LIST:
      return {
        ...state,
        fetchedList: payload
      }
    case ActionTypes.SET_FLOW_STATUS_LIST:
      return {
        ...state,
        allFlowStatus: payload
      }
    case ActionTypes.SET_WORK_FLOW_LIST:
      return {
        ...state,
        allWorkFlow: payload
      }
    case ActionTypes.SET_MEMBERS:
      return {
        ...state,
        allMembers: payload
      }
    case ActionTypes.SET_TASK_TYPES:
      return {
        ...state,
        allTaskTypes: payload
      }
    case ActionTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload
      }
    case ActionTypes.SET_APPROVAL_TRACKING_LIST:
      return {
        ...state,
        allApprovalTrackings: payload
      }
    case ActionTypes.SET_FETCHED_APPROVAL_LIST:
      return {
        ...state,
        fetchedApprovalList: payload
      }
    case ActionTypes.SET_KPIPOINTS_DATA:
      return {
        ...state,
        allKpiPointsData: payload
      }
    case ActionTypes.SET_PROJECT_VERSION_DATA:
      return {
        ...state,
        allProjectVersion: payload
      }
    case ActionTypes.SET_REPORT_TIMELOGGER_DATA:
      return {
        ...state,
        allTimeloggerData: payload
      }
    case ActionTypes.SET_FILTERS:
      if (
        (payload.assignedTo?.length ||
          payload.priority?.length ||
          payload.projects?.length ||
          payload.reportedBy?.length ||
          payload.status?.length ||
          payload.type?.length) === 0
      ) {
        return {
          ...state,
          filters: payload,
          isFiltered: false
        }
      } else {
        return {
          ...state,
          filters: payload,
          isFiltered: true
        }
      }
    case ActionTypes.SET_START_ALL_TREK_ID:
      return {
        ...state,
        startAllTrackingId: payload
      }
    case ActionTypes.SET_INPUTFORMDATA:
      return {
        ...state,
        inputFormArr: payload
      }
    case ActionTypes.SET_START_MY_TREK_ID:
      return {
        ...state,
        startMyTrackingId: payload
      }
    case ActionTypes.SET_MY_TRACKINGS:
      return {
        ...state,
        myTrackings: payload
      }
    case ActionTypes.SET_FETCHED_ALL_TRACKINGS_LIST:
      return {
        ...state,
        fetchedAllTrackings: payload
      }
    case ActionTypes.SET_FETCHED_MY_TRACKINGS_LIST:
      return {
        ...state,
        fetchedMyTrackings: payload
      }
    case ActionTypes.SET_PLAN_INFO:
      return {
        ...state,
        planInformation: payload
      }
    case ActionTypes.SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: payload
      }
    case ActionTypes.SET_MY_TASK_PROJECT:
      return {
        ...state,
        myTaskSelectedProject: payload
      }
    case ActionTypes.SET_ALL_TASK_PROJECT:
      return {
        ...state,
        allTaskSelectedProject: payload
      }
    case ActionTypes.SET_MY_TASK_VIEW:
      return {
        ...state,
        myTaskView: payload
      }
    case ActionTypes.SET_ALL_TASK_VIEW:
      return {
        ...state,
        allTaskView: payload
      }
    case ActionTypes.SET_ALL_PRIORITY_VIEW:
      return {
        ...state,
        addPriorityView: payload
      }
    case ActionTypes.SET_NEW_PROJECT_MODEL:
      return {
        ...state,
        newProjectModelView: payload
      }
    case ActionTypes.SET_NEW_WORKFLOW_MODEL:
      return {
        ...state,
        newWorkflowModelView: payload
      }
    case ActionTypes.SET_NEW_DOCUMENT_MODEL:
      return {
        ...state,
        newDocumentModelView: payload
      }
    case ActionTypes.SET_APPROVAL_VIEW:
      return {
        ...state,
        approvalView: payload
      }
    case ActionTypes.SET_PROJECT_VIEW:
      return {
        ...state,
        projectView: payload
      }
    case ActionTypes.SET_PROJECTS_MODELS:
      return {
        ...state,
        projectModel: payload
      }
    case ActionTypes.SET_WORKFLOW_VIEW:
      return {
        ...state,
        workflowView: payload
      }
    case ActionTypes.SET_MAIN_PANEL_HEIGHT:
      return {
        ...state,
        mainPanelHeight: payload
      }
    case ActionTypes.SET_ALL_MEMBERS_FETCHED:
      return {
        ...state,
        fetchedAllMembers: payload
      }
    case ActionTypes.SET_GLOBAL_SEARCH:
      return {
        ...state,
        globalSearch: payload
      }
    case ActionTypes.SET_GLOBAL_SEARCH_RESULT:
      return {
        ...state,
        globalSearchResult: payload
      }
    case ActionTypes.SET_TRACKING_ITEM_DATA:
      return {
        ...state,
        trackingItemData: payload
      }
    case ActionTypes.SET_PROJECTMODELDATA:
      return {
        ...state,
        allProjectModelData: payload
      }
    case ActionTypes.SET_PROJECT_DASHBOARD_DATA:
      return {
        ...state,
        allProjectDashboard: payload
      }
    case ActionTypes.SET_ALL_PRIORITY_DATA:
      return {
        ...state,
        allPriorityData: payload
      }
    case ActionTypes.SET_ACTIVE_PROJECT:
      return {
        ...state,
        allActiveProject: payload
      }
    case ActionTypes.SET_ALL_PROJECT_DASHBOARD_DATA:
      return {
        ...state,
        allProjectDashboardData: payload
      }
    default:
      return state
  }
}
