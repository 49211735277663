import React, { useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Searchbox from "../../../../Reuseable/Searchbox";
import backlogs_priority from "../../../../../assets/images/backlogs-priority.svg"
import backlogs_search from "../../../../../assets/images/backlogs-search.svg"
import { style } from "@mui/system";
import { useSelector } from "react-redux";

export const PriorityListColumns = ({
  handleSearch,
  trackingSortings,
  setTrackingSorting,
  handleCheckboxChange,
  handleHeaderCheckboxChange,
  selectedRows,
  selectAll
}) => {
  const allBacklogsHeaderNames = useSelector((state) => state.Tracking.allBacklogsHeaderNames);
  const applyTrackingNumberSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "trackingid",
        projectNameSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, trackingNumberSorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, trackingNumberSorting: "down" };
      });
  };

  const applyProjectNumberSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "projectname",
        trackingNumberSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, projectNameSorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, projectNameSorting: "down" };
      });
  };

  const applyReportedBySorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "reportedby",
        trackingNumberSorting: "",
        projectNameSorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, reportedBySorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, reportedBySorting: "down" };
      });
  };

  const applyPrioritySorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "reporteddate",
        trackingNumberSorting: "",
        projectNameSorting: "",
        reportedBySorting: "",
        assignedToSorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, prioritySorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, prioritySorting: "down" };
      });
  };

  const applyAssignToSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "priority",
        trackingNumberSorting: "",
        projectNameSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, assignedToSorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, assignedToSorting: "down" };
      });
  };


  return (
    <>
      <th className="text-center"> Priority ID </th>
      <th className="text-center" >Priority name</th>
      <th className="text-center" >Version number</th>
      <th className="text-center" >Project name</th>
      <th className="text-center">SLA</th>
      <th className="text-center" >Status</th>
      <th className="text-center" >Created by</th>
      <th className="text-center" >Created on</th>
      <th className="text-center" >Updated date</th>
      <th className="text-center" >Updated by</th>
    </>
  );
};
