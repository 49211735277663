import Styled from "styled-components"

const HeaderWrap = Styled.div`
    .plus_icon_show{
        width: 100%;
        position: absolute;
        right: 0;
        background: #000000;
        margin-top: 3.6rem;
    }

    .add_plus_container{
        display: flex;
        align-items: center;
        margin-top: -0.8rem;
        padding: 13px;
    }

    .custom-dropdown-menu {
        padding: 0px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
        border: none;
        border-radius: 0px;
        width: 100vw !important;
        background: #000000;
        display: flex;
        justify-content: center;
        left: auto;
        top: 16px;
        right: -206px;
        position: absolute !important;
    }
    .dropdown_search {
        background: transparent;
    }
    .plus_close_icon{
        position: absolute;
        right: 12px;
        font-size: 30px;
        color: white !important;
        cursor: pointer;
    }
    .blus_ison_img{
        padding-top: 9px !important;
        padding-left: 2px;
    }
    .custom-dropdown-menu:has(.plus_add_tasks) {
        background: transparent;
    }
    .plus_add_tasks{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 1.6rem;
        padding: 26px 80px 10px 80px;
        background: grey;
        border-radius: 5px;
    }
    .add_tasks_titles{
        display: flex;
        align-items: center;
        cursor: pointer;
         color: ${({ theme }) => theme["themeColor"]};
        font-size: 16px;
        .task_add_icon{
            font-size: 22px;
        }
        &:hover {
            color: black;
        }
    }
    .userType-container{
        margin-right: 70px;
        display: inline-flex;
        height: 37px;
        background-color: rgb(178 185 185 / 40%);
        border-radius: 25px;
    }
    .user-role{
        padding: 0px 13px 0px 10px;
        margin-top: 10px;
        letter-spacing: 0.5px;
        font-size: 13px;
        display: inline-block;
        opacity: 0.80;
    }
    .siteLogoImg
    {
        max-height: 37px;
        margin-top: 5px;
    }
    .layout-radio {
        margin-left: 20px !important;
    }
    .tp-nav{
        color:#4EB6EE;  
        width: 90px;
        text-align: center;
    }

    .layout-label {
        margin-left: 45px;
    }
    .navbar-dark.bg-black,
    .navbar-light.bg-white {
        border-bottom: 1px solid transparent !important;
    }

    nav.topNav {
        position: fixed;
        z-index: 99;
        left: 160px;
        right: 0;
        top: 0;
        margin-top: 0 !important;
        background: #fff;
    }
    .navbar {
        font-weight: 300;
        background: ${({ theme }) => theme["background-color"]};
        transition: background 0.25s ease;
        -webkit-transition: background 0.25s ease;
        -moz-transition: background 0.25s ease;
        -ms-transition: background 0.25s ease;
        border-bottom: 1px solid ${({ theme }) => theme["border-color"]};
    .navbar-brand-wrapper {
        transition: width 0.25s ease, background 0.25s ease;
        -webkit-transition: width 0.25s ease, background 0.25s ease;
        -moz-transition: width 0.25s ease, background 0.25s ease;
        -ms-transition: width 0.25s ease, background 0.25s ease;
        height: 70px;
        margin-left: 25px;
        .navbar-brand {
            margin-right: 0;
        }
        .navbar-brand.brand-logo-mini {
            display: none;
        }
    }
    .navbar-menu-wrapper {
        transition: width 0.25s ease;
        -webkit-transition: width 0.25s ease;
        -moz-transition: width 0.25s ease;
        -ms-transition: width 0.25s ease;
        color: ${({ theme }) => theme["themeColor"]};
        padding-left: 15px;
        padding-right: 15px;
        width: calc(100% - 150px);
        /* width: 100%; */
        height: 70px;
    }
    }
    .tp-nav.active {
        background: ${(props) => (props.darkMode ? "#292A29" : "#F3F5F5")} ;
        padding: 24px 8px 24px 8px;
    }
    .fixed-bottom {
        position: fixed;
        right: 0;
        left: 0;
        z-index: 1030;
    }
    .fixed-top {
        position: fixed;
        right: 0;
        left: 0;
        z-index: 1030;
        top: 0;
    }
    .navbar-brand-wrapper {
        i.fal {
            font-size: 19px;
            cursor: pointer;
        }
    }
    .menubarMain {
        border-radius: 50%;
        display: inline-block;
        margin: 0 0px 0 0;
        padding: 7px 4px;
        overflow: hidden;
        vertical-align: middle;
        cursor: pointer;    
        height: 35px;
        width: 35px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        flex: 0 0 auto;
        svg {
            width: 22px !important;
            height: 22px;
            path {
            fill: #ff8f1c;
            }
        }
        &:hover {
            background-color: rgba(60, 64, 67, 0.08);
            outline: none;
        }
    }
    .topNavListing {
        flex-wrap: nowrap;
        .nav-link {
            padding: 0;
        }
        .dropdown-toggle {
            &::after {
                display: none !important;
            }
         }
        }
        /* .search-icon{
            @media (max-width:426px){
                display:none;
            }
        } */
        .dropdown-menu {
            min-width: 328px !important;
            position: absolute !important;
            padding: 0;
            -webkit-box-shadow: 0 2px 10px #00000033;
            box-shadow: 0 2px 10px #00000033;
            -webkit-border-radius: 10px;
            border-radius: 10px;
            inset: 40px 0px auto auto !important;
            transform: inherit !important;
            .dropdown-item {
                text-align: left;
                padding: 10px 15px;
                &.active, &:active {
                    color: #fff;
                    background-color: ${({ theme }) => theme["orange-color"]};
                }
            }
        }
        .topnavProfile{
            width: 100px;
            .dropdown-menu {
                
            }
        }
        .hoverBg {
            .dropdown-menu {
                min-width: 200px !important;
                border-radius: 5px;
            }
            &.topnavOptions {
                .dropdown-menu {
                    min-width: 300px !important;
                    padding: 15px 15px;
                }
            }
        }
    

    .all_new_form_container {
        .dropdown-menu {
            min-width: 2px !important;
            border-radius: 5px;
        }
    }

    .all_new_form_container {
        padding: 4px 0 0 0;

        .dropdown-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            padding: 6px;
            border-radius: 50%;
            background-color: transparent;
            transition: background-color 0.2s;

            svg {
                width: 100%;
                height: 100%;
                path {
                    fill: ${({ theme }) => theme["icon-color"]};
                }
            }

            &:hover,&:focus {
                background-color: #f2f2f2;
                outline: none;

                svg path {
                    fill: ${({ theme }) => theme["hover-icon-color"]};
                }
            }
        }
    }

    .content-wrapper {
        width: 100%;
        flex-grow: 1;
        padding: 10px;
        border-radius: 10px;
    }
    .nav-profile-img {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        border: 1px solid #ddd;
    }
    .nav-profile-name {
        vertical-align: middle;
        padding: 0 5px;
        text-transform: capitalize;
        margin-right: 10px;
    }
    ul.topNavListing {
        padding-top: 13px;
    }
    .navArrow {
        box-sizing: border-box;
        height: 8px;
        width: 8px;
        border-style: solid;
        border-color: #333;
        border-width: 0px 2px 2px 0px;
        transform: rotate(45deg);
        transition: border-width 150ms ease-in-out;
        position: absolute;
        top: 12px;
        right: 2px;
    }
    .serchFieldMain {
        padding-top: 12px;
    input.form-control {
        padding-left: 40px;
        border-radius: 8px;
        background: #f1f3f4;
        border: 1px solid transparent;
        height: 46px;
        color: #333333;
        width: 100%;
        padding-top: 2px;
    }
    svg {
        position: absolute;
        top: 26px;
        left: 14px;
    }
    svg.searchRightSvg {
        right: 28px;
        left: inherit;
        top: 23px;
        opacity: 0.4;
        cursor: pointer;
    }
    i {
        position: absolute;
        top: 27px;
        left: 15px;
        color: ${({ theme }) => theme["body-text"]};;
        font-weight: 600;
    }
    }
    .form-control {
    &:focus {
        box-shadow: inherit !important;
    }
    }
    .imgfirstLetr {
        margin-top: 2px !important;
        display: inline-block;
        background-color: ${({ theme }) => theme["orange-color"]};
        width: 32px;
        height: 32px;
        border-radius: 50%;
        color: #fff;
        box-shadow: inset 0px 3px 6px #ffffff30, 0px 3px 6px #00000029;
        font-size: 18px;
        padding: 6px 6px;
        margin: 0 auto;
        text-align: center;
    }
    .bgGreyLetr.imgfirstLetr {
        background-color: ${({ theme }) => theme["gray-color"]};
        box-shadow: inherit;
    }
    .imgfirstLetrDropdwnTogle {
        padding: 4px !important;
        border-radius: 50% !important;
        width: 40px;
        height: 40px;
    }
    .dropdown-toggle.btn-primary {
        color: #fff;
        background-color: transparent;
        border-color: transparent;
    }
    .dropdown-toggle.imgfirstLetrDropdwnTogle[aria-expanded="true"] {
        background-color: #5f63681f;
        border-radius: 50%;
        padding: 4px;
        width: 40px;
        height: 40px;
    }
    .dropdown-toggle.imgfirstLetrDropdwnTogle {
        &:hover[aria-expanded="true"] {
            background-color: #5f63681f;
            border-radius: 50%;
            padding: 4px;
            width: 40px;
            height: 40px;
        }
    }
    .profInfo {
        .defultImgFirsLetr {
            vertical-align: top;
            height: 70px;
            width: 70px;
            font-size: 40px;
            padding: 10px;
            margin-bottom: 10px;
        }
        h5 {
            font-weight: 600;
            font-size: 18px;
            line-height: normal;
        }
    }

    .singleProfDetail {
        border-bottom: 1px solid #f2f2f2;
        padding: 15px 0;
        &:last-child {
            border-bottom: none;
        }
    }
    .addUserIcon {
        width: 32px;
        height: 32px;
        svg {
            width: 32px;
            height: 32px;
        }
    }
    .anothrAcount {
        margin-top: 10px;
    }
    .dpdwnProfDetail {
        h6 {
            font-weight: 400;
        }
    }
    .search_icon {
        display: none;
    }
    .top_menu_nav_item {
        padding: 0 12px;
    }
    .singleProfDetail.acountList {
        padding: 0;
        h6 {
            font-weight: 500;
            font-size: 15px;
        }
    p {
        font-size: 12px;
        font-weight: 400;
    }
    small {
        font-size: 11px;
        font-weight: 400;
        color: ${({ theme }) => theme["gray-color"]};
    }
    }
    .singleProfListng {
        padding: 10px 15px;
        &:hover {
            background-color: transparent;
        }
    }
    .top_menu_bar_menu_icon {
        display: none;
    }
    .signOutAcount {
        a.btn {
            font-size: 14px;
            border: 1px solid ${({ theme }) => theme["gray-color"]};
            padding: 10px 24px;
            letter-spacing: 0.15px;
            white-space: normal;
            &:hover {
            background-color: ${({ theme }) => theme["gray-color"]};
            }
        }
    }
    .hoverBg {
        padding: 4px 10px 0 0;
        .dropdown-toggle {
            svg {
            width: 35px;
            height: 35px;
            padding: 6px;
            border-radius: 40px;
            path {
                fill: ${({ theme }) => theme["icon-color"]};
            }

            &:hover {
                background-color: #f2f2f2;
                path {
                fill: ${({ theme }) => theme["hover-icon-color"]};
                }
            }
            &:focus {
                background-color: #f2f2f2;
                path {
                fill: ${({ theme }) => theme["hover-icon-color"]};
                }
            }
            &:active {
                background-color: #f2f2f2;
                path {
                fill: ${({ theme }) => theme["hover-icon-color"]};
                }
            }
            }
        }
    }
    .singleOption {
        padding: 10px 0;
    }
    .selectThemepart {
        .form-label {
            letter-spacing: 1.4px;
            display: block;
            margin-bottom: 15px;
            padding-bottom: 5px;
        }
        .feedbckTxt {
            font-size: 12px;
            color: ${({ theme }) => theme["icon-color"]};
            margin-left: 5px;
        }
        .defaultTheme {
            .form-check-input {
            cursor: pointer;
            border: 2px solid #0c0c0c40;
            position: absolute;
            top: 9px;
            }
            .themePreviewimg {
            top: 6px;
            }
        }
        .themePreviewimg {
            position: absolute;
            top: -6px;
            right: 0;
            border: 1px solid #d2d2d2;
            padding: 3px;
            border-radius: 3px;
        }
    }

    .notification-badge{
        font-size: 10px;
        position: absolute;
        right: -2px;
        top: -3px;
    }

    @media (min-width: 992px) {
        .navbar {
            .navbar-menu-wrapper {
            .topNavListing.topNavListing-right {
                margin-left: auto;
            }
            }
        }
    }
    @media (max-width: 991px) {
        .defultImgFirsLetr {
            height: 50px;
            width: 50px;
            font-size: 30px;
            padding: 7px;
            margin-bottom: 10px;
        }
        .top_menu_nav_item {
            padding: 0 8px;
        }
        .singleProfDetail {
            h5 {
            font-size: 16px;
            }
            p {
            font-size: 13px;
            }
        }
        .singleProfDetail.acountList {
            h6 {
            font-size: 13px;
            }
        }
        .signOutAcount {
            a.btn {
            font-size: 12px;
            }
        }
        .top_menu_bar_menu_icon {
            display: block;
        }
        .top_menu_bar_nav {
            display: none;
        }
    }

    @media (min-width:424px) and (max-width: 1023px){
        .userRole{
            display: none;
        }
    }

    @media (max-width: 767px) {
        .topNavListing .dropdown-menu {
            min-width: 250px !important;
            top: 30px !important;
        }
        .profInfo .defultImgFirsLetr {
            height: 35px;
            width: 35px;
            font-size: 23px;
            padding: 5px;
            margin-bottom: 5px;
        }
        .singleProfDetail.profInfo {
        }
        .singleProfDetail.signOutAcount {
            padding-top: 10px !important;
        }
        .tp-nav{
            color: ${({ theme }) => theme["themeColor"]}; 
            display:none; 
        }
        .search_icon {
            display: block;
        }

    }

    @media (max-width: 735px) {
        .navbar {
            .searchFieldPart {
            display: none;
            }
        }
    }


    @media (max-width: 567px) {
        .custom-dropdown-menu {
            left: 0 !important;
            right: 0 !important;
            width: 100% !important;
            border-radius: 0px !important;
            .plus_add_tasks {
                padding: 12px 4px;
            }
        }
        .navbar {
            .topnavHelp {
            display: none;
            }
            .topnavSetting {
            display: none;
            }
            .topnavOptions {
            display: none;
            }
            .userRole
            {
                display: none;
            }
        }
        .dropdown_search {
        position: absolute;
        top: 10px;
        left: 30px;
         width: 100%;

        @media (max-width: 375px) {
        top: 10px;
        left: 35pxpx;
        width: 100%;
        }

        @media (max-width: 321px) {
        top: 10px;
        left: 10px;
        width: 100%;
        }
    }
        .topNavListing {
            .dropdown-menu {
            border: none;
            }
        }
        .dpdwnProfDetail {
        }
        .singleProfDetail {
            h5 {
            font-weight: 600;
            }
        }
        .content-wrapper {
            padding: 0;
        }
    }
        
`

export default HeaderWrap
