import Styled from "styled-components";

const ResetPasswordWrap = Styled.div`
    .loginPageRow .form-control{
        color: #000000;
    }
    .loginformbody .form-control{
        background: #F1E8E6;
        border: 1px solid #bfbdbd;
        border-radius: 5px;
        color: #000000;
        opacity: 0.90 !important;
    }  
    .loginformbody .form-label{
        color: #000000;
        margin-top: 25px;
    }
    .modalbody .form-control{
        color: #000000;
    }
    .loginPageRow{
        display:flex;
    }
    .card-body{
        padding: 0rem 0rem;
    }
    .loginformbody{
        background: #F1E8E6;
    }
    .loginScreenLogoMain{
        width: 100%;
        margin-left: 15px;
    }
    .loginScreenLogoimg {
        max-height: 56px;
    }
    .loginPageContent {
        overflow: auto;
        overflow-x: hidden;
        width: 100%;
        height: 100vh;
        background: #292A29 0% 0% no-repeat padding-box;
        opacity: 1;
        background-blend-mode: multiply;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .loginPageRightside{
        margin-top: 140px;
        margin-left: 140px;
    }
    .loginPageLeftside{
        height: 600px;
        float: right;
    }
    .loginaccountft{
        margin-bottom: 0px!important;
        margin-top: 30px;
        font-size: 17px;
        font-weight: 600;
        color: black; 
        text-align: center;         
    }
    .loginFormScreens {
        overflow: hidden;
        overflow-y: auto;
        margin: 10px;
        padding:0px 30px 0px 30px;
        background: #F1E8E6;
        border-radius: 5px;
        opacity: 1;
    }
    .text-center{
        text-align: center
    }
    .loginformbody .text-center .btn {
        height: 30px;
        padding: 0px;
    }
    .btn.btn-orange {
        font-size: 15px;
    }
    .login{
        display: unset;
        line-height: 0rem;
    }
    .loginFormScreens {
        .form-control {
            padding: 10px 20px;
            font-size: 15px;
        }
    }
    .d-block{
        text-align: start;
    }
    .text-center .btn-padding{
        padding: 0px;
    }
    .backbtn{
        margin-right: 10px;
    }
    @media (max-width: 1440px){
        .loginPageRightside {
            margin-top: 115px;
        }
        .loginPageLeftside {
            height: 520px;
        }
    }
    @media (max-width: 1024px){
        .loginSubmitBtn{
            width: 50%;
        }
        .loginPageRightside {
            margin-left: 65px;
            margin-top: 130px;
        }
    }
    @media (max-width: 991px){
        .loginPageRightside {
            margin-top: 120px;
            margin-left: 75px;
        }
        .loginScreenLogoMain {
            width: 85%;
        }
        .loginFormScreens{
            width: 83%;
        }
    }
    @media (max-width: 768px){
        .loginPageRightside {
            margin-left: 55px;
        }
    }
    @media (max-width: 767px){
        .loginScreenLogoMain {
            width: 100%;
        }
        .loginPageRightside {
            margin-left: 0px;
        }
        .loginFormScreens{
            width: 97%;
        }
    }
    @media (max-width: 500px){
        .loginFormScreens{
            width: 95%;
        }
        .loginScreenLogoMain {
            margin-left: 0px;
        }
        .loginPageRightside {
            margin-left: 0px;
        }
        .loginaccountft{
            text-align: unset;
        }
        .forgotLink{
            float: left;
        }
    }
    @media (max-width: 325px){
        .loginFormScreens{
            width: 94%;
        }
    }
        
`;

export default ResetPasswordWrap;
