import React, { useState, useEffect } from "react"
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import EditorWrap from "./style.js"
import { validateInput } from "../../../Utilities/validator.js"
import { renderToString } from "react-dom/server"

export const WysiwygEditor = ({
  value,
  onChange,
  changed,
  hideToolbar = false,
  resetEditorState,
  setEditorState,
  editorRef,
  formAr,
  index,
  setFormAr,
  handleReturn,
  validators,
  getTemplate
}) => {
  let editorState = EditorState.createWithContent(
    ContentState.createFromBlockArray(convertFromHTML(value))
  )

  let defaultValue = renderToString(getTemplate)

  // let editorState = useMemo(() => {
  //   const contentState = value
  //     ? ContentState.createFromBlockArray(convertFromHTML(value))
  //     : ContentState.createFromText(defaultValue)
  //   return EditorState.createWithContent(contentState)
  // }, [resetEditorState, value])

  const [description, setDescription] = useState(editorState)

  useEffect(() => {
    if (
      draftToHtml(convertToRaw(editorState.getCurrentContent())) !==
      draftToHtml(convertToRaw(description.getCurrentContent()))
    )
      setDescription(editorState)
  }, [changed])

  useEffect(() => {
    if (resetEditorState === true) {
      let emptyEditorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(defaultValue))
      )
      setDescription(emptyEditorState)
      setEditorState(false)
    }
    if (resetEditorState === false) {
      let emptyEditorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(defaultValue))
      )
      setDescription(emptyEditorState)
      setEditorState(false)
    }
  }, [resetEditorState, defaultValue])

  const validateField = (e) => {
    if (formAr) {
      const result = validateInput(validators, e)
      formAr[index].error = result
      if (e.preventDefault) e.preventDefault()
      formAr[index].errormsg = result
      setFormAr(formAr)
    }
  }

  handleReturn = (e, des) => {
    if (e.keyCode === 13) {
      e.preventDefault()
    }
  }

  return (
    <EditorWrap>
      <Editor
        handleReturn={handleReturn}
        // handleReturn={(e) =>
        //   handleReturn(e, draftToHtml(convertToRaw(description.getCurrentContent())))
        // }
        ref={editorRef}
        editorState={description}
        toolbarClassName='toolbarClassName'
        wrapperClassName='wrapperClassName'
        editorClassName='editorClassName'
        onEditorStateChange={(editorState) => setDescription(editorState)}
        onChange={(e) => {
          validateField(draftToHtml(convertToRaw(description.getCurrentContent())))
          onChange(draftToHtml(convertToRaw(description.getCurrentContent())))
        }}
        // toolbarOnFocus={true}
        toolbarHidden={hideToolbar}
        toolbar={{
          fontFamily: {
            options: ["Arial", "Georgia", "Impact", "Tahoma", "Verdana"]
          },
          options: ["fontFamily", "inline", "blockType", "link", "list", "emoji"],
          inline: {
            options: ["bold", "italic", "underline", "superscript", "subscript"]
          },
          list: { inDropdown: true },
          image: {
            // uploadCallback: uploadImageCallBack,
            alt: { present: false, mandatory: false }
          }
        }}
      />
    </EditorWrap>
  )
}
