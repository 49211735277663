import Styled from 'styled-components';

const ProjectModelEditView = Styled.div`
    .taskconatiner{
        background-color:${({ theme }) => theme["tableViewCard"]};;
    }
    .select-template-options{
        border-radius: 5px;
        padding: 0px 10px;
        /* width :210px; */
        height: 30px;
    }
    .projectModel_edit_container{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
`
export default ProjectModelEditView;