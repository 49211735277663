import React, { useState } from "react"
import { PricingPlansWrap } from "../../Login/PricingPlans"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import Plan from "../../Login/Plan"
import { afterFreeTrialPricingPlans } from "../../Login/Pricings"
import { Box } from "@mui/system"
import Payment from "./Payment"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { api } from "../../../../Services/api"
import { useSelector, useDispatch } from "react-redux"
import { setAlertMessage } from "../../../../redux/Tracking/actionCreator"

const Subscribe = ({ subscriptionPlan, currentStep, setShowSubscribe }) => {
  const stripe_public_key = "pk_live_51Knzi8K5kiUDeKKvbmo4QZPHeQ7cJiLLvXgidzNOJ7ufO6zUgRE0jkBhHUWW2PfSPg4pX5o7y7ziRc637YRukasa00XOBucbxQ"
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)
  const user = useSelector((state) => state.Auth.user)

  const chosenPlan = afterFreeTrialPricingPlans.map((pricingPlan) => {
    return pricingPlan.tier === subscriptionPlan ? { ...pricingPlan, checked: true } : pricingPlan
  })

  const [plans, setPlans] = useState(chosenPlan)
  const [selectedPlan, setSelectedPlan] = useState(subscriptionPlan)
  const [stripeSecretKey, setStripeSecretKey] = useState("")
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1)

  const dispatch = useDispatch()

  const handlePlans = (id) => {
    // setIsChecked(true)
    // setOpen(false)
    const revisedPlans = afterFreeTrialPricingPlans.map((plan) => {
      if (plan.id === id) {
        if (plan.checked) return plan
        return { ...plan, checked: true }
      }
      return plan
    })

    revisedPlans.forEach((plan) => {
      if (plan.checked) {
        setSelectedPlan(plan.tier)
      }
    })

    setPlans(revisedPlans)
  }

  const getStripeSecretKey = async () => {
    let data = {
      subscriptionPlan: selectedPlan,
      clientid: trackingClientId,
      emailid: user.trackinguserid
    }

    if (selectedPlan === "") {
      setShowSubscribe(false)
      dispatch(setAlertMessage("Please select a plan"))
      return
    }

    setLoading(true)
    try {
      const url = "/trekrpayment/create"
      const response = await api.post(url, data)
      setLoading(false)

      setStep(2)
      currentStep(2)

      setStripeSecretKey(response.data.clientSecret)
    } catch (error) {
      setLoading(false)
    }
  }

  const stripePromise = loadStripe(stripe_public_key)

  let options = {
    clientSecret: stripeSecretKey,
    appearance: {
      theme: "stripe",

      variables: {
        colorPrimary: "#0570de",
        colorBackground: "#ffffff",
        colorText: "white",
        colorDanger: "#df1b41",
        fontFamily: "Ideal Sans, system-ui, sans-serif",
        spacingUnit: "2px",
        borderRadius: "4px"

        // See all possible variables below
      },
      rules: {
        ".Input": {
          color: "black"
        }
      }
    }
  }

  return (
    <PricingPlansWrap>
      {/* <div className='trekr-plans'>
        Trekr pricing plans
    </div> */}

      {step === 1 ? (
        <Row className='mb-4 d-flex justify-content-center'>
          {plans.map((plan) => {
            return (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={3}
                key={plan.id}
                className={`d-flex justify-content-center pb-2 pb-sm-4`}
              >
                <Plan
                  tier={plan.tier}
                  price={plan.price}
                  title={plan.title}
                  duration={plan.duration}
                  benefits={plan.benefits}
                  checked={plan.checked}
                  handlePlans={handlePlans}
                  planId={plan.id}
                  mobileScreen={false}
                />
              </Col>
            )
          })}
        </Row>
      ) : loading ? (
        <div className='text-center mt-5 mb-5'>
          <Spinner animation='border' variant='info' />
        </div>
      ) : stripePromise && stripeSecretKey !== "" ? (
        <Elements stripe={stripePromise} options={options}>
          <Payment
            setStep={setStep}
            currentStep={currentStep}
            setShowSubscribe={setShowSubscribe}
            clientId={trackingClientId}
            loading={loading}
            setLoading={setLoading}
          />
        </Elements>
      ) : (
        <div className='text-center mt-5 mb-5'>
          <Spinner animation='border' variant='info' />
        </div>
      )}

      {step === 1 && (
        <Box className='d-flex justify-content-end'>
          <Button
            variant='info'
            disabled={loading}
            onClick={() => {
              getStripeSecretKey()
            }}
          >
            Next
          </Button>
        </Box>
      )}
    </PricingPlansWrap>
  )
}

export default Subscribe
