import Styled from "styled-components"

const SidebarWrap = Styled.div`
            /*   ------------------------ */
            input.form-control::placeholder {
                color: ${({ theme }) => theme["placeholder"]};
            }
            input.form-control::-webkit-input-placeholder {
                color: ${({ theme }) => theme["placeholder"]};
            }
    
            input.form-control::-moz-placeholder {
                color: ${({ theme }) => theme["placeholder"]};
            }
    
            input.form-control:-ms-input-placeholder {
                color: ${({ theme }) => theme["placeholder"]};
            }
    
            input.form-control:-moz-placeholder {
                color: ${({ theme }) => theme["placeholder"]};
            }
            .hideIcon{
                display: none;
            }
            .sidebar {
                position: fixed;
                top: 0px;
                left: 0;
                bottom: 0;
                width: 200px;
                z-index: 11;
                padding: 70px 0 0;
                background: ${({ theme }) => theme["background-color"]};
                min-height: calc(100vh - 70px);

                .nav {
                    &:not(.sub-menu) {
                        padding-bottom: 60px;
                        padding-top: 0px;
                    }

                    overflow: hidden;
                    flex-wrap: nowrap;
                    flex-direction: column;

                    .nav-link {
                        display: flex;
                        justify-content: space-between;
                        -webkit-font-smoothing: antialiased;
                        font-size: 14px;
                        letter-spacing: 0.2px;
                        color: ${({ theme }) => theme["panel-heading"]};
                        text-shadow: none;
                        text-align: left;
                        padding: 6px 10px 6px 15px;
                        // border-radius: 0 15px 15px 0;
                        font-weight: 500;
                        svg {
                            margin-right: 15px;
                            font-size: 20px;
                            font-weight: 800;
                            vertical-align: middle;
                        }

                        span {
                            vertical-align: middle;
                        }

                        &:focus,&:hover {
                            background-color: #e8eaed;
                            background-color: ${({ theme }) => theme["panel-color"]};
                            svg {
                                color: ${({ theme }) => theme["themeColor"]};
                            }
                            .sidebar_plus_icon{
                                display: block;
                                color: rgb(41, 42, 41);          
                                .plus_bg_icon{
                                    color: ${({ theme }) => theme["themeColor"]};
                                }
                            }
                        }
            
                    }

                    .dropmenuitems {
                        .nav-link {
                            display: flex;
                            justify-content: flex-start;
                            padding: 6px 6px 6px 30px;
                            font-size: 13px;
                            color: ${({ theme }) => theme["panel-heading"]};
                        }
                        .nav-link.active {
                            font-weight: 500;
                            background-color: transparent;
                            color: ${({ theme }) => theme["orange-color"]};
                        }
                    }

                    .nav-link.active {
                        font-weight: 600;
                    }
                }

                li.nav-item {
                    list-style: none;
                    margin: 3px 0;

                    .active {
                        background-color: #e8eaed;
                        background-color: ${({ theme }) => theme["panel-color"]};
                        svg {
                            color: ${({ theme }) => theme["themeColor"]};
                        }
                    }
                    .dropmenuitems {
                        ul {
                            padding-left: 0px;
                            .nav-link {
                                &:focus {
                                    background-color: transparent;
                                    border-radius: 0;
                                }
                                &:active {
                                    background-color: transparent;
                                    border-radius: 0;
                                }
                                &:hover {
                                    background-color: transparent;
                                    border-radius: 0;
                                }
                            }
                        }
                    }
                }
            }

            .serchFieldMain {
                svg {
                    path {
                        fill: ${({ theme }) => theme["icon-color"]};
                    }
                }
            }

            .sidebarMainLinks {
                position: absolute;
                padding: 0px 0px 0;
                top: 135px;
                overflow: hidden;
                overflow-y: auto;
                min-height: calc(100vh - 70px);
            }
            .sidebarMainLinks::-webkit-scrollbar {
                width: 5px;
                background: transparent;
            }
            .sidebarMainLinks::-webkit-scrollbar-thumb {
                height: 20px !important;
                background: white;
                border-radius: 20px;
            }
            .sidebarMain { 
                .serchFieldMain {
                    padding-top: 10px;
                    margin: 0 27px;
                    svg {
                    position: absolute;
                    top: 23px;
                    left: 0px;
                    width: 32px;
                    height: 22px;
                }
                input.form-control {
                    width: 100%;
                    border-radius: 0;
                    border: none;
                    border-bottom: 1px solid #e1e5eb;
                    background-color: transparent;
                    height: 42px;
                    padding-bottom: 0;
                    padding-left: 32px;
                    color: ${({ theme }) => theme["themeColor"]};
                }
            }
        
            
            .sideBar {
                .siteLogo {
                    min-height: 90px;
                }
                .nav-menu {
                    a {
                        svg {
                            vertical-align: middle;
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }

            .sidebar.backtoMainSideBar {
                ul.nav {
                    padding-top: 10px;
                }
            }

            .mobSideBar {
                .backLinkMain {
                    .backlink {
                        .backTxt {
                            display: none;
                        }
                        padding: 10px 10px 10px 14px;
                    }
                }
                .sidebarMain {
                    .serchFieldMain {
                    margin: 0 17px;
                    input.form-control {
                        display: none;
                    }
                }
            }

            .sidebar {
                .nav {
                    .dropmenuitems {
                        .nav-link {
                            display: none;
                        }
                    }
                    .nav-link {
                        padding: 6px 10px 6px 17px;
                    }
                }
            }
                
                .sidebarMainLinks {
                    width: 60px;
                }

                .sidebar.sidebarInerLinks {
                    width: 60px;
                }

                .sidebar.sidebarMain {
                    width: 60px;
                }

                .listArowIcon {
                    display: none;
                }

                .navItemArrow {
                    display: none;
                }

                .nav-link {
                    span.dsktopView {
                    display: none;
                    }
                }

                .main-panel {
                    margin-left: 60px;
                }
                
            }
            .sidebar_plus_icon {
                display: none;
            }
            .sidebar_plus_icon:hover {
               color:rgb(78, 182, 238) !important;
            }
            .sidebar_main_div{
                display: grid;
                grid-template-columns: 85% 15%;
                align-items: center;
            }

            .sidebar_main_div:hover{
                background: rgb(41, 42, 41);
            }

            .backLinkMain {
                padding: 0;
                text-align: left;
                border-bottom: 1px solid ${({ theme }) => theme["border-color"]};
                .backlink {
                    text-decoration: none;
                    line-height: normal;
                    display: block;
                    padding: 15px 10px 15px 25px;
                    font-size: 14px;
                    letter-spacing: 0.2px;
                    text-shadow: none;
                    text-align: left;
                    font-weight: 500;
                    color: ${({ theme }) => theme["panel-heading"]};
                    background-color: ${({ theme }) => theme["background-color"]};
                   
                    .backTxt {
                        padding-left: 6px;
                        padding-top: 0px;
                        display: inline-block;
                    }
                }
            }

            .backtoMainSideBar {
                position: fixed;
                top: 0px;
                left: 0;
                bottom: 0;
                width: 160px;
                z-index: 11;
                padding: 70px 0 0;
                background: #fff;
                min-height: calc(100vh - 70px);
                left: -260px;
                visibility: hidden;
                transition: all ease-in-out 0.5s;
            }

            .sidebar.sidebarInerLinks {
                position: absolute;
                padding: 0;
                top: 119px;
                overflow: hidden;
                overflow-y: auto;
                min-height: calc(100vh - 70px);
            }

            .backtoMainSideBar.backtoMainSideBarActive {
                left: 0;
                visibility: visible;
                transition: all ease-in-out 0.5s;
            }

            .navItemArrow {
                float: right;
                width: 27px;
                height: 27px;
                margin: 0 auto;
            }

            .mobSideBar {
                .main-panel {
                    margin-left: 60px;
                }
            }


            .main-panel {
                margin-left: 200px;
                display: flex;
                flex-direction: column;
                min-width: 0;
                overflow: hidden;
                min-height: calc(100vh - 70px);
                z-index: 11;
                background: rgba(242, 245, 245, 0.8);
                padding: 15px;
                width: 100%;
                flex-grow: 1;
            }
            @media (max-width: 767px) {
                
            }
            @media (max-width: 575px) {

                .main-panel {
                    margin-left: 0;
                }
                .mobSideBar .main-panel {
                    margin-left: 0;
                }
                .sidebar {
                    z-index: 111;
                    width:0
                }
                .mobSideBar .sidebar.sidebarMain {
                    width: 0;
                }
                .mobSideBar .sidebarMainLinks {
                    width: 0;
                }
                .mobSideBar .sidebarMain .serchFieldMain {
                    display: none;
                }
                .mobSideBar .sidebar.sidebarMain {
                    box-shadow: 0 0 10px #00000020;
                    -webkit-transition: all 0.5s ease-out;
                    width: 160px;
                    z-index: 111;
                }
                
                
            }

        
`

export default SidebarWrap
