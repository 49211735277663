import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import SearchIcon from "@mui/icons-material/Search"
import { useDispatch, useSelector } from "react-redux"
import { setGlobalSearch } from "../../../../redux/Tracking/actionCreator"
import TaskIcon from "@mui/icons-material/Task"
import useTrackingItemData from "../../../../Hooks/useTrackingItemData"
import { Spinner } from "react-bootstrap"
import ClearIcon from "@mui/icons-material/Clear"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"
import moment from "moment"

const SearchWrap = styled.div`
  input {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 4px;
    border: 1px solid black;
    color: black;
    width: ${({ globalSearch }) => (globalSearch ? "100%" : "205px")};
    min-width: ${({ globalSearch }) => (globalSearch ? "300px" : "100%")};
    padding-left: ${({ globalSearch }) => (globalSearch ? "24px" : "8px")} !important;
    padding-right: 28px;
    &::placeholder {
      color: ${({ theme }) => !theme["themeColor"]};
      opacity: 0.5;
    }
  }

  .input-width {
    max-width: 205px;
  }

  .global-input-width {
    width: 100%;
  }
  .user_Input_container {
    width: 100% !important;
  }
  .userInput-form {
    width: 100%;
  }
  .userInput {
    width: 100%;
  }
  .global-input {
    padding: 0.5rem;
    width: 100%;
  }

  .search-icon {
    cursor: pointer;
    top: 4px;
    right: 0;
  }

  .global-search-icon {
    cursor: pointer;
    top: 6px;
    left: 2px;
  }

  .global-clear-icon {
    cursor: pointer;
    top: 8px;
    right: 4px;
  }

  .filter-box {
    position: absolute;
    left: 0;
    top: 50px;
    max-height: 400px;
    overflow: auto;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid black;
  }

  .items {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 6px;
    align-items: center;
    padding: 5px 10px 10px 10px;
  }

  .item {
    width: 100%;
  }

  .item:hover {
    background-color: #e5e5e5;
  }

  .task {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    width: 100%;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .key-binding {
    position: absolute;
    top: 4px;
    right: 15px;
    padding: 0;
  }
  .assigned-to {
    font-size: 12px;
    text-align: start;
  }
`

const Search = ({
  search,
  searchLoading,
  placeholder,
  handleSearch,
  type,
  setShowTaskViewModal
}) => {
  const dispatch = useDispatch()

  const SearchFilter = (e) => {
    console.log("SearchFilter  e-->", e.target.value)
    handleSearch(e.target.value)
  }
  const globalSearchResult = useSelector((state) => state.Tracking.globalSearchResult)

  const searchRef = useRef()

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.ctrlKey && event.keyCode === 75) {
        event.preventDefault()
        searchRef.current.focus()
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return (
    <SearchWrap
      globalSearch={type === "globalSearch" ? true : false}
      className={type === "userFilter" ? "user_Input_container w-100" : ""}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
        }}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault()
        }}
        className={`position-relative ${
          type === "globalSearch"
            ? "global-input-width"
            : (type = "userFilter" ? "userInput-form" : "input-width")
        }`}
      >
        <input
          className={`${
            type === "globalSearch"
              ? "global-input"
              : (type = "userFilter" ? "userInput" : "search-input")
          }`}
          value={search}
          placeholder={placeholder}
          onChange={SearchFilter}
          ref={type === "globalSearch" ? searchRef : null}
        />

        {search && (
          <span
            onClick={() => {
              dispatch(setGlobalSearch(""))
            }}
            className={`global-clear-icon  position-absolute `}
          >
            <ClearIcon style={{ color: type === "globalSearch" ? "black" : "#4EB6EE" }} />
          </span>
        )}
        <span
          className={`${
            type === "globalSearch" ? "global-search-icon" : "search-icon"
          }  position-absolute `}
        >
          <SearchIcon style={{ color: type === "globalSearch" ? "#CCCCCC" : "#4EB6EE" }} />
        </span>

        {search?.length > 0 && (
          <SearchFiltered
            setShowTaskViewModal={setShowTaskViewModal}
            globalSearchResult={globalSearchResult}
            searchLoading={searchLoading}
          />
        )}
      </form>
    </SearchWrap>
  )
}

export default Search

const SearchFiltered = ({ globalSearchResult, searchLoading, setShowTaskViewModal }) => {
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)

  const { data: allProjectsRes } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allProjects = allProjectsRes?.data?.Items ?? []
  const { data: allProjectMembers } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMembers = allProjectMembers?.data ?? []
  const search = useSelector((state) => state.Tracking.globalSearch)

  const isProjectExist = (task) => {
    const result = allProjects.find((e) => e.trackingprojectid == task || e.number == task)
    return result !== undefined ? true : false
  }

  const dispatch = useDispatch()

  const filterTask = globalSearchResult.filter((item) => {
    return isProjectExist(item.trackingprojectsid)
  })

  const [trackingItemData, setTrackingItemData] = useTrackingItemData()

  return (
    <div className='filter-box'>
      <div className='items'>
        {filterTask.length === 0 ? (
          <div className=''>{searchLoading ? <Spinner size='sm' /> : "No Task Found"}</div>
        ) : null}
        {filterTask.length > 0 &&
          filterTask.map((item, index) => {
            let title = item.title
            let trackingid = item.trackingid
            let trackingclientid = item.trackingclientid

            return (
              <div
                className='item'
                key={index}
                onClick={() => {
                  dispatch(setGlobalSearch(""))
                  setTrackingItemData(item)
                  setShowTaskViewModal(true)
                }}
              >
                <div className='task'>
                  <TaskIcon style={{ color: "#4EB6EE" }} />
                  <div className=''>
                    {/* // bold the character on the title which is equivalent to Bidlead new important */}
                    <BoldText text={title} filter={search} /> - {trackingclientid}-{trackingid}
                  </div>

                  <div className='ms-auto'>
                    {moment(item.reporteddate).format("MMM D, YYYY hh:mm A")}
                  </div>
                </div>

                <div className='assigned-to'>
                  Assigned To:{" "}
                  <span className='fw-bold'>
                    {allMembers.find((e) => e.userId === item.assignedto)?.personname ?? "-"}
                  </span>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

const BoldText = ({ text, filter }) => {
  const regex = new RegExp(`(${filter})`, "gi") // create a regex from the filter
  const words = text.split(regex) // split the text by the filter

  return (
    <>
      {words.map((word, index) =>
        regex.test(word) ? (
          <span className='fw-bold ' key={index}>
            {word}
          </span> // bold the filtered word
        ) : (
          word
        )
      )}
    </>
  )
}
