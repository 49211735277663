import Styled from "styled-components";

const DocUploadWrap = Styled.div`
  #form-file-upload {
    height: 100px;
    max-width: 100%;
    text-align: center;
    position: relative;
  }
  
  #input-file-upload {
    display: none;
  }
  .filename{
    width: 120px;
    color: ${({ theme }) => theme["themeColor"]};
    font-size: 10px;
    position: absolute;
    margin-top: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .file-container{
  width: 80vw;
  height: 50vh;
  }
  .fileuploadname{
    width: 120px;
    height: 50px;
    overflow: hidden;
    color: ${({ theme }) => theme["themeColor"]};
    font-size: 10px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #label-file-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #CCCCCC;
    border-radius: 5px;
    opacity: 0.50;
    border-color:  ${({ theme }) => theme["themeColor"]};
    background-color: transparent;
  }
  
  .pdf_iframer{
    width: 80vw;
    height: 53vh;
  }

  #label-file-upload.drag-active {
    background-color:  ${({ theme }) => theme["themeColor"]};
  }
  
  .upload-button {
    cursor: pointer;
    color:  ${({ theme }) => theme["themeColor"]};
   
  }
   .upload-format{
    color: ${({ theme }) => theme["themeColor"]};
    font-size: 10px;
   }
  .upload-text{
    cursor: pointer;
    text-align: center;
    letter-spacing: 0px;
    color: ${({ theme }) => theme["themeColor"]};
  }
  .upload-button:hover {
    text-decoration-line: underline;
  }
  
  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .removebtn{
    color: red;
    bottom: 30px;
    right: 3px;
    height: 40px;
    z-index: 100;
  }
  .imgRow{
    top: 50px;
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    height: 50vh;
  }
`;

export default DocUploadWrap;