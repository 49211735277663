import React from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";

const UIModal = ({ title, children, showModal, setShowModal, size = "lg", forBacklogsModel, className }) => {
  const handleClose = () => {
    setShowModal(false);
  };
  if (!showModal) {
    return null;
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={(e) => { handleClose() }}
        centered
        size={size}
        keyboard={false}
        className={`${className} ${forBacklogsModel ? "backlogs_move_model" : ""}`}
      >
        <div
          className="p-2 mt-2"
          style={{ color: "#4eb6ee" }}
        >
          <span
            className="title ps-3"
            id="add_new"
            style={{ fontSize: "17px" }}
          >
            {" "}
            {title}
          </span>
          <CloseIcon
            className={forBacklogsModel ? "close-icon backlogs_move_model_close" : "close-icon"}
            onClick={() => handleClose(false)}
            style={{ cursor: "pointer", fontSize: "25px", color: "#4eb6ee", float: 'right' }}
          />
        </div>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
};

export default UIModal;
