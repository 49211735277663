import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import IconButton from "@mui/material/IconButton";
import Iframe from "react-iframe";
import FileViewer from "react-file-viewer";
import FormWrap from "./styles";

export const DocUploader = ({
  value,
  name,
  FileArr,
  FileName,
  submitted,
  resetField,
  setInputFileFieldReset,
  setDocfiletype,
  setFileSize
}) => {
  const [selectedFile, setSelectedFile] = useState("");
  const [FileUrl, setlFileUrl] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const [fileType, setFileType] = useState([]);
  const [fname, setFName] = useState([]);
  const [csvArray, setCsvArray] = useState([]);
  const inputRef = useRef(null);


  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    setSelectedFile(e.dataTransfer.files[0]);
    setFName(e.dataTransfer.files[0].name);
    setFileType(
      e.dataTransfer.files[0].name.split(".").pop(),
    );
  };

  const handleChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setFName((prevfiles) => [...prevfiles, e.target.files[0].name]);
    setFileType(
      (prevfiles) => [...prevfiles, e.target.files[0].name.split(".").pop()],
    );
  };

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    if (selectedFile.length < 1) return;
    FileArr(selectedFile);
    FileName(fname);
    setDocfiletype(fileType)
    const newImageURL = [];
    newImageURL.push(URL.createObjectURL(selectedFile))
    setlFileUrl(newImageURL);
    setFileSize(selectedFile.size);
  }, [selectedFile]);

  var FileArray = [];
  for (var i = 0; i < FileUrl.length; i++)
    FileArray[i] = [fileType[i], FileUrl[i], fname[i]];

  const handleRemoveFile = (index) => {
    setlFileUrl([
      ...FileUrl.slice(0, index),
      ...FileUrl.slice(index + 1, FileUrl.length),
    ]);
    setFName([
      ...fname.slice(0, index),
      ...fname.slice(index + 1, fname.length),
    ]);
    setFileType([
      ...fileType.slice(0, index),
      ...fileType.slice(index + 1, fileType.length),
    ]);
    setSelectedFile('');
    const result = FileArray.map((element) => element.slice(index));
  };

  useEffect(() => {
    const initial = [];
    setFileType(initial);
    setlFileUrl(initial);
    setFName(initial);
    setSelectedFile(initial);
  }, [submitted]);

  useEffect(() => {
    if (resetField === true) {
      const initial = [];
      setFileType(initial);
      setlFileUrl(initial);
      setFName(initial);
      setSelectedFile(initial);
      setInputFileFieldReset(false);
    }
  }, [resetField]);

  return (
    <>
      <FormWrap>
        <>
          {
            selectedFile.length === 0 ?
              <div className="mb-5">
                <form
                  id="form-file-upload"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <input
                    className="mb-5"
                    ref={inputRef}
                    type="file"
                    name={name}
                    value={value}
                    id="input-file-upload"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    accept=".pdf,.doc,.docx,image/*,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv"
                  />
                  <label
                    id="label-file-upload"
                    className={dragActive ? "drag-active" : ""}
                  >
                    <div>
                      <IconButton
                        className="upload-button"
                        onClick={handleButtonClick}
                      >
                        <UploadFileIcon fontSize="large" />
                      </IconButton>
                      <p className="upload-text">Drag and drop file here</p>
                      <p className="upload-format">
                        Supported formats (PNG, JPEG, JPG, Pdf, DOC, CSV,
                        XLS)
                      </p>
                    </div>
                  </label>
                  {dragActive && (
                    <div
                      id="drag-file-element"
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    ></div>
                  )}
                </form>
              </div>
              :
              <>
                {selectedFile && FileArray ? (
                  <span className="file-container">
                    {FileArray.slice(0, 10).map((item, index) => {
                      return (<>
                        <div className="imgRow mb-5 mt-5">
                          {item[0] == "pdf" || item[0] == "PDF" ? (
                            <Iframe className='img-fluid pdf_iframer' width="100%" scrolling="no" frameBorder="0" src={item[1]} />
                          ) : item[0] == "docx" ||
                            item[0] == "doc" ||
                            item[0] == "DOCX" ||
                            item[0] == "DOC" ? (

                            <FileViewer
                              fileType='docx'
                              filePath={item[1]}
                              width='100%'
                            />
                          ) : item[0] == "csv" ||
                            item[0] == "CSV" ||
                            item[0] == "xls" ||
                            item[0] == "xlsx" ||
                            item[0] == "XLS" ||
                            item[0] == "XLSX" ? (
                            <img
                              src="https://img.icons8.com/color/144/000000/microsoft-excel-2019--v1.png"
                              width="113px"
                              height="110px"
                              alt="Csv file"
                            />
                          ) : (
                            <img className="img-fluid" src={item[1]} />
                          )}
                          <IconButton
                            className="removebtn"
                            onClick={() => handleRemoveFile(index)}
                          >
                            <HighlightOffIcon fontSize="small" />
                          </IconButton>
                        </div>
                        <p className="text-center">{item[2]}</p>
                      </>);
                    })}
                  </span>
                ) : null}
              </>
          }
        </>
      </FormWrap>
    </>
  );
};
