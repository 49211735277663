import Styled from "styled-components";

const ApprovalWrap = Styled.div`

.table-view-card
{
    background-color: ${({ theme }) => theme["tableViewCard"]};
}
.modal-header{
 position: relative;
}
.bar-loader{
    margin-right: 6px;
}
.modal-header:after {
 background: linear-gradient(to right, #4285f4 25%,#34a853 25%, #fabb05 50%, #e94235 75%);
 position: absolute;
 content: '';
 height: 4px;
 right: 0;
 left: 0;
 bottom: 0;
}

.icon-color{
    color: #4EB6EE;
    width: 16px;
}


.approvePage .table-responsive {
    overflow: hidden;
    overflow-x: auto;
}

.approvePage table thead th {
    font-size: 14px;
    vertical-align: middle;
    white-space: nowrap;
}

.approvePage .actionBtns .btn
{
    // width: 100px;
    // margin: 5px;
}

@media (min-width: 992px) {
.approvePage table tbody tr td .mobHead
    {
        display:none
    }
}

@media (min-width: 576px) {
    .pagetitle
    {
        display:none;
    }

}

@media (max-width: 991px) {
    .approvePage table tbody tr td {
        // white-space: inherit;
    }
}
@media (max-width: 991px) {
               
.approvePage table thead th
{
    display:none;
}
.approvePage table tbody tr td {
    white-space: break-spaces;
    padding: 10px 15px !important;
    width: auto !important;
    // border: inherit;
    display: block;
}
.approvePage table tbody, 
.approvePage table td, 
.approvePage table tr {
    // border-width: 0 !important;
}
.approvePage table tr
{
   
}
.approvePage table.table-striped>tbody>tr:nth-of-type(odd)>* {
    // --bs-table-accent-bg: #fff;
}
.approvePage table tbody tr td.actionBtns {
    border-top: 1px solid #ddd;
    // --bs-table-accent-bg: #fbfbfb !important;
    border-bottom: 1px solid #ddd;
}
}
@media (max-width: 575px) {
    .pagetitle
    {
        color: ${({ theme }) => theme["themeColor"]};
    }

}


        
`;

export default ApprovalWrap;
