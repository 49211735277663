import { useMutation } from "react-query"
import { api } from "../Services/api"

export const useDelete = (queryKey, url, onSuccess, onError, select) => {

    return useMutation(
        [queryKey],
        async (id) => {
            return await api.delete(
                url + "/" + id, {
					headers: {
						authorizationToken: localStorage.getItem("token"),
					},
				}
            )
                .then((res) => {
                    return res.data
                })
        },
        {
            onSuccess,
            onError,
            select: select,
        })
}