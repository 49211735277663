import { setSessionTimeout } from "../redux/Auth/actionCreator"
import { api } from "../Services/api"
import { useQuery } from "react-query";
import { useDispatch } from "react-redux"

export const useFetch = (
  queryKey,
  url,
  onSuccess,
  onError,
  interval = false,
  enabled = false,
  select,
  initialData
) => {
  const dispatch = useDispatch()

  return useQuery(
    [queryKey],
    async () => {
      return await api
        .get(url, {
          headers: {
            authorizationToken: localStorage.getItem("token"),
            loadall: true
          }
        })
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // 401 is the status code for session timeout
            dispatch(setSessionTimeout(true))
            // dispatch(userLogout())
          }
        })
    },
    {
      refetchInterval: interval,
      onSuccess,
      onError,
      // cacheTime: 30000,
      enabled,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      select: select,
      initialData: initialData
    }
  )
}