import jwtDecode from "jwt-decode"

export const dateFormat = (date) => {
  if (!date) return "" // Handle undefined or empty input

  const splitDate = date.split(" ")
  if (splitDate.length < 2) return "" // Ensure the date is in the expected format

  const formattedDate = `${splitDate[0].slice(0, 3)} ${splitDate.slice(1).join(" ")}`
  return formattedDate
}

export const checkIfValid = (accessToken, user) => {
  const decoded = accessToken !== "" ? jwtDecode(accessToken) : ""
  if (decoded && user) {
    if (decoded.trackinguserid === user.trackinguserid) return true
    return false
  }
  return false
}

export const colors = ["#E60B59", "#FA9505", "#FBF206", "#34181C", "#50CE03", "#198CFD", "#BA02D1"]

export const getRandomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)]
}

export const normalizeNumber = (value) => {
  return value.replace(/[^0-9]/g, "")
}

export function fileToBase64(file) {
  if (!file) return

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = (error) => {
      reject(error)
    }
  })
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
