import styled from "styled-components";

const CartContainer = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
  }

  .cards {
    width: 100%;
    max-height: auto;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-color: ${({ theme }) => theme["border"]};
    border-radius: 10px;
    background-color: ${({ theme }) => theme["tableViewCard"]};
  }
  .plusIcon {
    font-size: 12px;
  }
  .customdropdown {
    background-color: white;
    padding: 1rem;
    position: absolute;
    right: 16px;
    top: 40px;
    z-index: 10;
    border-radius: 6px;
  }
  .cardBody {
    display: flex;
    flex-direction: column;
  }
  .cardheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .projectLabel {
    width: auto;
    padding: 0px 5px;
    border-radius: 10px;
    /* color: ${({ theme }) => theme["body-text"]}; */
    color: white;
  }
  .cardheader h1 {
    font-size: 15px;
    cursor: pointer;
    text-decoration: underline;
    text-transform: uppercase;
    /* margin-bottom: 8px; */
    color: ${({ theme }) => theme["body-text"]};
  }
  .cardIcon {
    color: #4eb6ee;
    cursor: pointer;
  }
  .cardcontent {
    color: ${({ theme }) => theme["body-text"]};
  }
  .projectName {
    font-size: 15px;
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }
  .cardfooter {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    gap: 0.5rem;
  }
  .footerBody {
    text-align: start;
    & p {
      margin: 0;
    }
  }
  .footerIcon {
    & h3 {
      font-size: 1rem;
      color: #2685E2;
      margin: 0;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: #292A29;
    flex-shrink: 0;
  }
  .footerContent p {
    color: ${({ theme }) => theme["body-text"]};
    font-size: 15px;
    max-width: auto;
    white-space: pre-wrap;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    opacity: 0.8;
  }
  .workflow {
    color: ${({ theme }) => theme["body-text"]};
  }
  .cardTask {
    color: #00d5e6;
    font-size: 15px;
  }
  .dropdown-toggle {
    background-color: transparent;
    outline: none;
    border: none;
    &::after {
      display: none !important;
    }
  }
  .dropdown-toggle:focus {
    background-color: transparent;
    border: none;
    outline: none;
  }

  .dropdown-menu.show {
    height: auto;
    min-height: 40px !important;
    min-width: 90px !important;
    overflow-x: hidden;
  }
  .dropdown-item {
  padding: 4px 4px 2px 8px;
  text-align: start;
  &:hover {
    background-color: hsla(201, 82%, 62%, 0.2) !important;
  }
 }
  .dropenu {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    button {
      padding: 2px 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border: none;
      outline: none;
      border-radius: 8px;
      font-size: 14px;
      display: inline-block;
    }
  }
  @media (min-width: 280px) and (max-width: 320px) {
    .card {
      padding: 8px;
    }
    .cardheader h1 {
      font-size: 10px;
      /* margin-bottom: 0px; */
    }
    .moreIcon {
      font-size: 1rem;
    }
    .cardIcon {
      font-size: 12px;
    }
    .cardcontent p {
      font-size: 10px;
      margin-bottom: 0.8rem;
    }
    .footerIcon {
      & h3 {
        font-size: 6px;
        color: #ffffff;
        margin: 0;
      }
      width: 10px;
      height: 10px;
    }
    .footerContent p {
      font-size: 10px;
    }
    .cardTask p {
      font-size: 10px;
    }
    .dropdown-toggle {
      padding: 2px;
    }
    .dropenu {
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0px;

      button {
        padding: 1px 2px;
        /* width: 50%; */
        border: none;
        outline: none;
        border-radius: 8px;
        font-size: 10px;
      }
    }
  }
  @media screen and (min-width: 320px) and (max-width: 480px) {
    .cardheader h1 {
      font-size: 12px;
    }
    .moreIcon {
      font-size: 1rem;
    }
    .cardcontent p {
      font-size: 12px;
    }
    .footerContent p {
      font-size: 12px;
    }
    .cardTask p {
      font-size: 12px;
    }
    .dropenu {
      padding: 4px 0px 4px 0px;
      gap: 1px;

      button {
        padding: 1px 2px;
        margin-bottom: 3px;
        border: none;
        outline: none;
        border-radius: 8px;
        font-size: 12px;
      }
    }
  }

  @media screen and (min-width: 481px) and (max-width: 720px) {
    .cardheader h1 {
      font-size: 12px;
    }
    .moreIcon {
      font-size: 1rem;
      color: #4eb6ee;
      cursor: pointer;
    }
    .cardcontent p {
      font-size: 12px;
    }
    .footerContent p {
      font-size: 12px;
    }
    .cardTask p {
      font-size: 12px;
    }
    .plusIcon {
      font-size: 12px;
    }
  }
  @media screen and (min-width: 720px) and (max-width: 900px) {
    .card {
      width: 100%;
      max-height: auto;
    }
    .cardheader h1 {
      font-size: 12px;
    }
    .moreIcon {
      font-size: 1rem;
      cursor: pointer;
    }
    .cardcontent p {
      font-size: 12px;
    }
    .footerContent p {
      font-size: 12px;
    }
    .cardTask p {
      font-size: 12px;
    }
  }
`;
export default CartContainer;
