import styled from "styled-components";

const CardWrap = styled.div`
  .cardWrap {
    /* min-height: calc(100vh - 130px); */
    /* width: 400px; */
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: stretch;
    /* padding: 1rem; */
    gap: 1rem;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .section {
    min-width: 350px;
    max-width: 300px;
    padding: 4px 4px 100% 4px;
    gap: 1rem;
    overflow-y: scroll;
    border-radius: 10px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    /* border: 2px solid hsla(201, 82%, 62%, 0.8); */
  }

  .closed {
    /* border: 2px solid hsla(124, 82%, 62%, 0.8); */
  } 
  .cardWrap::-webkit-scrollbar {
    height: 4px;
    background-color: transparent;
  }
  .cardWrap::-webkit-scrollbar-thumb:horizontal {
    background-color: #3e3f3e;
    border-radius: 10px;
  }
  .section::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }

  .stateNav {
    font-size: 15px;
    padding-left: 12px;
    color: ${({ theme }) => theme["body-text"]};
    background: ${({ theme }) => theme["panel-color"]};
  }
  .Error {
    width: 100%;
    padding: 10px 10px;
    /* max-height: 150px; */
    height: auto;
    border-color: ${({ theme }) => theme["border"]};
    background-color: ${({ theme }) => theme["tableViewCard"]};
    border-radius: 12px;
    position: relative;
  }
  .Error-card{
     width: 100%;
    padding: 1.1rem;
    border-color: ${({ theme }) => theme["border"]};
    background-color: ${({ theme }) => theme["tableViewCard"]};
    border-radius: 10px;
  }
  .error-text {
  letter-spacing: 1px;
  font-size: 18px !important;
  }
  .emptyDtat {
      button{
        color: ${({ theme }) => theme["body-text"]};
      }
      /* display: flex; */
      /* justify-content: center; */
      /* align-items: center; */
      padding: 0px 12px;
      color: ${({ theme }) => theme["body-text"]};
      font-size: 15px;
      .card {
        box-shadow: none !important;
      }
      .form-label {
        margin: 0;
        padding-top: 4px;
      }
      .loginformbody  {
        padding: 6px;
      }
      .ant-picker {
        background-color: ${({ theme }) => theme["tableViewCard"]};
        border: 1px solid ${({ theme }) => theme["border"]}!important;
      }

      input {
        color: ${({ theme }) => theme["body-text"]} !important;
      }
  }

  @media (max-width: 1669px) {
    .section {
      min-width: 330px;
      max-width: 300px;
    }
  }


  @media (max-width: 1505px) {
    .section {
      min-width: 320px;
      max-width: 300px;
    }
  }

  @media (max-width: 1373px) {
    .section {
      min-width: 300px;
      max-width: 300px;
    }
  }

  @media (max-width: 1278px) {
    /* .cardWrap {
      justify-content: space-between;
    } */
    .section {
      min-width: 260px;
      max-width: 300px;
    }
    .Error {
      width: 100%;
    }
  }

  /* Worst cast scenario 866px */

  @media (min-width: 768px) and (max-width: 900px) {
    .cardWrap {
      justify-content: space-between;
    }
    .section {
      min-width: 260px;
      max-width: 260px;
    }
    .Error {
      width: 100%;
      padding: 1.2rem;
    }
    .emptyDtat {
      button {
        font-size: 12px;
      }
      p {
        font-size: 12px;
      }
    }
  }
  @media (min-width: 321px) and (max-width: 767px) {
    .cardWrap {
      justify-content: space-between;
    }
    .section {
      min-width: 260px;
      max-width: 260px;
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow-y: scroll;
    }
    .Error {
      width: 100%;
      padding: 1.2rem;
    }
    .emptyDtat {
      button {
        font-size: 12px;
      }
      p {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 320px) {
    .cardWrap {
      height: calc(100vh - 130px);
      padding: 1rem;
      display: flex;
      gap: 1.5rem;
      overflow-y: hidden;
      justify-content: space-between;
    
      overflow-x: scroll;
    }
    .section {
      min-width: 260px;
      max-width: 260px;
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow-y: scroll;
    }
    .Error {
      width: 100%;
    }
    .stateNav {
      font-size: 12px;
    }
    .Error {
      padding: 19px;
    }
    .emptyDtat {
      button {
        font-size: 12px;
        margin-bottom: 6px;
      }
      p {
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export default CardWrap;
