import React from "react"
import { Row, Col } from "react-bootstrap"

export const MyDashboardLishHeader = () => {
  return (
    <>
      <Row>
        <Col>
          <p className='myDashboard_current_title'>Current Tasks</p>
        </Col>
      </Row>
    </>
  )
}
