import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import TableWrap from "./style"
import { Card, Table, Row, Col, Alert, Spinner, Button } from "react-bootstrap"
import InfiniteScroll from "react-infinite-scroll-component"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import Copy from "copy-to-clipboard"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import { useMediaQuery } from "@mui/material"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import CopyIcon from "../../../assets/images/icons/copy.svg"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import BlockIcon from "@mui/icons-material/Block"
import trashIcon from "../../../assets/images/icons/bluedelete12.svg"
import moment from "moment"
import { useFetch } from "../../../Hooks/useFetch"
import { key } from "../../../data/queryKeys"

const Tables = (props) => {
  const {
    data = [],
    setHistory,
    setShowEditTask,
    setTaskview,
    setTrackingItemData,
    setProjectTable,
    selected_menu,
    setDeleteConfirmationShow,
    fetchAllTracking,
    deletePermission,
    addColumns,
    setAllCheckTable,
    allcheckTable,
    handleMoveCheckboxTable
  } = props

  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)
  let allTrackingsList = useSelector((state) => state.Tracking.allTrackings)
  let myTrackingList = useSelector((state) => state.Tracking.myTrackings)

  const { data: allProjectMembers } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMembers = allProjectMembers?.data ?? []

  const { data: allTableHeaders } = useFetch(
    key.get_table_headers,
    `/masterdata/3`,
    () => {},
    () => {},
    false,
    false
  )

  let allTaskHeadersNames = allTableHeaders?.data ?? []

  const largeScreen = useMediaQuery("(min-width:991px)")

  const resetState = () => {
    setHistory("")
    setShowEditTask({
      editTitle: false,
      editProject: false,
      editTaskType: false,
      editReportedBy: false,
      editAssignedTo: false,
      editPriority: false,
      editTimeline: false,
      editStatus: false,
      editDescription: false,
      editfileurls: false
    })
  }
  const [tableData, setTableData] = useState(data)
  useEffect(() => {
    setTableData(data)
  }, [data])

  const handleDragEnd = (e) => {
    if (!e.destination) return
    let tempData = Array.from(tableData)
    let [source_data] = tempData.splice(e.source.index, 1)
    tempData.splice(e.destination.index, 0, source_data)
    setTableData(tempData)
  }

  const checkIconStyle = {
    color: "#4EB6EE",
    fontSize: "14px",
    marginTop: "-2%",
    paddingRight: "2px"
  }
  const copytextStyle = {
    color: "#4EB6EE",
    fontSize: "10px",
    padding: "3px"
  }

  let columnValues = {}

  for (let i = 0; i < allTaskHeadersNames.length; i++) {
    columnValues.column1 = allTaskHeadersNames[i].column1
    columnValues.column2 = allTaskHeadersNames[i].column2
    columnValues.column3 = allTaskHeadersNames[i].column3
    columnValues.column4 = allTaskHeadersNames[i].column4
    columnValues.column5 = allTaskHeadersNames[i].column5
    columnValues.column6 = allTaskHeadersNames[i].column6
    columnValues.column7 = allTaskHeadersNames[i].column7
    columnValues.column8 = allTaskHeadersNames[i].column8
    columnValues.column9 = allTaskHeadersNames[i].column9
  }

  const DropableTable = () => {
    return (
      <Droppable droppableId='droppable-1'>
        {(provider) => (
          <tbody ref={provider.innerRef} {...provider.droppableProps}>
            {tableData.map((item, i) => {
              const handleCopyIcon = (e) => {}

              const copyTicketNumber = (e) => {
                var text = trackingClientId + "-" + item.trackingid
                Copy(text)
              }
              return (
                <Draggable key={item.trackingid} draggableId={item.trackingid.toString()} index={i}>
                  {(provider) => (
                    <tr
                      className='all_table_row'
                      val={item}
                      {...provider.draggableProps}
                      ref={provider.innerRef}
                      onClick={(e) => {
                        setTaskview("taskview")
                        setTrackingItemData(
                          (selected_menu === "all_tasks" ? allTrackingsList : myTrackingList).find(
                            (element) => element.trackingid === item.trackingid
                          )
                        )
                        resetState()
                      }}
                    >
                      {largeScreen && (
                        <>
                          <td {...provider.dragHandleProps} className='drag-indicator'>
                            <div className='d-flex justify-content-between align-items-center gap-2'>
                              <div className='drag-icon'>
                                <DragIndicatorIcon />
                              </div>
                              <input
                                type='checkbox'
                                name='group1'
                                className='table_check_box'
                                onChange={() => handleMoveCheckboxTable(item, i)} // Wrap the function call inside an arrow function
                                checked={allcheckTable?.some((row) => row.index === i)}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </div>
                          </td>
                        </>
                      )}
                      <td className='text-nowrap'>
                        <strong className='mobHead'>Task No. : </strong>
                        <span className='tracking-id'>
                          {trackingClientId}-{item.trackingid}
                          {item.priority === "blocker" || item.priority === "blocker2" ? (
                            <BlockIcon
                              className='block-icon'
                              style={{
                                cursor: "pointer",
                                color: "red",
                                fontSize: "17px",
                                margin: "0px 0px 3px 0px"
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </span>
                        <div
                          style={{
                            width: 20,
                            height: 20,
                            display: "inline-block",
                            padding: "6px 0px 3px 3px",
                            cursor: "pointer"
                          }}
                          id={`showicon-index-${i}`}
                          onMouseEnter={handleCopyIcon}
                          onMouseLeave={(e) => {}}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <OverlayTrigger
                            trigger='click'
                            rootClose='false'
                            placement='top'
                            overlay={
                              <Popover style={copytextStyle}>
                                <CheckCircleIcon style={checkIconStyle} />
                                Copied
                              </Popover>
                            }
                          >
                            <img
                              src={CopyIcon}
                              height={15}
                              width={15}
                              alt='copyIcon'
                              style={{ display: "block" }}
                              onClick={copyTicketNumber}
                            />
                          </OverlayTrigger>
                        </div>
                      </td>
                      <td>
                        <strong className='mobHead'>Title : </strong>
                        <span>{item.title}</span>
                      </td>
                      <td className='text-capitalize'>
                        <strong className='mobHead'>Task type : </strong>
                        <span>{item.taskName}</span>
                      </td>
                      <td className='text-capitalize'>
                        <strong className='mobHead'>Status : </strong>
                        <span>{item.status_name}</span>
                      </td>

                      <td className='text-capitalize'>
                        <strong className='mobHead'>Timeline : </strong>
                        {item.timeline !== "" ? (
                          <span>{item.timeline} hr</span>
                        ) : (
                          <span className='timeline_isempty'>-</span>
                        )}
                      </td>

                      <td>
                        <strong className='mobHead'>Reported On : </strong>
                        <span>
                          {item.reporteddate !== ""
                            ? moment(item.reporteddate).format("MMM D, YYYY hh:mm A")
                            : "-"}
                        </span>
                      </td>
                      <td className=''>
                        <strong className='mobHead'>Reported By : </strong>
                        <span>
                          {item.reportedby !== ""
                            ? item.reportedByName ||
                              allMembers.map((member) => {
                                if (member.userId === item.reportedby) {
                                  return member.personname
                                }
                                return ""
                              })
                            : "-"}
                        </span>
                      </td>
                      {selected_menu === "all_tasks" && (
                        <td className=''>
                          <strong className='mobHead'>Assigned To : </strong>
                          <span>
                            {item.assignedto !== "" ? item.assignedtoName : "Not Assigned"}
                          </span>
                        </td>
                      )}
                      <td className='text-nowrap'>
                        <strong className='mobHead'>Priority : </strong>
                        <span className='text-capitalize'>
                          {item.priority !== ""
                            ? item.priority === "blocker"
                              ? "Blocker Level 1"
                              : item.priority === "blocker2"
                              ? "Blocker Level 2"
                              : item.priority === ""
                              ? "-"
                              : item.priority
                            : "-"}
                        </span>
                      </td>
                      <td
                        style={{ width: "300", whiteSpace: "nowrap" }}
                        className='text-center text-nowrap'
                      >
                        {deletePermission && (
                          <Button
                            size='sm'
                            variant='link'
                            className='text-decoration-none pe-1 ps-1'
                            onClick={(e) => e.stopPropagation()}
                          >
                            <img
                              alt='img'
                              src={trashIcon}
                              onClick={(e) => {
                                setDeleteConfirmationShow(true)
                                setTrackingItemData(item)
                              }}
                            />
                          </Button>
                        )}
                      </td>
                    </tr>
                  )}
                </Draggable>
              )
            })}
            {provider.placeholder}
          </tbody>
        )}
      </Droppable>
    )
  }

  return setProjectTable ? (
    !props.loading ? (
      <Card className='border-0 mb-3 table-view-card'>
        <Card.Body>
          <Row>
            <Col sm={12} className='mb-2'>
              {props.header}
            </Col>
            {props.extra ? (
              <Col sm={12} className='mb-2'>
                {props.extra}
              </Col>
            ) : (
              <></>
            )}
            <Col md={12}>
              {props.data?.length > 0 ? (
                <TableWrap>
                  {props.showInfiniteScroll ? (
                    <InfiniteScroll
                      refreshFunction={props.fetchAllTracking}
                      dataLength={props.data.length}
                      next={props.fetchAllTracking}
                      hasMore={
                        props.startTrackingId !== "none" && props.data.length > 7 ? true : false
                      }
                      loader={
                        <div className='text-center mt-5 mb-5'>
                          <Spinner animation='border' variant='info' />
                        </div>
                      }
                    >
                      <Table
                        className='mb-0'
                        striped
                        responsive
                        bordered
                        hover
                        size='sm md lg xl xxl'
                      >
                        <thead>
                          <tr>{props.columns}</tr>
                        </thead>
                        <tbody>{props.data}</tbody>
                      </Table>
                    </InfiniteScroll>
                  ) : (
                    <Table
                      className='mb-0'
                      striped
                      responsive
                      bordered
                      hover
                      size='sm md lg xl xxl '
                    >
                      <thead>
                        <tr>{props.columns}</tr>
                      </thead>
                      <tbody>
                        {props.data}
                        {addColumns}
                      </tbody>
                    </Table>
                  )}
                </TableWrap>
              ) : (
                <Alert style={{ height: "auto" }} className='text-center mt-2' variant='primary'>
                  {props.notfound}
                </Alert>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    ) : (
      <div className='text-center mt-5 mb-5'>
        <Spinner animation='border' variant='info' />
      </div>
    )
  ) : (
    <Card className='border-0 mb-3 table-view-card'>
      <Card.Body>
        {!props.loading ? (
          <Row>
            <Col sm={12} className='mb-2'>
              {props.header}
            </Col>
            {props.extra ? (
              <Col sm={12} className='mb-2'>
                {props.extra}
              </Col>
            ) : (
              <></>
            )}
            <Col md={12}>
              {props.data?.length > 0 ? (
                <TableWrap>
                  {props.showInfiniteScroll ? (
                    <InfiniteScroll
                      dataLength={props.data.length}
                      next={props.fetchAllTracking}
                      hasMore={
                        props.startTrackingId !== "none" && props.data.length > 0 ? true : false
                      }
                      loader={
                        <div className='text-center mt-5 mb-5'>
                          <Spinner animation='border' variant='info' />
                        </div>
                      }
                    >
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Table
                          className='mb-0'
                          striped
                          responsive
                          bordered
                          hover
                          size='sm md lg xl xxl '
                        >
                          <thead>
                            <tr>{props.columns}</tr>
                          </thead>

                          <DropableTable />
                        </Table>
                      </DragDropContext>
                    </InfiniteScroll>
                  ) : (
                    <Table
                      className='mb-0'
                      striped
                      responsive
                      bordered
                      hover
                      size='sm md lg xl xxl '
                    >
                      <thead>
                        <tr>{props.columns}</tr>
                      </thead>

                      <DropableTable />
                    </Table>
                  )}
                </TableWrap>
              ) : (
                <Alert style={{ height: "auto" }} className='text-center mt-2' variant='primary'>
                  {props.notfound}
                </Alert>
              )}
            </Col>
          </Row>
        ) : (
          <div className='text-center mt-5 mb-5'>
            <Spinner animation='border' variant='info' />
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default Tables
