import React from "react"
import { Box } from "@mui/material"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Doughnut } from "react-chartjs-2"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"
import { useSelector } from "react-redux"

ChartJS.register(ArcElement, Tooltip, Legend)

const StorageInformation = () => {
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)

  const { data: planInformation } = useFetch(
    key.get_trial_period,
    `/trackingadmin/clients/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false,
    (data) => data?.client
  )

  const data = {
    labels: ["Used Storage in MB ", "Remaining Storage in MB"],
    datasets: [
      {
        data: [planInformation?.storageUsed, planInformation?.storageRemaining],
        backgroundColor: ["rgba(230, 0, 0, 0.9)", "rgba(0, 132, 255, 1)"],
        borderColor: ["rgba(230, 0, 0, 0.9)", "rgba(255, 255, 255, 1)"],
        borderWidth: 1
      }
    ]
  }

  return (
    <Box sx={{ width: "400px" }}>
      <Doughnut data={data} />
    </Box>
  )
}

export default StorageInformation
