import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import moment from 'moment';
const DoughnutChart = ({priorityCount, allProjectDashboard}) => {

  const priorityArray = Object.entries(priorityCount || {})?.map(([name, value]) => ({ name, value }));
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const sum = priorityArray.reduce((accumulator, obj) => accumulator + obj.value, 0);

  useEffect(() => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }
    drawChart();
  }, [priorityCount]);

let colorArray = [];
let colorAr = ['#B100FF', '#D06CFC', '#E1A7FA', '#F9EAFF',"#724187","#5f407c"];

function generateRandomColor() {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);
  const color = '#' + red.toString(16) + green.toString(16) + blue.toString(16);
  return color;
}

for (let i = 0; i < priorityArray.length; i++) {
  const randomColor = generateRandomColor();
  colorArray.push(randomColor);
}
  const drawChart = () => {
    const chartCanvas = chartRef.current.getContext('2d');
    
    chartInstanceRef.current = new Chart(chartCanvas, {
      type: 'doughnut',
      data: {
        labels : priorityArray.map((item)=> item.name),
        datasets: [
          {
            label: '',
            data: priorityArray.map((item)=> item.value),
            borderColor: 'transparent',
            backgroundColor: (priorityArray.length >= 5) ? colorAr : colorArray,
          },
        ],
      },

      options: {
        cutout: '70%',
        spacing: 0,
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              boxWidth: 10,
              color: 'white',
            },
          },
        },
      },
       plugins : [{
        beforeDraw: function(chart) {
         var width = chart.width,
             height = chart.height,
             ctx = chart.ctx;
             ctx.restore();
             var fontSize = "3";
             ctx.font = fontSize + "em sans-serif";
             ctx.textBaseline = "middle";
             ctx.fillStyle = "white";
             var text = `${sum}`,
             textX = Math.round((width - ctx.measureText(text).width) / 2),
             textY = height / 2 - 20;
             ctx.fillText(text, textX, textY);
             ctx.save();
        }   
       }
   ]   
    });
  };

  return (
    <div className='project_dashboard_chart p-2'>
      <p className='mb-0 text-white'>Tasks by Priority</p>
      <div className='doughnut_chart_container p-1'>
        <canvas ref={chartRef} className='myDashboard_doughnut_chart' />
      </div>
    </div>
  );
};

  export default DoughnutChart;