import React from "react";
import { useSelector } from "react-redux";

export const TaskListColumns = () => {
    const reportTasksHeaderNames = useSelector((state) => state.Tracking.reportTasksHeaderNames);

    let ReportTaskscolumn = [];

    for (let i = 0; i < reportTasksHeaderNames.length; i++) {
        ReportTaskscolumn.push(reportTasksHeaderNames[i].column1)
        ReportTaskscolumn.push(reportTasksHeaderNames[i].column2)
        ReportTaskscolumn.push(reportTasksHeaderNames[i].column3)
        ReportTaskscolumn.push(reportTasksHeaderNames[i].column4)
        ReportTaskscolumn.push(reportTasksHeaderNames[i].column5)
        ReportTaskscolumn.push(reportTasksHeaderNames[i].column6)
        ReportTaskscolumn.push(reportTasksHeaderNames[i].column7)
        ReportTaskscolumn.push(reportTasksHeaderNames[i].column8)
    }

    return (
        <>
            {
                ReportTaskscolumn.map((item, index) => {
                    return(
                        <th className="text-center" key={index}>
                            {item}
                        </th>  
                    )
                })
            }
        </>
    );
};