import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import { TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl } from 'react-bootstrap';
import usePrevious from '../../../Hooks/usePrevious';


const EditWorkFlow = ({ column, editSwitch }) => {

    const prevColumnName = usePrevious(column.name)
    const [columnName, setColumnName] = useState(column.name)

    useEffect(() => {
        setColumnName(column.name)
    }, [column.edit])

    return (<div className='editWorkFlow'>
        {column.edit ?
            <div className='px-2 d-flex justify-content-center align-items-center'>

                <FormControl
                    className='ps-2'
                    id="basic-textField"
                    variant="standard"
                    value={columnName}
                    onChange={(e) => { setColumnName(e.target.value) }}
                />
                <CheckIcon className='ms-auto me-2' onClick={() => { editSwitch(column.name, false, columnName, true) }} />
                <CloseIcon onClick={() => { editSwitch(column.name, false, prevColumnName) }} />

            </div>

            : <div className="px-2 d-flex justify-content-between align-items-center">
                <h4 className="workFlowStep" style={{ minHeight: "21.59px" }} onClick={() => { editSwitch(column.name, true, columnName) }}>{column.name}</h4>
                {/* <EditIcon onClick={() => { editSwitch(column.name, true, columnName) }} /> */}
            </div>}
    </div>
    )
}

export default EditWorkFlow