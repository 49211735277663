const regex = {
  email: new RegExp(
    "^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$"
  ),
  number: new RegExp("^[0-9]+$"),
  numberWithDotComma: new RegExp("([0-9]+[.,]*)+"),
  dollerNum: new RegExp("^[0-9.$,]{1,20}$"),
};

export class Validators {
  static email(value, message) {
    if (value) {
      const result = regex.email.test(value);
      if (!result) return { error: true, message };
    }
    // return false;
  }
  static dollerNum(value, message) {
    if (value) {
      const result = regex.dollerNum.test(value);
      if (!result) return { error: true, message };
    }
    return false;
  }

  static required(value, message) {
    if (!value || !value.toString().trim().length) {
      return { error: true, message };
    }
    return false;
  }

  static password(value, message) {
    const length = value ? value.toString().length : 0;
    if (length < 6) return { error: true, message };
  }

  static percent(value, message) {
    if (value > 100) return { error: true, message };
  }

  static number(value, message) {
    const length = value ? value.toString().length : 0;

    if (length > 0) {
      const result = regex.number.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }
}

export const validateInput = (validators, value) => {
  const convertedValidators = validators?.map(validator => {
    const checkFunction = eval(validator.check);
    return {
      check: checkFunction,
      message: validator.message
    };
  });
  if (convertedValidators && convertedValidators?.length) {
    for (let i = 0; i < convertedValidators?.length; i++) {
      const { check, message } = convertedValidators[i];
      const error = check(value, message);
      if (error) {
        return error;
      }
    }
  }
  return false;
};
