import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Alert } from "react-bootstrap"
import Tables from "../../../Reuseable/Tables"
import { api } from "../../../../Services/api"
import "react-confirm-alert/src/react-confirm-alert.css"
import { useSessionStorage } from "react-use"
import ProjectsModalView from "../Projects/style"
import { BacklogsListColumns } from "./BacklogsListColumns"
import { BacklogsListHeader } from "./BackLogsListHeader"
import { BacklogsRefineModalBox } from "../../../Reuseable/BacklogsRefineModalBox"
import { key } from "../../../../data/queryKeys"
import { useFetch } from "../../../../Hooks/useFetch"
import { client } from "../../../.."
import moment from "moment"
import { BacklogsView } from "./BacklogsView"
import { setAllBacklogsTaskView, setAllTrackings } from "../../../../redux/Tracking/actionCreator"
import { ConfirmationBox } from "../../../Reuseable/ConfimationBox"
import { useDelete } from "../../../../Hooks/useDelete"
import BackLogsViewWrap from "./BacklogsView/style"

export default function BacklogsData({ permission }) {
  const editPermission = permission?.edit
  const createPermission = permission?.create
  const deletePermission = permission?.delete

  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)

  const { data: allProjectWorkflowRes } = useFetch(
    key.get_all_workflow,
    `/trekrworkflow`,
    () => {},
    () => {},
    false,
    false
  )
  let allWorkFlow = allProjectWorkflowRes?.data?.workflowList ?? []

  const { data: allTasksTypesRes } = useFetch(
    key.get_all_tasktypes,
    "/trackingtasktype",
    () => {},
    () => {},
    false,
    false
  )
  let allTasksTypes = allTasksTypesRes?.data ?? []

  const { data: allProjectMembers } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMembers = allProjectMembers?.data ?? []

  const { data: allProjectsRes } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allProjectsData = allProjectsRes?.data?.Items ?? []

  let allBacklogsView = useSelector((state) => state.Tracking.allBacklogsView)
  let allTrackingsList = useSelector((state) => state.Tracking.allTrackings)

  const dispatch = useDispatch()
  let allProjects = useSelector((state) => state.Tracking.projects)
  const user = useSelector((state) => state.Auth.user)
  const authToken = useSelector((state) => state.Auth.authToken)
  const [searchValue, setSearchValue] = useState("")
  const [refreshLoading, setRefreshLoading] = useState(false)
  const [showAlertBox, setShowAlertBox] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [showView, setShowView] = useState(false)

  /***********************************************/
  const allFlowStatus = useSelector((state) => state.Tracking.allFlowStatus)
  const [fileLoading, setFileLoading] = useState(false)
  const [viewTaskData, setViewTaskData] = useState({})
  const [taskfileList, setTaskFileList] = useState("")
  const [taskfileType, setTaskFileType] = useState("")
  const [showEditTask, setShowEditTask] = useState("")
  const [updatedData, setUpdatedData] = useSessionStorage("trackingUpdatedData", {})
  const [taskHistory, setHistory] = useState("")
  const [showClone, setShowClone] = useState(false)
  const [taskfile, setTaskFile] = useState("")
  const [clonetaskData, setCloneTask] = useState({})
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false)

  const [trackingItemData, setTrackingItemData] = useSessionStorage("backLogsTrackingData", {})
  const [refineConfirmationShow, setRefineConfirmationShow] = useState(false)

  useEffect(() => {
    setViewTaskData((prevState) => {
      return {
        ...prevState,
        title: trackingItemData.title,
        projectid: trackingItemData.trackingprojectsid,
        taskType: trackingItemData.type,
        assignedTo: trackingItemData.assignedto,
        priority: trackingItemData.priority,
        status: trackingItemData.trackingprojectstatusid,
        description: trackingItemData.description,
        timeline: trackingItemData.timeline,
        fileurls: trackingItemData.fileurls,
        filesize: trackingItemData.filesize ?? [],
        fileArray: []
      }
    })
    setCloneTask((prevState) => {
      return {
        ...prevState,
        title: trackingItemData.cloneTitle,
        assignedto: ""
      }
    })
    setUpdatedData((prevState) => {
      return {
        ...prevState,
        title: trackingItemData.title,
        description: trackingItemData.description,
        fileurls: trackingItemData.fileurls,
        timeline: trackingItemData.timeline,
        filesize: trackingItemData.filesize
      }
    })
  }, [trackingItemData])
  /***********************************************/

  const {
    data: allBacklogsRes,
    isLoading: loading,
    isFetching,
    refetch: refetchBackLogs
  } = useFetch(
    key.get_backlogs_data,
    `/backloglist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    true // !client.getQueryData([key.get_backlogs_data])
  )
  let allBacklogs = allBacklogsRes?.data ?? []

  const [selectedRows, setSelectedRows] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [searchColumn, setColumnName] = useState("")

  const [trackingSortings, setTrackingSorting] = useState({
    selectedSortingItem: "trackingid",
    trackingNumberSorting: "down",
    statusSorting: "",
    projectNameSorting: "",
    reportedBySorting: "",
    prioritySorting: "",
    assignedToSorting: ""
  })

  let RefinedItems = []
  const refineTrackingId = selectedRows.map((item) => {
    RefinedItems.push(item.item?.trackingid)
  })

  const refreshAllTracking = () => {
    refetchBackLogs()
  }

  const selected_menu = "Tablebacklogs"

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
    }, 2500)
    setSelectedRows([])
  }, [showAlertBox])

  const onDeleteSuccess = async (res) => {
    if (res.status) {
      setShowAlertBox(true)
      dispatch(setAllBacklogsTaskView("backlogsview"))
      let index = allBacklogs.findIndex(
        (element) => element.trackingid === trackingItemData.trackingid
      )

      if (index !== -1) {
        allBacklogs.splice(index, 1)
      }

      await client.setQueryData([key.get_backlogs_data], (oldData) => {
        return {
          ...oldData,
          data: allBacklogs
        }
      })
      setAlertMessage("Selected item has been deleted successfully")
    }
  }

  const { mutate: deleteTask } = useDelete(
    "delete-task",
    "/backloglist/" + trackingClientId,
    onDeleteSuccess,
    () => {}
  )

  const deleteConfimrationHandler = (response) => {
    if (response) {
      deleteTask(trackingItemData.trackingid)
    }
  }

  const FetchBacklogsRetain = () => {
    if (refineTrackingId) {
      let obj = {
        trackingid: RefinedItems
      }
      api
        .patch("/backloglist/" + trackingClientId, obj, {
          headers: {
            authorizationtoken: ` ${authToken}`
          }
        })
        .then(function (response) {
          if (response.status === 200) {
            setShowAlertBox(true)

            let index = allBacklogs.filter((element) => RefinedItems.includes(element?.trackingid))
            index.forEach((item) => {
              dispatch(setAllTrackings([...allTrackingsList, item]))
              const itemIndex = allBacklogs.findIndex(
                (element) => element.trackingid === item?.trackingid
              )
              if (itemIndex >= 0) {
                allBacklogs.splice(itemIndex, 1)
              }
            })
            setAlertMessage("Selected item has been moved to All Tasks successfully")
          }
        })
    }
  }

  const handleCheckboxChange = (item, index) => {
    if (selectedRows.some((row) => row.index === index)) {
      setSelectedRows((prevSelectedRows) => prevSelectedRows.filter((row) => row.index !== index))
    } else {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, { item, index }])
    }
  }

  const handleHeaderCheckboxChange = () => {
    if (selectAll) {
      setSelectedRows([])
    } else {
      const allRowIndexes = allBacklogs.map((item, index) => ({ item, index }))
      setSelectedRows(allRowIndexes)
    }
    setSelectAll((prevSelectAll) => !prevSelectAll)
  }

  const handleColumnSearch = (value) => {
    setColumnName(value)
  }

  const getPriorityClass = (priority) => {
    if (
      priority === "blocker" ||
      priority === "Blocker Level 1" ||
      priority === "Blocker Level 2" ||
      priority === "blocker2"
    ) {
      return "priority-red"
    } else if (priority === "Medium" || priority === "medium") {
      return "priority-Lightgreen"
    } else if (priority === "Major" || priority === "major") {
      return "priority-orange"
    } else if (priority === "Minor" || priority === "minor") {
      return "priority-white"
    } else {
      return ""
    }
  }

  const getTableData = allBacklogs
    .sort((item1, item2) => {
      if (trackingSortings.selectedSortingItem === "priority") {
        if (trackingSortings.prioritySorting) {
          if (trackingSortings.prioritySorting === "down") {
            if (item1.priority < item2.priority) return 1
            else return -1
          } else {
            if (item1.priority < item2.priority) return -1
            else return 1
          }
        }
      }
      return item2.trackingid - item1.trackingid
    })
    .filter((item) => {
      let trackingnumber = trackingClientId + "-" + item.trackingid
      let trackingnumber1 = trackingClientId + item.trackingid
      return (
        trackingnumber?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        trackingnumber1?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.title?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.projectname?.toLowerCase().includes(searchValue?.toLowerCase())
      )
    })
    .map((item, i) => {
      let task = allTasksTypes.find((e) => e.id == item.type)
      if (task) item.taskName = task.label
      const Work_Flow = Object.values(allWorkFlow).flatMap((obj) => Object.values(obj))
      let workflow = Work_Flow.find((e) => e.workFlowId == item.workflowid)
      if (workflow) item.workflow = workflow?.workFlow
      const statusName =
        item.workflow && item.workflow[item.trackingprojectstatusid]
          ? item.workflow[item.trackingprojectstatusid]
          : ""
      if (statusName) {
        item.status_name = statusName ? statusName && Object.values(statusName) : ""
      }
      let projetName =
        allProjectsData.find((ele) => ele.trackingprojectid == item.trackingprojectsid)?.label ?? ""

      return (
        <tr
          key={i}
          val={item}
          onClick={(e) => {
            let findBacklogsItem = allBacklogs.find((itm) => itm.trackingid == item.trackingid)
            setTrackingItemData(findBacklogsItem)
            dispatch(setAllBacklogsTaskView("backlogsview"))
          }}
        >
          <td>
            <input
              type='checkbox'
              className='backlogs_checkbox'
              onChange={() => handleCheckboxChange(item, i)}
              checked={selectedRows.some((row) => row.index === i)}
              onClick={(e) => e.stopPropagation()}
            />
          </td>
          <td style={{ cursor: "pointer" }}>
            <span
              className=' center_content'
              onClick={() => {
                setTrackingItemData(item)
              }}
            >
              <strong className='mobHead'>Task number :</strong>
              {trackingClientId}-{item.trackingid}
            </span>
          </td>
          <td className='center_content'>
            <strong className='mobHead'>Project Name : </strong>
            <span>{projetName}</span>
          </td>
          <td className='center_content'>
            <strong className='mobHead'>Title : </strong>
            <span>{item?.title}</span>
          </td>
          <td className='center_content'>
            <strong className='mobHead'>Task Type : </strong>
            <span>{item?.taskName}</span>
          </td>
          <td className='center_content'>
            <strong className='mobHead'>Status : </strong>
            {item.status_name === "" ? "-" : item.status_name}
          </td>
          <td className='center_content'>
            <strong className='mobHead'>Reported On : </strong>
            <span>
              {item.reporteddate !== ""
                ? moment(item.reporteddate).format("MMM D, YYYY hh:mm A")
                : "-"}
            </span>
          </td>
          <td className='center_content'>
            <strong className='mobHead'>Reported By : </strong>
            <span>
              {item.reportedby !== ""
                ? item.reportedByName ||
                  allMembers.map((member) => {
                    if (member.userId === item.reportedby) {
                      return member.personname
                    }
                    return ""
                  })
                : "-"}
            </span>
          </td>
          <td className='center_content'>
            <strong className='mobHead'>Assigned To : </strong>
            <span className=''>
              {item.assignedto == "" || item.assignedto === 'notAssigned' ?
               "Not Assigned" :
                 allMembers.map((member) => {
                    if (member.userId === item.assignedto) {
                      return member.personname
                    }
                    return ""
                  })
                }
            </span>
          </td>
          <td className='center_content'>
            <strong className='mobHead'>Priority : </strong>
            <span className={`text-capitalize ${getPriorityClass(item.priority)}`}>
              {item.priority === "blocker"
                ? "Blocker Level 1"
                : item.priority === "blocker2"
                ? "Blocker Level 2"
                : item.priority === ""
                ? "-"
                : item.priority}
            </span>
          </td>
        </tr>
      )
    })

  const handleSearch = (value) => {
    setSearchValue(value)
  }

  const taskViewRender = (value) => {
    dispatch(setAllBacklogsTaskView(value))
  }

  return (
    <BackLogsViewWrap>
      {showAlertBox && (
        <Alert
          variant='success'
          className='text-center alert-sucess alert mt-3 mb-1'
          dismissible
          closeVariant='white'
          onClick={(e) => setShowAlertBox(false)}
        >
          {alertMessage}
        </Alert>
      )}
      <BacklogsRefineModalBox
        heading='Are you sure you want to send these to refined?'
        hidePopup={(e) => setRefineConfirmationShow(false)}
        show={refineConfirmationShow}
        confirmationResponse={FetchBacklogsRetain}
      />
      {allBacklogs.length > 0 && allBacklogsView === "All Backlogs" && (
        <div className='addTask move_to_refined'>
          <Button
            className={
              selectedRows.length > 0 ? "move_to_refined_btn" : "move_to_refined_btn disabled"
            }
            onClick={() => setRefineConfirmationShow(!refineConfirmationShow)}
          >
            Move to refined
          </Button>
        </div>
      )}
      <ProjectsModalView>
        {allBacklogsView === "All Backlogs" ? (
          <div className='trackingPage'>
            <Tables
              setProjectTable={true}
              columns={
                <BacklogsListColumns
                  handleSearch={handleSearch}
                  trackingSortings={trackingSortings}
                  setTrackingSorting={setTrackingSorting}
                  handleCheckboxChange={handleCheckboxChange}
                  handleHeaderCheckboxChange={handleHeaderCheckboxChange}
                  selectedRows={selectedRows}
                  selectAll={selectAll}
                  handleColumnSearch={handleColumnSearch}
                  searchColumn={searchColumn}
                />
              }
              data={getTableData}
              showInfiniteScroll={false}
              header={
                <BacklogsListHeader
                  searchValue={searchValue}
                  handleSearch={handleSearch}
                  userRole={user.rolename}
                  userRoleType={user.roletype}
                  allMembers={allMembers}
                  allProjects={allProjects}
                  allBacklogs={allBacklogs}
                  refreshAllTracking={refreshAllTracking}
                  isLoading={loading || isFetching}
                  createPermission={createPermission}
                  handleHeaderCheckboxChange={handleHeaderCheckboxChange}
                  selectedRows={selectedRows}
                  selectAll={selectAll}
                />
              }
              notfound={"No Backlogs Found"}
              loading={loading}
              refreshLoading={refreshLoading}
            />
          </div>
        ) : allBacklogsView === "backlogsview" ? (
          <>
            {trackingItemData.title ? (
              <BacklogsView
                setCloneTask={setCloneTask}
                fileLoading={fileLoading}
                setTaskview={taskViewRender}
                show={showView}
                setTaskFile={setTaskFile}
                setTaskFileType={setTaskFileType}
                taskfileType={taskfileType}
                setTrackingItemData={setTrackingItemData}
                taskfileList={taskfileList}
                setTaskFileList={setTaskFileList}
                taskfile={taskfile}
                refreshAllTracking={refreshAllTracking}
                setShowView={(value) => setShowView(value)}
                allTaskTypes={allTasksTypes}
                allProjectsData={allProjectsData}
                allMemberres={allMembers}
                allStatus={allFlowStatus}
                item={trackingItemData}
                data={viewTaskData}
                setViewTaskData={setViewTaskData}
                showEditTask={showEditTask}
                setShowEditTask={setShowEditTask}
                updatedData={updatedData}
                setUpdatedData={setUpdatedData}
                history={taskHistory}
                setHistory={setHistory}
                setShowClone={setShowClone}
                selected_menu={selected_menu}
                setDeleteConfirmationShow={setDeleteConfirmationShow}
                deletePermission={deletePermission}
                editPermission={editPermission}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        <ConfirmationBox
          heading='Are you sure to delete this ?'
          hidePopup={(e) => setDeleteConfirmationShow(false)}
          show={deleteConfirmationShow}
          confirmationResponse={deleteConfimrationHandler}
        />
      </ProjectsModalView>
    </BackLogsViewWrap>
  )
}
