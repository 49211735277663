import React from "react"
import { useSelector } from "react-redux"
import { Container, Card } from "react-bootstrap"
import WorkflowBox from "../WorkflowBox"
import { BsFillTrashFill } from "react-icons/bs"
import WorkflowView from "../../WorkflowView"
import LocalHospitalIcon from "@mui/icons-material/LocalHospital"
import "bootstrap/dist/css/bootstrap.min.css"
import { dateFormat } from "../../../../../../Utilities/commonFunctions"
import { useFetch } from "../../../../../../Hooks/useFetch"
import { key } from "../../../../../../data/queryKeys"

const HorizontalCardView = ({
  allWorkFlow,
  setDeleteConfirmationShow,
  setTrackingItemData,
  updateData,
  handleCardUpdate,
  handleCardChange,
  setEditData,
  EditNodeData,
  temp,
  openView,
  setOpenView,
  viewData,
  setViewData,
  editNode,
  setEditNode,
  deletePermission,
  editPermission
}) => {
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)

  const { data: allProjectsRes } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allProjectsData = allProjectsRes?.data?.Items ?? []

  return (
    <>
      <Container className='wf-container mt-3'>
        {Object.keys(allWorkFlow).map((key, index) => {
          return (
            <React.Fragment key={index}>
              <Card className='card-bd card-content my-3'>
                <Card.Body>
                  {allWorkFlow[key]?.map((value, index) => {
                    let project_name = allProjectsData.find(
                      (e) => e.trackingprojectid == value.projectId
                    )
                    if (project_name) value.project_name = project_name.label
                    else value.project_name = "Not Assigned"

                    let length = value.workFlow?.length

                    const addWorkflowBox = (length, key) => {
                      let newfield = { [length]: "" }
                      {
                        allWorkFlow[key].map((value) => {
                          let temp = [...value.workFlow, newfield]
                          return (value.workFlow = temp)
                        })
                      }
                      setEditNode({
                        editable: true,
                        index: length,
                        key: key,
                        value: value,
                        newNode: true
                      })
                      EditNodeData(value.workFlow)
                      setEditData(value)
                    }
                    return (
                      <React.Fragment key={index}>
                        {value.workFlowId === "default"
                          ? null
                          : deletePermission && (
                              <div className='float-end'>
                                <BsFillTrashFill
                                  onClick={() => {
                                    setDeleteConfirmationShow(true)
                                    setTrackingItemData(
                                      value.workFlowId !== null && value.workFlowId !== undefined
                                        ? value.workFlowId
                                        : value.workFlowid !== null &&
                                          value.workFlowid !== undefined
                                        ? value.workFlowid
                                        : ""
                                    )
                                  }}
                                  className='plusIcon mx-1'
                                  style={{
                                    color: "#4eb6ee",
                                    cursor: "pointer"
                                  }}
                                />
                              </div>
                            )}
                        <div style={{ fontSize: 15 }}>
                          <span className='workflow-name  mb-2'>
                            <strong className='me-2'>Workflow ID:</strong>
                            <span
                              className='ms-4'
                              style={{
                                color: "#4eb6ee",
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                setOpenView(true)
                                setViewData({
                                  value: value,
                                  key: key
                                })
                              }}
                            >
                              {value.workFlowId}
                            </span>
                          </span>
                          <span className='workflow-name mb-2'>
                            <strong className='me-2'>Workflow name:</strong>
                            {value.workFlowName}
                          </span>
                          <span className='workflow-name  mb-2'>
                            <strong className='me-2'>Project name:</strong>
                            <span className='ms-3'>{value.project_name}</span>
                          </span>
                          <span className='workflow-name  mb-2'>
                            <strong className='me-2'>Created On:</strong>
                            <span className='ms-3'>{dateFormat(value.createdOn)}</span>
                          </span>
                        </div>
                        <div className='flow-chart'>
                          {value.workFlow?.map((e, index) => {
                            const workFlowLength = index + 1
                            let viewData = []
                            Object.keys(e).map((n) => {
                              viewData.push(e[n])
                            })
                            return (
                              <React.Fragment key={index}>
                                {workFlowLength <= 10 && (
                                  <WorkflowBox
                                    index={index}
                                    editNode={editNode}
                                    updateData={updateData}
                                    id={key}
                                    allWorkFlow={allWorkFlow}
                                    temp={temp}
                                    viewData={viewData}
                                    value={value}
                                    handleCardChange={handleCardChange}
                                    setEditNode={setEditNode}
                                    EditNodeData={EditNodeData}
                                    handleCardUpdate={handleCardUpdate}
                                    setEditData={setEditData}
                                    verticalview={false}
                                    editPermission={editPermission}
                                    setViewData={setViewData}
                                  />
                                )}
                              </React.Fragment>
                            )
                          })}
                          {editNode && editNode.editable && editNode.key === key && editNode.newNode
                            ? null
                            : length <= 10 && (
                                <div
                                  className='horizontalplus'
                                  style={{
                                    cursor: "pointer",
                                    color: "#4eb6ee"
                                  }}
                                  onClick={() => {
                                    addWorkflowBox(length, key)
                                  }}
                                >
                                  <LocalHospitalIcon />
                                </div>
                              )}
                        </div>
                      </React.Fragment>
                    )
                  })}
                </Card.Body>
              </Card>
            </React.Fragment>
          )
        })}
      </Container>
      {/* {openView ? (
        <WorkflowView
          setOpenView={setOpenView}
          openView={openView}
          viewData={viewData}
          setViewData={setViewData}
          setEditNode={setEditNode}
          EditNodeData={EditNodeData}
          setEditData={setEditData}
          handleCardUpdate={handleCardUpdate}
          handleCardChange={handleCardChange}
          updateData={updateData}
          editNode={editNode}
          allWorkFlow={allWorkFlow}
          editPermission={editPermission}
        />
      ) : null} */}
    </>
  )
}

export default HorizontalCardView
