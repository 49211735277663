import { client } from "../..";
import { ActionTypes } from "./actions";

const userLogin = (response) => {
  localStorage.setItem("token", response.authToken);
  localStorage.setItem("menuItem", JSON.stringify(response.menuList));
  localStorage.setItem("Trekr-Layout", "sidebar")
  return {
    type: ActionTypes.LOGIN,
    payload: response,
  };
};

const userLogout = (response) => {
  localStorage.removeItem("token");
  localStorage.removeItem("menuItem");
  localStorage.removeItem("Trekr-Layout")
  client.clear()
  
  return {
    type: ActionTypes.LOGOUT,
    payload: response,
  };
};

const setSessionTimeout = (response) => {
  return {
    type: ActionTypes.SET_SESSION_TIMEOUT,
    payload: response,
  };
};

export { userLogin, userLogout, setSessionTimeout };
