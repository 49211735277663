import Styled from "styled-components"

const DeleteAccountWrap = Styled.div`
.table tbody tr td {
    color: ${({ theme }) => theme["themeColor"]};
    opacity: 0.9;
    background: #3E3F3E;
}
.close-btn{
    background-color:#4EB6EE;
    border-color:#4EB6EE;
    &:hover {
        color: #fff;
        background-color: ${({ theme }) => theme["orange-hover"]};
    border-color:${({ theme }) => theme["orange-hover"]};
    }
}
.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
    border-color: #4EB6EE;
}
.was-validated .form-check-input:invalid ~ .form-check-label {
    color: ${({ theme }) => theme["themeColor"]};
}
.was-validated .form-check-input:valid ~ .form-check-label{
    color: ${({ theme }) => theme["themeColor"]};
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
     background-color:  #4EB6EE !important;
}
p{
    text-align: justify;
    text-justify: inter-word;
}
.card{
    box-shadow: none;
}
.table tbody tr td {
            font-size: 16px;
            font-weight: 700;
        }  

@media (max-width: 565px) {
    .table tbody tr td {
        font-size: 13px;
    }  
    p{
      font-size: 13px;
    }
    .form-check-label{
        font-size: 13px;  
    }
}
`

export default DeleteAccountWrap
