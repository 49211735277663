import React from "react"
import { Button, Form, Row, Col, Card } from "react-bootstrap"
import { Box } from "@mui/material"
import BarLoader from "react-spinners/ClipLoader"
import WorkflowWrap from "../style"

const NewWorkFlow = ({
  handleSubmit,
  handleUpdate,
  selected,
  setWorkflowname,
  workflowname,
  showDetails,
  inputFields,
  handleFormChange,
  addWorkflowStep,
  removeIcon,
  plusIcon,
  handleSplChar,
  removeWorkflowStep,
  loaderstyle,
  Loading,
}) => {
  // const [validated, setValidated] = useState(false);

  // const handleSubmit = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }

  //   setValidated(true);
  // };
  console.log(inputFields)
  return (
    <>
      <WorkflowWrap>
        <Card className='card-bd'>
          <Form
            autoComplete='off'
            // noValidate
            // validated={validated}
            onSubmit={selected === null ? handleSubmit : handleUpdate}
            style={{ padding: "1rem" }}
          >
            <Row>
              <Col>
                <Form.Group className='mb-3' controlId='ControlInput1'>
                  <Form.Label className='float-start'>
                    Name<span className='mandatorySign '> * </span>
                  </Form.Label>
                  <Form.Control
                    name='workflowname'
                    value={workflowname}
                    onChange={e => setWorkflowname(e.target.value)}
                    onKeyDown={handleSplChar}
                    type='text'
                    id='workflowname'
                    placeholder='Enter workflow name'
                    disabled={showDetails ? true : false}
                    required={true}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Form.Label className='float-start'>
              Steps<span className='mandatorySign'>*</span>
            </Form.Label>
            <div className='mt-5'>
              {inputFields.slice(0, 10).map((input, index) => {
                return (
                  <div key={index}>
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ width: "100%" }}>
                        <Row>
                          <Col>
                            <Form.Group controlId='ControlInput2'>
                              <Form.Control
                                name='status'
                                value={input.status}
                                onChange={event => handleFormChange(index, event)}
                                onKeyDown={handleSplChar}
                                type=''
                                placeholder=' '
                                disabled={showDetails ? true : false}
                                required={true}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <div className='mt-2 mb-1'>
                          <Row>
                            <Col className='hovering'>
                              {index === inputFields.length - 1 && !showDetails && (
                                <>
                                  <div className='position-relative'>
                                    <div className='dashed-line'></div>
                                    <img
                                      className={`addcircle btn rounded-button-absolute `}
                                      src={plusIcon}
                                      onClick={addWorkflowStep}
                                      alt='plus'
                                    />
                                  </div>
                                </>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Box>
                      {index !== 0 && !showDetails && (
                        <Box sx={{ padding: "0 15px" }}>
                          <img
                            className='removeicon'
                            src={removeIcon}
                            onClick={() => removeWorkflowStep(index)}
                            alt='plus'
                          />
                        </Box>
                      )}
                    </Box>
                  </div>
                )
              })}
            </div>
            {!showDetails ? (
              <Row>
                <Col>
                  <Button type='submit' className='btn-orange mt-2' style={loaderstyle}>
                    {Loading ? (
                      <BarLoader
                        color='#808080'
                        className='bar-loader'
                        size={14}
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      <></>
                    )}
                    {selected === null ? "Create" : "Update"}
                  </Button>
                </Col>
              </Row>
            ) : null}
          </Form>
        </Card>
      </WorkflowWrap>
    </>
  )
}
export default NewWorkFlow
