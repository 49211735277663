import React, { useState } from "react"
import { Container } from "react-bootstrap"
import { useSelector } from "react-redux"
import { ReactComponent as EditIcon } from "../../../../../assets/images/icons/blue_edit_icon.svg"
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/icons/delete_blue_icon.svg"
import ProjectModelWrap from "../ProjectModelCard/style"
import { ConfirmationBox } from "../../../../Reuseable/ConfimationBox"

function ProjectModelCard({
  borderLine,
  setTaskview,
  setTempView,
  deleteConfimrationHandler,
  setDeleteModelId,
  setTrackingItemData,
  item,
  props,
}) {
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false)
  let allProjectModelData = useSelector(state => state.Tracking.allProjectModelData)
  const trackingClientId = useSelector(state => state.Auth.user.trackingclientid)
  let projectmodelid = item?.ProjectModelId

  const modelData = [
    {
      list1: "Daily task",
      list2: "Daily delivery",
      list3: "Board looks like new, in progress, completed",
    },
    {
      list1: "Fixed model project with timeline 1-2 months",
      list2: "The scope of the project will get ready for you before the start of project",
      list3: "Fixed timeline",
    },
  ]

  return (
    <ProjectModelWrap>
      <ConfirmationBox
        heading='Are you sure to delete this ?'
        hidePopup={e => setDeleteConfirmationShow(false)}
        show={deleteConfirmationShow}
        confirmationResponse={deleteConfimrationHandler}
      />
      <Container className='project_model_con'>
        <div className='d-flex justify-content-between mt-2 pb-2 me-lg-5 '>
          <div>
            <div className='d-flex mb-3'>
              <div className='fw-bold'>Project model name:</div>
              <div className='ms-2'>{item.ModelTitle}</div>
            </div>
            <div className='mb-3'>
              <div className='fw-bold mb-2'> Description:</div>
              <div dangerouslySetInnerHTML={{ __html: item?.ModelDescription }} />
            </div>
            <div className='d-flex'>
              <div className='fw-bold'>Note:</div>
              <div className='ms-2'>{item?.Note}</div>
            </div>
          </div>
          <div>
            <EditIcon
              className='me-4 cursor_pointer'
              height={20}
              style={{ fill: "#4EB6EE" }}
              onClick={e => {
                const selectedTemplate = allProjectModelData.find(
                  element => element.ProjectModelId === item?.ProjectModelId
                )
                setTaskview("View ProjectModal")
                setTrackingItemData(selectedTemplate)
              }}
            />
            <DeleteIcon
              className='cursor_pointer'
              onClick={e => {
                setDeleteModelId(projectmodelid)
                setDeleteConfirmationShow(true)
              }}
              style={{ fill: "#4EB6EE" }}
            />
          </div>
        </div>
        {borderLine ? <hr className='mb-0' /> : <></>}
      </Container>
    </ProjectModelWrap>
  )
}

export default ProjectModelCard
