import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Alert, Button } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OTPWrap from "./style";
import personimage from "../../../assets/images/login/personimage12.png";
import { api } from '../../../Services/api';
import OTPInput from "otp-input-react";
import BarLoader from "react-spinners/ClipLoader";
import ResetPassword from "../ResetPassword";

const OTP = ({ data }) => {
  const [invalidUser, setInvalidUser] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [resentMessage, setResentMessage] = useState("Resent OTP Successfully");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [Show, setShow] = useState(true);
  const [otp, setotp] = useState("");
  const navigate = useNavigate();

  const VerifyOTP = () => {
    if (otp !== "") {
      setLoading(true);
      const obj = {
        emailId: data,
        OTP: otp,
      };
      api
        .post("/trackinguserauth/email", obj)
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.status) {
              setShow(false);
              setSubmitted(true);
              setInvalidUser(true);
              setInvalid(false);
            } else {
              setShow(true);
              setInvalidUser(true);
              setInvalid(false);
              setLoading(false);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status) {
              setInvalidUser(true);
              setInvalid(false);
              setInvalidMessage(error.response.data.message);
            }
          }
          setLoading(false);
        });
    }
  };

  const ResentOTP = () => {
    const obj = {
      emailId: data,
    };
    api
      .post("/trackinguserauth/email", obj)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            setInvalid(true);
            setInvalidUser(false);
          } else {
            setInvalid(true);
            setInvalidUser(false);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status) {
            setInvalidUser(false);
            setInvalid(true);
          }
        }
      });
  };

  return (
    <OTPWrap>
      {Show === true ? (
        <section className="loginPageContent">
          <Row>
            <div className="loginPageRow">
              <Col
                className="loginPageRightside ps-0 pe-0"
                lg={5}
                md={12}
                sm={12}
                xs={12}
              >
                <div className="loginFormScreens text-center flex-fill col-md-12">
                  <Row>
                    <p className="loginaccountft">
                      Enter OTP sent to your registered <br />
                      E-mail {data}
                    </p>
                    {invalid ? (
                      <Alert
                        className="p-1 text-center alert-message"
                        variant="danger"
                      >
                        {resentMessage}
                      </Alert>
                    ) : (
                      <></>
                    )}
                    {invalidUser ? (
                      <Alert
                        className="p-1 text-center alert-message"
                        variant="danger"
                      >
                        {invalidMessage}
                      </Alert>
                    ) : (
                      <></>
                    )}
                    <OTPInput
                      className="user-input"
                      value={otp}
                      onChange={setotp}
                      autoFocus
                      OTPLength={6}
                      placeholder={["*", "*", "*", "*", "*", "*"]}
                      otpType="number"
                      disabled={false}
                    />
                    <p className="got-otp">
                      Haven’t got OTP?{" "}
                      <span
                        className="resend-otp"
                        style={{ cursor: "pointer" }}
                        onClick={ResentOTP}
                      >
                        Resend OTP
                      </span>
                    </p>
                    <Col className="text-center btn-padding col-md-12">
                      <Button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="back-btn"
                        variant="secondary"
                      >
                        Back
                      </Button>
                      <Button
                        type="button"
                        className="btn-verify"
                        onClick={VerifyOTP}
                        submitted={submitted}
                        style={{
                          background: loading ? "#FFFFFF" : "#4EB6EE",
                          color: loading ? "#808080" : "#FFFFFF",
                          border: loading
                            ? "2px solid #FFFFFF"
                            : "2px solid #4EB6EE",
                        }}
                      >
                        {loading ? (
                          <BarLoader
                            className="bar-loader"
                            color="#808080"
                            size={14}
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <></>
                        )}
                        Verify OTP{""}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col>
                <img
                  className="loginPageLeftside"
                  src={personimage}
                  alt="PersonImage"
                />
              </Col>
            </div>
          </Row>
        </section>
      ) : (
        <ResetPassword data={data} />
      )}
    </OTPWrap>
  );
};

export default OTP;
