const fontstack = "Montserrat,sans-serif";
const bodytext = "#000000";
const heading = "#000000";
const orangeColor = "#4EB6EE";
const orangeHover = "#4EB6EE";
const iconColor = "#000000";
const canbtnclr = "grey";
const darkmodeLightColor = "#ffffff";
const border = "#c3c3c3";
const grayColor = "#808080";
const placeholder = "#cccccc";
const panelColor = "rgba(242, 245, 245, 1)";
const backgroundColor = "#ffffff";
const borderColor = "#e1e5eb";
const hoverIconColor = "#000000";
const panelHeading = "#000000";
const tabLink = "#000000";
const tabBg = "rgba(242,245,245,0.8)";
const themeColor = "#000000";
const tableViewCard = "#ffffff";
const tableViewBody = "rgba(242, 245, 245, 0.8)";
const Clonebg = "#dddada";
const Cloneag = "#bcbcbc";
const divider = "#d4d7d4";
const tboxtext = "rgb(0, 0, 0)";
const bordercolor = "rgb(100 121 143 / 30%)";
const normalColor = "rgba(0,0,0,0.2)";
const focusColor = "rgba(0,0,0,0.5)";
const inputColor = "#000000";
const searchBar = "#ece9e9";
const dateColor = "rgba(0,0,0,0.7)";
const blurview = "linear-gradient(to bottom, rgba(255,255,255, 0), rgba(235, 228, 228, 1) 100%)";

export const theme = {
  "font-stack": fontstack,
  "body-text": bodytext,
  "heading": heading,
  "canbtnclr": canbtnclr,
  "orange-color": orangeColor,
  "orange-hover": orangeHover,
  "icon-color": iconColor,
  "darkMode-lightColor": darkmodeLightColor,
  "border": border,
  "gray-color": grayColor,
  "placeholder": placeholder,
  "panel-color": panelColor,
  "background-color": backgroundColor,
  "border-color": borderColor,
  "hover-icon-color": hoverIconColor,
  "panel-heading": panelHeading,
  "tabLink": tabLink,
  "tabBg": tabBg,
  "Clonebg": Clonebg,
  "Cloneag": Cloneag,
  "themeColor": themeColor,
  "tableViewCard": tableViewCard,
  "tableViewBody": tableViewBody,
  "divider": divider,
  "tboxtext": tboxtext,
  "bordercolor": bordercolor,
  "normalColor": normalColor,
  "focusColor": focusColor,
  "inputColor": inputColor,
  "searchBar": searchBar,
  "dateColor": dateColor,
  "blurview": blurview
};

export const darktheme = {
  ...theme,
  "panel-color": "#292A29",
  "background-color": "#000000",
  "border-color": "#6c6c6c",
  "icon-color": "#ffffff",
  "panel-heading": "#ffffff",
  "tabLink": "#ffffff",
  "canbtnclr": "#ffffff",
  "tabBg": "#2a2a2a",
  "themeColor": "#ffffff",
  "placeholder": "#cccccc",
  "body-text": "#ffffff",
  "tableViewCard": "#3E3F3E",
  "tableViewBody": "#494b49",
  "searchcolor": "#434443",
  "tableHeadBg": "#535353",
  "lightOrange": "#4EB6EE",
  "Clonebg": "#bcbcbc",
  "Cloneag": "#dddada",
  "divider": "#515451",
  "tboxtext": "rgb(255, 255, 255",
  "bordercolor": "rgb(100 121 143 / 30%)",
  "normalColor": "rgba(255,255,255,0.2)",
  "focusColor": "rgba(255,255,255,0.5)",
  "inputColor": "#ffffff",
  "searchBar": "#5C5C5C",
  "dateColor": "rgba(255,255,255,0.7)",
  "blurview": "linear-gradient(to bottom, rgba(255,255,255, 0), rgba(87 ,84 ,84, 1) 100%)"
};

