import Styled from "styled-components";

const ProjectModelWrap = Styled.div`
  .project_model_con{
    color: ${({ theme }) => theme["themeColor"]};
    padding:10px;
    font-size:15px;
  }
 .cursor_pointer{
    cursor:pointer;
 }
 .projectModel_edit_container{
  width: 100%;
  display: flex;
  justify-content: space-between;
 }
`
export default ProjectModelWrap;