import React, { useState } from "react"
import { Col, Row, Alert } from "react-bootstrap"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Forms } from "../../../components/Reuseable/Forms"
import ForgotPasswordWrap from "./style"
import tractaskLogo from "../../../assets/images/logo/tracTasklogo.svg"
import personimage from "../../../assets/images/login/personimage12.png"
import { api } from "../../../Services/api"
import { Validators } from "../../../Utilities/validator"
import OTP from "../OTP"
import { Link } from "react-router-dom"

const ForgotPassword = () => {
  const [invalidUser, setInvalidUser] = useState(false)
  const [invalidMessage, setInvalidMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [changeInForm, setChangeInForm] = useState(false)
  const [data, setdata] = useState()
  const [Show, setShow] = useState(true)

  const inValidMsgJsx = () => {
    return (
      <div>
        Sorry to hear that, but we cannot find an account with this email
        address. create a <Link to='/register'> new account </Link>
      </div>
    )
  }

  const SubmitEmail = (form, valid) => {
    if (valid) {
      setLoading(true)
      const obj = {
        emailId: form.email,
      }
      setdata(form.email)
      api
        .post("/trackinguserauth/email", obj)
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.status) {
              setShow(false)
              setSubmitted(true)
              setInvalidUser(true)
            } else {
              setShow(true)
              setInvalidUser(true)
              setLoading(false)
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status) {
              setInvalidUser(true)
              let invalidMsg = error.response.data.message

              if (invalidMsg === "User ID does not Exist") {
                setInvalidMessage(inValidMsgJsx)
              } else {
                setInvalidMessage(invalidMsg)
              }
            }
          }
          setLoading(false)
        })
    }
  }

  return (
    <ForgotPasswordWrap>
      {Show === true ? (
        <section className='loginPageContent'>
          <Row>
            <div className='loginPageRow'>
              <Col
                className='loginPageRightside ps-0 pe-0'
                lg={5}
                md={12}
                sm={12}
                xs={12}
              >
                <div className='loginScreenLogoMain text-center pb-3 '>
                  <a href='app.tracktask.com/'>
                    <img
                      className='img-fluid mx-auto loginScreenLogoimg'
                      src={tractaskLogo}
                      alt='TracTask icon'
                    />
                  </a>
                </div>
                <div className='loginFormScreens flex-fill col-md-12'>
                  <Row>
                    <Col className='mb-3'>
                      {invalidUser ? (
                        <Alert
                          className='text-center alert-message'
                          variant='danger'
                        >
                          {invalidMessage}
                        </Alert>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  <Forms
                    title={""}
                    formArr={[
                      {
                        label: "Enter E-mail to verify",
                        name: "email",
                        type: "email",
                        placeholder: "Email",
                        validators: [
                          {
                            check: Validators.required,
                            message: "Email is required",
                          },
                          {
                            check: Validators.email,
                            message: "Email is not Valid",
                          },
                        ],
                        error: false,
                        errormsg: "",
                        value: "",
                        show: true,
                      },
                    ]}
                    backBtn={true}
                    submitBtn={"Verify"}
                    onSubmit={SubmitEmail}
                    loading={loading}
                    submitted={submitted}
                    changeInForm={changeInForm}
                    setChangeInForm={setChangeInForm}
                  />
                </div>
              </Col>
              <Col>
                <img
                  className='loginPageLeftside'
                  src={personimage}
                  alt='PersonImage'
                />
              </Col>
            </div>
          </Row>
        </section>
      ) : (
        <OTP data={data} />
      )}
    </ForgotPasswordWrap>
  )
}

export default ForgotPassword
