import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import Select from "react-select"
import { useFetch } from "../../../Hooks/useFetch"
import { key } from "../../../data/queryKeys"

const Dynamicdata = ({ getSelectedOption, selectedProject }) => {
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)

  const { data: allProjectsRes } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    true
  )

  let allProjects = useMemo(() => {
    if (allProjectsRes) {
      return allProjectsRes?.data?.Items
    }
    return []
  }, [allProjectsRes])

  const [itemList, setItemlist] = useState([])
  const [selectionItem, setSelectionItem] = useState(null)

  const darkmode = useSelector((state) => state.Layout.darkmode)

  useEffect(() => {
    if (allProjects?.length > 0) {
      let result = []
      allProjects.forEach((element) => {
        result.push({
          label: element.label,
          id: element.trackingprojectid,
          value: element.label
        })
      })
      setItemlist(result)
    }
  }, [allProjects])

  useEffect(() => {
    if (selectedProject) {
      const project = allProjects.find(
        (element) =>
          element.trackingprojectid === selectedProject || element.number == selectedProject
      )

      project &&
        setSelectionItem({
          label: project.label,
          id: project.trackingprojectid,
          value: project.label
        })
    }
  }, [selectedProject, allProjects])

  return (
    <div style={{ width: "280px" }}>
      <Select
        options={itemList}
        value={selectionItem ? selectionItem : (itemList.length > 0 && itemList[0]) || null}
        name=''
        placeholder={"Select..."}
        onChange={(e) => {
          getSelectedOption(e.id, e.label)
          setSelectionItem({ label: e.label, id: e.id, value: e.label })
        }}
        styles={{
          control: (base) => ({
            ...base,
            height: "10px",
            backgroundColor: "none",
            border: `1px solid ${darkmode ? "white" : "black"} `,
            color: "white",
            outline: "none"
          }),
          valueContainer: (base) => ({
            ...base,
            margin: 0,
            ":after": {
              ...base[":after"],
              color: darkmode ? "#FFFFFF" : "#3E3F3E"
            }
          }),
          singleValue: (base) => ({
            ...base,
            color: darkmode ? "white" : "black"
          }),
          input: (base) => ({
            ...base,
            color: darkmode ? "#FFFFFF" : "#3E3F3E",
            ":after": {
              ...base[":after"],
              color: darkmode ? "#FFFFFF" : "#3E3F3E"
            }
          }),
          menuList: (base) => ({
            ...base,
            width: "auto",
            backgroundColor: `${darkmode ? "#3E3F3E" : "#FFFFFF"}`
          }),
          menu: (base) => ({
            ...base,
            backgroundColor: `${darkmode ? "#3E3F3E" : "#FFFFFF"}`
          }),
          option: (styles, { isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected ? "#4EB6EE" : null,
              color: `${darkmode ? "#FFFFFF" : "#3E3F3E"}`,
              height: "auto",
              width: "auto",
              paddingTop: "0px",
              "&:hover": {
                backgroundColor: "hsla(201, 82%, 62%, 0.5)"
              }
            }
          }
        }}
      />
    </div>
  )
}

export default Dynamicdata
