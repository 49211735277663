import React, { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Forms } from "../../../../Reuseable/Forms"
import { Validators } from "../../../../../Utilities/validator"
import { api } from "../../../../../Services/api"
import {
  setMembers,
  setShowAlert,
  setAllTaskView,
} from "../../../../../redux/Tracking/actionCreator"
import axios from "axios"
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft"

const AddNewPriority = ({ setTaskview, defaultProjectid, setShowAlertBox, setAlertMessage }) => {
  const dispatch = useDispatch()

  let allProjects = useSelector(state => state.Tracking.projects)

  if (allProjects.length > 0) {
    let result = []
    allProjects.forEach(element => {
      result.push({ label: element.label, id: element.trackingprojectid })
    })
    allProjects = result
  }
  const menus = useSelector(state => state.Auth.menuList)
  const user = useSelector(state => state.Auth.user)
  const trackingClientId = useSelector(state => state.Auth.user.trackingclientid)

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [changeInForm, setChangeInForm] = useState(false)

  const editorRef = useRef(null)
  // const addNewProject = [{ label: '+ Add New Project', id: 'Add New Project' }]
  // const isAbleToAddNewProject = (menus.find((menu) => menu.name === "Projects"))?.access ?? false ? true : false

  // allProjects = isAbleToAddNewProject ? [...addNewProject, ...allProjects] : allProjects
  let formArr = [
    {
      label: "Priority name",
      name: "priorityname",
      type: "text",
      halfWidth: true,
      placeholder: "Enter",
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      value: "",
      maxLength: 100,
      show: true,
    },
    {
      label: "Project name",
      name: "projectname",
      type: "selectbox",
      placeholder: "Select",
      halfWidth: true,
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      options: allProjects,
      value: "",
      defaultValue:
        defaultProjectid !== ("" || undefined)
          ? allProjects.find(e => e.id === defaultProjectid)
          : "",
      show: true,
    },
    {
      label: "Version number",
      name: "modelversionnumber",
      type: "text",
      placeholder: "Enter",
      halfWidth: true,
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      value: "",
      show: true,
    },
    {
      label: "SLA - Service Level Agreement",
      name: "SLA",
      type: "text",
      placeholder: "Enter",
      halfWidth: true,
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      value: "",
      show: true,
    },
  ]

  const SubmitForm = (form, valid) => {
    if (valid) {
      setLoading(true)
      let Obj = {
        priorityname: form.priorityname,
        projectname: form.title,
        modelversionnumber: form.modelversionnumber,
        SLA: form.SLA,
        Staus: "open",
        createdby: user.personname,
      }
      api.post("/3_prioritylist/" + trackingClientId, Obj).then(function (response) {
        if (response.status === 200) {
          dispatch(setShowAlert(true))
          setSubmitted(true)
          setShowAlertBox(true)
          setAlertMessage("New Priority has been created successfully")
          // dispatch(setShowAlert(true));
          setLoading(false)
          if (!form.add_task) {
            setTaskview("All Priority")
          }
        }
      })
    }
  }

  return (
    <>
      <h4 className='d-flex justify-content-start align-items-center mb-1'>
        <ArrowCircleLeftIcon
          className='me-3'
          onClick={() => {
            setTaskview("All Priority")
          }}
          style={{ cursor: "pointer", color: "#4EB6EE", fontSize: "30px" }}
        />
        <div className='title add-new-task'>Add new Priority</div>
      </h4>
      <Forms
        title={""}
        classes='add-project-container'
        formArr={formArr}
        submitBtn={"Create"}
        onSubmit={SubmitForm}
        loading={loading}
        submitted={submitted}
        changeInForm={changeInForm}
        setChangeInForm={setChangeInForm}
      />
    </>
  )
}

export default AddNewPriority
