import Styled from "styled-components";

export const  NodataWrap =  Styled.div`
    
        .nodata_container {
            padding: 2%;
            background-color:${({ theme }) => theme["tableViewCard"]};
            border-radius:10px;
        }
        .nodata_content {
            padding-left: 2%;
            padding-top: 2%;
            text-align: left;
            color:${({ theme }) => theme["body-text"]};
            letter-spacing: 1px;
        }
        .nodata_content p {
            line-height: 30px;
        }
        .nodata_content span {
            display: block;
        }
        .nodata_svg{
            width:100%;
            display: flex;
            justify-content: end;
            padding-right: 4%;
        }
        @media (max-width: 991px) {
            .nodata_svg img{
                width:100%;
            }
            .nodata_svg{
                padding:5%;
                width:100%;
                display: flex;
                justify-content: start;
            }
        }
`;

