import React, { useEffect, useState } from "react";
import UseMultipleForms from "../../../Hooks/useMultiForms";
import RegisterAccount from "./RegisterAccount";
import tractaskLogo from "../../../assets/images/logo/tracTasklogo.svg";
import { Button, Container, Spinner } from "react-bootstrap";
import OTPField from "./OTPField";
import styled from "styled-components";
import PricingPlans from "./PricingPlans";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Alert, ClickAwayListener, Snackbar } from "@mui/material";
import { pricingPlans } from "./Pricings";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { api } from "../../../Services/api";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { userLogin } from "../../../redux/Auth/actionCreator";
import { useDispatch } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const MultiFormWrap = styled.div`
  height: auto;
  background-color: #151d25;

  .multi-form-btn {
    padding: 0.5rem 2.75rem;
    color: #0054d9;
    font-size: 1.25rem;
    font-weight: bold;
    background-color: #5fccf2;
    border: none;
    margin-bottom: 30px;
    &:hover {
      background-color: #5fccf2;
      opacity: 0.9;
    }
  }

  .multi-form-btn.outline {
    color: #5fccf2;
    border: 1px solid #5fccf2;
    background-color: #151d25;
  }

  .multi-form-btn.outline.back {
    padding: 4px 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .hide-on-mobile {
    display: none;
  }

  .hide {
    visibility: hidden;
  }

  .total_div {
    justify-content: space-between;
    @media (max-width: 576px) {
      justify-content: center !important;
      text-align: center;
    }
  }

  .third_div {
    @media (max-width: 576px) {
      display: none;
    }
  }

  .logo_img {
    align-items: center;
    display: flex;
    justify-content: center;
    @media (max-width: 576px) {
      margin-left: 50px;
    }
  }

  .back-arrow {
    display: none;
    @media (min-width: 576px) {
      display: inline;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: ${(props) => props.secondForm && "970px"};
    }
  }
`;

const BasisCircle = styled.div`
  flex-basis: 0;
  -webkit-box-flex: 1;
  max-width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  cursor: pointer;
  z-index: 10;

  & + &::after {
    content: "";
    z-index: 0;
    position: absolute;
    left: 24px;
    top: 25px;
    height: 2px;
    background-color: #5fccf2;
    translate: -50%;
    width: 100%;
  }
`;

const initialDataFields = {
  otp: "",
  plan: "Free",
  companyname: "",
  personname: "",
  emailId: "",
  password: "",
  confirmPassword: "",
  phonenumber: "",
  // accountNumber: "",
  // expiryDate: "",
  // cvc: "",
  agreedToTerms: false,
  phonenumberError: "",
  clientId: "",
};

const schema = yup
  .object()
  .shape({
    companyname: yup
      .string()
      .required("Company Name is Required")
      .min(3, "Company Name should atleast have 3 letters"),
    personname: yup.string().required("Name is Required"),
    emailId: yup.string().required("Email is Required").email().label("Email"),
    password: yup
      .string()
      .min(6, "Password Must be minimum 6 digits")
      .max(30)
      .required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

const MultiForm = () => {
  const [dataFields, setDataFields] = useState(initialDataFields);

  const [isChecked, setIsChecked] = useState(true);
  const [open, setOpen] = useState(false);

  const initialPlans = (pricingPlans) => {
    return pricingPlans.map((plan) => {
      if (plan.tier === "Free") {
        return { ...plan, checked: true };
      }
      return plan;
    });
  };

  const [plans, setPlans] = useState(initialPlans(pricingPlans));
  const [validationMessage, setValidationMessage] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [accountCreationResponse, setAccountCreationResponse] = useState({
    error: false,
    message: "",
    show: false,
  });
  const [otpLoader, setOtpLoader] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [postOTPResponse, setPostOTPResponse] = useState({
    error: false,
    message: "",
  });

  useEffect(() => {
    // cleanup function initiallising everything to default
    return () => {
      setDataFields(initialDataFields);
      setIsChecked(false);
      setOpen(false);
      setPlans(pricingPlans);
      setValidationMessage("");
      setIsValid(false);
      setAccountCreationResponse({
        error: false,
        message: "",
        show: false,
      });
      setOtpLoader(false);
      setPostOTPResponse({
        error: false,
        message: "",
      });
    };
  }, []);

  const dispatch = useDispatch();

  const mobileScreen = useMediaQuery("(max-width:600px)");

  const updateFields = (fields) => {
    setDataFields((prevFields) => {
      return { ...prevFields, ...fields };
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const postData = async (data) => {
    setPostLoading(true);
    try {
      const response = await api.post("/trackingadmin/clients", {
        ...data,
      });
      console.log("Post User Creation response", response);
      if (!lastStep && isChecked && dataFields.agreedToTerms) {
        next();
      }
      setPostLoading(false);
      setIsValid(true);
      // setAccountCreationResponse({ error: false, message: response.data.message, show: true })
    } catch (error) {
      setPostLoading(false);
      console.log("post User Creation Error response", error);
      setAccountCreationResponse({
        error: true,
        message: error.response.data.message,
        show: true,
      });
    }
  };

  const resendPostOtp = async (id) => {
    const data = {
      emailId: id,
    };
    setOtpLoader(true);
    try {
      const response = await api.post("/trackinguserauth/email", data);
      setOtpLoader(false);
      console.log("resend Response", response);
      setPostOTPResponse({
        error: false,
        message: response?.data?.message,
      });
    } catch (error) {
      setOtpLoader(false);
      setPostOTPResponse({
        error: true,
        message: error?.response?.data?.message,
      });
      console.log("resendOtp Error: ", error);
    }
  };

  const postOtp = async (otp, id) => {
    setOtpLoader(true);
    setPostLoading(true);
    const data = {
      userId: id,
      OTP: parseInt(otp),
      password: dataFields.password,
    };
    try {
      const response = await api.post("/trackingadmin/confirmOtp", data);

      setOtpLoader(false);
      console.log("postOtp Response", response);
      let user = jwt_decode(response.data.authorizationToken);
      setPostLoading(false);
      if (response.status === 200) {
        let result = {
          isLoggedIn: true,
          authToken: response.data.authorizationToken,
          user: user,
          menuList: response.data.menuList,
        };
        dispatch(userLogin(result));
      }
    } catch (error) {
      setOtpLoader(false);
      setPostLoading(false);
      console.log("postOtp Error Response", error);
      setPostOTPResponse({
        error: true,
        message: error?.response?.data?.message,
      });
    }
  };

  const firstForm = (e) => {
    e.preventDefault();
    if (!lastStep && isChecked) next();
    if (!isChecked) {
      setOpen(true);
      setValidationMessage("Select any one of the Plan to Continue");
    }
  };
  const secondForm = (d) => {
    console.log("secondForm  d", d);

    // if (!dataFields.phonenumber || dataFields.phonenumber === (null || undefined)) {
    //     updateFields({ phonenumberError: "Phone number is required" })
    //     return
    // }

    if (!dataFields.agreedToTerms) {
      setOpen(true);
      setValidationMessage(
        "To continue you must agree to Terms and conditions"
      );
      return;
    }
    console.log("form Data", d);
    setDataFields((prev) => {
      return { ...prev, ...d };
    });
    const createdOn = moment().format("DD/MM/YYYY");
    const endsOn = moment().add(15, "days").format("DD/MM/YYYY");
    const planStatus = dataFields.plan;
    const data = {
      ...d,
      phonenumber: dataFields.phonenumber,
      createdon: createdOn,
      trailPeriodEndOn: endsOn,
      subscriptionPlan: planStatus,
    };
    postData(data);
  };

  const thirdForm = (e) => {
    e.preventDefault();
    if (dataFields.otp.length === 6) {
      postOtp(dataFields.otp, dataFields.emailId);
    }
  };

  const resendOTP = () => {
    resendPostOtp(dataFields.emailId);
  };

  const { step, currentStep, next, back, goTo, firstStep, lastStep } =
    UseMultipleForms([

      <PricingPlans
        {...dataFields}
        updateFields={updateFields}
        setOpen={setOpen}
        plans={plans}
        setPlans={setPlans}
        mobileScreen={mobileScreen}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
      />,
      <RegisterAccount
        {...dataFields}
        updateFields={updateFields}
        register={register}
        errors={errors}
      />,
      <OTPField
      {...dataFields}
      updateFields={updateFields}
      resendOTP={resendOTP}
      otpLoader={otpLoader}
    />

    ]);

  const onError = (errors, e) => {
    if (
      !dataFields.phonenumber ||
      dataFields.phonenumber === (null || undefined)
    ) {
      updateFields({ phonenumberError: "Phone number is required" });
      return;
    }
  };

  return (
    <MultiFormWrap id="registrationForm" secondForm={currentStep === 2}>
      {/* After personal Information */}
      <ClickAwayListener
        onClickAway={() => {
          setAccountCreationResponse((prev) => {
            return { ...prev, show: false };
          });
        }}
      >
        <Snackbar
          open={accountCreationResponse.show}
          sx={{ paddingTop: "10px" }}
          onClose={() => {
            setAccountCreationResponse((prev) => {
              return { ...prev, show: false };
            });
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          // autoHideDuration={4000}
        >
          <Alert
            className="py-1 px-3"
            variant="filled"
            severity={accountCreationResponse.error ? "error" : "success"}
          >
            <div className="text-base">
              {accountCreationResponse.message ===
              " An account with this Email address already exists" ? (
                <div>
                  An account with this Email address already exists. Please{" "}
                  {
                    <Link className="fw-bold" to="/login">
                      Login
                    </Link>
                  }{" "}
                  or try again
                </div>
              ) : (
                accountCreationResponse.message
              )}
            </div>
          </Alert>
        </Snackbar>
      </ClickAwayListener>

      <Snackbar
        open={open}
        sx={{ paddingTop: "40px" }}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        // autoHideDuration={4000}
      >
        <Alert className="py-1 px-3" variant="filled" severity={"error"}>
          <div className="text-base">{validationMessage}</div>
        </Alert>
      </Snackbar>

      {postOTPResponse.message !== "" && (
        <Snackbar
          open={postOTPResponse.message !== "" ? true : false}
          sx={{ paddingTop: "10px" }}
          onClose={() => {
            setPostOTPResponse({ error: false, message: "" });
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          // autoHideDuration={4000}
        >
          <Alert
            className="py-1 px-3"
            variant="filled"
            severity={postOTPResponse.error ? "error" : "success"}
          >
            <div className="text-base">{postOTPResponse.message}</div>
          </Alert>
        </Snackbar>
      )}

      <div className="d-flex justify-content-between align-items-center total_div">
        <div className="back-arrow">
          {!lastStep && (
            <Link to="/login">
              <Button
                className={`ms-2 ms-sm-4 multi-form-btn outline back mb-0 flex-shrink-1 ${
                  window.innerWidth <= 576 ? "hide-on-mobile" : ""
                }`}
              >
                <ArrowBackIcon />
              </Button>
            </Link>
          )}
        </div>

        <div className="text-center me-5 pt-2 pt-md-4 logo_img">
          <a href="https://app.tractask.com/">
            <img src={tractaskLogo} alt="trekr icon" />
          </a>
        </div>

        <div className="third_div"></div>
      </div>

      {/* <div className="step-index text-white">
                        {currentStep}/{totalSteps}
                    </div> */}

      <div
        style={{ maxWidth: "1250px" }}
        className="d-flex py-2 py-md-4 justify-content-between mx-auto text-white "
      >
        <BasisCircle onClick={() => isChecked && goTo(0)}>
          <HollowCircle fill={currentStep === 1}>
            {isChecked ? <CheckIcon fontSize="large" /> : "1"}
          </HollowCircle>
          <div>
            <div>Select Plan</div>
          </div>
        </BasisCircle>
        <BasisCircle onClick={() => isChecked && goTo(1)}>
          <HollowCircle fill={currentStep === 2}>
            {isChecked && dataFields.agreedToTerms && isValid ? (
              <CheckIcon fontSize="large" />
            ) : (
              "2"
            )}
          </HollowCircle>
          <div>
            <div>Personal Information</div>
          </div>
        </BasisCircle>
        <BasisCircle
          onClick={() =>
            isChecked && dataFields.agreedToTerms && isValid && goTo(2)
          }
        >
          <HollowCircle fill={currentStep === 3}>3</HollowCircle>
          <div>
            <div>Verify</div>
          </div>
        </BasisCircle>
      </div>

      <Container>
        <form
          onSubmit={
            (currentStep === 1 && firstForm) ||
            (currentStep === 2 && handleSubmit(secondForm, onError)) ||
            (currentStep === 3 && thirdForm)
          }
        >
          {step}

          <div
            className={`mt-5 pt-1 pt-md-3 d-flex ${
              !firstStep
                ? "justify-content-between"
                : "justify-content-center justify-content-lg-end"
            } `}
          >
            {!firstStep && (
              <Button
                className="multi-form-btn outline"
                type="button"
                onClick={back}
              >
                <span className="back-arrow">
                  <KeyboardBackspaceIcon />
                </span>{" "}
                Back
              </Button>
            )}
            {!lastStep && (!firstStep || !mobileScreen) && (
              <Button className="multi-form-btn" type="submit">
                {postLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Next"
                )}
              </Button>
            )}
          </div>
        </form>
      </Container>
    </MultiFormWrap>
  );
};

export default MultiForm;

const HollowCircleWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-bottom: 10px;
  color: ${(props) => (props.fill ? "#0054D9" : "#5FCCF2")};
  font-size: large;
  font-weight: 600;

  .circle {
    z-index: 50;
    border: 2px solid #5fccf2;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #151d25;

    &::after {
      content: "";
      position: absolute;
      z-index: 50;
      border: 2px solid #5fccf2;
      height: 50px;
      width: 50px;
      border-radius: 100%;
      background-color: ${(props) =>
        props.fill === true ? "#5FCCF2" : "transparent"};
    }
  }
`;

const HollowCircle = ({ children, fill }) => {
  return (
    <HollowCircleWrap fill={fill}>
      <div className="circle">
        <div style={{ zIndex: "100", textAlign: "center" }}>{children}</div>
      </div>
    </HollowCircleWrap>
  );
};
