import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import sideViewPreview from "../../../../assets/images/sideview-preview.svg";
import topViewPreview from "../../../../assets/images/top-menu-view.svg";
import SettingsWrap from "./style";
import $ from "jquery";
// import { useMedia } from 'react-use';
import { changepanelView } from "../../../../redux/Layout/actionCreator";
import CloseAccount from "../../CloseAccount";
import PlanInformation from "./PlanInformation";
import StorageInformation from "./StorageInformation";

export const Settings = () => {
  const darkmode = useSelector((state) => state.Layout.darkmode);

  const panelview = useSelector((state) => state.Layout.panelview);
  const user = useSelector((state) => state.Auth.user);
  // const responsiveCard = useMedia('(max-width: 1050px)');

  const [key, setKey] = useState("planInformation");

  useEffect(() => {
    setKey(user.userRole === "User" ? "layout" : "planInformation");

    return () => setKey("planInformation");
  }, [user]);

  const dispatch = useDispatch();

  if (panelview === "sidebar") {
    $("#sideview").prop("checked", true);
    $("#topview").prop("checked", false);
  } else {
    $("#sideview").prop("checked", false);
    $("#topview").prop("checked", true);
  }

  function setView(e) {
    localStorage.setItem("Trekr-Layout", e.target.value);
    dispatch(changepanelView(e.target.value));
  }

  function setViewBigClick(e) {
    localStorage.setItem("Trekr-Layout", e);
    dispatch(changepanelView(e));
  }

  // useEffect(() => {
  //   localStorage.setItem('Trekr-Layout', "sidebar");
  //   dispatch(changepanelView("sidebar"));
  //   console.log('Settings ~ panelview', panelview)
  // }, [responsiveCard]);

  return (
    <>
      <SettingsWrap darkmode={darkmode}>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          {user.userRole !== "User" && (
            <Tab eventKey="planInformation" title="Plan Information">
              <PlanInformation />
            </Tab>
          )}

          {user.userRole !== "User" && (
            <Tab eventKey="storage" title="Storage">
              <StorageInformation />
            </Tab>
          )}

          <Tab eventKey="layout" title="Layout">
            <Row>
              <Col sm={12} md={6}>
                <div
                  className="mb-3 mt-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setViewBigClick("sidebar");
                  }}
                >
                  <Form.Check.Input
                    id="sideview"
                    className="layout-radio"
                    name="sideView"
                    type="radio"
                    value="sidebar"
                    onClick={setView}
                  />
                  <Form.Check.Label className="layout-label">
                    Home Page Side Menu View
                  </Form.Check.Label>

                  <img
                    className="Previewimg sideViewPreviewimg mt-4 mb-4"
                    width="80%"
                    height="80%"
                    src={sideViewPreview}
                    alt="sideViewPreview"
                  />
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div
                  className="mb-3 mt-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setViewBigClick("topmenu");
                  }}
                >
                  <Form.Check.Input
                    id="topview"
                    className="layout-radio"
                    name="topView"
                    value="topmenu"
                    type="radio"
                    onClick={setView}
                  />
                  <Form.Check.Label className="layout-label">
                    Home Page Top Menu View
                  </Form.Check.Label>

                  <img
                    className="Previewimg topViewPreviewimg mt-4 mb-4"
                    width="80%"
                    height="80%"
                    src={topViewPreview}
                    alt="topViewPreview"
                  />
                </div>
              </Col>
            </Row>
          </Tab>
          {/* <Tab eventKey="logo" title="Logo">
            Logo Settings
          </Tab> */}
          {/* <Tab eventKey="title" title="Title">
            Title Settings
          </Tab> */}
          {user.userRole === "User" ? null : (
            <Tab eventKey="account" title="Close Account">
              <CloseAccount />
            </Tab>
          )}
        </Tabs>
      </SettingsWrap>
    </>
  );
};
