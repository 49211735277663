import React from "react";
import { InputGroup } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import { DebounceInput } from "react-debounce-input";

const Searchbox = ({ handleSearch, placeholder, value, forarchive, byName, filterNameIcon }) => {
  const SearchFilter = (e) => {
    handleSearch(e.target.value.trim());
  };
  return (
    <>
      <InputGroup className={byName === "searchByName" ? "mb-0" : "mb-2"}>
        <InputGroup.Text
          className="input-group-text search-box border-end-0 input-group-text pe-0"
          id="basic-addon1"
        >
          {forarchive === "forarchive" ? "" : filterNameIcon ? 
            <img src={filterNameIcon} alt="filterNameIcon" /> :
            <SearchIcon className="search-icon" />
          }
        </InputGroup.Text>
        <DebounceInput
          className="form-control search-control border-start-0"
          minLength={1}
          debounceTimeout={800}
          onChange={SearchFilter}
          placeholder={placeholder}
          value={value ? value : ""}
        />
      </InputGroup>
    </>
  );
};

export default Searchbox;
