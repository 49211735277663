import styled from "styled-components";

const CardWrap = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .cardWrap {
    /* min-height: calc(100vh - 130px);  This will cause the screen to overflow so i comment it out */
    /* height: 73vh; */
    max-height: calc(${props => props.mainPanelHeight} - 180px);
    padding: .5rem 0rem;
    display: flex;
    gap: 1.5rem;
    overflow-y: auto;
    overflow-x: scroll;
  }
  .section {
    min-width: 260px;
    margin-bottom: 0px;
    flex-grow: 1;
    padding-bottom: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* overflow: hidden; */
  }
  .cardWrap::-webkit-scrollbar {
    height: 6px;
    width: 8px;
    background-color: transparent;
  }
  .cardWrap::-webkit-scrollbar-thumb:horizontal {
    background-color: #fff;
    border-radius: 10px;
  }
  .cardWrap::-webkit-scrollbar-thumb:vertical {
    background-color: #fff;
    border-radius: 10px;
    width: 5px;
  }
  .section::-webkit-scrollbar {
    width: 0px;
    /* background-color: transparent; */
  }
  .stateHeader {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .editWorkFlow {
    color: ${({ theme }) => theme["body-text"]};
  }
  .text-field {
    input {
      color: ${({ theme }) => theme["body-text"]};
    }
  }
  .stateNav {
    font-size: 15px;
    color: ${({ theme }) => theme["body-text"]};
    background: ${({ theme }) => theme["panel-color"]};
  }
  .workFlowStep {
    font-size: large;
    cursor: pointer;
  }
  .Error {
    width: 100%;
    padding: 10px 10px;
    max-height: 180px;
    height: auto;
    border-color: ${({ theme }) => theme["border"]};
    background-color: ${({ theme }) => theme["tableViewCard"]};
    border-radius: 12px;
    margin-bottom: 8px;
    position: relative;
  }
  .Error-card{
     width: 100%;
    padding: 1.1rem;
    border-color: ${({ theme }) => theme["border"]};
    background-color: ${({ theme }) => theme["tableViewCard"]};
    border-radius: 10px;
  }
  .error-text {
  letter-spacing: 1px;
  font-size: 18px !important;
  }
  .emptyDtat {
      /* display: flex; */
      /* justify-content: center; */
      /* align-items: center; */
      padding: 0px 12px;
      color: ${({ theme }) => theme["body-text"]};
      font-size: 15px;
      button{
        color: ${({ theme }) => theme["body-text"]};
      }
      .card {
        box-shadow: none !important;
      }
  }
  @media (max-width: 2900px) {
    .section {
      min-width: 300px !important;
      max-width: 300px;
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow-y: scroll;
    }
  }
  @media (min-width: 1024px) and (max-width: 1200px) {
    .section {
      min-width: 370px !important;
      max-width: 370px;
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow-y: scroll;
    }
    .Error {
      width: 100%;
    }
  }
  @media (min-width: 901px) and (max-width: 1023px) {
    .section {
      min-width: 370px !important;
      max-width: 370px;
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow-y: scroll;
    }
    .Error {
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 900px) {
    .section {
      min-width: 260px !important;
      max-width: 260px;
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow-y: scroll;
    }
    .Error {
      width: 100%;
      padding: 1.2rem;
    }
    .emptyDtat {
      button {
        font-size: 12px;
      }
      p {
        font-size: 12px;
      }
    }
  }
  @media (min-width: 321px) and (max-width: 767px) {
    .section {
      min-width: 260px !important;
      max-width: 260px;
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow-y: scroll;
    }
    .Error {
      width: 100%;
      padding: 1.2rem;
    }
    .emptyDtat {
      button {
        font-size: 12px;
      }
      p {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 320px) {
    .section {
      min-width: 260px !important;
      max-width: 260px;
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow-y: scroll;
    }
    .Error {
      width: 100%;
    }
    .stateNav {
      font-size: 12px;
    }
    .Error {
      padding: 19px;
    }
    .emptyDtat {
      button {
        font-size: 12px;
        margin-bottom: 6px;
      }
      p {
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export default CardWrap;
