import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Alert, Spinner } from "react-bootstrap"
import { api } from "../../../../Services/api"
import "react-confirm-alert/src/react-confirm-alert.css"
import {
  setShowAlert,
  setProjectView,
  setProjectDashboardData
} from "../../../../redux/Tracking/actionCreator"
import { ConfirmationBox } from "../../../Reuseable/ConfimationBox"
import viewIcon from "../../../../assets/images/view.svg"
import trashIcon from "../../../../assets/images/icons/bluedelete12.svg"
import { ProjectView } from "./ProjectView"
import { ProjectsListHeader } from "./ProjectsListHeader"
import { Clone } from "./Clone"
import norecordsvg from "../../../../assets/images/norecord.svg"
import { AddNewProject } from "./AddNewProject"
import NoDataFound from "../Tracking/NoDataFound"
import CardUI from "./ProjectCards/Projectindex"
import { v4 as uuid } from "uuid"
import { useSessionStorage } from "react-use"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"
import { client } from "../../../.."

export default function Projects({ getAllWorkFlow, permission }) {
  const editPermission = permission?.edit
  const createPermission = permission?.create
  const deletePermission = permission?.delete
  const viewPermission = permission?.view

  let allTrackingsList = useSelector((state) => state.Tracking.allTrackings)
  let myTrackingList = useSelector((state) => state.Tracking.myTrackings)
  const allFlowStatus = useSelector((state) => state.Tracking.allFlowStatus)
  const allMembers = useSelector((state) => state.Tracking.allMembers)
  const allTaskTypes = useSelector((state) => state.Tracking.allTaskTypes)
  const user = useSelector((state) => state.Auth.user)
  const authToken = useSelector((state) => state.Auth.authToken)
  const filters = useSelector((state) => state.Tracking.filters)
  const [searchValue, setSearchValue] = useState("")
  const [showAlertBox, setShowAlertBox] = useState(false)
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)
  const [showView, setShowView] = useState(false)
  const [trackingItemData, setTrackingItemData] = useSessionStorage("projectTrackingData", {})
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false)
  const [clonetaskData, setCloneTask] = useState({})
  const [taskfile, setTaskFile] = useState("")
  const [taskfileList, setTaskFileList] = useState("")
  const [taskfileType, setTaskFileType] = useState("")
  const [showEditTask, setShowEditTask] = useState("")
  const [disableBtn, setDisableBtn] = useState(false)
  const [showProject, setShowProject] = useState(false)
  const [updatedData, setUpdatedData] = useSessionStorage("projectUpdatedData", {})

  const view = useSelector((state) => state.Tracking.projectView)

  const {
    data: allProjectsRes,
    isFetched: fetchedProjects,
    refetch,
    isLoading: loading,
    isFetching
  } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )

  const getAllProjects = () => {
    refetch()
  }

  let allProjects = allProjectsRes?.data?.Items ?? []

  let projectView = showProject ? "add project" : view ?? "all projects"
  const projectViewRender = (value) => {
    return dispatch(setProjectView(value))
  }

  useEffect(() => {
    if (projectView === "all projects") {
      setShowProject(false)
    }
  }, [projectView])

  const selected_menu = "all projects"

  const includeUuid = (data) => {
    return Object.entries(data).map(([k, d]) => {
      return { ...d, id: uuid() }
    })
  }
  const onClickProject = () => {
    projectViewRender("project view")
    setShowView(true)
  }
  const fetchProjectDashboard = async (item) => {
    try {
      const url = `/projectdashboard/${trackingClientId}/${item.trackingprojectid}`
      const response = await api.get(url)

      if (response.data.data) {
        dispatch(setProjectDashboardData(response.data.data))
        onClickProject()
        setDisableBtn(false)
      }
    } catch (error) {
      console.error("fetchArchiveDataList error", error)
      if (error.response && error.response.status === 401) {
        setDisableBtn(false)
      }
    }
  }

  let getData = (data) => {
    const result = data
      ?.filter((item) => {
        let projectIdSearch = item.trackingprojectid
        return (
          projectIdSearch.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.label.toLowerCase().includes(searchValue.toLowerCase())
        )
      })
      .map((item, i) => {
        if (i === "1") {
          setTrackingItemData(item)
        }
        item.cloneTitle = "Clone - " + item.label
        return (
          <tr key={i} val={item}>
            <td style={{ cursor: "pointer" }}>
              <strong className='mobHead'>Project ID :</strong>
              <span
                style={{
                  textDecoration: "underline",
                  pointerEvents: disableBtn ? "none" : "auto"
                }}
                onClick={(e) => {
                  setDisableBtn(true)
                  fetchProjectDashboard(item)
                  setTrackingItemData(item)
                }}
              >
                {item.trackingprojectid}
              </span>
            </td>
            <td>
              <strong className='mobHead'>Project Name :</strong>
              <span>{item?.label}</span>
            </td>
            <td>
              <strong className='mobHead'>Estimated Start date :</strong>
              <span>{item.startdate === "" ? "-" : item.startdate}</span>
            </td>
            <td>
              <strong className='mobHead'>Estimated Due date :</strong>
              <span>{item.duedate === "" ? "-" : item.duedate}</span>
            </td>
            <td className='text-capitalize'>
              <strong className='mobHead'>Created By: </strong>
              <span>{item.createdby === "" ? "-" : item.createdby}</span>
            </td>
            <td>
              <strong className='mobHead'>Priority : </strong>
              <span className='text-capitalize'>
                {item.priority === "blocker"
                  ? "Blocker Level 1"
                  : item.priority === "blocker2"
                  ? "Blocker Level 2"
                  : item.priority === ""
                  ? "-"
                  : item.priority}
              </span>
            </td>
            <td style={{ width: "300", whiteSpace: "nowrap" }} className='text-center'>
              <Button size='sm' variant='link' className='text-decoration-none pe-1 ps-1'>
                <img
                  alt='img'
                  src={viewIcon}
                  onClick={(e) => {
                    // fetchFileData(item)
                    projectViewRender("project view")
                    setShowView(true)
                    setTrackingItemData(item)
                    resetState()
                  }}
                />
              </Button>
              <>
                {deletePermission && (
                  <Button size='sm' variant='link' className='text-decoration-none pe-1 ps-1'>
                    <img
                      alt='img'
                      src={trashIcon}
                      onClick={(e) => {
                        setDeleteConfirmationShow(true)
                        setTrackingItemData(item)
                      }}
                    />
                  </Button>
                )}{" "}
              </>
            </td>
          </tr>
        )
      })

    return result
  }

  const [columns, setColumns] = useState({
    [uuid()]: {
      name: "New",
      items: [],
      loader: false
    },
    [uuid()]: {
      name: "In Progress",
      items: [],
      loader: false
    },
    [uuid()]: {
      name: "Completed",
      items: [],
      loader: false
    }
  })

  const makeCategory = (data) => {
    let uuidData = includeUuid(data)
    let cols = {
      [uuid()]: {
        name: "New",
        items: [],
        loader: false
      },
      [uuid()]: {
        name: "In Progress",
        items: [],
        loader: false
      },
      [uuid()]: {
        name: "Completed",
        items: [],
        loader: false
      }
    }
    Object.entries(uuidData).map(([_key, value]) => {
      return Object.entries(cols).map(([_k, col]) => {
        if (col?.name?.toLowerCase() === value?.priority?.toLowerCase()) {
          return col.items.push(value)
        }
      })
    })

    setColumns(cols)
  }

  const dispatch = useDispatch()

  const [trackingFilters, setTrackingFilters] = useState({
    projectsFilter: filters?.projects,
    assignedtoFilter: filters?.assignedTo,
    statusFilter: filters?.status,
    priorityFilter: filters?.priority,
    reportedByFilter: filters?.reportedBy
  })

  const selected_tab = "my_tasks"
  const showFilter = false
  const [viewTaskData, setViewTaskData] = useSessionStorage("ProjectViewData", {})
  const [taskHistory, setHistory] = useState("")
  const [showClone, setShowClone] = useState(false)

  const isLoading = (columnName, ans) => {
    const newColumns = Object.entries(columns).map(([key, value]) => {
      if (value.name === columnName) {
        return {
          [uuid()]: { ...value, loader: ans }
        }
      }
      return { [uuid()]: value }
    })

    let updatedColumns = {}

    newColumns.forEach((element, index) => {
      const [key] = Object.keys(element)
      const [value] = Object.values(element)
      updatedColumns[key] = value
    })
    setColumns(updatedColumns)
  }

  useEffect(() => {
    let filteredProjects = allProjects.filter((item) => {
      // let projectIdSearch = item?.trackingprojectid;
      return (
        // projectIdSearch.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.label.toLowerCase().includes(searchValue.toLowerCase())
      )
    })

    if (filteredProjects?.length > 0) {
      makeCategory(filteredProjects)
    }
  }, [allProjects, searchValue])

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
      setDeleteAlert(false)
    }, 2000)
  }, [showAlertBox])

  useEffect(() => {
    setViewTaskData((prevState) => {
      return {
        ...prevState,
        label: trackingItemData.label,
        projectid: trackingItemData.trackingprojectid,
        startdate: trackingItemData.startdate,
        duedate: trackingItemData.duedate,
        percentage: trackingItemData.percentage,
        priority: trackingItemData.priority,
        createdby: trackingItemData.createdby,
        projectdescription: trackingItemData.projectdescription,
        budget: trackingItemData.budget,
        workflow: trackingItemData.workflow,
        fileurls: trackingItemData.fileurls,
        teammates: trackingItemData.teammates
      }
    })
    setCloneTask((prevState) => {
      return {
        ...prevState,
        title: trackingItemData.cloneTitle,
        priority: trackingItemData.priority
      }
    })
    setUpdatedData((prevState) => {
      return {
        ...prevState,
        fileurls: trackingItemData.fileurls,
        filesize: trackingItemData.filesize
      }
    })
  }, [trackingItemData])

  /************ Delete project ******************/

  const deleteConfimrationHandler = (response) => {
    if (response) {
      api
        .delete(
          "/trackingprojects/" + trackingClientId + "/" + trackingItemData.trackingprojectid,
          {
            headers: {
              authorizationtoken: `${authToken}`
            }
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            setShowAlertBox(true)
            projectViewRender("all projects")
            const updatedProjects = response.data.data
            client.setQueryData([key.get_all_projects], (oldData) => {
              return {
                ...oldData,
                data: {
                  Items: updatedProjects
                }
              }
            })
            setAlertMessage(trackingItemData.label + " - has been deleted successfully")
          }
        })
        .catch((error) => {
          if (error) {
            setShowAlertBox(true)
            setDeleteAlert(true)
            setAlertMessage(
              "Unable to delete this project! " + trackingItemData.label + " - has task"
            )
          }
          console.log(error)
        })
    }
  }

  const resetState = () => {
    setHistory("")
    setShowEditTask({
      editTitle: false,
      editProject: false,
      editTaskType: false,
      editReportedBy: false,
      editAssignedTo: false,
      editPriority: false,
      editTimeline: false,
      editStatus: false,
      editDescription: false
    })
  }

  const handleSearch = (value) => {
    setSearchValue(value)
  }

  if (projectView === "add project") {
    return (
      createPermission && (
        <AddNewProject
          setProjectView={projectViewRender}
          setShowAlertBox={setShowAlertBox}
          setAlertMessage={setAlertMessage}
          onTaskView={false}
          getAllWorkFlow={getAllWorkFlow}
          getAllProjects={getAllProjects}
          taskViewPanel='all projects'
        />
      )
    )
  }

  return (
    <>
      <ConfirmationBox
        heading='Are you sure to delete this ?'
        hidePopup={(e) => setDeleteConfirmationShow(false)}
        show={deleteConfirmationShow}
        confirmationResponse={deleteConfimrationHandler}
      />
      {projectView === "all projects" ? (
        <>
          {fetchedProjects ? (
            <>
              {!allProjects?.length > 0 && fetchedProjects ? (
                <>
                  <NoDataFound
                    setProjectView={projectViewRender}
                    setShowAlertBox={setShowAlertBox}
                    setAlertMessage={setAlertMessage}
                    addtask='add projects'
                    headcontent='You have not added any Project to you account.'
                    bodycontent='Add a new project to keep track of all your task and progress with your entire team.'
                    btncontent='Projects'
                    img={norecordsvg}
                  />
                </>
              ) : (
                <>
                  {showAlertBox ? (
                    <>
                      {deleteAlert ? (
                        <Alert
                          variant='danger'
                          closeVariant='white'
                          className='text-center alert-sucess'
                          dismissible
                          onClick={(e) => setShowAlertBox(false)}
                        >
                          {alertMessage}
                        </Alert>
                      ) : (
                        <Alert
                          variant='success'
                          closeVariant='white'
                          className='text-center alert-sucess'
                          dismissible
                          onClick={(e) => setShowAlertBox(false)}
                        >
                          {alertMessage}
                        </Alert>
                      )}{" "}
                    </>
                  ) : (
                    <></>
                  )}

                  <div className='mt-2'>
                    <ProjectsListHeader
                      allTrackingsList={
                        selected_tab === "all_tasks" ? allTrackingsList : myTrackingList
                      }
                      setProjectView={projectViewRender}
                      searchValue={searchValue}
                      createPermission={createPermission}
                      handleSearch={handleSearch}
                      userRole={user.rolename}
                      userRoleType={user.roletype}
                      allMembers={allMembers}
                      allProjects={allProjects}
                      cardView='Card'
                      refreshAllTracking={() => getAllProjects()}
                      setLoading={(val) => console.log(val)}
                      loading={loading || isFetching}
                      trackingFilters={trackingFilters}
                      setTrackingFilters={setTrackingFilters}
                      trackingData={[]}
                      setFormType={() => {}}
                      setChangeInForm={() => {}}
                      showFilter={showFilter}
                    />
                  </div>

                  {loading ? (
                    <div className='text-center mt-5 mb-5'>
                      <Spinner animation='border' variant='info' />
                    </div>
                  ) : (
                    <CardUI
                      columns={columns}
                      setColumns={setColumns}
                      data={getData(allProjects)}
                      isLoading={isLoading}
                      setProjectView={projectViewRender}
                      setShowView={setShowView}
                      setTrackingItemData={setTrackingItemData}
                      selected_menu={selected_menu}
                      setDeleteConfirmationShow={setDeleteConfirmationShow}
                      resetState={resetState}
                      setShowEditTask={setShowEditTask}
                      setShowAlertBox={setShowAlertBox}
                      setAlertMessage={setAlertMessage}
                      deletePermission={deletePermission}
                      createPermission={createPermission}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <div className='text-center mt-5 mb-5'>
              <Spinner animation='border' variant='info' />
            </div>
          )}
        </>
      ) : projectView === "project view" || projectView === "all projects" ? (
        <>
          {trackingItemData ? (
            <ProjectView
              setProjectView={projectViewRender}
              show={showView}
              setTaskFile={setTaskFile}
              setTaskFileType={setTaskFileType}
              taskfileType={taskfileType}
              item={trackingItemData}
              taskfileList={taskfileList}
              setTaskFileList={setTaskFileList}
              taskfile={taskfile}
              setShowView={(value) => setShowView(value)}
              allTaskTypes={allTaskTypes}
              allProjects={allProjects}
              allMembers={allMembers}
              allStatus={allFlowStatus}
              data={viewTaskData}
              refreshfiles={() => {}}
              setViewTaskData={setViewTaskData}
              trackingItemData={trackingItemData}
              setTrackingItemData={setTrackingItemData}
              showEditTask={showEditTask}
              setShowEditTask={setShowEditTask}
              updatedData={updatedData}
              setUpdatedData={setUpdatedData}
              history={taskHistory}
              setHistory={setHistory}
              setShowClone={setShowClone}
              type={"tracking"}
              activity={true}
              selected_menu={selected_menu}
              setDeleteConfirmationShow={setDeleteConfirmationShow}
              fetchFileData={() => {}}
              getAllProjects={getAllProjects}
              getAllWorkFlow={getAllWorkFlow}
              permission={permission}
              deletePermission={deletePermission}
              editPermission={editPermission}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}

      <Clone
        showClone={showClone}
        setShowClone={setShowClone}
        item={trackingItemData}
        allTrackingsList={allTrackingsList}
        setShowAlertBox={setShowAlertBox}
        setAlertMessage={setAlertMessage}
        clonetaskData={clonetaskData}
        setCloneTask={setCloneTask}
        setShowAlert={setShowAlert}
        onTaskView={false}
        setProjectView={projectViewRender}
        getAllProjects={getAllProjects}
      />
    </>
  )
}
