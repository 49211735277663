import React, { useState } from "react"
import { Row, Col, Card, Modal, Button, Spinner } from "react-bootstrap"
import { useSelector } from "react-redux"
import DashboardView from "./style"
import { Validators } from "../../../../Utilities/validator"
import { Forms } from "../../../Reuseable/Forms"
import ProgressBar from "react-bootstrap/ProgressBar"
import "react-circular-progressbar/dist/styles.css"
import homeImage from "../../../../../src/assets/images/icons/Add notes-pana.svg"
import moment from "moment"
import { Tooltip } from "@mui/material"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"
import { colors } from "../../../../Utilities/commonFunctions"

export const Dashboard = ({ setShowProject }) => {
  const user = useSelector((state) => state.Auth.user)
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)
  let allTrackingsList = useSelector((state) => state.Tracking.allTrackings)

  const { data: allProjectsRes, isLoading: allProjectsLoading } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )

  let allProjects = allProjectsRes?.data?.Items ?? []

  const [addMemberShow, setAddMemberShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const SubmitForm = () => {
    setLoading(true)
  }

  let items = []
  allTrackingsList?.forEach((element) => {
    items.push(element)
  })

  let formArr = [
    {
      label: "Email",
      name: "email",
      type: "email",
      placeholder: "example@gmail.com",
      validators: [
        { check: Validators.required, message: "This Field is required" },
        { check: Validators.email, message: "Email is not Valid" }
      ],
      error: false,
      errormsg: "",
      value: "",
      show: true
    },
    {
      label: "Role",
      name: "role",
      type: "select",
      placeholder: "Select",
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      options: [
        { label: "Business", id: "business" },
        { label: "Developer", id: "developer" },
        { label: "Tester", id: "tester" }
      ],
      value: "",
      show: true
    },
    {
      label: "Type",
      name: "type",
      type: "select",
      placeholder: "Select",
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      options: [
        { label: "Authoriser", id: "authoriser" },
        { label: "Creator", id: "creator" }
      ],
      value: "",
      show: true
    }
  ]

  const sortAllProject = () => {
    const projectDurationPercentageAdded = allProjects.map((allProject) => {
      const currentTime = new moment()
      const startDate = moment(allProject.startdate, "DD/MM/YYYY")
      const dueDate = moment(allProject.duedate, "DD/MM/YYYY")
      const duration = moment.duration(moment(dueDate).diff(moment(startDate))).asMilliseconds()
      const actualDuration = Math.abs(
        moment.duration(currentTime.diff(moment(startDate))).asMilliseconds()
      )
      const projectDurationPercentage = (actualDuration / duration) * 100
      return {
        ...allProject,
        projectDurationPercentage: projectDurationPercentage
      }
    })

    return projectDurationPercentageAdded.sort((a, b) =>
      a.projectDurationPercentage < b.projectDurationPercentage
        ? 1
        : a.projectDurationPercentage > b.projectDurationPercentage
        ? -1
        : 0
    )
  }

  return (
    <DashboardView>
      {allProjectsLoading ? (
        <div className='text-center mt-5 mb-5'>
          <Spinner animation='border' variant='info' />
        </div>
      ) : (
        <>
          {allProjects.length === 0 ? (
            <>
              <div className='mb-4 mt-3'>
                <h5 className='me-2 heading org-color home-head'>Hello {user.personname}!</h5>
              </div>
              <Row className='view-card'>
                <Col sm='12' md='12' lg='12'>
                  <Card className='card-bd'>
                    <Card.Body>
                      <div className='member-list'>
                        <Row className='p-xl-4 '>
                          <Col xl='7' className='p-xl-4 py-2'>
                            <p style={{ fontSize: "20px" }}>
                              Looks like you have not added any project to your dashboard yet.{` `}
                              <span style={{ color: "#4EB6EE" }}>Add new project</span> and start
                              tracking all your task and progress.
                            </p>
                            <Col className='py-xl-4 py-2'>
                              <Button
                                className='me-1'
                                onClick={() => {
                                  setShowProject()
                                }}
                                style={{
                                  padding: "3px 6px",
                                  backgroundColor: "#4EB6EE",
                                  borderColor: "transparent"
                                }}
                              >
                                Add Project
                              </Button>
                            </Col>
                          </Col>
                          <Col xl='3' className='home-image'>
                            <img src={homeImage} alt='homeImg'></img>
                          </Col>
                        </Row>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {/* <Row className='project-select py-1'></Row> */}
              {/* <Col sm='12' md='6' lg='6' xl='4' className='circle-progressDiv mt-3'>
                <div className='circle-div mt-3'>
                  <div className='circle-div1'>
                    <CircularProgressbar
                      value={0}
                      text={"0%"}
                      background
                      backgroundPadding={6}
                      styles={buildStyles({
                        backgroundColor: "#3E3F3E",
                        textColor: "#fff",
                        pathColor: "#fff",
                        trailColor: "none",
                        strokeLinecap: "butt"
                      })}
                    />
                  </div>
                </div>
                <Row className='py-xl-2'>
                  <Col xl='7' xs='12' lg='6' className=' px-4 py-2 py-lg-3'>
                    <span style={{ color: "#3e3f3e" }} className='task-box'>
                      Open: 0
                    </span>
                  </Col>
                  <Col xl='5' xs='12' lg='6' className=' px-4 py-md-1 pb-1 py-lg-3'>
                    <span style={{ color: "#FFFFFF" }} className='task-box'>
                      Total Tasks: 0
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col sm='12' md='6' lg='6' xl='4' className='circle-progressDiv pro-gressdiv mt-3'>
                <div className='circle-div mt-3'>
                  <div className='circle-div1'>
                    <CircularProgressbar
                      value={0}
                      text={"0%"}
                      background
                      backgroundPadding={6}
                      styles={buildStyles({
                        backgroundColor: "#3E3F3E",
                        textColor: "#fff",
                        pathColor: "#fff",
                        trailColor: "transparent",
                        strokeLinecap: "butt"
                      })}
                    />
                  </div>
                </div>
                <Row className='py-xl-2'>
                  <Col xl='7' xs='12' lg='6' className=' px-4 py-2 py-lg-3'>
                    <span style={{ color: "#3e3f3e" }} className='task-box'>
                      In Progress: 0
                    </span>
                  </Col>
                  <Col xl='5' xs='12' lg='6' className='px-4 py-md-1 py-lg-3'>
                    <span style={{ color: "#FFFFFF" }} className='task-box'>
                      Total Tasks: 0
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col sm='12' md='6' lg='6' xl='4' className='circle-progressDiv pro-gressdiv mt-3'>
                <div className='circle-div mt-3'>
                  <div className='circle-div1'>
                    <CircularProgressbar
                      value={0}
                      text={"0%"}
                      background
                      backgroundPadding={6}
                      styles={buildStyles({
                        backgroundColor: "#3E3F3E",
                        textColor: "#fff",
                        pathColor: "#fff",
                        trailColor: "transparent",
                        strokeLinecap: "butt"
                      })}
                    />
                  </div>
                </div>
                <Row className='py-xl-2'>
                  <Col xl='7' xs='12' lg='7' className=' px-4 py-2 py-lg-3'>
                    <span style={{ color: "#3e3f3e" }} className='task-box'>
                      Ready for testing: 0
                    </span>
                  </Col>
                  <Col xl='5' xs='12' lg='5' className='px-4 py-md-1 py-lg-3'>
                    <span style={{ color: "#FFFFFF" }} className='task-box'>
                      Total Tasks: 0
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col sm='12' md='6' lg='6' xl='4' className='circle-progressDiv mt-3'>
                <div className='circle-div mt-3'>
                  <div className='circle-div1'>
                    <CircularProgressbar
                      value={0}
                      text={"0%"}
                      background
                      backgroundPadding={6}
                      styles={buildStyles({
                        backgroundColor: "#3E3F3E",
                        textColor: "#fff",
                        pathColor: "#fff",
                        trailColor: "transparent",
                        strokeLinecap: "butt"
                      })}
                    />
                  </div>
                </div>
                <Row className='py-xl-2'>
                  <Col xl='7' xs='12' lg='6' className=' px-4 py-2 py-lg-3'>
                    <span style={{ color: "#3e3f3e" }} className='task-box'>
                      Verified: 0
                    </span>
                  </Col>
                  <Col xl='5' xs='12' lg='6' className='px-4 py-md-1 py-lg-3'>
                    <span style={{ color: "#FFFFFF" }} className='task-box'>
                      Total Tasks: 0
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col sm='12' md='6' lg='6' xl='4' className='circle-progressDiv pro-gressdiv mt-3'>
                <div className='circle-div mt-3'>
                  <div className='circle-div1'>
                    <CircularProgressbar
                      value={0}
                      text={"0%"}
                      background
                      backgroundPadding={6}
                      styles={buildStyles({
                        backgroundColor: "#3E3F3E",
                        textColor: "#fff",
                        pathColor: "#fff",
                        trailColor: "transparent",
                        strokeLinecap: "butt"
                      })}
                    />
                  </div>
                </div>
                <Row className='py-xl-2 py-lg-1'>
                  <Col xl='7' xs='12' lg='6' className=' px-4 py-2 py-lg-3'>
                    <span style={{ color: "#3e3f3e" }} className='task-box'>
                      Deployed: 0
                    </span>
                  </Col>
                  <Col xl='5' xs='12' lg='6' className='px-4 py-md-1 py-lg-3'>
                    <span style={{ color: "#FFFFFF" }} className='task-box'>
                      Total Tasks: 0
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col sm='12' md='6' lg='6' xl='4' className='circle-progressDiv pro-gressdiv mt-3'>
                <div className='circle-div mt-3'>
                  <div className='circle-div1'>
                    <CircularProgressbar
                      value={0}
                      text={"0%"}
                      background
                      backgroundPadding={6}
                      styles={buildStyles({
                        backgroundColor: "#3E3F3E",
                        textColor: "#fff",
                        pathColor: "#fff",
                        trailColor: "transparent",
                        strokeLinecap: "butt"
                      })}
                    />
                  </div>
                </div>
                <Row className='py-xl-2'>
                  <Col xl='7' xs='12' lg='6' className=' px-4 py-2 py-lg-3'>
                    <span style={{ color: "#3e3f3e" }} className='task-box'>
                      Closed: 0
                    </span>
                  </Col>
                  <Col xl='5' xs='12' lg='6' className='px-4 py-md-1 py-lg-3'>
                    <span style={{ color: "#FFFFFF" }} className='task-box'>
                      Total Tasks: 0
                    </span>
                  </Col>
                </Row>
              </Col> */}
            </>
          ) : (
            <>
              <div className='mb-4 mt-3'>
                <h5 className='me-2 heading org-color home-head1'>Hello {user.personname}!</h5>
              </div>
              <Row className='view-card2'>
                <Col sm='12' md='12' lg='12'>
                  <Card className='card-bd'>
                    <Card.Body>
                      <div className='member-list'>
                        <Row>
                          {sortAllProject().map((item, index) => {
                            const { projectDurationPercentage } = item

                            const settingProjectLabel = () => {
                              if (projectDurationPercentage >= 0) {
                                if (projectDurationPercentage >= 100) {
                                  return {
                                    color: "#A80809",
                                    label: "Off track",
                                    class: "progress-off-track"
                                  }
                                }
                                if (projectDurationPercentage >= 80) {
                                  return {
                                    color: "#4EB6EE", // old color for at risk #FCC404
                                    label: "At risk",
                                    class: "progress-at-risk"
                                  }
                                }
                                if (projectDurationPercentage < 80) {
                                  return {
                                    color: "#4EB6EE", // old color for at good #246324
                                    label: "Good",
                                    class: "progress-good"
                                  }
                                }
                                return {
                                  color: "grey",
                                  label: "-",
                                  class: "progress-default"
                                }
                              }
                              return { class: "progress-default" }
                            }

                            return index % 2 === 0 ? (
                              <ProgressColumn
                                settingProjectLabel={settingProjectLabel}
                                item={item}
                              />
                            ) : (
                              <ProgressColumn
                                settingProjectLabel={settingProjectLabel}
                                item={item}
                              />
                            )
                          })}
                        </Row>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className='view-card2'>
                <Col className='pt-2' sm='12' md='12' lg='12'>
                  <Card className='card-bd'>
                    <Card.Body>
                      <h6 className='mb-4 mt-1 projectlist'>
                        <strong>Project List</strong>
                      </h6>
                      <div className='project-list'>
                        <Row>
                          {allProjects.map((item, index) => {
                            // only have 7 colors
                            let color = colors[index % 7]
                            return (
                              <Col className='mb-3 data-div' xs='6' sm='6' md='4' lg='3' xl='2'>
                                <div
                                  className='project-color-block text-capitalize pt-4'
                                  style={{ backgroundColor: color }}
                                >
                                  <div className='project-block text-center'>
                                    {item.label ? item.label[0] : ""}
                                  </div>
                                </div>
                                <Col
                                  className='data-div'
                                  xs='12'
                                  sm='12'
                                  md='12'
                                  lg='12'
                                  xl='12'
                                  style={{ textAlign: "center" }}
                                >
                                  <small className='project-label'>{item.label}</small>
                                </Col>
                              </Col>
                            )
                          })}
                        </Row>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </>
      )}

      <Modal
        show={addMemberShow}
        onHide={(e) => setAddMemberShow(false)}
        size='md'
        centered
        keyboard={false}
      >
        <Modal.Header closeButton className='border-0'>
          <Modal.Title>
            <h5>Add new team member</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Forms
            title={""}
            formArr={formArr}
            classes='p-1'
            submitBtn={"Send Request"}
            cancelBtn={false}
            onSubmit={SubmitForm}
            loading={loading}
          />
        </Modal.Body>
      </Modal>
    </DashboardView>
  )
}

const ProgressColumn = ({ settingProjectLabel, item }) => {
  return (
    <Col xs={6}>
      <Row>
        <Col>
          <p
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline"
            }}
          >
            <small>
              {item.label} : <span className='due-date'>Due date {item.duedate}</span>
            </small>
            {/* <span className="percent-column">{item.percentage}%</span> */}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tooltip
            title={`${item.percentage}% Completed | ${100 - item.percentage}% Remaining`}
            placement='top'
            followCursor
            arrow
          >
            <ProgressBar
              // animated
              className={`${settingProjectLabel().class}`}
              // style={{ backgroundColor: settingProjectLabel().color }}
              now={item.percentage}
            />
          </Tooltip>
          <br />
        </Col>
      </Row>
    </Col>
  )
}
