import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setProjectModelData } from "../../../../../redux/Tracking/actionCreator"
import EditIcon from "../../../../../../src/assets/images/icons/noun-edit-3556018.svg"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import CopyIcon from "../../../../../../src/assets/images/icons/copy.svg"
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft"
import { Button, Col, Form, Row, Table } from "react-bootstrap"
import BarLoader from "react-spinners/ClipLoader"
import ProjectModelEditView from "./style"
import { WysiwygEditor } from "../../../../Reuseable/WysiwygEditor"
import usePrevious from "../../../../../Hooks/usePrevious"
import { api } from "../../../../../Services/api"

const EditProjectModel = ({
  item,
  data,
  setTaskview,
  setViewTaskData,
  showEditTask,
  setShowEditTask,
  editPermission,
  updatedData,
  setUpdatedData,
  setHistory
}) => {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.Auth.user)
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)
  let allProjectModelData = useSelector((state) => state.Tracking.allProjectModelData)
  const index = allProjectModelData.findIndex(
    (element) => element.ProjectModelId === item.ProjectModelId
  )
  const authToken = useSelector((state) => state.Auth.authToken)

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [changeInForm, setChangeInForm] = useState(false)
  const [templateDescription, setTemplateDescription] = useState(true)
  const [msg, setMsg] = useState()
  const [deletedFile, setDeletedFile] = useState([])

  const previousData = usePrevious(data)
  const titleRef = useRef()
  const descriptionRef = useRef()

  const updateTask = (name, value) => {
    setLoading(true)
    let Obj = {
      name: name,
      value: value
    }
    if (name === "ModelTitle")
      setViewTaskData((prevState) => {
        return { ...prevState, ModelTitle: value }
      })
    if (value) {
      api
        .patch(`/projectmodellist/${trackingClientId}/${item.ProjectModelId}`, Obj, {
          headers: {
            authorizationtoken: `${authToken}`
          }
        })
        .then(function (response) {
          setLoading(false)
          if (response.status === 200) {
            setHistory(response.data.updatedHistory)
            if (index !== -1 || index === 0) {
              allProjectModelData[index][name] = value
              let newObj = [...allProjectModelData]
              dispatch(setProjectModelData(newObj))
            }
            if (name === "ModelDescription") {
              setViewTaskData((prevState) => {
                return { ...prevState, ModelDescription: value }
              })
              setShowEditTask((prevState) => {
                return { ...prevState, editDescription: false }
              })
            }
          }
        })
        .catch((_error) => {})
    }
  }

  const editItem = (item) => {
    if (item === "ModelTitle")
      setShowEditTask((prevState) => {
        return { ...prevState, editTitle: true }
      })
    if (item === "ModelDescription")
      setShowEditTask((prevState) => {
        return { ...prevState, editDescription: true }
      })
  }

  const copyTicketNumber = (e) => {
    var text = trackingClientId + "-" + item.trackingid
    navigator.clipboard.writeText(text)
    setMsg("Copied")
    setTimeout(() => {
      setMsg("")
    }, 750)
  }

  useEffect(() => {
    if (showEditTask.editTitle) {
      titleRef.current.focus()
    }
    if (showEditTask.editDescription) {
      descriptionRef.current.focusEditor()
    }
  }, [showEditTask])

  const handleSplChar = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      editItem("ModelTitle")
      updateTask("ModelTitle", updatedData.ModelTitle)
      setShowEditTask((prevState) => {
        return { ...prevState, editTitle: false }
      })
    }
    if (
      e.key === "#" ||
      e.key === "$" ||
      e.key === "^" ||
      e.key === "@" ||
      e.key === "*" ||
      e.key === "(" ||
      e.key === "!" ||
      e.key === ")" ||
      e.key === "%" ||
      e.key === "="
    ) {
      e.preventDefault()
    }
  }

  const editIconCSS = {
    marginLeft: "3%",
    cursor: "pointer",
    marginTop: "1%"
  }

  const copyIconCSS = {
    marginLeft: "3%",
    cursor: "pointer",
    marginTop: "2%"
  }

  const tickCloseIconCSS = {
    fontSize: "17px",
    marginTop: "-2px",
    marginLeft: "1%"
  }

  return (
    <>
      <ProjectModelEditView>
        <h4 className='d-flex justify-content-start align-items-center mb-1'>
          <ArrowCircleLeftIcon
            className='me-3'
            onClick={() => {
              setTaskview("All ProjectModel")
            }}
            style={{ cursor: "pointer", color: "#4EB6EE", fontSize: "30px" }}
          />
          <div className='title add-new-task'>Edit Template</div>
        </h4>
        <div className='taskconatiner p-2'>
          <Table className='trackingviewtable'>
            <tbody className='trackingviewtbody'>
              <div className='trackingviewtbody'>
                <Row>
                  <Col sm={12} md={12} lg={6}>
                    <tr className=''>
                      <td className='border-0 tr-color select-template-options'>
                        <strong>Projectmodel ID:</strong>
                      </td>
                      <td className=' border-0  text-capitalize'>
                        <strong className='edit-content tr-color'>
                          {trackingClientId}-{item.ProjectModelId}
                          <img
                            src={CopyIcon}
                            height={15}
                            width={15}
                            style={copyIconCSS}
                            onClick={copyTicketNumber}
                          />
                          <span style={{ marginLeft: "4%", color: "#4EB6EE", fontWeight: "300" }}>
                            {msg}
                          </span>
                        </strong>
                      </td>
                    </tr>
                  </Col>
                  <Col sm={12} md={12} lg={6}></Col>
                  <Col sm={12} md={12} lg={6}>
                    <tr className='tr-color '>
                      <td className='border-0 select-template-options'>Projectmodel name:</td>
                      <td className='border-0'>
                        {showEditTask && showEditTask.editTitle ? (
                          <div className='d-flex edit-content trackingdatarow'>
                            <Form.Control
                              type='text'
                              className='p-0 select-options'
                              ref={titleRef}
                              value={updatedData.ModelTitle}
                              onChange={(e) =>
                                setUpdatedData((prevState) => {
                                  return { ...prevState, ModelTitle: e.target.value }
                                })
                              }
                              onKeyDown={handleSplChar}
                            />
                            <div
                              className='ms-2 text-center edit-cross'
                              onClick={(e) => {
                                setShowEditTask((prevState) => {
                                  return { ...prevState, editTitle: false }
                                })
                                updateTask("ModelTitle", updatedData.ModelTitle)
                              }}
                            >
                              <CheckIcon style={tickCloseIconCSS} />
                            </div>
                            <div
                              className='ms-2 text-center edit-cross'
                              onClick={(e) =>
                                setShowEditTask((prevState) => {
                                  return { ...prevState, editTitle: false }
                                })
                              }
                            >
                              <CloseIcon style={tickCloseIconCSS} />
                            </div>
                          </div>
                        ) : (
                          <span className='edit-content'>
                            {item.ModelTitle ? updatedData.ModelTitle : ""}
                            {editPermission && (
                              <img
                                src={EditIcon}
                                alt='editIcon'
                                height={17}
                                width={17}
                                style={editIconCSS}
                                onClick={(e) => editItem("ModelTitle")}
                              />
                            )}
                          </span>
                        )}
                      </td>
                    </tr>
                  </Col>
                </Row>
              </div>
            </tbody>
          </Table>
          <div className='p-2'>
            <p className='mb-1 ps-3'>
              <strong className='tr-color'> Description: </strong>
            </p>
            {showEditTask && editPermission && showEditTask.editDescription ? (
              <div className='mb-4 editor-section ps-4'>
                <WysiwygEditor
                  editorRef={descriptionRef}
                  handleReturn={(e, des) => {
                    if (e.keyCode === 13) {
                      setShowEditTask((prevState) => {
                        return { ...prevState, editDescription: false }
                      })
                      updateTask("ModelDescription", des)
                    }
                  }}
                  value={updatedData.ModelDescription}
                  onChange={(val) =>
                    setUpdatedData((prevState) => {
                      return { ...prevState, ModelDescription: val }
                    })
                  }
                />
                <div className='text-end'>
                  <Button
                    size='sm'
                    sx={{ display: "flex", alignItems: "center" }}
                    variant='secondary'
                    className='titleupdate me-1 ps-3 pe-3 border-none'
                    onClick={(e) => {
                      updateTask("ModelDescription", updatedData.ModelDescription)
                    }}
                  >
                    {loading ? (
                      <BarLoader
                        color='#808080'
                        className='bar-loader'
                        size={14}
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      <></>
                    )}
                    <CheckIcon />
                  </Button>
                  <Button
                    size='sm'
                    variant='secondary'
                    className='titlecancel ps-3 pe-3  border-none'
                    onClick={(e) =>
                      setShowEditTask((prevState) => {
                        return { ...prevState, editDescription: false }
                      })
                    }
                  >
                    <CloseIcon />
                  </Button>
                </div>
              </div>
            ) : (
              <Row
                style={{ width: "100%" }}
                onClick={(e) => editPermission && editItem("ModelDescription")}
                className='edit-content description-edit'
                dangerouslySetInnerHTML={{ __html: updatedData?.ModelDescription }}
              />
            )}
          </div>
        </div>
      </ProjectModelEditView>
    </>
  )
}

export default EditProjectModel
