import React from "react";
import { Modal, Button } from "react-bootstrap";

export const BacklogsRefineModalBox = ({
  heading,
  show,
  hidePopup,
  confirmationResponse,
}) => {
  const reponseHandler = (response) => {
    confirmationResponse(response);
    hidePopup(false);
  };
  return (
    <Modal
        show={show}
        onHide={(e) => hidePopup(false)}
        size="md"
        keyboard={false}
        className="backlogs_refine_modal"
    >
        <Modal.Header className='border-0 backlogs_modal_close' closeButton>
        </Modal.Header>
        <Modal.Body className="text-center backlogs_modal">
            <p className="backlogs_modal_head">{heading}</p>
            <div className="btn_backlogs_sec">
                <Button
                    className="me-2 btn btn_backlogs_orange"
                    onClick={() => hidePopup(false)}
                >
                    Cancel
                </Button>
                <Button
                    className=" btn btn_backlogs_orange"
                    onClick={(e) => reponseHandler(true)}
                >
                    Yes
                </Button>
            </div>
        </Modal.Body>
    </Modal>
  );
};
