import React, { useState, useEffect, useRef } from "react"
import { Row } from "react-bootstrap"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import IconButton from "@mui/material/IconButton"
import Iframe from "react-iframe"
import FormWrap from "./styles"

export const FileUploader = ({
  value,
  name,
  FileArr,
  FileName,
  submitted,
  resetField,
  setInputFileFieldReset,
  setFileSize
}) => {
  const [selectedFile, setSelectedFile] = useState("")
  const [FileUrl, setlFileUrl] = useState([])
  const [dragActive, setDragActive] = useState(false)
  const [fileType, setFileType] = useState([])
  const [fname, setFName] = useState([])
  const inputRef = useRef(null)

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(true)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    setSelectedFile((prevfiles) => [...prevfiles, e.dataTransfer.files[0]])
    setFName((prevfiles) => [...prevfiles, e.dataTransfer.files[0].name])
    setFileType((prevfiles) => [...prevfiles, e.dataTransfer.files[0].name.split(".").pop()])
    setFileSize((prevfiles) => [...prevfiles, e.dataTransfer.files[0].size])
  }

  const handleChange = (e) => {
    setSelectedFile((prevfiles) => [...prevfiles, e.target.files[0]])
    setFName((prevfiles) => [...prevfiles, e.target.files[0].name])
    setFileType((prevfiles) => [...prevfiles, e.target.files[0].name.split(".").pop()])
    setFileSize((prevSizes) => [...prevSizes, e.target.files[0].size])
  }

  const handleButtonClick = () => {
    inputRef.current.click()
  }

  useEffect(() => {
    if (selectedFile.length < 1) return
    FileArr(selectedFile)
    FileName(fname)
    const newImageURL = []
    selectedFile.forEach((selectedFile) => newImageURL.push(URL.createObjectURL(selectedFile)))
    setlFileUrl(newImageURL)
  }, [selectedFile])

  var FileArray = []
  for (var i = 0; i < FileUrl.length; i++) FileArray[i] = [fileType[i], FileUrl[i], fname[i]]

  const handleRemoveFile = (index) => {
    setlFileUrl([...FileUrl.slice(0, index), ...FileUrl.slice(index + 1, FileUrl.length)])
    setFName([...fname.slice(0, index), ...fname.slice(index + 1, fname.length)])
    setFileType([...fileType.slice(0, index), ...fileType.slice(index + 1, fileType.length)])
    setSelectedFile([
      ...selectedFile.slice(0, index),
      ...selectedFile.slice(index + 1, selectedFile.length)
    ])
    const result = FileArray.map((element) => element.slice(index))
  }

  useEffect(() => {
    const initial = []
    setFileType(initial)
    setlFileUrl(initial)
    setFName(initial)
    setSelectedFile(initial)
  }, [submitted])

  useEffect(() => {
    if (resetField === true) {
      const initial = []
      setFileType(initial)
      setlFileUrl(initial)
      setFName(initial)
      setSelectedFile(initial)
      setInputFileFieldReset(false)
    }
  }, [resetField])

  return (
    <>
      <FormWrap>
        <>
          <div className='mb-5'>
            <form
              id='form-file-upload'
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
              <input
                className='mb-5'
                ref={inputRef}
                type='file'
                name={name}
                value={value}
                id='input-file-upload'
                onChange={(e) => {
                  handleChange(e)
                }}
                multiple
                accept='image/*,video/*,.pdf,.mp4,.webm'
              />
              <label id='label-file-upload' className={dragActive ? "drag-active" : ""}>
                <div>
                  <IconButton className='upload-button' onClick={handleButtonClick}>
                    <UploadFileIcon fontSize='large' />
                  </IconButton>
                  <p className='upload-text'>Drag and drop file here</p>
                  <p className='upload-format'>
                    Supported formats (PNG, JPEG, JPG, Mp4, webm, Pdf)
                  </p>
                </div>
              </label>
              {dragActive && (
                <div
                  id='drag-file-element'
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </form>
          </div>
          <Row>
            {selectedFile && FileArray ? (
              <span>
                {FileArray.slice(0, 10).map(([fileType, fileUrl, fileName], index) => {
                  return (
                    <div className='imgRow mb-5 mt-5' key={index}>
                      {fileType === "mp4" || fileType === "webm" ? (
                        <Iframe url={fileUrl} height='112px' width='200px' allowFullScreen />
                      ) : fileType === "pdf" || fileType === "PDF" ? (
                        <img
                          src='https://img.icons8.com/color/96/000000/pdf-2--v1.png'
                          width='113px'
                          height='110px'
                          alt='pdf'
                        />
                      ) : fileType === "docx" ||
                        fileType === "doc" ||
                        fileType === "DOCX" ||
                        fileType === "DOC" ? (
                        <img
                          src='https://img.icons8.com/color/144/000000/google-docs--v1.png'
                          width='113px'
                          height='110px'
                          alt='Document'
                        />
                      ) : fileType === "csv" ||
                        fileType === "CSV" ||
                        fileType === "xls" ||
                        fileType === "xlsx" ||
                        fileType === "XLS" ||
                        fileType === "XLSX" ? (
                        <img
                          src='https://img.icons8.com/color/144/000000/microsoft-excel-2019--v1.png'
                          width='113px'
                          height='110px'
                          alt='Csv file'
                        />
                      ) : (
                        <img src={fileUrl} width='113px' height='110px' alt='Image' />
                      )}
                      <p className='filename'>{fileName}</p>
                      <IconButton className='removebtn' onClick={() => handleRemoveFile(index)}>
                        <HighlightOffIcon fontSize='small' />
                      </IconButton>
                    </div>
                  )
                })}
              </span>
            ) : null}
          </Row>
        </>
      </FormWrap>
    </>
  )
}
