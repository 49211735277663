import React, { useState } from "react";
import { style } from "@mui/system";
import { useSelector } from "react-redux";

export const ProjectDashboardColumns = ({
  trackingSortings,
  setTrackingSorting,
}) => {
    let allMydashBoardHeader = useSelector((state) => state.Tracking.allMydashBoardHeader);
  const applyTrackingNumberSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "trackingid",
        projectNameSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, trackingNumberSorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, trackingNumberSorting: "down" };
      });
  };

  const applyProjectNumberSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "projectname",
        trackingNumberSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, projectNameSorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, projectNameSorting: "down" };
      });
  };

  const applyReportedBySorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "reportedby",
        trackingNumberSorting: "",
        projectNameSorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, reportedBySorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, reportedBySorting: "down" };
      });
  };

  const applyPrioritySorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "reporteddate",
        trackingNumberSorting: "",
        projectNameSorting: "",
        reportedBySorting: "",
        assignedToSorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, prioritySorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, prioritySorting: "down" };
      });
  };

  const applyAssignToSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "priority",
        trackingNumberSorting: "",
        projectNameSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        statusSorting: "",
      };
    });
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, assignedToSorting: "up" };
      });
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, assignedToSorting: "down" };
      });
    };
    
    let MydashBoardcolumnValues = [];

    // for (let i = 0; i < allMydashBoardHeader.length; i++) {
    //     MydashBoardcolumnValues.push(allMydashBoardHeader[i].column1)
    //     MydashBoardcolumnValues.push(allMydashBoardHeader[i].column2)
    //     MydashBoardcolumnValues.push(allMydashBoardHeader[i].column3)
    //     MydashBoardcolumnValues.push(allMydashBoardHeader[i].column4)
    //     MydashBoardcolumnValues.push(allMydashBoardHeader[i].column5)
    //     MydashBoardcolumnValues.push(allMydashBoardHeader[i].column6)
    // }

    return (
        <>
            {MydashBoardcolumnValues.map((item, index) => {
                return (
                    <th key={index} className="text-center">
                        {item}
                    </th>
                )
            })}
        </>
    );
};