import Styled from "styled-components";

const SettingsWrap = Styled.div`
    .Previewimg{
        border: solid $border 3px;
        border-radius: 10px;
    }

    .table tbody tr td {
    color: ${({ theme }) => theme["themeColor"]};
    opacity: 0.9;
    }
    p{
    text-align: justify;
    text-justify: inter-word;
    }
    .card{
        box-shadow: none;
    }
    .nav-item {
        border-bottom: 1px solid ${(props) => props.darkmode ? "white" : "black"};
    }
    .layout-radio
    {
        margin-right: 10px;
    }
    .nav-tabs .nav-link.active {
        border-bottom: 1px solid ${(props) => props.darkmode ? "transparent" : "#F2F5F5"} ;
    }

    @media (max-width: 565px) {
    .table tbody tr td {
        font-size: 13px;
    }  
    p{
      font-size: 13px;
    }
}
`;

export default SettingsWrap;
