import styled from "styled-components";

export const TeamsWrapper = styled.div`
  .plus-button {
    cursor: pointer;
    background-color: #4eb6ee;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    font-weight: 100;
    padding: 0px 6px;
  }

  .team-name-circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    font-weight: 100;
    padding: 8px 12px;
  }

  .table {
    border-color: #5c5c5c;
  }

  th {
    background-color: #5c5c5c;
  }

  td {
    border-bottom: 1px solid #5c5c5c !important;
  }
`;
