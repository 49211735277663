import Styled from "styled-components";

const TrackingFormWrap = Styled.div`
.loginSubmitBtn{
    width: 155px;
}

.loginCancelBtn{
    width: 150px !important;
    height: 44px ! important;
    font-size : 20px ! important;
}
.loginSubmitBtn{
    width: 150px !important;
    height: 44px ! important;
    font-size : 20px ! important;
}

.closeicon{
    display: inline-flex;
}

@media (min-width:840px){
    .loginformbody{
        padding: 10px 15px 10px 15px !important;
    }
    .title{
        padding: 0px 10px 0px 10px !important;
    }
}

@media (min-width:411px)
{
    .loginSubmitBtn{width: 155px;
        margin-left: 20px;
    }
    .cancelbtn{
        margin-left: 20px !important;
    }
}

`;

export default TrackingFormWrap;