import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import LoginWrap from "./style";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";
import { Link } from "react-router-dom";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import "react-phone-number-input/style.css";

const CreateAccountWrap = styled.section`
  margin-top: 1.5rem;
  height: auto;
  color: white;
  background-color: #151d25;

  .form-label {
    color: white;
    float: left;
  }

  .form-control {
    border-radius: 5px;
    color: black;
  }

  .form-control.is-invalid:focus,
  .form-control.is-invalid:focus-within,
  .form-control.PhoneInput.is-invalid:focus,
  .form-control.PhoneInput.is-invalid:focus-within {
    -webkit-box-shadow: 0px 0px 0px 3px hsla(00, 70%, 70%, 0.7);
    -moz-box-shadow: 0px 0px 0px 3px hsla(00, 70%, 70%, 0.7);
    box-shadow: 0px 0px 0px 3px hsla(00, 70%, 70%, 0.7);
    transition: hsla(00, 70%, 70%, 0.7) 0.15s ease-in-out;
  }

  .invalid-feedback {
    color: #5fccf2 !important;
  }

  input {
    ::placeholder {
      color: hsla(0, 0%, 50%, 1);
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black;
  }

  .form-control.PhoneInput input {
    /* background: #2E353C; */
    background: white;
    color: black;
    border: none;
    outline: none;
    box-shadow: none;
    ::placeholder {
      color: hsla(0, 0%, 50%, 1);
    }
  }

  select > option {
    color: black;
  }

  .password-flex {
    flex-basis: 50%;
  }

  .personal-info {
    font-weight: 600;
    font-size: 2rem;
    color: #5fccf2;
    padding-bottom: 10px;
    background-color: #151d25;
    text-align: start;
    padding-left: 22px;
  }

  .conditions {
    color: #5fccf2;
  }

  @media screen and (max-width: 576px) {
    .form-col {
      margin-top: 1rem;
      padding-left: 1em;
      padding-right: 1em;
    }
  }
`;

const RegisterAccount = ({
  register,
  errors,
  agreedToTerms,
  phonenumber,
  phonenumberError,
  updateFields,
}) => {
  // const normalizeCardNUmber = (value) => {
  //   return value.replace(/\s/g, "").replace(/\D+/g, '').match(/.{1,4}/g)?.join(" ").substr(0, 19) || ""
  // }

  // const normalizeCVCNumber = (value) => {
  //   return value.replace(/\s/g, "").replace(/\D+/g, "").substr(0, 4) || ""
  // }

  // const normalizeExpiryDate = (value) => {
  //   return value.replace(/\s/g, "").replace(/\D+/g, '').match(/.{1,2}/g)?.join("/").substr(0, 5) || ""
  // }

  return (
    <LoginWrap>
      <div>
        <CreateAccountWrap>
          {/* <div className='personal-info'>
            Personal Information
          </div> */}
          {/* <Form noValidate onSubmit={handleSubmit(submitForm)}> */}
          <Row className="Form-width">
            <Col lg={6} className="form-col">
              <Form.Group className="mb-3" controlId="companyname">
                <Form.Label>Company Name*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company name"
                  {...register("companyname")}
                  isInvalid={errors?.companyname?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.companyname?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6} className="form-col">
              <Form.Group className="mb-3" controlId="personname">
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter full name"
                  {...register("personname")}
                  isInvalid={errors.personname?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.personname?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6} className="form-col">
              <Form.Group className="mb-3" controlId="emailId">
                <Form.Label>Email Address*</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email address"
                  {...register("emailId")}
                  isInvalid={errors?.emailId?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.emailId?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6} className="form-col">
              <div className="d-flex">
                <Form.Label className="me-auto">Password*</Form.Label>
              </div>

              <div className="d-flex align-items-baseline gap-2">
                <Form.Group
                  className="mb-3 align-self-baseline password-flex"
                  controlId="password"
                >
                  <Form.Control
                    className="mb-2"
                    type="password"
                    placeholder="Enter password"
                    {...register("password")}
                    isInvalid={errors?.password?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.password?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  className="mb-3 align-self-baseline password-flex"
                  controlId="confirm_password"
                >
                  <Form.Control
                    className="mb-2"
                    type="password"
                    placeholder="Confirm password"
                    {...register("confirmPassword")}
                    isInvalid={errors?.confirmPassword?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.confirmPassword?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Col>

            <Col lg={6} className="form-col">
              <div className="d-flex">
                <Form.Label className="me-auto">Mobile Number</Form.Label>
              </div>

              {/* <Form.Group className="mb-3" controlId="phonenumber"> */}
              <Form.Control
                as={PhoneInput}
                defaultCountry="AU"
                autocomplete="off"
                type="tel"
                className="mb-2 d-flex align-items-center justify-content-center"
                maxLength={16}
                placeholder="Enter mobile number"
                value={phonenumber}
                onChange={(phone) => {
                  // updateFields({ phonenumber: phone });
                  // if (phonenumber && phonenumber.length < 10) {
                  //   updateFields({
                  //     phonenumberError: "Enter valid phone number",
                  //   });
                  // } else {
                  //   updateFields({ phonenumberError: "" });
                  // }
                }}
                // pattern="[0-9]{17}"
                // isInvalid={phonenumberError}
              />
              {/* <Form.Control.Feedback type="invalid">
                {phonenumberError}
              </Form.Control.Feedback> */}
              {/* </Form.Group> */}
            </Col>
            {/* {
              plan !== "Free" ?
                <>
                  <Col lg={6} className="form-col">
                    <Form.Group className="mb-3" controlId="accountNumber">
                      <Form.Label>Account Number*</Form.Label>
                      <Form.Control
                        type="text"
                        // maxLength={2}
                        pattern="[0-9]"
                        placeholder="Enter Card Account Number"
                        {...register("accountNumber")}
                        onChange={(e) => {
                          const { value } = e.target
                          e.target.value = normalizeCardNUmber(value)
                        }}
                        isInvalid={errors?.accountNumber?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.accountNumber?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={6} className="form-col">
                    <Form.Group className="mb-3" controlId="expiryDate">
                      <Form.Label>Expiry Date*</Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={8}
                        // pattern="[0-9]{2}"
                        placeholder="DD/YY"
                        {...register("expiryDate")}
                        onChange={(e) => {
                          const { value } = e.target
                          e.target.value = normalizeExpiryDate(value)
                        }}
                        isInvalid={errors?.expiryDate?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.expiryDate?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} className="form-col">
                    <Form.Group className="mb-3" controlId="cvcNumber">
                      <Form.Label>CVC*</Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={3}
                        // pattern="[0-9]{2}"
                        placeholder="Enter Card CVC Number"
                        {...register("cvc")}
                        onChange={(e) => {
                          const { value } = e.target
                          e.target.value = normalizeCVCNumber(value)
                        }}
                        isInvalid={errors?.cvc?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.cvc?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </>
                :
                null
            } */}

            <FormGroup sx={{ marginLeft: "-20px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreedToTerms}
                    checkedIcon={
                      <CheckBoxOutlinedIcon sx={{ color: "#5FCCF2" }} />
                    }
                    onChange={(e) => {
                      updateFields({ agreedToTerms: e.target.checked });
                    }}
                    size="medium"
                    sx={{ color: "#5FCCF2", marginLeft: "22px" }}
                  />
                }
                label={
                  <Typography sx={{ fontSize: "18px" }}>
                    I understand{" "}
                    <Link
                      to="/privacy-policy"
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      <span className="conditions">Privacy Policy</span>
                    </Link>{" "}
                    and{" "}
                    <Link
                      to="/terms-&-conditions"
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      <span className="conditions">Terms of Use</span>
                    </Link>
                  </Typography>
                }
              />
            </FormGroup>
          </Row>

          {/* <div className="pb-4">
              <Button
                style={{
                  backgroundColor: "#4EB6EE",
                  color: "white",
                  border: "none",
                }}
                className="px-5"
                type="submit"
                disabled={!isAllDirty()}
              >
                {postLoading ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  "Create"
                )}
              </Button>
            </div> */}
        </CreateAccountWrap>
      </div>
    </LoginWrap>
  );
};

export default RegisterAccount;
